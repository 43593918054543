// DISABLED: At some point this needs to be fixed by swapping the anchors for buttons
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ConstLink } from "../ConstLink";
import { useUserDeets, useUserData } from "CustomHooks";

import logo from "../Images/logo4.png";

const Header = () => {
  const navigate = useNavigate();
  const { clearUserData } = useUserData();
  const { closeHeaderMenu, toggleMenu, isActive } = useContext(ConstLink);
  const userDeets = useUserDeets();
  const username = userDeets.username;
  const isLoggedIn = userDeets.loggedIn;

  let isDev = false;
  if ( userDeets.roles && userDeets.roles.includes("Developer") ) {
    isDev = true
  }


  const menuRef = useRef(null);
  // const [isActive, setIsActive] = useState(false);

  // const [isMenuCollapsed, setIsMenuCollapsed] = useState(true);
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [activeSubAccordion, setActiveSubAccordion] = useState(null);
  // const activeElementRef = useRef(null);
  const reportsSubMenuRef = useRef(null);
  const setupSubMenuRef = useRef(null);
  const toolsSubMenuRef = useRef(null);
  const uploadsSubMenuRef = useRef(null);
  // const defaultColor = document.documentElement.style.getPropertyValue('--dropdownBackground');
  const [defaultColor, setDefaultColor] = useState("#595959");
  const [newColor, _setNewColor] = useState("#595959");
  const [buttonColors, setButtonColors] = useState([
    defaultColor,
    defaultColor,
    defaultColor,
    defaultColor,
  ]);
  const defaultColors = [
    defaultColor,
    defaultColor,
    defaultColor,
    defaultColor,
  ];

  useEffect(() => {
    // console.log(defaultColor);
    setButtonColors([defaultColor, defaultColor, defaultColor, defaultColor]);
  }, [defaultColor]);

  useEffect(() => {
    const colorCorrection = () => {
      if (window.innerWidth > 992) {
        setDefaultColor("#393939");
      }
    };

    // Run the colorCorrection function only when the viewport size is greater than 992 pixels
    if (window.innerWidth > 992) {
      colorCorrection();
    }

    // Add an event listener to handle changes in viewport size
    const handleResize = () => {
      if (window.innerWidth > 992) {
        colorCorrection();
      }
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const changeColor = (index) => {
    if (window.innerWidth > 992) {
      const updatedColors = [...buttonColors];
      updatedColors[index] = newColor;
      setButtonColors(updatedColors);
    }
  };

  const changeColorBack = (index) => {
    if (window.innerWidth > 992) {
      const updatedColors = [...buttonColors];
      updatedColors[index] = defaultColor;
      setButtonColors(updatedColors);
    }
  };

  const handleLogout = () => {
    clearUserData();
    navigate("/login");
  };

  const toggleHeaderMenu = () => {
    toggleMenu();
  };

  const navigateTo = (address) => {
    if (window.innerWidth < 900) {
      closeHeaderMenu(); // Close the navigation menu by calling the toggleMenu function
    }
    navigate(address);
    setActiveAccordion(null);
    setActiveSubAccordion(null);
  };

  const handleAccordionToggle = (index) => {
    if (window.innerWidth > 992) {
      setActiveAccordion((activeAccordion) =>
        activeAccordion === index ? null : index
      );
    } else {
      if (activeAccordion === index) {
        setActiveAccordion(null);
      } else {
        setActiveAccordion(index);
      }
    }
  };

  const handleAccordionOpen = (index) => {
    setActiveAccordion(index);
  };

  const handleAccordionClose = () => {
    setActiveAccordion(null);
  };

  const handleSubAccordionToggle = (index) => {
    if (window.innerWidth > 992) {
      setActiveSubAccordion((activeSubAccordion) =>
        activeSubAccordion === index ? null : index
      );
    } else {
      if (activeSubAccordion === null) {
        setActiveSubAccordion(index);
      } else {
        setActiveSubAccordion(null);
        changeColorBack(index);
      }
    }
  };

  const handleSubAccordionOpen = (index) => {
    setActiveSubAccordion(index);
    // const delay = 500; // Delay in milliseconds
    // setTimeout(() => {
    //     setActiveSubAccordion(index);
    // }, delay);
  };

  const handleSubAccordionClose = () => {
    setActiveSubAccordion(null);
  };

  const handleMenuItemClick = () => {
    const checkbox1 = document.getElementById("checkbox1");
    const checkbox2 = document.getElementById("checkbox2");
    const checkbox3 = document.getElementById("checkbox3");

    if (checkbox1) {
      checkbox1.checked = false;
    }

    if (checkbox2) {
      checkbox2.checked = false;
    }

    if (checkbox3) {
      checkbox3.checked = false;
    }
  };

  // This tracks the pixel under the mouse and closes the sub menu
  const handleMouseMove = (event, elementRef) => {
    const { clientX } = event;
    // const { clientY } = event;

    const activeElement = elementRef.current;
    const { left, right } = activeElement.getBoundingClientRect();

    const offsetLeftPixel = left - 300;
    const offsetRightPixel = right - 300;

    if (clientX <= offsetLeftPixel || offsetRightPixel <= clientX) {
      // Trigger the onMouseLeave function
      handleSubAccordionClose(0);
    }
  };


  // Clsoe the menus when the mouse leaves the window
  useEffect(() => {
    const handleMouseOut = (event) => {
      if (!event.relatedTarget && !event.toElement) {
        handleAccordionClose();
        handleSubAccordionClose();
      }
    };

    window.addEventListener("mouseout", handleMouseOut);

    return () => {
      window.removeEventListener("mouseout", handleMouseOut);
    };
  }, []);

  const handleMenuButtonMouseLeave = (event) => {
    let toElement = event.relatedTarget;
  
    if (!toElement && event.nativeEvent) {
      toElement = event.nativeEvent.relatedTarget || event.nativeEvent.toElement;
    }
  
    if (!toElement || !(toElement instanceof Element)) {
      setActiveAccordion(null);
      return;
    }
  
    // Check if the mouse is moving into the submenu
    if (toElement.closest('.dropdown-menu')) {
      return;
    }
  
    setActiveAccordion(null);
  };
  

  return (
    <div className="header-overflow">
      <nav>
        <div className="header-container">
          <section className="menu menu--circle">
            <input type="checkbox" id="menu__active" />
            <label htmlFor="menu__active" className="menu__active">
              <div className="menu__toggle">
                <div className="icon">
                  <img
                    src={logo}
                    style={{ width: 80, zIndex: 9999 }}
                    alt="roo"
                  />
                </div>
              </div>
              {/* eslint-disable-next-line react/jsx-no-duplicate-props */}
              <input
                // id="checkbox1"
                type="radio"
                name="arrow--up"
                id="degree--up-0"
                hidden={true}
              />
              {/* eslint-disable-next-line react/jsx-no-duplicate-props */}
              <input
                // id="checkbox2"
                type="radio"
                name="arrow--up"
                id="degree--up-1"
                hidden={true}
              />
              {/* eslint-disable-next-line react/jsx-no-duplicate-props */}
              <input
                // id="checkbox3"
                type="radio"
                name="arrow--up"
                id="degree--up-2"
                hidden={true}
              />
              <div className="menu__listings" ref={menuRef}>
                <ul className="circle">
                  <li>
                    <div className="placeholder">
                      <div className="upside">
                        <a
                          draggable={false}
                          onClick={() => navigateTo("/Administration/Setup")}
                          className="button"
                        >
                          <i className="fa fa-cog"></i>
                        </a>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="placeholder">
                      <div className="upside">
                        <a
                          draggable={false}
                          onClick={() => {
                            navigateTo("/");
                            handleMenuItemClick();
                          }}
                          className="button"
                        >
                          <i className="fa fa-home"></i>
                        </a>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="placeholder">
                      <div className="upside">
                        <a draggable={false} className="button">
                          <i className="fa fa-user"></i>
                        </a>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="placeholder">
                      <div className="upside">
                        <a draggable={false} className="button">
                          <i className="fa fa-commenting"></i>
                        </a>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="placeholder">
                      <div className="upside">
                        <a draggable={false} className="button">
                          <i className="fa fa-folder-open"></i>
                        </a>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="placeholder">
                      <div className="upside">
                        <a draggable={false} className="button">
                          <i className="fa fa-envelope"></i>
                        </a>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="placeholder">
                      <div className="upside">
                        <a draggable={false} className="button">
                          <i className="fa fa-calendar"></i>
                        </a>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="placeholder">
                      <div className="upside">
                        <a draggable={false} className="button">
                          <i className="fa fa-cloud"></i>
                        </a>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="placeholder">
                      <div className="upside">
                        <a draggable={false} className="button">
                          <i className="fa fa-id-card"></i>
                        </a>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="placeholder">
                      <div className="upside">
                        <a
                          draggable={false}
                          onClick={() => navigateTo("/history")}
                          className="button"
                        >
                          <i className="fa fa-history"></i>
                        </a>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="menu__arrow menu__arrow--top">
                <ul>
                  <li>
                    <label htmlFor="degree--up-0">
                      <div className="arrow"></div>
                    </label>
                    <label htmlFor="degree--up-1">
                      <div className="arrow"></div>
                    </label>
                    <label htmlFor="degree--up-2">
                      <div className="arrow"></div>
                    </label>
                  </li>
                </ul>
              </div>
            </label>
          </section>

          <div className="navbar-container">
            {isLoggedIn && (
              <nav className="navbar navbar-expand-lg">
                <button
                  className="navbar-toggler"
                  type="button"
                  style={{ outline: "none", border: "none" }}
                  onClick={toggleHeaderMenu}
                >
                  <span style={{ color: "white" }}>MENU</span>
                </button>

                <div
                  className={`collapse navbar-collapse ${
                    isActive ? "show" : ""
                  }`}
                >
                  <ul className="navbar-nav mb-2 mb-lg-0" style={{}}>
                    <li>
                      <button className="customSpacer"></button>
                    </li>

                    <li>
                      <button
                        className={`customToggle ${
                          activeAccordion === 0 ? "active" : ""
                        }`}
                        onMouseOver={() => handleAccordionOpen(0)}
                        onMouseLeave={(event) => handleMenuButtonMouseLeave(event, 0)}
                        onClick={() => navigate("/people")}
                      >
                        PEOPLE
                      </button>

                      <ul
                        className={`dropdown-menu ${
                          activeAccordion === 0 ? "show" : ""
                        }`}
                        onMouseLeave={() => handleAccordionClose(0)}
                      >
                        <li>
                          <button
                            className="customDropdown"
                            onClick={() => navigateTo("/people")}
                          >
                            CANDIDATES
                          </button>
                        </li>
                        <li>
                          <button
                            className="customDropdown"
                            onClick={() => navigateTo("/people")}
                          >
                            TRAINERS
                          </button>
                        </li>
                        <li>
                          <button
                            className="customDropdown"
                            onClick={() => navigateTo("/people")}
                          >
                            BDMS
                          </button>
                        </li>
                        <li>
                          <button
                            className="customDropdown"
                            onClick={() => navigateTo("/register-interest")}
                          >
                            REGISTER INTEREST
                          </button>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <button
                        className={`customToggle ${
                          activeAccordion === 1 ? "active" : ""
                        }`}
                        onMouseOver={() => handleAccordionOpen(1)}
                        onMouseLeave={(event) => handleMenuButtonMouseLeave(event, 0)}
                        onClick={() => navigateTo("/businesses")}
                      >
                        BUSINESSES
                      </button>

                      <ul
                        className={`dropdown-menu ${
                          activeAccordion === 1 ? "show" : ""
                        }`}
                        onMouseLeave={() => handleAccordionClose(0)}
                      >
                        <li>
                          <button
                            className="customDropdown"
                            onClick={() => navigateTo("/businesses")}
                          >
                            BUSINESSES
                          </button>
                        </li>
                        <li>
                          <button
                            className="customDropdown"
                            onClick={() =>
                              navigateTo("/businesses/venues-view")
                            }
                          >
                            VENUES
                          </button>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <button
                        className={`customToggle ${
                          activeAccordion === 3 ? "active" : ""
                        }`}
                        onMouseOver={() => handleAccordionOpen(3)}
                        onMouseLeave={(event) => handleMenuButtonMouseLeave(event, 0)}
                        onClick={() => navigate("/calendar")}
                      >
                        CALENDAR
                      </button>

                      <ul
                        className={`dropdown-menu ${
                          activeAccordion === 3 ? "show" : ""
                        }`}
                        onMouseLeave={() => handleAccordionClose(0)}
                      >
                        <li>
                          <button
                            className="customDropdown"
                            onClick={() => navigateTo("/calendar/my-classes")}
                          >
                            MY CLASSES
                          </button>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <button
                        className={`customToggle ${
                          activeAccordion === 2 ? "active" : ""
                        }`}
                        onMouseOver={() => handleAccordionOpen(2)}
                        onMouseLeave={(event) => handleMenuButtonMouseLeave(event, 0)}
                        onClick={() => navigate("/certificates-modules/modules")}
                      >
                        CERTS & MODULES
                      </button>

                      <ul
                        className={`dropdown-menu ${
                          activeAccordion === 2 ? "show" : ""
                        }`}
                        onMouseLeave={() => handleAccordionClose(0)}
                      >
                        <li>
                          <button
                            className="customDropdown"
                            onClick={() =>
                              navigateTo("/certificates-modules/certificates")
                            }
                          >
                            CERTIFICATES
                          </button>
                        </li>
                        <li>
                          <button
                            className="customDropdown"
                            onClick={() =>
                              navigateTo("/certificates-modules/modules")
                            }
                          >
                            MODULES
                          </button>
                        </li>
                        <li>
                          <button
                            className="customDropdown"
                            onClick={() =>
                              navigateTo(
                                "/certificates-modules/certificates-templates"
                              )
                            }
                          >
                            CERTIFICATE TEMPLATES
                          </button>
                        </li>
                        {/*<li><button className="customDropdown" onClick={() => navigateTo('/certificates-modules/certificate-create')}>CERT C</button></li>*/}
                        <li>
                          <button
                            className="customDropdown"
                            onClick={() =>
                              navigateTo(
                                "/certificates-modules/module-visibility-current"
                              )
                            }
                          >
                            CURRENT MODULE VISIBILITY
                          </button>
                        </li>
                        <li>
                          <button
                            className="customDropdown"
                            onClick={() =>
                              navigateTo(
                                "/certificates-modules/module-visibility-old"
                              )
                            }
                          >
                            OLD MODULE VISIBILITY
                          </button>
                        </li>
                        <li>
                          <button
                            className="customDropdown"
                            onClick={() =>
                              navigateTo("/certificates-modules/module-billing")
                            }
                          >
                            MODULE BILLING
                          </button>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <button
                        className={`customToggle ${
                          activeAccordion === 4 ? "active" : ""
                        }`}
                        onMouseOver={() => handleAccordionOpen(4)}
                        onMouseLeave={(event) => handleMenuButtonMouseLeave(event, 0)}
                        onClick={() => navigate('/documentation')}
                      >
                        DOCUMENTATION
                      </button>

                      <ul
                        className={`dropdown-menu ${
                          activeAccordion === 4 ? "show" : ""
                        }`}
                        onMouseLeave={() => handleAccordionClose(0)}
                      >
                        <li>
                          <button
                            className="customDropdown"
                            onClick={() =>
                              navigateTo("/documentation/document-repository")
                            }
                          >
                            REPOSITORY
                          </button>
                        </li>
                        <li>
                          <button
                            className="customDropdown"
                            onClick={() =>
                              navigateTo("/documentation/policies-procedures")
                            }
                          >
                            POLICIES & PROCEDURES
                          </button>
                        </li>
                        <li>
                          <button
                            className="customDropdown"
                            onClick={() =>
                              navigateTo("/documentation/marketing")
                            }
                          >
                            MARKETING
                          </button>
                        </li>
                        <li>
                          <button
                            className="customDropdown"
                            onClick={() =>
                              navigateTo("/documentation/compliance-auditing")
                            }
                          >
                            COMPLIANCE & AUDITING
                          </button>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <button
                        className={`customToggle ${
                          activeAccordion === 6 ? "active" : ""
                        }`}
                        onMouseOver={() => handleAccordionOpen(6)}
                        onMouseLeave={(event) => handleMenuButtonMouseLeave(event, 0)}
                        onClick={() => handleAccordionToggle(6)}
                      >
                        TASKS
                      </button>

                      <ul
                        className={`dropdown-menu ${
                          activeAccordion === 6 ? "show" : ""
                        }`}
                        onMouseLeave={() => handleAccordionClose(0)}
                      >
                        <li>
                          <button
                            className="customDropdown"
                            onClick={() => navigateTo("/tasks/new-task")}
                          >
                            NEW TASK
                          </button>
                        </li>
                        <li>
                          <button
                            className="customDropdown"
                            onClick={() =>
                              navigateTo("/tasks/unassigned-tasks")
                            }
                          >
                            UNASSIGNED TASKS
                          </button>
                        </li>
                        <li>
                          <button
                            className="customDropdown"
                            onClick={() => navigateTo("/tasks/marking")}
                          >
                            MARKING
                          </button>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <button
                        className={`customToggle ${
                          activeAccordion === 5 ? "active" : ""
                        }`}
                        onMouseOver={() => handleAccordionOpen(5)}
                        onMouseLeave={(event) => handleMenuButtonMouseLeave(event, 0)}
                        onClick={() => handleAccordionToggle(5)}
                        // style={{marginRight: '250px'}}
                      >
                        ADMIN
                      </button>

                      <ul
                        className={`dropdown-menu ${
                          activeAccordion === 5 ? "show" : ""
                        }`}
                        onMouseLeave={() => {
                          handleAccordionClose(0);
                          handleSubAccordionClose(0);
                        }}
                      >

                        <li>
                          <button
                            className={`customDropdown ${
                              activeSubAccordion === 0 ? "active" : ""
                            }`}
                            onMouseOver={() => {
                              handleSubAccordionOpen(0);
                              setButtonColors(defaultColors);
                            }}
                            onMouseLeave={() => changeColor(0)}
                            style={{ backgroundColor: buttonColors[0] }}
                            onClick={() => handleSubAccordionToggle(0)}
                          >
                            REPORTS
                          </button>

                          <ul
                            ref={reportsSubMenuRef}
                            className={`dropdown-menu ${
                              activeSubAccordion === 0 ? "show" : ""
                            }`}
                            onMouseMove={(event) =>
                              handleMouseMove(event, reportsSubMenuRef)
                            }
                            onMouseLeave={() => changeColorBack(0)}
                          >
                            <li>
                              <button
                                className="customSubDropdown"
                                onClick={() =>
                                  navigateTo(
                                    "/administration/reports/avetmiss-report"
                                  )
                                }
                              >
                                AVETMISS REPORT
                              </button>
                            </li>
                            <li>
                              <button
                                className="customSubDropdown"
                                onClick={() =>
                                  navigateTo(
                                    "/administration/reports/billing-report"
                                  )
                                }
                              >
                                BILLING REPORT
                              </button>
                            </li>
                            <li>
                              <button
                                className="customSubDropdown"
                                onClick={() =>
                                  navigateTo(
                                    "/administration/reports/module-reports"
                                  )
                                }
                              >
                                MODULE REPORT
                              </button>
                            </li>
                            <li>
                              <button
                                className="customSubDropdown"
                                onClick={() =>
                                  navigateTo(
                                    "/administration/reports/white-card-report"
                                  )
                                }
                              >
                                WHITE CARD REPORT
                              </button>
                            </li>
                            <li>
                              <button
                                className="customSubDropdown"
                                onClick={() =>
                                  navigateTo(
                                    "/administration/reports/usi-report"
                                  )
                                }
                              >
                                USI REPORT
                              </button>
                            </li>
                            <li>
                              <button
                                className="customSubDropdown"
                                onClick={() =>
                                  navigateTo(
                                    "/administration/reports/view-funding-prices"
                                  )
                                }
                              >
                                VIEW FUNDING PRICES
                              </button>
                            </li>
                            <li>
                              <button
                                className="customSubDropdown"
                                onClick={() =>
                                  navigateTo(
                                    "/administration/reports/active-certificate-report"
                                  )
                                }
                              >
                                ACTIVE CERTIFICATE REPORT
                              </button>
                            </li>
                            <li>
                              <button
                                className="customSubDropdown"
                                onClick={() =>
                                  navigateTo(
                                    "/administration/reports/certificates-unassigned-report"
                                  )
                                }
                              >
                                CERTIFICATES UNASSIGNED REPORT
                              </button>
                            </li>
                            <li>
                              <button
                                className="customSubDropdown"
                                onClick={() =>
                                  navigateTo(
                                    "/administration/reports/backup-report"
                                  )
                                }
                              >
                                BACKUP REPORT
                              </button>
                            </li>
                            <li>
                              <button
                                className="customSubDropdown"
                                onClick={() =>
                                  navigateTo(
                                    "/administration/reports/email-report"
                                  )
                                }
                              >
                                EMAIL REPORT
                              </button>
                            </li>
                            <li>
                              <button
                                className="customSubDropdown"
                                onClick={() =>
                                  navigateTo(
                                    "/administration/reports/event-reports"
                                  )
                                }
                              >
                                EVENT REPORT
                              </button>
                            </li>
                            <li>
                              <button
                                className="customSubDropdown"
                                onClick={() =>
                                  navigateTo(
                                    "/administration/reports/invalid-usi-report"
                                  )
                                }
                              >
                                INVALID USI REPORT
                              </button>
                            </li>
                            <li>
                              <button
                                className="customSubDropdown"
                                onClick={() =>
                                  navigateTo(
                                    "/administration/reports/invoice-reminders"
                                  )
                                }
                              >
                                INVOICE REMINDERS
                              </button>
                            </li>
                            <li>
                              <button
                                className="customSubDropdown"
                                onClick={() =>
                                  navigateTo(
                                    "/administration/reports/lui-report"
                                  )
                                }
                              >
                                LUI REPORT
                              </button>
                            </li>
                            <li>
                              <button
                                className="customSubDropdown"
                                onClick={() =>
                                  navigateTo(
                                    "/administration/reports/module-kpis"
                                  )
                                }
                              >
                                MODULE KPIs
                              </button>
                            </li>
                            <li>
                              <button
                                className="customSubDropdown"
                                onClick={() =>
                                  navigateTo(
                                    "/administration/reports/outstanding-invoices-report"
                                  )
                                }
                              >
                                OUTSTANDING INVOICES REPORT
                              </button>
                            </li>
                            <li>
                              <button
                                className="customSubDropdown"
                                onClick={() =>
                                  navigateTo("/administration/reports/qi-ccos")
                                }
                              >
                                QI CCOS REPORT
                              </button>
                            </li>
                            <li>
                              <button
                                className="customSubDropdown"
                                onClick={() =>
                                  navigateTo(
                                    "/administration/reports/registered-interest-report"
                                  )
                                }
                              >
                                REGISTERED INTEREST REPORT
                              </button>
                            </li>
                            <li>
                              <button
                                className="customSubDropdown"
                                onClick={() =>
                                  navigateTo(
                                    "/administration/reports/report-settings"
                                  )
                                }
                              >
                                REPORT SETTINGS
                              </button>
                            </li>
                            <li>
                              <button
                                className="customSubDropdown"
                                onClick={() =>
                                  navigateTo(
                                    "/administration/reports/superseded-module-report"
                                  )
                                }
                              >
                                SUPERSEDED MODULE REPORT
                              </button>
                            </li>
                            <li>
                              <button
                                className="customSubDropdown"
                                onClick={() =>
                                  navigateTo(
                                    "/administration/reports/view-payment-data"
                                  )
                                }
                              >
                                VIEW PAYMENT DATA
                              </button>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <button
                            className={`customDropdown ${
                              activeSubAccordion === 1 ? "active" : ""
                            }`}
                            onMouseOver={() => {
                              handleSubAccordionOpen(1);
                              setButtonColors(defaultColors);
                            }}
                            onMouseLeave={() => changeColor(1)}
                            style={{ backgroundColor: buttonColors[1] }}
                            onClick={() => handleSubAccordionToggle(1)}
                          >
                            SETUP
                          </button>

                          <ul
                            ref={setupSubMenuRef}
                            className={`dropdown-menu ${
                              activeSubAccordion === 1 ? "show" : ""
                            }`}
                            onMouseMove={(event) =>
                              handleMouseMove(event, setupSubMenuRef)
                            }
                            onMouseLeave={() => changeColorBack(1)}
                          >
                            <li>
                              <button
                                className="customSubDropdown"
                                onClick={() =>
                                  navigateTo("/administration/setup/branding")
                                }
                              >
                                BRANDING
                              </button>
                            </li>
                            <li>
                              <button
                                className="customSubDropdown"
                                onClick={() =>
                                  navigateTo(
                                    "/administration/setup/create-funding-prices"
                                  )
                                }
                              >
                                CREATE FUNDING PRICES
                              </button>
                            </li>
                            <li>
                              <button
                                className="customSubDropdown"
                                onClick={() =>
                                  navigateTo(
                                    "/administration/setup/permissions"
                                  )
                                }
                              >
                                ROLE PERMISSIONS
                              </button>
                            </li>
                            <li>
                              <button
                                className="customSubDropdown"
                                onClick={() => navigateTo("/scraper")}
                              >
                                CURRENT SCRAPER
                              </button>
                            </li>
                            <li>
                              <button
                                className="customSubDropdown"
                                onClick={() =>
                                  navigateTo(
                                    "/administration/setup/integration-settings"
                                  )
                                }
                              >
                                INTEGRATION
                              </button>
                            </li>
                            <li>
                              <button
                                className="customSubDropdown"
                                onClick={() =>
                                  navigateTo(
                                    "/administration/setup/search-event-actions"
                                  )
                                }
                              >
                                SHOW EVENT ACTIONS
                              </button>
                            </li>
                            <li>
                              <button
                                className="customSubDropdown"
                                onClick={() =>
                                  navigateTo(
                                    "/administration/setup/event-action-editor"
                                  )
                                }
                              >
                                CREATE EVENT ACTIONS
                              </button>
                            </li>
                            <li>
                              <button
                                className="customSubDropdown"
                                onClick={() =>
                                  navigateTo("/administration/setup/templates")
                                }
                              >
                                MESSAGE TEMPLATES
                              </button>
                            </li>
                            <li>
                              <button
                                className="customSubDropdown"
                                onClick={() =>
                                  navigateTo(
                                    "/administration/setup/task-group-edit"
                                  )
                                }
                              >
                                EVENT TRIGGERS
                              </button>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <button
                            className={`customDropdown ${
                              activeSubAccordion === 2 ? "active" : ""
                            }`}
                            onMouseOver={() => {
                              handleSubAccordionOpen(2);
                              setButtonColors(defaultColors);
                            }}
                            onMouseLeave={() => changeColor(2)}
                            style={{ backgroundColor: buttonColors[2] }}
                            onClick={() => handleSubAccordionToggle(2)}
                          >
                            TOOLS
                          </button>

                          <ul
                            ref={toolsSubMenuRef}
                            className={`dropdown-menu ${
                              activeSubAccordion === 2 ? "show" : ""
                            }`}
                            onMouseMove={(event) =>
                              handleMouseMove(event, toolsSubMenuRef)
                            }
                            onMouseLeave={() => changeColorBack(2)}
                          >
                            <li>
                              <button
                                className="customSubDropdown"
                                onClick={() =>
                                  navigateTo(
                                    "/administration/tools/statement-fix-name"
                                  )
                                }
                              >
                                FIX STATEMENT NAME
                              </button>
                            </li>
                            <li>
                              <button
                                className="customSubDropdown"
                                onClick={() =>
                                  navigateTo(
                                    "/administration/tools/billing-fix-reports-tool"
                                  )
                                }
                              >
                                FIX BILLING REPORTS
                              </button>
                            </li>
                            <li>
                              <button
                                className="customSubDropdown"
                                onClick={() =>
                                  navigateTo(
                                    "/administration/tools/bulk-verify-usis"
                                  )
                                }
                              >
                                BULK VERRIFY USIs
                              </button>
                            </li>
                            <li>
                              <button
                                className="customSubDropdown"
                                onClick={() =>
                                  navigateTo(
                                    "/administration/tools/credit-transfer-tool"
                                  )
                                }
                              >
                                CREDIT TRANSFERS
                              </button>
                            </li>
                            <li>
                              <button
                                className="customSubDropdown"
                                onClick={() =>
                                  navigateTo(
                                    "/administration/tools/usi-generator"
                                  )
                                }
                              >
                                USI GENERATOR
                              </button>
                            </li>
                            <li>
                              <button
                                className="customSubDropdown"
                                onClick={() =>
                                  navigateTo(
                                    "/administration/tools/certificate-transition-tool"
                                  )
                                }
                              >
                                CERTIFICATE TRANSFER TOOL
                              </button>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <button
                            className={`customDropdown ${
                              activeSubAccordion === 3 ? "active" : ""
                            }`}
                            onMouseOver={() => handleSubAccordionOpen(3)}
                            onMouseLeave={() => changeColor(3)}
                            style={{ backgroundColor: buttonColors[3] }}
                            onClick={() => handleSubAccordionToggle(3)}
                          >
                            UPLOADS
                          </button>

                          <ul
                            ref={uploadsSubMenuRef}
                            className={`dropdown-menu ${
                              activeSubAccordion === 3 ? "show" : ""
                            }`}
                            onMouseMove={(event) =>
                              handleMouseMove(event, uploadsSubMenuRef)
                            }
                            onMouseLeave={() => changeColorBack(3)}
                          >
                            <li>
                              <button
                                className="customSubDropdown"
                                onClick={() =>
                                  navigateTo(
                                    "/administration/uploads/upload-assessment"
                                  )
                                }
                              >
                                ASSESSMENT
                              </button>
                            </li>
                            <li>
                              <button
                                className="customSubDropdown"
                                onClick={() =>
                                  navigateTo(
                                    "/administration/uploads/upload-det-payment"
                                  )
                                }
                              >
                                QGOV FUNDING PAYMENT
                              </button>
                            </li>
                            <li>
                              <button
                                className="customSubDropdown"
                                onClick={() =>
                                  navigateTo(
                                    "/administration/uploads/upload-det-data-error-validation"
                                  )
                                }
                              >
                                QGOV ERROR REPORT
                              </button>
                            </li>
                            <li>
                              <button
                                className="customSubDropdown"
                                onClick={() =>
                                  navigateTo(
                                    "/administration/uploads/upload-det-modules-snapshot"
                                  )
                                }
                              >
                                QGOV FUNDED SCOPE(Sch A)
                              </button>
                            </li>
                            <li>
                              <button
                                className="customSubDropdown"
                                onClick={() =>
                                  navigateTo(
                                    "/administration/uploads/upload-approved-training-snapshot"
                                  )
                                }
                              >
                                QGOV APPROVED APPRENTICESHIPS
                              </button>
                            </li>
                            <li>
                              <button
                                className="customSubDropdown"
                                onClick={() =>
                                  navigateTo(
                                    "/administration/uploads/upload-user-choice-pricelist"
                                  )
                                }
                              >
                                QGOV UC PRICE LIST
                              </button>
                            </li>
                            <li>
                              <button
                                className="customSubDropdown"
                                onClick={() =>
                                  navigateTo(
                                    "/administration/uploads/upload-nominal-hours"
                                  )
                                }
                              >
                                NATIONAL NOMINAL HOURS
                              </button>
                            </li>
                            <li>
                              <button
                                className="customSubDropdown"
                                onClick={() =>
                                  navigateTo(
                                    "/administration/uploads/upload-legacy-assessment"
                                  )
                                }
                              >
                                LEGACY ASSESSMENT
                              </button>
                            </li>
                            <li>
                              <button
                                className="customSubDropdown"
                                onClick={() =>
                                  navigateTo(
                                    "/administration/uploads/upload-det-payment"
                                  )
                                }
                              >
                                AVETMISS FROM OTHER SOFTWARE
                              </button>
                            </li>
                          </ul>
                        </li>

                       {isDev && <li>
                          <button
                            className={`customDropdown ${
                              activeSubAccordion === 4 ? "active" : ""
                            }`}
                            onMouseOver={() => {
                              handleSubAccordionOpen(4);
                              setButtonColors(defaultColors);
                            }}
                            onMouseLeave={() => changeColor(4)}
                            style={{ backgroundColor: buttonColors[4] }}
                            onClick={() =>
                              navigateTo("/administration/tools/dev-tools")
                            }
                          >
                            DEV TOOLS
                          </button>

                          <ul
                            ref={toolsSubMenuRef}
                            className={`dropdown-menu ${
                              activeSubAccordion === 4 ? "show" : ""
                            }`}
                            onMouseMove={(event) =>
                              handleMouseMove(event, toolsSubMenuRef)
                            }
                            onMouseLeave={() => changeColorBack(4)}
                          >
                            <li>
                              <button
                                className="customSubDropdown"
                                onClick={() =>
                                  navigateTo(
                                    "/event-viewer"
                                  )
                                }
                              >
                                EVENT VIEWER
                              </button>
                            </li>

                          </ul>
                        </li>}
                      </ul>
                    </li>

                    <li>
                      <button className="customSpacer"></button>
                    </li>
                  </ul>
                </div>
              </nav>
            )}

            <div className="profile-container">
              <a
                className="username"
                href="#"
                onClick={(e) => e.preventDefault()}
              >
                {username}
              </a>

              <a className="header-button">
                <i
                  onClick={() => navigateTo("/personal-profile")}
                  className="fa fa-user"
                ></i>
              </a>

              <a className="header-button">
                {isLoggedIn ? (
                  <i onClick={handleLogout} className="fa fa-lock-open"></i>
                ) : (
                  <i
                    onClick={() => navigateTo("/login")}
                    className="fa fa-lock"
                  ></i>
                )}
              </a>

              {/* <a className="header-button">
                <i onClick={() => navigateTo("")} className="fa fa-search"></i>
              </a> */}
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
