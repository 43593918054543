// DISABLED LINTING FOR THIS FILE BECAUSE IT IS A TEMPLATE FILE
/* eslint-disable */
import React, {useCallback, useRef, useState} from 'react';
import { AgGridReact } from 'ag-grid-react';
import { useNavigate } from 'react-router-dom';
import { Modal, Container, Row, Col } from 'react-bootstrap';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'bootstrap/dist/css/bootstrap.css';
import '../Styling/App.css';
import routes from "./Routes";
import studentData from "../TestingDataObjects/FakeStudentData";
import {Document, Page} from "react-pdf";
import BorderedComponent from "./BorderedComponent";
import { FrappeGantt } from 'frappe-gantt-react';

const TemplatesTable = () => {

    const gridRef = useRef();
    const gridRef1 = useRef();
    const gridRef2 = useRef();
    const [searchValue, setSearchValue] = useState('');
    const navigate = useNavigate();
    const [showModuleModal, setShowModuleModal] = useState(true);
    // New state for storing the added modules
    const [addedModules, setAddedModules] = useState([]);

    // Updated handleModuleClose function


    function generateRandomData() {
        const certificates = [
            "Certificate 3 in Landscape Construction",
            "Certificate 1 in Construction",
            "Certificate 4 in Disco Boogy",
            "Diploma in Memeology",
            "Bachelors Degree in bein' Fuckin' Awesome!",
            "Cerificate 1 in Coversation",
            "Certificate 3 in Barbering",
            "Certificate 3 in Learning People Stuff and Things",
            "Certificate 3 in Doin' Stuff",
            "Certificate 3 in Spleling"
        ];
        const templates = [
            "The best of Trainin Dave",
            "Basics of life",
            "Certificate 4 in Disco Boogy",
            "Memeology 101",
            "Totally legit template",
            "OMG YES",
            "Cant think of a name",
            "Things and stuff",
            "Stuff and Thangs",
            "Other Thangs"
        ];
        const fakeData = [];
        for(let i = 1; i <= 50; i++) {
            fakeData.push({
                templateName: templates[Math.floor(Math.random() * templates.length)], // pick a random template
                certCode: `MOD${("0000" + Math.floor(Math.random() * 10000)).slice(-4)}`, // generate a random 4-digit code
                certName: certificates[Math.floor(Math.random() * certificates.length)], // pick a random certificate
                // endDate: `2023-${("0" + Math.floor(Math.random() * 12 + 1)).slice(-2)}-01`, // generate a random month and use 1st as end date
                courseType: Math.random() > 0.5 ? "Full" : "Partial" // randomly assign inScope as 1 or the other
            });
        }
        return fakeData;
    }

    let fakeData = generateRandomData();

    // console.log(fakeData);

    const handleSubmit = (event) => {
        event.preventDefault();

        // Filter to check if the search content is a number
        const numberFilter = /^\d+$/;
        const moduleFilter = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]+$/;
        const nameFilter = /^[A-Za-z]+\s[A-Za-z]+$/;



        switch (true) {
            case numberFilter.test(searchValue):
                console.log('Search value is a number:', searchValue);
                navigate(`/search?number=${searchValue}`);
                break;

            case moduleFilter.test(searchValue):
                console.log('Search value is a module:', searchValue);
                navigate(`portal/certificates-modules/modules/module-edit/?id=${searchValue}`);
                break;

            case nameFilter.test(searchValue):
                console.log('Search value is a person:', searchValue);
                navigate(`portal/people/candidate-edit/?id=${searchValue}`);
                break;

            default:
                console.log('Search value is a route:', searchValue);
                // Check if the searchValue is a route element
                const foundRoute = routes.find(
                    (route) => route.element.toLowerCase() === searchValue.toLowerCase()
                );
                if (foundRoute) {
                    console.log('Search value:', searchValue);
                    navigate(foundRoute.path);
                } else {
                    // Handle the case when no matching route is found
                    console.log('Search value:', searchValue);
                    console.log('No matching route found.');
                }
                break;
        }

        console.log('Search value:', searchValue);

    };

    const handleInputChange = (event) => {

        setSearchValue(event.target.value);
    };

    const [chartData, setChartData] = useState([]);
    const [columnDefs, setColumnDefs] = useState([

        { headerName: 'Template Name', field: 'templateName', flex: 2, sortable: true },
        { headerName: 'Certificate Code', field: 'certCode', flex: 1, sortable: true  },
        { headerName: 'Certificate Name', field: 'certName', flex: 2, sortable: true  },
        { headerName: 'Type', field: 'courseType', flex: 1, sortable: true  },
        // { headerName: 'In Scope', field: 'inScope', flex: 1, sortable: true  },
    ]);

    const [tasks, setTasks] = useState([
        {
            id: 'Task 1',
            name: 'Redesign website',
            start: '2016-12-28',
            end: '2016-12-31',
            progress: 20,
            dependencies: 'Task 2, Task 3'
        },

    ]);

    const loadData = (params) => {
        const pageSize = params.endRow - params.startRow;
        const currentPage = Math.floor(params.startRow / pageSize) + 1;

        const url = "";

        fetch(url)
            .then((resp) => resp.json())
            .then((result) => {

                const rowsThisPage = result.data;
                let lastRow = -1;

                if (rowsThisPage.length !== 100) {
                    lastRow = rowsThisPage.length;
                }

                params.successCallback(rowsThisPage, lastRow);
            });
    };

    const onGridReady = useCallback((params) => {
        // if (!searched) {
        //     params.api.setDatasource({
        //         getRows: loadData,
        //     });
        // }
    }, []);


    const getSelectedData = useCallback(() => {
        // const selectedRows = gridRef1.current.api.getSelectedRows();
        // let selectedIMDB = selectedRows.length === 1 ? selectedRows[0].movieId : '';
        // // handleClosePerson()
        // // handleCloseMovie()
        // // movieRequest('http://sefdb02.qut.edu.au:3000/movies/data/' + selectedIMDB)

    }, []);

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    );

    const labels = ['0-1', '1-2', '2-3', '3-4', '4-5', '5-6', '6-7', '7-8', '8-9', '9-10'];

    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: '',
            },
        },
    };


    const handleModuleClose = () => {
        const selectedRows = gridRef1.current.api.getSelectedRows();
        setAddedModules(prevModules => [...prevModules, ...selectedRows]);
        setShowModuleModal(false);
    }

    const handleModuleOpen = () => {
        setShowModuleModal(true);
    }

    // const removeModule = () => {
    //
    // }
    function removeModule(index) {
        setAddedModules(addedModules.filter((_, i) => i !== index));
    }

    // const tasks = [
    //     {
    //         id: 'Task 1',
    //         name: 'Redesign website',
    //         start: '2016-12-28',
    //         end: '2016-12-31',
    //         progress: 20,
    //         dependencies: 'Task 2, Task 3'
    //     },
    //
    // ];
    // const gantt = new Gantt("#gantt", tasks);

    return (

        <div>

            <div style={{marginTop: 50}}></div>

            {/*<BorderedComponent name="Edit Template">*/}

            <div style={{textAlign: 'right', margin: '25px'}}>
                <button type="button" onClick={handleModuleOpen} className="btn-primary">CHANGE TEMPLATE</button>
            </div>

            {/*<div style={{display: 'flex', justifyContent: 'space-around'}}>*/}

                <table style={{width: '100%'}}>
                    <thead>
                    <tr>

                        {/*<th style={{width: '15%', textAlign: 'left'}}></th>*/}
                        <th style={{width: '20%', textAlign: 'left', paddingLeft: 20, fontSize: 20}}>Code</th>
                        <th style={{width: '40%', textAlign: 'left', fontSize: 20}}>Name</th>
                        <th style={{width: '25%', fontSize: 20}}>Flags</th>

                    </tr>
                    </thead>
                    <tbody>
                    {addedModules.map((module, index) => (
                        <tr key={index} className={index % 2 === 0 ? 'dark-grey' : 'light-grey'}>
                            <td style={{textAlign: 'left', paddingLeft: 20}}>{module.code}</td>
                            <td style={{textAlign: 'left'}}>{module.certName}</td>
                            <td>
                                {/*<i className="fas fa-flag" style={{ color: 'green' }}></i>*/}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>

            {/*</div>*/}

            {/*</BorderedComponent>*/}

            <Container style={{width:'100%'}}>
                <Row style={{ display: 'flex', flexWrap: 'nowrap' }}>
                    <Col xs={12} md={12} style={{ paddingLeft: '5px', paddingRight: '5px', flex: '1 1 0' }}>
                        {/* File List */}
                        <Row w-100>
                            {/*<Col style={{ flex: '1 1 0' }} xs={12} md={4}><strong>Code</strong></Col>*/}
                            {/*<Col style={{ flex: '1 1 0' }} xs={12} md={4}><strong>Name</strong></Col>*/}
                            {/*<Col style={{ flex: '1 1 0' }} xs={12} md={4}><strong>Flags</strong></Col>*/}
                        </Row>

                    </Col>
                    {/*<Col xs={12} md={6} style={{ paddingLeft: '5px', paddingRight: '5px', flex: '1 1 0' }}>*/}

                    {/*</Col>*/}
                </Row>

            </Container>

            {/*<div style={{ display: 'flex', flexDirection: 'row' }}>*/}
            {/*    <div style={{ marginRight: '20px' }}>*/}
            {/*        {tasks.map(task => <p>{task.name}</p>)}*/}
            {/*    </div>*/}
            {/*    <div>*/}
            {/*        <Gantt*/}
            {/*            tasks={tasks}*/}
            {/*            viewMode={'Day'}*/}
            {/*            //... other props*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*</div>*/}

            {/*<div>*/}
            <FrappeGantt
                tasks={tasks}
                // viewMode={this.state.mode}
                onClick={task => console.log(task)}
                onDateChange={(task, start, end) => console.log(task, start, end)}
                onProgressChange={(task, progress) => console.log(task, progress)}
                onTasksChange={tasks => console.log(tasks)}
            />
            {/*</div>*/}

            {/*<button type="button" onClick={() => handleModuleOpen()} className={"btn-primary"}>Add Module</button>*/}

            <Modal show={showModuleModal} onHide={handleModuleClose}>
                {/*<Modal.Header>*/}
                {/*    <Modal.Title>Select Module</Modal.Title>*/}
                {/*</Modal.Header>*/}
                <Modal.Header>
                    <Modal.Title>Select Module</Modal.Title>
                    <button onClick={handleModuleClose} style={{border: 'none', background: 'transparent', color: 'white', position: 'absolute', top: '20px', right: '5px', fontSize: '1.5em'}}>
                        <strong>X</strong>
                    </button>
                </Modal.Header>

                <Modal.Body>

                    <div>
                        <div className="container" style={{ width: '100%', marginTop: 75}}></div>
                        <form onSubmit={handleSubmit}>
                            <div className="container" style={{ width: '100%', padding: 15, marginBottom: 15}}>
                                <div className="search-bar">
                                    <input
                                        id="searchForDis"
                                        type="text"
                                        className="search-input"
                                        value={searchValue} // Bind the input value to the state variable
                                        onChange={handleInputChange} // Handle input change to update the state
                                        placeholder="Search..."
                                    />
                                    <button type="submit" className="search-button">
                                        <i className="fas fa-search"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                        {/*</div>*/}
                    </div>

                    <div className="container" style={{width: "100%", height: '100%'}}>
                        <div style={{width: "100%", height: '80%'}} className="ag-theme-alpine custom-grid">
                            <AgGridReact
                                ref={gridRef1}
                                columnDefs={columnDefs}
                                rowBuffer={0}
                                rowData={fakeData} // Remove once backend implemented
                                rowSelection={'single'}
                                // rowModelType={'infinite'}
                                // cacheBlockSize={100}
                                // cacheOverflowSize={2}
                                // maxConcurrentDatasourceRequests={1}
                                // infiniteInitialRowCount={100}
                                // maxBlocksInCache={100}
                                // onGridReady={onGridReady}
                                pagination={true}
                                onCellClicked={getSelectedData}
                            />
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <button onClick={handleModuleClose} style={{zIndex: 100}}>Select Template</button>
                </Modal.Footer>
            </Modal>


        </div>
    );
};

export default TemplatesTable;