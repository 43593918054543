// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Dropzone {
    height: 200px;
    width: 500px;
    background-color: #fff;
    border: 2px dashed rgb(187, 186, 186);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 16px;
}

.Highlight {
    background-color: rgb(188, 185, 236);
}

.Icon {
    opacity: 0.3;
    height: 64px;
    width: 64px;
}

.FileInput {
    display: none;
}`, "",{"version":3,"sources":["webpack://./src/Components/Dropzone/dropzone/Dropzone.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,sBAAsB;IACtB,qCAAqC;IACrC,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,sBAAsB;IACtB,eAAe;AACnB;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".Dropzone {\n    height: 200px;\n    width: 500px;\n    background-color: #fff;\n    border: 2px dashed rgb(187, 186, 186);\n    border-radius: 5px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n    font-size: 16px;\n}\n\n.Highlight {\n    background-color: rgb(188, 185, 236);\n}\n\n.Icon {\n    opacity: 0.3;\n    height: 64px;\n    width: 64px;\n}\n\n.FileInput {\n    display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
