import React, { useEffect, useRef } from 'react';
import Select from 'react-select';
import { customSelectStyles } from "../../Pages/Styles/customSelectStyles";
import QRCodeStyling from "qr-code-styling";
import QRLogo from 'Images/QRLogo.png'
import {SpacerDiv, FileUpload} from 'Components';

interface UploadContentProps {
  formData: any;
  setFormData: any;
  url: string;
  // eslint-disable-next-line no-unused-vars
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, name: string) => void;
  // eslint-disable-next-line no-unused-vars
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  // eslint-disable-next-line no-unused-vars
  handleSelectChange: (fieldName: string, selectedOption: any) => void;
}

const UploadContent: React.FC<UploadContentProps> = ({
  formData,
  setFormData,
  url,
  handleInputChange,
  handleSubmit,
  handleSelectChange,
}) => {

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.files);
  }

  return (
    <form>
      Hello
     <FileUpload onChange={handleChange}/>
    </form>
  );
};

export default UploadContent;