import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { useUserData } from '../../CustomHooks';
import { useNavigate } from 'react-router-dom';
import SpacerDiv from '../SpacerDiv';

import '../Styles/InactivityCheck.scss';

interface InactivityCheckProps {}

const InactivityCheck: React.FC<InactivityCheckProps> = () => {
  const COUNTDOWN = 30;
  const isActive = useSelector((state: { activityMonitor: { isUserActive: boolean } }) => state.activityMonitor.isUserActive);
  const [show, setShow] = useState<boolean>(false);
  const [countdown, setCountdown] = useState<number>(COUNTDOWN);
  const { clearUserData } = useUserData();
  const navigate = useNavigate();

  // Handle user logout when countdown reaches zero
  const handleLogout = React.useCallback(() => {
    console.log("Logging out user due to inactivity...");
    clearUserData();
    setShow(false);
    navigate("/");
  }, [clearUserData, navigate]);

  // Countdown timer logic
  useEffect(() => {
    if (show && countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    } else if (countdown === 0) {
      handleLogout();
    }
  }, [countdown, handleLogout, show]);

  // Reset countdown when modal is shown again
  useEffect(() => {
    if (show) {
      setCountdown(COUNTDOWN);
    }
  }, [show, COUNTDOWN]);

  // Show or hide modal based on user activity
  useEffect(() => {
    if (!isActive) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [isActive]);

  return (
    <Modal show={show} onHide={() => setShow(false)} centered backdrop="static">
      <Modal.Header className='inactivity-modal-header'>
        <Modal.Title>Are you still there?</Modal.Title>
      </Modal.Header>
      <Modal.Body className='inactivity-modal-body'>
        <SpacerDiv />
        <p>You will be signed out in <span>{countdown}</span> seconds.</p>
        <p className='desktop-only'>Interact with the page to stay signed in.</p>
        <p className='mobile-only'>Click the button below or interact with the page to stay signed in.</p>
        <SpacerDiv />
        <button className='btn-primary mobile-only' onClick={() => setShow(false)}>
          Stay Signed In
        </button>
      </Modal.Body>
    </Modal>
  );
};

export default InactivityCheck;
