import { useLocation } from 'react-router-dom';
import logError from '../Services/Logger';
import { showRefreshLogin } from 'State/Reducers/refreshLoginSlice';
import store from 'State/store';
import { getDocument } from 'pdfjs-dist'; // Assuming you're using PDF.js to load the PDF

const environment = process.env.REACT_APP_ENV;

const useFetchPdf = () => {

    const location = useLocation();

    const fetchPdf = async (endpoint, postData, fallbackPdf) => {

        let api;

        switch(environment) {
            case 'staging':
                api = 'https://api.trainingprofessionals.com.au/';
                break;
            case 'production':
                api = 'https://api.tp.edu.au/';
                break;
            case 'development':
                api = 'https://10.1.10.49:5001/';
                break;
            default:
                api = 'https://localhost:3001/';
        }
        const errorLog = async (errorDeets, msg) => {
            try {
                const response = await logError({ errorDeets });
                return msg;
            } catch (err) {
                console.error('Task failed and Error logging failed:', err);
                throw err; // Re-throw the error if needed for further handling
            }
        };

        const requestBody = JSON.stringify(postData);
        const destination = api + endpoint;

        let msg;
        let msgCode;
        let status;
        let pdf;

        await fetch(destination, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: requestBody
        })
            .then(async response => {
                status = response.status;

                const errorDeets = {
                    page: location.pathname,
                    dataSubmitted: requestBody,
                    errorCode: response.status,
                    errorDesc: response.statusText,
                    endpoint: endpoint,
                };

                // Handle the responses based on the status code
                switch (true) {
                    case (response.status >= 200 && response.status < 300):
                        // If the response is OK, we proceed with fetching the PDF
                        const blob = await response.blob();
                        const url = URL.createObjectURL(blob);
                        pdf = await getDocument(url).promise;
                        msgCode = 1;
                        break;

                    case (response.status >= 300 && response.status < 400):
                        // Handle warnings
                        msg = response.statusText;
                        msgCode = 2;
                        break;

                    case (response.status >= 400 && response.status < 500):
                        // Handle client errors
                        msgCode = 3;
                        if (response.statusText === 'JWT token has expired') {
                            store.dispatch(showRefreshLogin());
                        }
                        msg = await errorLog(errorDeets, response.statusText);
                        throw new Error(msg);

                    case (response.status >= 500 && response.status < 600):
                        // Handle server errors
                        msgCode = 4;
                        msg = await errorLog(errorDeets, response.statusText);
                        throw new Error(msg);

                    default:
                        // Handle unknown errors
                        errorDeets.errorDesc = 'Fetch returned a response code that was not recognized';
                        msg = await errorLog(errorDeets, response.statusText);
                        console.log('Fetch Failed. Server responded with:', response);
                        throw new Error(msg);
                }
            })
            .catch(async error => {
                console.error('Error fetching PDF from API:', error);
                await logError({
                    page: location.pathname,
                    dataSubmitted: requestBody,
                    errorMsg: error.message,
                    errorDesc: 'Failed to fetch request.',
                    endpoint: endpoint,
                });

                // Fallback to local PDF in case of error
                console.log('Loading local PDF as a fallback.');
                pdf = await getDocument(fallbackPdf).promise;
                msg = 'Failed to fetch PDF from API. Loaded fallback PDF.';
                msgCode = 4;
            });

        return { pdf, msg, msgCode, status };
    };

    return fetchPdf;
};

export default useFetchPdf;



// =============================== To Do =================================== //



// =============================== Bugs =================================== //