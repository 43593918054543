import React, { useEffect, useState } from "react";
import useFetchApi from "Services/FetchApi";
import CustomAlert from "Components/CustomAlert";
import "./dictionaryManager.css"; // Import styles

export default function DictionaryManager() {
    const fetchApi = useFetchApi();

    const [dictionaryData, setDictionaryData] = useState([]);
    const [categories, setCategories] = useState([]);
    const [msg, setMsg] = useState("");
    const [msgCode, setMsgCode] = useState(0);
    const [unsavedRows, setUnsavedRows] = useState(new Set());
    const [filters, setFilters] = useState({
        search: "",
        category: "All",
        sourceDB: "All",
        sourceTable: "All",
        filter: "All"
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const pageSize = 100;

    /**
     * Fetch Dictionary Data
     */
    const fetchDictionaryData = async () => {
        try {
            const response = await fetchApi("system/dictionary", {
                page: currentPage,
                pageSize,
                search: filters.search,
                category: filters.category,
                sourceDB: filters.sourceDB,
                sourceTable: filters.sourceTable,
                filter: filters.filter
            });

            if (response.error) {
                setMsg(response.message);
                setMsgCode(1);
                return;
            }

            setDictionaryData(response.responseData.dictionary || []);
            setTotalPages(response.responseData.totalPages || 1);
            setCategories(response.responseData.categoriesList || []);
            setMsgCode(0);
        } catch (error) {
            console.error("Fetch error:", error);
            setMsg("An unexpected error occurred. Contact your administrator.");
            setMsgCode(4);
        }
    };

    /**
     * Fetch data when `currentPage` or `filters` change
     */
    useEffect(() => {
        fetchDictionaryData();
    }, [currentPage, filters]);

    const handleArchiveRow = async (id) => {
        if (!window.confirm("Do you really want to archive this entry?")) return;

        try {
            const response = await fetchApi("system/archiveDictionary", { id });

            if (response.error) {
                setMsg(response.message);
                setMsgCode(1);
                return;
            }

            setDictionaryData((prevData) => prevData.filter(row => row.id !== id));
            setMsg("Entry archived successfully.");
            setMsgCode(0);
        } catch (error) {
            console.error("Archive error:", error);
            setMsg("An unexpected error occurred while archiving.");
            setMsgCode(4);
        }
    };


    /**
     * Handle Pagination Change
     */
    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    /**
     * Handle Filter Change & Reset Pagination
     */
    const handleFilterChange = (key, value) => {
        setFilters((prev) => ({
            ...prev,
            [key]: value
        }));
        setCurrentPage(1); // 🔥 Reset to first page on filter change
    };

    const handleToggleWritable = (index) => {
        const updatedData = [...dictionaryData];
        updatedData[index].writable = updatedData[index].writable ? 0 : 1;
        updatedData[index].active = updatedData[index].active ? 0 : 1;
        setDictionaryData(updatedData);
        updateDictionaryData(index);
    };

    const handleToggleActive = (index) => {
        const updatedData = [...dictionaryData];
        updatedData[index].active = updatedData[index].active ? 0 : 1;
        setDictionaryData(updatedData);
        updateDictionaryData(index);
    };

    const toggleSourceKeyInput = (index) => {
        const updatedData = [...dictionaryData];
        updatedData[index].showSourceKeyInput = !updatedData[index].showSourceKeyInput;
        setDictionaryData(updatedData);
    };

    /**
     * Handle Input Change
     */
    const handleChange = (index, key, value) => {
        const updatedData = [...dictionaryData];
        updatedData[index][key] = value;
        setUnsavedRows((prev) => new Set([...prev, index]));
        setDictionaryData(updatedData);
    };

    const updateDictionaryData = async (index) => {
        let rowData = { ...dictionaryData[index] };

        // 🔥 Only keep editable fields
        const allowedFields = ["id", "writable", "active", "dateUpdated", "archived", "prettyName", "category", "sourceKey"];
        let updatedData = Object.keys(rowData)
            .filter(key => allowedFields.includes(key))
            .reduce((obj, key) => {
                obj[key] = rowData[key];
                return obj;
            }, {});

        // 🔥 Add dateUpdated in the correct format
        updatedData.dateUpdated = new Date().toISOString().slice(0, 19).replace("T", " "); // MySQL-friendly timestamp

        try {
            const response = await fetchApi("system/updateDictionary", updatedData);

            if (response.status >= 200 && response.status < 300) {
                setUnsavedRows((prev) => {
                    const newSet = new Set(prev);
                    newSet.delete(index);
                    return newSet;
                });
                setMsg("Entry updated successfully.");
                setMsgCode(0);
            } else {
                setMsg(response.msg);
                setMsgCode(1);
            }
        } catch (error) {
            console.error("Update failed:", error);
            setMsg("An unexpected error occurred. Contact your administrator.");
            setMsgCode(4);
        }
    };

    const toggleEditingSourceKey = (index) => {
        const updatedData = [...dictionaryData];
        updatedData[index].isEditingSourceKey = true;
        setDictionaryData(updatedData);
    };

    const saveSourceKey = (index) => {
        const updatedData = [...dictionaryData];
        updatedData[index].isEditingSourceKey = false;
        setDictionaryData(updatedData);
        updateDictionaryData(index); // 🔥 Auto-save changes when exiting input
    };


    return (
        <div>
            <CustomAlert msg={msg} msgCode={msgCode} />

            <div className="dictionary-manager-container">
                <h2>Dictionary Manager</h2>

                {/* Filters */}
                <div className="filter-container">
                    <input
                        type="text"
                        placeholder="Search..."
                        value={filters.search}
                        onChange={(e) => handleFilterChange("search", e.target.value)}
                    />
                    <select onChange={(e) => handleFilterChange("category", e.target.value)}>
                        <option value="All">All Categories</option>
                        {/*{[...new Set(dictionaryData.map(entry => entry.category))].map(cat => (*/}
                        {/*    <option key={cat} value={cat}>{cat}</option>*/}
                        {/*))}*/}
                        {categories.map(cat => (
                            <option key={cat} value={cat}>{cat}</option>
                        ))}
                    </select>
                    <select onChange={(e) => setFilters({...filters, filter: e.target.value})}>
                        <option value="All">All</option>
                        <option value="Active">Active</option>
                        <option value="Archived">Archived</option>
                    </select>
                </div>

                {/* Table */}
                {dictionaryData.length > 0 ? (
                    <div>
                        <table className="dictionary-table">
                            <thead>
                            <tr>
                                <th>ID</th>
                                <th>Pretty Name</th>
                                <th>Source DB</th>
                                <th>Source Table</th>
                                <th>Source Column</th>
                                <th>Category</th>
                                <th>Status</th>
                                <th></th>
                                <th>Keys</th>
                                <th>Active</th>
                                <th>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {dictionaryData.map((row, index) => (
                                <tr key={index}>
                                    <td>{row.id}</td>
                                    {/*<td>{row.prettyName}</td>*/}
                                    <td>
                                        <input
                                            type="text"
                                            value={row.prettyName}
                                            onChange={(e) => handleChange(index, "prettyName", e.target.value)}
                                        />
                                    </td>
                                    <td>{row.sourceDB}</td>
                                    <td>{row.sourceTable}</td>
                                    <td>{row.sourceColumn}</td>
                                    {/*<td>{row.category}</td>*/}
                                    <td>
                                        <input
                                            type="text"
                                            value={row.category}
                                            onChange={(e) => handleChange(index, "category", e.target.value)}
                                        />
                                    </td>
                                    <td
                                        onClick={() => handleToggleWritable(index)}>
                                        {row.writable ? "Writable" : "Read"}
                                    </td>
                                    <td>
                                        <div className="source-key-container">
                                            <span className="source-key-icon"
                                                  onClick={() => toggleSourceKeyInput(index)}>
                                                🔑
                                            </span>
                                            {row.showSourceKeyInput && (
                                                <input
                                                    type="text"
                                                    className="source-key-input"
                                                    value={row.sourceKey || ""}
                                                    onChange={(e) => handleChange(index, "sourceKey", e.target.value)}
                                                    placeholder="Enter Source Key"
                                                />
                                            )}
                                        </div>
                                    </td>

                                    <td onClick={() => toggleEditingSourceKey(index)}>
                                        {!row.isEditingSourceKey ? (
                                            <span className="source-key-label">{row.sourceKey || ""}</span>
                                        ) : (
                                            <input
                                                type="text"
                                                className="source-key-input"
                                                value={row.sourceKey || ""}
                                                onChange={(e) => handleChange(index, "sourceKey", e.target.value)}
                                                onBlur={() => saveSourceKey(index)}
                                                autoFocus
                                            />
                                        )}
                                    </td>


                                    {/*<td>*/}
                                    {/*    <input*/}
                                    {/*        type="text"*/}
                                    {/*        value={row.souceKey}*/}
                                    {/*        onChange={(e) => handleChange(index, "sourceKey", e.target.value)}*/}
                                    {/*    />*/}
                                    {/*</td>*/}

                                    {/*<td style={{textAlign: 'center'}} onClick={() => handleToggleActive(index)}>*/}
                                    {/*    {row.active ? "✔" : "❌"}*/}
                                    {/*</td>*/}

                                    {/*<td style={{textAlign: "center", cursor: "pointer"}}*/}
                                    {/*    onClick={() => handleToggleActive(index)}>*/}
                                    {/*    {row.active ? <span dangerouslySetInnerHTML={{__html: "&#10003;"}}/> : "❌"}*/}
                                    {/*</td>*/}

                                    {/*<td style={{textAlign: "center", cursor: "pointer"}}*/}
                                    {/*    onClick={() => handleToggleActive(index)}>*/}
                                    {/*    {row.active ? (*/}
                                    {/*        <span className="status-icon">✔</span>*/}
                                    {/*    ) : (*/}
                                    {/*        <span className="status-icon">❌</span>*/}
                                    {/*    )}*/}
                                    {/*</td>*/}

                                    <td
                                        style={{
                                            textAlign: "center",
                                            cursor: "pointer",
                                            fontSize: "24px",
                                            fontWeight: "bold"
                                        }}
                                        onClick={() => handleToggleActive(index)}
                                    >
                                        {row.active ? (
                                            <span style={{color: "green"}}
                                                  dangerouslySetInnerHTML={{__html: "&#10003;"}}/>
                                        ) : (
                                            <span style={{color: "red"}}
                                                  dangerouslySetInnerHTML={{__html: "&#10007;"}}/>
                                        )}
                                    </td>

                                    <td>
                                        <button
                                            onClick={() => updateDictionaryData(index)}
                                            style={{
                                                backgroundColor: unsavedRows.has(index) ? "purple" : "grey",
                                                color: "white",
                                                cursor: unsavedRows.has(index) ? "pointer" : "default",
                                                opacity: unsavedRows.has(index) ? 1 : 0.5
                                            }}>
                                            Save
                                        </button>
                                        <button className="archive-btn" onClick={() => handleArchiveRow(row.id)}>🗑️
                                        </button>
                                    </td>

                                </tr>
                            ))}
                            </tbody>

                        </table>

                        <p className="info-text">
                            🔑 <strong>Source Authority Check:</strong> This field restricts **reading & writing** access
                            based on authorization checks, such as **role ID validation against a cookie payload**. Use
                            this to **limit** dictionary updates to authorized systems only.
                        </p>

                    </div>
                ) : <p>Loading dictionary data...</p>}

                {/* Pagination */}
                <div className="pagination">
                    <button disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)}>Previous
                    </button>
                    <span>Page {currentPage} of {totalPages}</span>
                    <button disabled={currentPage === totalPages}
                            onClick={() => handlePageChange(currentPage + 1)}>Next
                    </button>
                </div>
            </div>
        </div>
    );
}
