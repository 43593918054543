import React, { ReactNode, useRef } from 'react';

interface FileUploadProps {
  buttonLabel?: string | ReactNode;
  elementId?: string;
  acceptedFileType?: 'image/*' | 'image/jpeg' | 'image/png' | 'image/gif' | 'video/*' | 'video/mp4' |
             'audio/*' | 'audio/mpeg' | 'application/pdf' | 'application/msword' | 'application/zip' |
             'text/plain' | 'text/csv' | 'application/json' | '';
  // eslint-disable-next-line no-unused-vars
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const FileUpload: React.FC<FileUploadProps> = ({ buttonLabel = 'Upload', acceptedFileType='', onChange}) => {
  const inputFileRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  return (
    <div>
      <button
        type="button"
        className="btn-file-input"
        style={{ width: "200px"}}
        onClick={handleButtonClick}
      >
        {buttonLabel}
      </button>
      <input
        type="file"
        ref={inputFileRef}
        accept={acceptedFileType}
        onChange={onChange}
        style={{ display: "none" }}
      />
    </div>
  );
};

export default FileUpload;
