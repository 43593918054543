import React, {useContext, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import {ConstLink} from "ConstLink";
import Bluey from "Components/Bluey";
// import { useUserField } from 'CustomHooks';

export default function Home() {
    // const isLoggedIn = useUserField('loggedIn');
    const navigate = useNavigate();
    const { setQuickMenu } = useContext(ConstLink);

    useEffect(() => {
        // Run the updateQuickMenu1 function on page load
        updateQuickMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateQuickMenu = () => {
        setQuickMenu([
            ["My Students", "/Students"],
            ["Upcoming Classes", "/Classes"],
            ["Student Certificates", "/Certificates"],
            ["Businesses I Work With", "/Businesses"],
            ["Modules I can train", "/Modules"],
            ["Quality Control", "/QualityControl"]
        ]); // Update the value of quickMenu
    };

    const handleButtonClick = () => {
        navigate('/calendar/class-edit');
    };

    const handleNavigate = () => {

        // const jsonObject = { modId: module };
        // window.sessionStorage.setItem("currentClassroom", module);
        // Navigate with State method
        // navigate('/study/classroom/assessment', { state: { jsonObject } });
        navigate('/classroom');
        // Navigate with JSON object
        // const serializedData = encodeURIComponent(JSON.stringify(yourJsonObject));
        // navigate(`/study/assessment?data=${serializedData}`);

        // Navigate with string
        // const message = "Hello, V!";
        // navigate(`/study/assessment?message=${encodeURIComponent(message)}`);

    };

    return (

        <div

            style={{
                height: '100vh',
                width: '100%',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                position: 'relative',
            }}
        >
            <Bluey />

            <div
                style={{
                    position: 'relative',
                    height: '100vh',
                    width: '100%',
                    paddingTop: '35vh',
                }}
            >


            {/*<Search />*/}
            <div style={{display: 'flex', justifyContent: 'center', gap: '1rem'}}>
                {/*<button className={'btn-primary'} onClick={handleButtonClick}>Go to Upload</button>*/}
                <button onClick={() => handleNavigate()} className={'btn-primary'} style={{ marginLeft: 50}}>Go to Classroom</button>
            </div>

            </div>
        </div>
    );
}




