import React, { useEffect, useState } from 'react';
import ModuleTable from "Components/ModuleTable";
import {Row, Col} from "react-bootstrap"

export default function Modules() {
    const [_pageName, setPageName] = useState('');

    useEffect(() => {
        const pathArray = window.location.pathname.split('/');
        const pageParam = pathArray[pathArray.length - 1];
        setPageName(pageParam || 'Default Page');
    }, []);

    return (
        // <div
        //     style={{
        //         display: 'flex',
        //         justifyContent: 'center',
        //         alignItems: 'center',
        //         height: '100vh',
        //         fontSize: '40px',
        //         fontWeight: 'bold',
        //     }}
        // >
            <div>
            {/*<Row>*/}
                {/*<Col xs={12} md={12} xl={12} style={{marginTop: 15, textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>*/}
                {/*    {pageName}*/}
                {/*</Col>*/}
            {/*</Row>*/}
            <Row>
                <Col xs={12} md={12} xl={12} style={{marginTop: 15, textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                <ModuleTable />
                  </Col>
            </Row>
            </div>
        // </div>
    );
}

