import React from 'react';
import { BugReportForm } from '../../Components';

interface BugReportProps {}

const BugReport: React.FC<BugReportProps> = () => {
  return (
    <div className='bug-report-container'>
     <BugReportForm />
    </div>
  );
};

export default BugReport;