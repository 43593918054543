import React from 'react';
import { SpacerDiv } from 'Components';
import { EducationFormData } from 'Types/formInterfaces';

interface EducationContentProps {
  formData: any;
  setFormData: any;
  // eslint-disable-next-line no-unused-vars
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, name: string) => void;
  // eslint-disable-next-line no-unused-vars
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  // eslint-disable-next-line no-unused-vars
  handleCheckboxGroupChange: (e: React.ChangeEvent<HTMLInputElement>, groupName: keyof EducationFormData) => void;
  // eslint-disable-next-line no-unused-vars
  handleRadioChange: (formName: string, fieldName: string, value: string) => void;
}

const EducationContent: React.FC<EducationContentProps> = ({
  formData,
  setFormData,
  handleInputChange,
  handleSubmit,
  handleCheckboxGroupChange,
  handleRadioChange,
}) => {
  return (
    <form className='form-container' onSubmit={handleSubmit} id="form-1">
    <div className='form-row'>
      <div>
        {/* USI */}
        <div className='section-container full-width'>
          <p className='section-heading'>Unique Student Identifier</p>
          <input
            className='large'
            type='text'
            name='usi'
            placeholder='USI'
            value={formData.usi}
            onChange={(e) => handleInputChange(e, 'education')}
          />
        </div>

        {/* Education */}
        <div className='section-container'>
            <p className='section-heading'>Current Education Status</p>
            <div>
              <div className='form-column start'>
                <div className='profile-input-container'>
                <label>Are you currently attending Secondary School?</label>
                <div>
                  <input
                    className='radio-as-checkbox'
                    type="radio"
                    name="secondarySchool"
                    id="yes"
                    checked={formData.secondarySchool === 'yes'}
                    onChange={() => handleRadioChange('education', 'secondarySchool', 'yes')}
                  />
                  <label htmlFor='yes'>Yes</label>
                  <input
                    className='radio-as-checkbox'
                    type="radio"
                    name="secondarySchool"
                    id="no"
                    checked={formData.secondarySchool === 'no'}
                    onChange={() => handleRadioChange('education', 'secondarySchool', 'no')}
                  />
                  <label htmlFor='no'>No</label>
                </div>
              </div>

              <div className='profile-input-container'>
                <label htmlFor='lui'>What is your Queensland Education LUI?</label>
                  <input
                    className='large'
                    type='text'
                    name='lui'
                    placeholder='LUI'
                    value={formData.lui}
                    onChange={(e) => handleInputChange(e, 'education')}
                  />
              </div>
              <div className='profile-input-container'>
                <label htmlFor='previousName'>What is your highest completed School level?</label>
                <select
                  className='medium'
                  id="highestCompleted"
                  name="highestCompleted"
                  value={formData.highestCompleted}
                  onChange={(e) =>
                    setFormData((prev: any) => ({
                      ...prev,
                      highestCompleted: e.target.value,
                    }))
                  }
                >
                  <option value="" disabled hidden>Select Level</option>
                  <option value="grade8">Grade 8</option>
                  <option value="grade9">Grade 9</option>
                  <option value="grade10">Grade 10</option>
                  <option value="grade11">Grade 11</option>
                  <option value="grade12">Grade 12</option>
                </select>
              </div>

              <div className='profile-input-container'>
              <label htmlFor='year-complete'>Year completed?</label>
                <input
                  className='large'
                  type='text'
                  name='lui'
                  placeholder='LUI'
                  value={formData.lui}
                  onChange={(e) => handleInputChange(e, 'education')}
                />
              </div>

              <p className='instruction-text'>
                Have you completed any of the following qualifications?
              </p>
                <div className='form-row wrap'>
                  <div className='profile-input-container'>
                    <input
                      className=''
                      type="checkbox"
                      name="certificate 1"
                      checked={formData.priorQuals.includes('certificate 1')}
                      onChange={(e) => handleCheckboxGroupChange(e, 'priorQuals')}
                    />
                    <label>Certificate 1</label>
                  </div>
                  <div className='profile-input-container'>
                    <input
                      className=''
                      type="checkbox" 
                      name='certificate 2'      
                      checked={formData.priorQuals.includes('certificate 2')}
                      onChange={(e) => handleCheckboxGroupChange(e, 'priorQuals')}
                    />
                    <label>Certificate 2</label>
                </div>
                <div className='profile-input-container'>
                  <input
                    className=''
                    type="checkbox"
                    name='certificate 3'
                    checked={formData.priorQuals.includes('certificate 3')}
                    onChange={(e) => handleCheckboxGroupChange(e, 'priorQuals')}
                  />
                  <label>Certificate 3</label>
                </div>
                <div className='profile-input-container'>
                  <input
                    className=''
                    type="checkbox"
                    name='certificate 4'
                    checked={formData.priorQuals.includes('certificate 4')}
                    onChange={(e) => handleCheckboxGroupChange(e, 'priorQuals')}
                  />
                  <label>Certificate 4</label>
                </div>
                <div className='profile-input-container'>
                  <input
                    className=''
                    type="checkbox"
                    name='diploma'
                    checked={formData.priorQuals.includes('diploma')}
                    onChange={(e) => handleCheckboxGroupChange(e, 'priorQuals')}
                  />
                  <label>Diploma</label>
                </div>
                <div className='profile-input-container'>
                  <input
                    className=''
                    type="checkbox"
                    name='advanced diploma'
                    checked={formData.priorQuals.includes('advanced diploma')}
                    onChange={(e) => handleCheckboxGroupChange(e, 'priorQuals')}
                  />
                  <label>Advanced Diploma</label>
                </div>
                <div className='profile-input-container'>
                  <input
                    className=''
                    type="checkbox"
                    name='bachelor degree'
                    checked={formData.priorQuals.includes('bachelor degree')}
                    onChange={(e) => handleCheckboxGroupChange(e, 'priorQuals')}
                  />
                  <label>Bachelor Degree or Higher</label>
                </div>
                <div className='profile-input-container'>
                  <input
                    className=''
                    type="checkbox"
                    name='other'
                    checked={formData.priorQuals.includes('other')}
                    onChange={(e) => handleCheckboxGroupChange(e, 'priorQuals')}
                  />
                  <label>Other</label>
                </div>
              </div>
                {formData.priorQuals.includes('other') &&
                  <div className='profile-input-container column full-width'>
                    <SpacerDiv rem={1} />
                    <textarea
                      name='otherQuals'
                      placeholder='Please specify other qualifications...' 
                      value={formData.otherQuals}
                      onChange={(e: any) => handleInputChange(e, 'education')}
                    />
                  </div>
                }
              </div>
            </div>
          </div>
      </div>

      {/* Support */}
      <div className='section-container'>
        <p className='section-heading'>Support Requirements</p>
        <div >
          <p className='instruction-text'>
            Do you have any specific needs in relation to the following:
            <br />
            <span className='note-text'>Please leave these blank if you do not require support in the following areas.</span>
          </p>
          <SpacerDiv rem={1} />
          <div className='form-column'>
            <div className='profile-input-container column full-width'>
              <label htmlFor='literacy'>Literacy</label>
              <textarea
                name='literacy'
                placeholder='Literacy or Numeracy Support...'
                value={formData.literacy}
                onChange={(e: any) => handleInputChange(e, 'education')}
              />
            </div>
            <div className='profile-input-container column full-width'>
              <label htmlFor='diet'>Diet</label>
              <textarea
                name='diet'
                placeholder='Dietary Requirements...'
                value={formData.diet}
                onChange={(e: any) => handleInputChange(e, 'education')}
              />
            </div>
            <div className='profile-input-container column full-width'>
              <label htmlFor='literacy'>Access</label>
              <textarea
                name='access'
                placeholder='Acess Requirements...'
                value={formData.access}
                onChange={(e: any) => handleInputChange(e, 'education')}
              />
            </div>
            <p className='instruction-text'>
              Do you consider yourself to have any of the following challenges, impairments or long-term conditions?
            </p>
      
            <div className='form-row wrap'>
              <div className='profile-input-container'>
                <input
                  className=''
                  type="checkbox"
                  name="hearing"
                  checked={formData.challenges.includes('hearing')}
                  onChange={(e) => handleCheckboxGroupChange(e, 'challenges')}
                />
                <label>Hearing</label>
              </div>
              <div className='profile-input-container'>
                <input
                  className=''
                  type="checkbox"
                  name="physical"
                  checked={formData.challenges.includes('physical')}
                  onChange={(e) => handleCheckboxGroupChange(e, 'challenges')}
                />
                <label>Physical</label>
              </div>
              <div className='profile-input-container'>
                <input
                  className=''
                  type="checkbox"
                  name="intellectual"
                  checked={formData.challenges.includes('intellectual')}
                  onChange={(e) => handleCheckboxGroupChange(e, 'challenges')}
                />
                <label>Intellectual</label>
              </div>
              <div className='profile-input-container'>
                <input
                  className=''
                  type="checkbox"
                  name="learning"
                  checked={formData.challenges.includes('learning')}
                  onChange={(e) => handleCheckboxGroupChange(e, 'challenges')}
                />
                <label>Learning</label>
              </div>
              <div className='profile-input-container'>
                <input
                  className=''
                  type="checkbox"
                  name="mental illness"
                  checked={formData.challenges.includes('mental illness')}
                  onChange={(e) => handleCheckboxGroupChange(e, 'challenges')}
                />
                <label>Mental Illness</label>
              </div>
              <div className='profile-input-container'>
                <input
                  className=''
                  type="checkbox"
                  name="vision"
                  checked={formData.challenges.includes('vision')}
                  onChange={(e) => handleCheckboxGroupChange(e, 'challenges')}
                />
                <label>Vision</label>
              </div>
              <div className='profile-input-container'>
                <input
                  className=''
                  type="checkbox"
                  name="medical condition"
                  checked={formData.challenges.includes('medical condition')}
                  onChange={(e) => handleCheckboxGroupChange(e, 'challenges')}
                />
                <label>Medical Condition</label>
              </div>
              <div className='profile-input-container'>
                <input
                  className=''
                  type="checkbox"
                  name="acquired brain impairment"
                  checked={formData.challenges.includes('acquired brain impairment')}
                  onChange={(e) => handleCheckboxGroupChange(e, 'challenges')}
                />
                <label>Acquired Brain Impairment</label>
              </div>
            </div>
            {formData.challenges.length !== 0 &&
              <div className='profile-input-container column full-width'>
                <SpacerDiv rem={1} />
                <textarea
                  name='support'
                  placeholder='How can we help support your training?'
                  value={formData.support}
                  onChange={(e: any) => handleInputChange(e, 'education')}
                />
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  </form>
  );
};

export default EducationContent;
