import React, {useContext, useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import {ConstLink} from "ConstLink";
// import { Viewer, Worker } from '@react-pdf-viewer/core';
// import { Document, Page } from 'react-pdf';
// import { Document, Page, setOptions } from 'react-pdf/build/entry.webpack'
import { pdfjs } from "react-pdf";
// import document from 'Components/document.pdf'
// import backgroundImage from "Images/TPLogoHighRes.png";
import Bluey from "Components/Bluey"
import {Row, Col} from "react-bootstrap"
import Select from "react-select";
import { useUserField } from 'CustomHooks';


export default function Template() {

    const navigate = useNavigate();
    const isLoggedIn = useUserField('loggedIn');
    const { setQuickMenu, _setStepButtons } = useContext(ConstLink);
    const [_pageName, setPageName] = useState('');

    // const [numPages, setNumPages] = useState();
    // const [pageNumber, setPageNumber] = useState(1);
    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;
        const newValue = type === 'checkbox' ? checked : value;
        setFormData((prevData) => ({
            ...prevData,
            [name]: newValue
        }));
    };
    const getFormattedDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const [formData, setFormData] = useState({
        nameClassVal: '',
        templateVal: '',
        startDateVal: getFormattedDate(new Date()),
        finishDateVal: getFormattedDate(new Date()),
        startTimeVal: '08:00',
        finishTimeVal: '15:00',
        classMinimumVal: '',
        classMaximumVal: '',
        classCostVal: '',
        isPrivateVal: 'checked',
        venueVal: '',
        businessVal: '',
        trainerVal: '',
        thirdPartyVal: '',
        aenVal: '',
        assessmentMethodVal: '',
        deliveryMethodVal: '',
        deliveryModeVal: '',
        txtCustomVal: '',
        venueAddress1Val: '',
        venueAddress2Val: '',
        venueSuburbVal: '',
        venueStateVal: '',
        venuePostCodeVal: '',
        venuePhoneVal: '',
        venueWebVal: '',
        venueParkingVal: '',
        venuePublicTransportVal: '',
        venueDisableVal: '',
        venueAuditDateVal: '',
        venueHousekeepingVal: '',
        venueHousekeepingTxtVal: '',
        venueLightingVal: '',
        venueLightingTxtVal: '',
        venueVentilationVal: '',
        venueVentilationTxtVal: '',
        venueNoiseVal: '',
        venueNoiseTxtVal: '',
        venueHygieneVal: '',
        venueHygieneTxtVal: '',
        venueElectricalVal: '',
        venueElectricalTxtVal: '',
        venueFirstAidVal: '',
        venueFirstAidTxtVal: '',
        venueFireVal: '',
        venueFireTxtVal: '',
        venueSmokingVal: '',
        venueSmokingTxtVal: '',
        venueSpecialTxtVal: '',
    });
    // setOptions({
    //     cMapUrl: 'cmaps/',
    //     cMapPacked: true
    // })
    const options = [
        { value: 'option1', label: 'Create New Venue' },
        { value: 'option2', label: 'Option 2' },
        { value: 'option3', label: 'Option 3' },
        // Add more options as needed
    ];
    const customSelectStyles = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: state.isFocused ? '#d0d0d0' : '#d0d0d0', // Border color changes on focus
            boxShadow: state.isFocused ? '0 0 5px #955cce' : 'none', // Box shadow changes on focus
            '&:hover': {
                borderColor: '#955cce', // Border color changes on hover
            },
        }),
        option: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: state.isFocused ? '#c1acd9' : 'white', // Background color changes on option hover
            color: state.isFocused ? 'white' : 'black',
        }),
    };
    if ( !isLoggedIn ) {
        navigate('/login')
    }
    useEffect(() => {
        // Run the updateQuickMenu1 function on page load
        updateQuickMenu();
        pdfjs.GlobalWorkerOptions.workerSrc =`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
        const pathArray = window.location.pathname.split('/');
        const pageParam = pathArray[pathArray.length - 1];
        setPageName(pageParam || 'Default Page');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateQuickMenu = () => {
        setQuickMenu(
            "Item 1",
            "Item 2",
            "Item 3",
            "Item 4",
            "Item 5",
            "Item 6" );
    };

    // const updateStepButtons = () => {

    //     setStepButtons([
    //         'Add More Buttons', null, null, null, null,
    //         null, null, null, null, null,
    //         null, null, null, null, null,
    //         null, null, null, null, null
    //     ]);

    // }

    return (
        <div classname="arrow-tab2">
            <form >
            <Bluey />
                <div className={"page-common"} style={{ marginTop: 100, zIndex: 1 }}>
<div>
                <Row>
                    <Col xs={6} md={6} xl={6} style={{marginTop: 15, textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                        <h1 style={{height: '100%'}}>Venue Photo</h1>
                    </Col>
                    <Col xs={6} md={6} xl={6} style={{marginTop: 15, textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                        <Row>
                            <Col xs={12} md={12} xl={12} style={{marginTop: 15, textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                <label htmlFor="comboBox">Training Venue -</label>
                                <Select
                                    id="comboBox"
                                    value={formData.venueVal}
                                    options={options}
                                    styles={customSelectStyles}
                                    // onChange={handleCombo1Change}
                                    isClearable
                                    isSearchable
                                    // placeholder="Venue Description"
                                    title="This is the name of the Physical Venue the training
                                                    is being held at"
                                    required
                                />

                                <Col xs={12} md={12} xl={12} style={{padding: 0, marginTop: 25, textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                    <label htmlFor="comboBox">Address</label>
                                    <input
                                        type="text"
                                        name="venueAddress1"
                                        value={formData.venueAddress1Val}
                                        placeholder="Venue Address"
                                        onChange={handleInputChange}
                                        title="This is the address of the venue"
                                    />
                                    <input
                                        type="text"
                                        name="venueAddress2"
                                        value={formData.venueAddress2Val}
                                        placeholder="Venue Address"
                                        onChange={handleInputChange}
                                        title="This is line 2 of the address of the venue"
                                    />
                                </Col>
                                    <Row>
                                        <Col xs={6} md={6} xl={6} style={{padding: 0, marginLeft: '13px', textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>

                                            <input
                                                type="text"
                                                name="venueSuburb"
                                                value={formData.venueSuburbVal}
                                                placeholder="Suburb"
                                                onChange={handleInputChange}
                                                title="This is the Suburb of the venue"
                                            />
                                        </Col>
                                        <Col xs={2} md={2} xl={2} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                            <input
                                                type="text"
                                                name="venueState"
                                                value={formData.venueStateVal}
                                                placeholder="State" onChange={handleInputChange}
                                                title="This is state of the venue"
                                            />
                                        </Col>
                                        <Col xs={3} md={3} xl={3} style={{textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                            <input
                                                type="text"
                                                name="venuePostCode"
                                                value={formData.venuePostCodeVal}
                                                placeholder="Post Code"
                                                onChange={handleInputChange}
                                                title="This is the postcode of the venue"
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={6} md={6} xl={6} style={{marginTop: 25, textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                            Phone
                                            <input
                                                type="text"
                                                name="venuePhone"
                                                value={formData.venuePhoneVal}
                                                placeholder="Phone"
                                                onChange={handleInputChange}
                                                title="This is the Phone number for the venue"
                                            />
                                        </Col>
                                        <Col xs={6} md={6} xl={6} style={{marginTop: 25, textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                            Web
                                            <input
                                                type="text"
                                                name="venueWeb"
                                                value={formData.venueWebVal}
                                                placeholder="Website URL"
                                                onChange={handleInputChange}
                                                title="This is the web site for the venue"
                                                // width={'100%'}
                                            />
                                        </Col>
                                    {/*</Row>*/}
                                    </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row>

                    <Col xs={6} md={6} xl={6} style={{marginTop: 25, textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                        Public Transport
                        <input
                            type="text"
                            name="venuePublicTransport"
                            value={formData.venuePublicTransportVal}
                            placeholder="Parking"
                            onChange={handleInputChange}
                            title="This is special instructions for finding parking at the venue"
                        />
                        Lunch / Meals
                        <input
                            type="text"
                            name="venueMeals"
                            value={formData.venueMealsVal}
                            placeholder="Meal Accessibility"
                            onChange={handleInputChange}
                            title="This is to give people an idea of what food facilities will be
                                                available to them at the venue"
                        />
                    </Col>
                    <Col xs={6} md={6} xl={6} style={{marginTop: 25, textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                        Disabled Access
                        <input
                            type="text"
                            name="venueDisable"
                            value={formData.venueDisableVal}
                            placeholder="Call ahead"
                            onChange={handleInputChange}
                            title="This is special instructions for Disabled Access at the venue"
                        />
                        Public Transport
                        <input
                            type="text"
                            name="venuePublicTransport"
                            value={formData.venuePublicTransportVal}
                            placeholder="Parking"
                            onChange={handleInputChange}
                            title="This is special instructions for finding parking at the venue"
                        />
                    </Col>
                </Row>
                </div>
                </div>

            </form>
        </div>
    );
}

// =============================== To Do =================================== //



// =============================== Bugs =================================== //