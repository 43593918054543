import React, { useState } from 'react';
import './Styles/tabbedContainer.scss';
import { SpacerDiv } from '.';

interface TabbedContainerProps {
  tabs: string[];
  content: React.ReactNode[];
  statuses?: number[];
}

const mapStatusToClass = (status: number) => {
  switch (status) {
    case 0:
      return 'default';
    case 1:
      return 'complete';
    case 2:
      return 'incomplete';
    default:
      return 'default';
  }
};

const TabbedContainer: React.FC<TabbedContainerProps> = ({ tabs, content, statuses }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleSubmit = () => {
    const formElement = document.querySelector(`#form-${activeTab}`);
    if (formElement) {
      console.log(`Submitting form for tab ${activeTab}`); 
      (formElement as HTMLFormElement).requestSubmit();
    } else {
      console.error(`No form element found for tab ${activeTab}`); 
    }
  };

  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };

  const isActive = (index: number) => {
    return index === activeTab;
  };

  return (
    <div className='tabbed-container'>
      <div className='tab-header-container'>
        {tabs.map((name, index) => {
          const statusClass = statuses ? mapStatusToClass(statuses[index]) : 'default';

          return (
            <div
              key={index}
              className={`tab-header ${isActive(index) ? 'active' : ''} ${statusClass !== 'default' ? statusClass : ''}`} 
              style={{ zIndex: tabs.length - index }}
              onClick={() => handleTabClick(index)}
            >
              <span className='tab-name'>{name}</span>
              {index !== tabs.length - 1 && <div className='arrow' />}
            </div>
          );
        })}
      </div>

      {/* Tab Content */}
      <div className='tab-content'>
        {content[activeTab]}
        <SpacerDiv rem={1} />
        <button className='btn-primary' onClick={handleSubmit}>Next</button>
        <SpacerDiv rem={0.5} />
      </div>
    </div>
  );
};

export default TabbedContainer;
