// DISABLED LINTING FOR THIS FILE BECAUSE IT IS A TEMPLATE FILE
/* eslint-disable */
import React, {useContext, useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import {ConstLink} from "ConstLink";
// import { Viewer, Worker } from '@react-pdf-viewer/core';
// import { Document, Page } from 'react-pdf';
// import { Document, Page, setOptions } from 'react-pdf/build/entry.webpack'
import { Document, Page, pdfjs } from "react-pdf";
import document from 'Components/document.pdf'
import {useUserField} from "CustomHooks";


export default function Template() {

    const navigate = useNavigate();
    const isLoggedIn = useUserField('loggedIn');
    const { setQuickMenu, setStepButtons } = useContext(ConstLink);
    const [pageName, setPageName] = useState('');

    const [numPages, setNumPages] = useState();
    const [pageNumber, setPageNumber] = useState(1);

    // setOptions({
    //     cMapUrl: 'cmaps/',
    //     cMapPacked: true
    // })


    if ( !isLoggedIn ) {
        navigate('/login')
    }
    useEffect(() => {
        // Run the updateQuickMenu1 function on page load
        updateQuickMenu();
        pdfjs.GlobalWorkerOptions.workerSrc =`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
        const pathArray = window.location.pathname.split('/');
        const pageParam = pathArray[pathArray.length - 1];
        setPageName(pageParam || 'Default Page');
    }, []);

    const updateQuickMenu = () => {
        setQuickMenu(
            "Item 1",
            "Item 2",
            "Item 3",
            "Item 4",
            "Item 5",
            "Item 6" );
    };

    const updateStepButtons = () => {

        setStepButtons([
            'Add More Buttons', null, null, null, null,
            null, null, null, null, null,
            null, null, null, null, null,
            null, null, null, null, null
        ]);

    }

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                fontSize: '40px',
                fontWeight: 'bold',
            }}
        >
            {pageName}

            {/*<Viewer fileUrl="https://s1.q4cdn.com/806093406/files/doc_downloads/test.pdf" />*/}

            <div style={{ width: "600px", height: "800px", overflow: "hidden" }}>
                <Document file={document}>
                    <Page pageNumber={1} />
                </Document>
            </div>
        </div>
    );
}

// =============================== To Do =================================== //



// =============================== Bugs =================================== //