// import React, {useContext, useState, useEffect} from 'react';
// import { useNavigate } from 'react-router-dom';
// import {ConstLink} from "ConstLink";
// import { Document, Page, pdfjs} from "react-pdf";
// import Bluey from "Components/Bluey"
// import pdfFile from 'Components/document.pdf';
//  import { useUserField } from 'CustomHooks';
//
// export default function Test() {
//
//     const navigate = useNavigate();
//     const isLoggedIn = useUserField('loggedIn');
//     const { setQuickMenu, setStepButtons } = useContext(ConstLink);
//     const [pageName, setPageName] = useState('');
//
//     const [numPages, setNumPages] = useState(null);
//     const [pageNumber, setPageNumber] = useState(1);
//     // const [pdfPath, setPdfPath] = useState("src/Components/document.pdf");
//     const [pdfPath, setPdfPath] = useState(pdfFile);
//
//     if ( !isLoggedIn ) {
//         navigate('/login')
//     }
//
//     useEffect(() => {
//         pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
//
//         // Asynchronous download PDF
//         pdfjs.getDocument(pdfPath).promise.then(function(pdf) {
//             console.log("Number of Pages: ", pdf.numPages);
//             setNumPages(pdf.numPages);
//
//             for(let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
//                 pdf.getPage(pageNum).then(function(page) {
//                     // Returns a promise of an object with an items array which contains our text
//                     return page.getTextContent();
//                 }).then(function(textContent) {
//                     textContent.items.forEach(function(textItem) {
//                         console.log(textItem.str); // Log each item's text
//                     });
//                 });
//             }
//         });
//     }, [pdfPath]); // Re-run effect when pdfPath changes
//
//     const updateQuickMenu = () => {
//         setQuickMenu(
//             "Item 1",
//             "Item 2",
//             "Item 3",
//             "Item 4",
//             "Item 5",
//             "Item 6" );
//     };
//
//     const updateStepButtons = () => {
//         setStepButtons([
//             'Add More Buttons', null, null, null, null,
//             null, null, null, null, null,
//             null, null, null, null, null,
//             null, null, null, null, null
//         ]);
//     }
//
//     return (
//         <div>
//             <Bluey />
//
//             <div className={"page-common"} style={{ marginTop: 100, zIndex: 1 }}>
//                 {pageName}
//             </div>
//
//             <Document
//                 file={pdfPath}
//                 onLoadSuccess={({ numPages }) => setNumPages(numPages)}
//             >
//                 {
//                     Array.from(
//                         new Array(numPages),
//                         (el, index) => (
//                             <Page key={`page_${index + 1}`} pageNumber={index + 1} />
//                         ),
//                     )
//                 }
//             </Document>
//             <p>Page {pageNumber} of {numPages}</p>
//         </div>
//     );
// }

import React, {useContext, useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import {ConstLink} from "ConstLink";
import { pdfjs} from "react-pdf";
import Bluey from "Components/Bluey"
import pdfFile from 'Components/document.pdf';
import { useUserField } from 'CustomHooks';


export default function Test() {

    const navigate = useNavigate();
    const isLoggedIn = useUserField('loggedIn');
    const { setQuickMenu, _setStepButtons } = useContext(ConstLink);
    const [pageName, setPageName] = useState('');

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, _setPageNumber] = useState(1);
    // const [pdfPath, setPdfPath] = useState("src/Components/document.pdf");
    const [pdfPath, _setPdfPath] = useState(pdfFile);

    if ( !isLoggedIn ) {
        navigate('/login')
    }

    useEffect(() => {
        updateQuickMenu();
        pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
        const pathArray = window.location.pathname.split('/');
        const pageParam = pathArray[pathArray.length - 1];
        setPageName(pageParam || 'Default Page');
        // Asynchronous download PDF
        pdfjs.getDocument(pdfPath).promise.then(function(pdf) {
            console.log("Number of Pages: ", pdf.numPages);
            setNumPages(pdf.numPages);

            for(let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
                pdf.getPage(pageNum).then(function(page) {
                    // Returns a promise of an object with an items array which contains our text
                    return page.getTextContent();
                }).then(function(textContent) {
                    textContent.items.forEach(function(textItem) {
                        console.log(textItem.str); // Log each item's text
                    });
                });
            }
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pdfPath]); // Re-run effect when pdfPath changes

    const updateQuickMenu = () => {
        setQuickMenu(
            "Item 1",
            "Item 2",
            "Item 3",
            "Item 4",
            "Item 5",
            "Item 6" );
    };

    // const updateStepButtons = () => {
    //     setStepButtons([
    //         'Add More Buttons', null, null, null, null,
    //         null, null, null, null, null,
    //         null, null, null, null, null,
    //         null, null, null, null, null
    //     ]);
    // }

    return (
        <div>
            <Bluey />

            <div className={"page-common"} style={{ marginTop: 100, zIndex: 100 }}>

                {pageName}

                {/*<Document*/}
                {/*    file={pdfPath}*/}
                {/*    onLoadSuccess={({ numPages }) => setNumPages(numPages)}*/}
                {/*>*/}
                {/*    <Page pageNumber={1} />*/}
                {/*</Document>*/}

                <p>Page {pageNumber} of {numPages}</p>

            </div>



        </div>
    );
}


// =============================== To Do =================================== //



// =============================== Bugs =================================== //
