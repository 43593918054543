import React, {useContext, useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import {ConstLink} from "ConstLink";
// import { Viewer, Worker } from '@react-pdf-viewer/core';
// import { Document, Page } from 'react-pdf';
// import { Document, Page, setOptions } from 'react-pdf/build/entry.webpack'
import {  pdfjs } from "react-pdf";
import TemplatesTable from "Components/TemplatesTable";
import {useUserField} from "CustomHooks";


export default function CertificateCreation() {

    const navigate = useNavigate();
    const isLoggedIn = useUserField('loggedIn');
    const { setQuickMenu, _setStepButtons } = useContext(ConstLink);
    const [_pageName, setPageName] = useState('');

    // const [numPages, setNumPages] = useState();
    // const [pageNumber, setPageNumber] = useState(1);

    // setOptions({
    //     cMapUrl: 'cmaps/',
    //     cMapPacked: true
    // })


    if ( !isLoggedIn ) {
        navigate('/login')
    }
    useEffect(() => {
        // Run the updateQuickMenu1 function on page load
        updateQuickMenu();
        pdfjs.GlobalWorkerOptions.workerSrc =`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
        const pathArray = window.location.pathname.split('/');
        const pageParam = pathArray[pathArray.length - 1];
        setPageName(pageParam || 'Default Page');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateQuickMenu = () => {
        setQuickMenu(
            "Item 1",
            "Item 2",
            "Item 3",
            "Item 4",
            "Item 5",
            "Item 6" );
    };

    // const updateStepButtons = () => {

    //     setStepButtons([
    //         'Add More Buttons', null, null, null, null,
    //         null, null, null, null, null,
    //         null, null, null, null, null,
    //         null, null, null, null, null
    //     ]);

    // }

    return (
        <div className={"page-common"} style={{ marginTop: 100 }}>

            <TemplatesTable />

        </div>
    );
}

// =============================== To Do =================================== //



// =============================== Bugs =================================== //