/* eslint-disable */

import React, { useContext, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {ConstLink} from "ConstLink";
import Bluey from "Components/Bluey"
import {Row, Col, Container} from "react-bootstrap";
import BorderedComponent from "Components/BorderedComponent";
import { useLocation, useNavigation } from 'react-router-dom';
import CustomAlert from "Components/CustomAlert";
import { useHotkeys } from 'react-hotkeys-hook';
import SignaturePanel from "Components/SignaturePanel";
import { useUserField } from 'CustomHooks';

export default function Marking() {

    // Inside your component
    const formRef = useRef(null);

    // Receives data from previous page (Will be lost on refresh)
    const location = useLocation();
    const navigate = useNavigate();
    const isLoggedIn = useUserField('loggedIn');
    const data = location.state ? location.state.jsonObject : null;
    const [moduleId, setModuleId] = useState(window.sessionStorage.getItem("currentClassroom"));
    const [studentId, setStudentId] = useState(window.sessionStorage.getItem("currentStudent"));
    const { setQuickMenu, setStepButtons } = useContext(ConstLink);
    const [msg, setMsg] = useState(window.sessionStorage.getItem("message"));
    const [msgCode, setMsgCode] = useState(0)
    const [assessmentData, setAssessmentData] = useState([]);
    const [assessmentCount, setAssessmentCount] = useState(null);
    const [assessmentLength, setAssessmentLength] = useState(null);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [ questionNumFix , setQuestionNumFix] = useState(2);
    const [ completed, setCompleted ] = useState(false);
    const [checkedBoxes, setCheckedBoxes] = useState(Array(20).fill(null));
    const [isValid, setIsValid] = useState(true);
    const [loading, setLoading] = useState(false);
    const init = useRef(false);
    const [isBlocking, setIsBlocking] = React.useState(false);

    const handleCheckboxChange = (index, value) => {
        const newCheckedBoxes = [...checkedBoxes];
        newCheckedBoxes[index] = value;
        setCheckedBoxes(newCheckedBoxes);
    };





    async function fetchData(moduleID, studentID) {
        setLoading(true); // Set loading to true when the fetch starts
        try {
            const response = await fetch('https://api.trainingprofessionals.com.au/student/assess_answers', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify({ 'moduleID': moduleID, 'studentID': studentID })
            });

            const apiData = await response.json(); // Wait for the JSON data

            if (response.status !== 200) {
                switch (response.status) {
                    case 201:
                        setMsgCode(4);
                        break;
                    case 400:
                        setMsgCode(1);
                        break;
                    case 401:
                        console.log('Unauthorized access');
                        setMsg('Unauthorized');
                        setMsgCode(1);
                        break;
                    case 429:
                        console.log('Too many requests');
                        alert('Too many requests, please try again later');
                        setMsg('Rate limit exceeded');
                        setMsgCode(2);
                        break;
                    default:
                        console.log('Unexpected error');
                        break;
                }
                return;
            }

            const assessmentData = apiData.assessmentDetails;
            const studentAnswers = apiData.studentAnswers;

            let questionCounter = 1; // Initialize a counter for the question numbers

            // Filter the data and reassign question numbers sequentially
            const filteredData = Object.values(assessmentData || {}).filter(item =>
                item.section === 1 &&
                item.assessmentNumber === 1 &&
                ![-2, -1, 4, 9, 10, 13, 14].includes(item.QType)
            ).map(item => ({
                ...item,
                questionNum: questionCounter++ // Assign new sequential question numbers
            }));

            // Combine assessment data with student answers
            const combinedData = filteredData.map(item => {
                const studentAnswer = studentAnswers.find(answer => answer.qNum === item.questionNum);
                return {
                    ...item,
                    studentAnswer: studentAnswer || { c1: null, c2: null, c3: null, c4: null, c5: null, c6: null, c7: null, c8: null, c9: null, c10: null, c11: null, c12: null, c13: null, c14: null, c15: null, c16: null, c17: null, c18: null, c19: null, c20: null }
                };
            });

            // Filter out questions that have already been marked as 'S' in outcome
            const finalFilteredData = combinedData.filter(item => item.studentAnswer.outcome !== 'S');

            setAssessmentCount(apiData.count);
            setAssessmentData(finalFilteredData);
            setAssessmentLength(finalFilteredData.length);
            console.log('Final Filtered Data:', finalFilteredData);
            setMsgCode(4);

        } catch (err) {
            console.error('Error fetching data:', err);
            setMsgCode(0);
        } finally {
            setLoading(false); // Set loading to false when fetch completes
        }
    }

    useEffect(() => {
        // Ensure that the fetch data function is called only once
        if (!init.current && data?.modId && data?.studentId) {
            fetchData(data.modId, data.studentId);
            init.current = true; // Ensure fetch is only called once
        }

        // Prevent the user from accidentally leaving the page with unsaved changes
        const handleBeforeUnload = (event) => {
            const message = 'Are you sure you want to leave? All unsaved changes will be lost.';
            event.returnValue = message; // Standard for most browsers
            return message; // For some older browsers
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        window.onbeforeunload = function() {
            return 'Are you sure you want to navigate away?';
        }

        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [data]);

    function formatString(str, showAnswers = false) {
        if (!str) return str;

        let formattedStr = str;

        // Handle the special case for "(Fill in the blanks) - "
        const fillInTheBlanksIndex = formattedStr.indexOf('(Fill in the blanks) - ');
        if (fillInTheBlanksIndex !== -1) {
            const beforeBlanks = formattedStr.substring(0, fillInTheBlanksIndex + '(Fill in the blanks)'.length);
            const afterBlanks = formattedStr.substring(fillInTheBlanksIndex + '(Fill in the blanks)'.length).replace(/-/g, ' '); // Replace '-' with ' '
            formattedStr = `${beforeBlanks}\n${afterBlanks}`;
        }

        // Add a newline character after every colon
        formattedStr = formattedStr.replace(/:/g, ':\n');

        // Add a newline character after every semi-colon
        formattedStr = formattedStr.replace(/;/g, ';\n');

        // Replace <li> and </li> with line breaks
        formattedStr = formattedStr.replace(/<\/?li>/g, '\n');

        // Replace &apos; with regular apostrophe
        formattedStr = formattedStr.replace(/&apos;/g, "'");

        // Replace ~number with a dash
        formattedStr = formattedStr.replace(/~\d+/g, '-');

        // Remove <text:s/>
        formattedStr = formattedStr.replace(/<text:s\/>/g, '');

        // Remove <br>
        formattedStr = formattedStr.replace(/<br>/g, '\n');

        // Existing logic for handling #. pattern
        formattedStr = formattedStr.split('<br>').map(paragraph => {
            // Check if the paragraph contains #. pattern
            if (paragraph.includes('#.')) {
                const parts = paragraph.split('#.');
                const intro = parts.shift(); // text before the first #.
                const bullets = parts.map(item => '• ' + item.trim()).join('\n');
                return `${intro}\n${bullets}`;
            }
            return paragraph;
        }).join('\n');

        return formattedStr;
    }
    const splitQuestion = (text) => {
        const segments = [];
        let lastIndex = 0;

        const regex = /(\S*?)\[([^\]]+)\](\S*?)(?=\s|$)/g; // Matches word, gap, and following word
        let match;

        while ((match = regex.exec(text)) !== null) {
            // Add any text that precedes the match
            if (lastIndex !== match.index) {
                segments.push({ type: "text", content: text.substring(lastIndex, match.index) });
            }
            // Combine prefix, gap, and suffix into a single segment
            segments.push({ type: "word_with_gap", prefix: match[1], gap: match[2], suffix: match[3] });

            lastIndex = match.index + match[0].length;
        }

        // Add any remaining text that follows the last match
        if (lastIndex < text.length) {
            segments.push({ type: "text", content: text.substring(lastIndex) });
        }

        return segments;
    };

    function numberToWord(number) {
        const words = [
            "first", "second", "third", "fourth", "fifth",
            "sixth", "seventh", "eighth", "ninth", "tenth",
            "eleventh", "twelfth", "thirteenth", "fourteenth", "fifteenth",
            "sixteenth", "seventeenth", "eighteenth", "nineteenth", "twentieth"
        ];

        return words[number - 1] || "";
    }

    if ( !isLoggedIn ) {
        navigate('/login')
    }



    const updateQuickMenu = () => {
        setQuickMenu(
            "Item 1",
            "Item 2",
            "Item 3",
            "Item 4",
            "Item 5",
            "Item 6" );
    };

    const updateStepButtons = () => {

        setStepButtons([
            'Add More Buttons', null, null, null, null,
            null, null, null, null, null,
            null, null, null, null, null,
            null, null, null, null, null
        ]);

    }

    const parseMultiAnswer = (answer, type) => {
        // console.log("Answer:", answer);
        // console.log("Type:", type);


        const parts = answer.split(/(\d+\.\s[^-]+)\s-\s([^\d]+)/g);
        const result = [];
        for(let i = 1; i < parts.length; i += 3) {
            result.push({ first: parts[i].trim(), second: parts[i+1].trim() });
        }
        // console.log("Result:", result);
        return result;
    };



    function PageComponent({ data, currentQuestionIndex, totalQuestions }) {

        const [selectedOutcome, setSelectedOutcome] = useState(null); // For Satisfactory/Not Satisfactory
        const feedbackRef = useRef(null);
        const [microMarking, setMicroMarking] = useState({});
        useEffect(() => { console.log(microMarking); }, [microMarking]);

        useEffect(() => {
            if (selectedOutcome === 'NS' && feedbackRef.current) {
                feedbackRef.current.focus();
            }
        }, [selectedOutcome]);

        const handleOutcomeChange = (event) => {
            setSelectedOutcome(event.target.value);
        };

        const handleNextQuestion = () => {
            let incrementQuestionNum = questionNumFix;
            let incrementValue = 1; // default increment

            if (assessmentData[currentQuestionIndex]?.QType === -1 &&
                assessmentData[currentQuestionIndex + 1]?.QType === 13 &&
                assessmentData[currentQuestionIndex + 2]?.QType === 14) {
                incrementValue = 3; // skip the next 2 questions
            } else if (assessmentData[currentQuestionIndex]?.QType === -1 &&
                assessmentData[currentQuestionIndex + 1]?.QType === 13) {
                incrementValue = 2;
                incrementQuestionNum = questionNumFix - 1;
            } else if (assessmentData[currentQuestionIndex]?.QType === -1 &&
                assessmentData[currentQuestionIndex + 1]?.QType === 14) {
                incrementValue = 2;
                incrementQuestionNum = questionNumFix - 1;
            } else if (assessmentData[currentQuestionIndex]?.QType === -1 ) {
                incrementValue = 1;
                incrementQuestionNum = questionNumFix - 2;
            }

            // Ensure we don't go beyond the assessmentData length
            while (currentQuestionIndex + incrementValue < assessmentData.length &&
            [4, 9, 10].includes(assessmentData[currentQuestionIndex + incrementValue]?.QType)) {
                incrementQuestionNum++;
                incrementValue++;
            }

            if (currentQuestionIndex + incrementValue >= assessmentData.length) {
                console.log('Reached the end! Go do sum other stuff!');
                setCompleted(true);

                setQuestionNumFix(incrementQuestionNum);
                setCurrentQuestionIndex(prevIndex => prevIndex + incrementValue);
                setCheckedBoxes(Array(20).fill(null)); // Reset checkboxes

                return;
            }

            setQuestionNumFix(incrementQuestionNum);
            setCurrentQuestionIndex(prevIndex => prevIndex + incrementValue);
            setSelectedOutcome(null);  // Clear the selectedOutcome after moving to the previous question
        };

        const handlePreviousQuestion = () => {
            let decrementQuestionNum = questionNumFix;
            let decrementValue = 1; // default decrement

            // Simple decrement, modify according to your specific logic if needed
            if (currentQuestionIndex > 0) {
                setCurrentQuestionIndex(prevIndex => prevIndex - decrementValue);
                setQuestionNumFix(decrementQuestionNum - 1);
            } else {
                console.log("Already at the first question!");
            }
            setSelectedOutcome(null);  // Clear the selectedOutcome after moving to the previous question
        };

        // Using hotkeys to navigate questions
        useHotkeys('ArrowRight', handleNextQuestion);  // Move to the next question
        useHotkeys('ArrowLeft', handlePreviousQuestion);  // Move to the previous question

        useHotkeys('upArrow', () => {
            setSelectedOutcome('S');  // Directly set the outcome to 'NS'
            handleFormSubmit('S');  // Directly call handleFormSubmit with 'S'
        });

        useHotkeys('downArrow', () => {
            setSelectedOutcome('NS');  // Set the outcome, which will trigger the useEffect below
        });


        function convertToAnswers(originalObject) {
            const steps = [];
            for (let i = 1; i <= 5; i++) {
                const key = `c${i}`;
                if (originalObject[key]) {
                    steps.push({ id: i, text: originalObject[key] });
                }
            }
            return steps;
        }

        function extractAnswers(question) {
            const answerRegex = /\[([^\]]+)\]/g;
            let match;
            const answers = [];
            while ((match = answerRegex.exec(question)) !== null) {
                answers.push(match[1]);
            }
            return answers;
        }

        const handleFormSubmit = (outcome) => {

            // event.preventDefault();  // Prevent the default form submission behavior

            const feedbackValue = feedbackRef.current.value;
            const hasCheckboxes = false;
            const hasSelectedRadio = false;

            if ((hasCheckboxes && !checkedBoxes.every((isChecked) => !isChecked)) || hasSelectedRadio) {
                handleNextQuestion(); // Call onNext immediately when the form is submitted

                fetch('', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ /* answers and other data */ })
                })
                    .then(response => response.json())
                    .then(data => {
                        setSelectedOutcome('');
                        // setFeedback('');
                        // Handle the response, possibly moving to the next question or whatever else needs to be done
                        // (The onNext is already called, so you don't need to call it here again.)
                    })
                    .catch(error => {
                        console.error("Error submitting answers:", error);
                    });

            } else if (hasCheckboxes && checkedBoxes.every((isChecked) => !isChecked)) {
                console.log('Has checkboxes, none checked');
                setIsValid(false);
            } else {
                handleNextQuestion();  // Call handleNextQuestion immediately when the form is submitted

                // Update the outcome and feedback
                fetch('https://api.trainingprofessionals.com.au/student/assess_outcome', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        studentID: studentId, // Ensure data.studentId is correctly referenced
                        moduleID: moduleId, // Ensure data.assessmentModsId is correctly referenced
                        qNum: data.questionNum,
                        outcome: outcome, // Ensure formData.outcome and formData.feedback are populated correctly
                        // feedback: formData.get('feedback')  // Get the feedback from form data
                        feedback: feedbackValue
                    })
                })
                    .then(response => response.json())
                    .then(data => {
                        setSelectedOutcome('');
                    })
                    .catch(error => {
                        console.error("Error submitting outcome:", error);
                    });
            }

            console.log("Form submitted with outcome:", selectedOutcome);
        };


        let buttonText = "Next Question";  // default button text

        // First question (title/intro page)
        if (currentQuestionIndex === 0) {
            buttonText = "Next Question";
        }

        // Last question
        else if (completed) {
            buttonText = "Finish";
        }

        function adjustTextareaHeight(event) {
            const textarea = event.target;
            textarea.style.height = 'auto'; // Reset height to shrink if needed
            textarea.style.height = `${textarea.scrollHeight}px`; // Expand to the scroll height
        }

        const isTrueOrFalse = (c1, c2) => {
            const lowerC1 = c1.toLowerCase().trim();
            const lowerC2 = c2.toLowerCase().trim();
            return (lowerC1 === "true." && lowerC2 === "false.") || (lowerC1 === "false." && lowerC2 === "true.");
        };

        const isYesOrNo = (c1, c2) => {
            const lowerC1 = c1.toLowerCase().trim();
            const lowerC2 = c2.toLowerCase().trim();
            return (lowerC1 === "yes" && lowerC2 === "no") || (lowerC1 === "no" && lowerC2 === "yes");
        };

        const handleKeyDown = (event) => {
            // Check if the Enter key was pressed and not a Shift+Enter which is often used to enter a new line in a textarea
            if (event.key === 'Enter' && !event.shiftKey) {
                event.preventDefault();  // Prevent the default Enter key behavior (new line or form submission)
                handleFormSubmit(null, selectedOutcome);  // Assuming your handleFormSubmit can handle null as the first argument
            }
        };

        const initDoneRef = useRef(false); // This ref will track whether the initialization has been done

        // useEffect(() => {
        //     const initialMicroMarking = {};
        //     // console.log(microMarking);
        //     // Assuming student answers are stored like c1, c2, c3...
        //     // This regex matches keys that are exactly of the form 'c' followed by one or more digits.
        //     const answerKeyRegex = /^c\d+$/;
        //
        //     Object.keys(data).forEach((key) => {
        //         if (answerKeyRegex.test(key) && data[key] !== null) {
        //             const questionIndex = key.substring(1); // Remove the 'c' to isolate the number
        //             initialMicroMarking[`A${questionIndex}`] = null;
        //         }
        //     });
        //
        //     setMicroMarking(initialMicroMarking); // <-- This will cause an infinite loop
        // }, []);  // Run this effect only once, when the component mounts

        useEffect(() => {
            const initialMicroMarking = {};
            // Regex to match keys of the form 'c' followed by one or more digits.
            const answerKeyRegex = /^c\d+$/;

            if (data.QType === 7) {
                // For qType=7, count the gaps directly from the question content
                const gapCount = (formatString(data.question).match(/\[([^\]]+)\]/g) || []).length;
                for (let i = 1; i <= gapCount; i++) {
                    initialMicroMarking[`A${i}`] = null; // Initialize marking state for each gap
                }
            } else {
                // For other question types, initialize based on available 'c' keys
                Object.keys(data).forEach((key) => {
                    if (answerKeyRegex.test(key) && data[key] !== null) {
                        const questionIndex = key.substring(1); // Isolate the number after 'c'
                        initialMicroMarking[`A${questionIndex}`] = null;
                    }
                });
            }

            setMicroMarking(initialMicroMarking); // Set state without causing re-render loops
        }, [data]); // Dependency on `data` to ensure it runs when data is available and updated


        const updateMicroMarking = (questionId, value) => {
            setMicroMarking(prev => ({  // <--- This works fine
                ...prev,
                [questionId]: value
            }));
        };


        useEffect(() => {
            if (selectedOutcome === 'S' && Object.keys(microMarking).length > 0) {
                const newValues = {};
                let anyUpdates = false;

                // Iterate over each key in microMarking
                Object.entries(microMarking).forEach(([key, value]) => {
                    // Check if the current value is not 'tick'
                    if (value !== 'S') {
                        newValues[key] = 'S';  // Set to 'tick' if selectedOutcome is 'S'
                        anyUpdates = true;  // Flag that we have updates to apply
                    } else {
                        newValues[key] = value;  // Maintain current value if already 'tick'
                    }
                });

                // If there were any updates, update the state
                if (anyUpdates) {
                    setMicroMarking(newValues); // <--- Yet this does not work at all
                }

            } else if (selectedOutcome === 'NS' && Object.keys(microMarking).length > 0) {
                const newValues = {};
                let anyUpdates = false;

                // console.log(microMarking);

                // Iterate over each key in microMarking
                Object.entries(microMarking).forEach(([key, value]) => {
                    // Check if the current value is not 'cross'
                    if (value === null) {
                        newValues[key] = 'NS';  // Set to 'cross' if selectedOutcome is 'NS'
                        anyUpdates = true;  // Flag that we have updates to apply
                    } else {
                        newValues[key] = value;  // Maintain current value if already 'cross'
                    }
                });

                // If there were any updates, update the state
                if (anyUpdates) {
                    setMicroMarking(newValues);
                }

            }

        }, [selectedOutcome, microMarking]);


        const ToggleCheckbox = ({ questionId, initialState, updateMicroMarking }) => {

            const [checked, setChecked] = useState(initialState);

            const toggleCheck = () => {
                // Toggle between 'tick' and 'cross', ignoring null after initial setup
                const newValue = checked === 'S' ? 'NS' : 'S';
                setChecked(newValue);
                updateMicroMarking(questionId, newValue);

                if (newValue === 'NS') {
                    setSelectedOutcome('NS');
                }

            };

            const boxStyle = {
                display: 'inline-block',
                width: '24px',
                height: '24px',
                marginRight: '0px',
                marginBottom: '15px',
                border: '2px solid #000',
                borderRadius: '5px',
                verticalAlign: 'middle',
                textAlign: 'center',
                lineHeight: '10px',
                fontSize: '30px',
                cursor: 'pointer',
                backgroundColor: '#fff',
                color: checked ? (checked === 'S' ? 'green' : 'red') : 'transparent',  // Transparent when null
                userSelect: 'none',  // Prevents text selection
                WebkitUserSelect: 'none',  // Prevents text selection for WebKit browsers
                MozUserSelect: 'none',  // Prevents text selection for older versions of Firefox
                msUserSelect: 'none'  // Prevents text selection in IE and Edge
            };

            const textStyle = {
                display: 'inline-flex',
                alignItems: 'center',
                fontSize: '30px',
                marginLeft: '5px',
                userSelect: 'none',  // Also apply here to ensure label text isn't selectable
                WebkitUserSelect: 'none',
                MozUserSelect: 'none',
                msUserSelect: 'none'
            };

            return (
                <label style={textStyle} onClick={toggleCheck}>
            <span style={boxStyle}>
                {checked === 'S' ? '✓' : checked === 'NS' ? '✗' : ''}
            </span>
                </label>
            );
        };


        const renderQType13 = () => {
            if (assessmentData[currentQuestionIndex + 1]?.QType === 13) {
                return (
                    <div style={{ padding: '15px', textAlign: 'left' }}>
                        <h2 style={{ paddingBottom: 15 }}><strong>Information on Assessment Task</strong></h2>
                        <h3 style={{ whiteSpace: 'pre-line' }}>
                            {formatString(assessmentData[currentQuestionIndex + 1].question)}
                        </h3>
                    </div>
                );
            }
            return null; // Or some fallback component
        };

        const renderQType14 = () => {
            if (assessmentData[currentQuestionIndex + 1]?.QType === 14) {
                return (
                    <div style={{ padding: '15px', textAlign: 'left' }}>
                        <h3 style={{ whiteSpace: 'pre-line' }}>
                            {formatString(assessmentData[currentQuestionIndex + 2].question)} {/* Not +2 */}
                        </h3>
                    </div>
                );
            }
            return null; // Or some fallback component
        };

        return (
            <div>

                {data.QType === -1 && (

                    <div className="intro-page">

                        <h1><strong>Assessment Task {data.section}</strong></h1>
                        <div style={{ margin: '40px 0' }}>
                            <h2 style={{ textAlign: 'left' }}>{formatString(data.question)}</h2>
                        </div>

                        {/* Check if the next QType is 13 and the one after is 14 */}
                        {assessmentData[currentQuestionIndex + 1]?.QType === 13 &&
                        assessmentData[currentQuestionIndex + 2]?.QType === 14 ? (
                            <div>
                                <div style={{ padding: '15px', textAlign: 'left'  }}>
                                    <h2 style={{paddingBottom: 15}}><strong>Information on Assessment Task</strong></h2>
                                    <h3 style={{ whiteSpace: 'pre-line' }}>
                                        {formatString(assessmentData[currentQuestionIndex + 1].question)}
                                    </h3>
                                </div>

                                <div style={{ padding: '15px', textAlign: 'left'  }}>
                                    <h3 style={{ whiteSpace: 'pre-line' }}>
                                        {formatString(assessmentData[currentQuestionIndex + 2].question)}
                                    </h3>
                                </div>
                            </div>

                        ) : (

                            <div>
                                {renderQType13()}
                                {renderQType14()}
                            </div>

                        )}

                    </div>

                )}

                {data.QType === 0 && (
                    <div className="free-range-answer">
                        <Row>
                            <h1><strong>Question {data.questionNum}</strong></h1>
                            <div style={{ margin: '10px 0' }}>
                                <h2 className="justify-text">{formatString(data.question)}</h2>
                            </div>
                        </Row>
                        <Row>
                            <Col xs={12} md={6} xl={6} className={"column-left"}>
                                <BorderedComponent name="Candidate's answer">
                                    <textarea
                                        className="textarea-answer"
                                        rows={data.studentAnswer.c1 ? data.studentAnswer.c1.split('\n').length : 2}
                                        placeholder="Type your answer here..."
                                        // onChange={(e) => handleInputChange("freeRangeAnswer", e.target.value)}
                                        onInput={adjustTextareaHeight}
                                        value={data.studentAnswer.c1 || ""}
                                    />
                                </BorderedComponent>
                            </Col>
                            <Col xs={12} md={6} xl={6} className={"column-right"}>
                                <BorderedComponent name="Model answer">
                                    <textarea
                                        className="textarea-correct-answer"
                                        rows={data.studentAnswer.c1 ? data.studentAnswer.c1.split('\n').length : 1}
                                        placeholder="Type your answer here..."
                                        // onChange={(e) => handleInputChange("freeRangeAnswer", e.target.value)}
                                        onInput={adjustTextareaHeight}
                                        value={data.answer}
                                        readOnly
                                    />
                                </BorderedComponent>
                            </Col>
                        </Row>
                    </div>
                )}

                {data.QType === 1 && (
                    <div className="question">
                        <Row>
                            <h1><strong>Question {data.questionNum}</strong></h1>
                            <div style={{ margin: '40px 0' }}>
                                <h2 className="justify-text">{formatString(data.question)}</h2>
                            </div>
                        </Row>
                        <Row>
                            <Col xs={12} md={6} xl={6} className={"column-left"}>
                                <BorderedComponent name="Candidate's answer">
                                    {Array.from({ length: 20 }).map((_, i) => {
                                        const choice = data[`c${i + 1}`];
                                        if (choice) {
                                            return (
                                                <div key={i} style={{ display: 'flex', alignItems: 'start', marginBottom: '10px' }}>
                                                    <input
                                                        type="checkbox"
                                                        className="radio-as-checkbox"
                                                        id={`choice${i + 1}`}
                                                        name="answer"
                                                        value={`c${i + 1}`}
                                                        checked={data.studentAnswer[`c${i + 1}`] === "1"}
                                                        onChange={(e) => handleCheckboxChange(i, e.target.checked)}
                                                    />
                                                    <label htmlFor={`choice${i + 1}`}>{formatString(choice)}</label>
                                                </div>
                                            );
                                        }
                                        return null;
                                    })}
                                </BorderedComponent>
                            </Col>
                            <Col xs={12} md={6} xl={6} className={"column-right"}>
                                <BorderedComponent name="Model answer">
                                    {Array.from({ length: 20 }).map((_, i) => {
                                        const choice = data[`c${i + 1}`];
                                        if (choice) {
                                            return (
                                                <div key={i} style={{ display: 'flex', alignItems: 'start', marginBottom: '10px' }}>
                                                    <input
                                                        type="checkbox"
                                                        className="radio-as-checkbox-answer"
                                                        id={`choice${i + 1}`}
                                                        name="answer"
                                                        value={`c${i + 1}`}
                                                        checked={data.answer.split(',').includes((i + 1).toString())}
                                                        readOnly
                                                    />
                                                    <label htmlFor={`choice${i + 1}`}>{formatString(choice)}</label>
                                                </div>
                                            );
                                        }
                                        return null;
                                    })}
                                </BorderedComponent>
                            </Col>
                        </Row>
                    </div>
                )}

                {data.QType === 2 && (
                    <div className="question">
                        <h1><strong>Question {data.questionNum}</strong></h1>
                        <div style={{ margin: '40px 0' }}>
                            <h2 className="justify-text">{formatString(data.question)}</h2>
                        </div>
                        <Row>
                            <Col xs={12} md={6} xl={6} className={"column-left"}>
                                <BorderedComponent name="Candidate's answer">
                                    {isTrueOrFalse(data.c1, data.c2) ? (
                                        [data.c1, data.c2].map((choice, i) => { // Only iterate over true and false
                                            if (choice) {
                                                return (
                                                    <div key={i} style={{ display: 'flex', alignItems: 'start', marginBottom: '10px' }}>
                                                        <input
                                                            type="radio"
                                                            className="radio-as-checkbox"
                                                            id={`student-choice${i + 1}`}
                                                            name="student-answer"
                                                            value={`c${i + 1}`}
                                                            checked={data.studentAnswer[`c${i + 1}`] === "1"}
                                                            readOnly
                                                        />
                                                        <label htmlFor={`student-choice${i + 1}`}>{formatString(choice)}</label>
                                                    </div>
                                                );
                                            }
                                            return null;
                                        })
                                    ) : data.c1.includes("____________") ? (
                                        // Multi answer question with possible dual-input
                                        <div>
                                            {Array.from({ length: 20 }).map((_, i) => {
                                                const choice = data[`c${i + 1}`];
                                                if (choice) {
                                                    const parts = choice.split("~3");
                                                    return (
                                                        <div key={i} style={{ display: 'flex', alignItems: 'center', marginBottom: '0px', width: '100%' }}>
                                                            <label style={{ marginRight: '10px', fontSize: 30, marginBottom: 15 }}>{i + 1}</label>
                                                            {parts.length > 1 ? (
                                                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px', width: '100%' }}>
                                                                    <input
                                                                        type="text"
                                                                        className="multi-answer"
                                                                        placeholder="Part 1..."
                                                                        style={{ flex: 1 }}
                                                                        value={data.studentAnswer[`c${i + 1}`] ? data.studentAnswer[`c${i + 1}`].split('|')[0] : ""}
                                                                        readOnly
                                                                    />
                                                                    <span style={{ margin: '0 5px', alignSelf: 'center' }}></span>
                                                                    <input
                                                                        type="text"
                                                                        className="multi-answer"
                                                                        placeholder="Part 2..."
                                                                        style={{ flex: 1 }}
                                                                        value={data.studentAnswer[`c${i + 1}`] ? data.studentAnswer[`c${i + 1}`].split('|')[1] : ""}
                                                                        readOnly
                                                                    />
                                                                    <ToggleCheckbox
                                                                        questionId={`A${i + 1}`}  // Ensure `i` is defined in the context
                                                                        initialState={microMarking[`A${i + 1}`] || null}  // Provide a default state if undefined
                                                                        updateMicroMarking={updateMicroMarking}
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <><input
                                                                    type="text"
                                                                    className="multi-answer"
                                                                    placeholder={`Type your ${numberToWord(i + 1)} answer here...`}
                                                                    style={{
                                                                        width: '100%',
                                                                        userSelect: 'none',   // Prevent text selection
                                                                        pointerEvents: 'none' // Disable mouse interactions
                                                                    }}
                                                                    value={data.studentAnswer[`c${i + 1}`] || ""}
                                                                    readOnly
                                                                />
                                                                <ToggleCheckbox
                                                                    questionId={`A${i + 1}`}  // Ensure `i` is defined in the context
                                                                    initialState={microMarking[`A${i + 1}`] || null}  // Provide a default state if undefined
                                                                    updateMicroMarking={updateMicroMarking}
                                                                /></>
                                                            )}
                                                        </div>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </div>
                                    ) : (
                                        // Multiple choices question
                                        <div>
                                            {Array.from({ length: 20 }).map((_, i) => {
                                                const choice = data[`c${i + 1}`];
                                                if (choice) {
                                                    return (
                                                        <div key={i} style={{ display: 'flex', alignItems: 'start', marginBottom: '10px' }}>
                                                            <input
                                                                type="checkbox"
                                                                className="radio-as-checkbox"
                                                                id={`student-choice${i + 1}`}
                                                                name="student-answer"
                                                                value={`c${i + 1}`}
                                                                checked={data.studentAnswer[`c${i + 1}`] === "1"}
                                                                readOnly
                                                            />
                                                            <label htmlFor={`student-choice${i + 1}`}>{formatString(choice)}</label>
                                                        </div>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </div>
                                    )}
                                </BorderedComponent>
                            </Col>
                            <Col xs={12} md={6} xl={6} className={"column-right"}>
                                <BorderedComponent name="Model answer">
                                    {isTrueOrFalse(data.c1, data.c2) ? (
                                        [data.c1, data.c2].map((choice, i) => { // Only iterate over true and false
                                            if (choice) {
                                                return (
                                                    <div key={i} style={{ display: 'flex', alignItems: 'start', marginBottom: '10px' }}>
                                                        <input
                                                            type="radio"
                                                            className="radio-as-checkbox-answer"
                                                            id={`model-choice${i + 1}`}
                                                            name="model-answer"
                                                            value={`c${i + 1}`}
                                                            checked={parseInt(data.answer) === i + 1}
                                                            disabled={true}
                                                        />
                                                        <label htmlFor={`model-choice${i + 1}`}>{formatString(choice)}</label>
                                                    </div>
                                                );
                                            }
                                            return null;
                                        })
                                    ) : data.c1.includes("____________") ? (
                                        // Handling split or multiple input answer types
                                        <div>
                                            {(() => {
                                                // Check if any entry has split parts
                                                const hasSplits = Array.from({ length: 20 }).some((_, i) => {
                                                    return data[`c${i + 1}`] && data[`c${i + 1}`].includes("~3");
                                                });

                                                if (!hasSplits) {
                                                    return <p style={{ color: 'darkred' }}>{data.answer}</p>;
                                                }

                                                // Regular expression to find all parts that start with a number followed by a period
                                                const questionRegex = /(\d+\.)\s+([^\.]+?(?=\s+\d+\.|$))/g;

                                                // Parse and prepare answers from data.answer
                                                const formattedAnswers = [...data.answer.matchAll(questionRegex)].map(match => {
                                                    const questionNumber = match[1].trim().slice(0, -1); // Remove the period and trim
                                                    const details = match[2].trim(); // Details are the text following the period
                                                    return { questionNumber, details };
                                                });

                                                // Map over the formatted answers and display them
                                                return formattedAnswers.map((answerDetail, index) => {
                                                    const detailParts = answerDetail.details.split('-').map(part => part.trim());

                                                    return (
                                                        <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                                            {/*<label style={{ marginRight: '10px', fontSize: 24 }}>{numberToWord(parseInt(answerDetail.questionNumber))}</label>*/}
                                                            {detailParts.length > 1 ? (
                                                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px', width: '100%' }}>
                                                                    <input
                                                                        type="text"
                                                                        className="multi-answer-example"
                                                                        placeholder="Part 1..."
                                                                        value={detailParts[0] || ""}
                                                                        disabled={true}
                                                                        style={{ flex: 1, minWidth: '50%' }} // Ensuring a minimum width
                                                                    />

                                                                    <input
                                                                        type="text"
                                                                        className="multi-answer-example"
                                                                        placeholder="Part 2..."
                                                                        value={detailParts[1] || ""}
                                                                        disabled={true}
                                                                        style={{ flex: 1, minWidth: '50%' }} // Ensuring a minimum width
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <input
                                                                    type="text"
                                                                    className="multi-answer-example"
                                                                    value={answerDetail.details}
                                                                    disabled={true}
                                                                    style={{ width: '100%' }}
                                                                />
                                                            )}
                                                        </div>
                                                    );
                                                });
                                            })()}
                                        </div>
                                    ) : (
                                        // Multiple choices question
                                        <div>
                                            {Array.from({ length: 20 }).map((_, i) => {
                                                const choice = data[`c${i + 1}`];
                                                if (choice) {
                                                    return (
                                                        <div key={i} style={{ display: 'flex', alignItems: 'start', marginBottom: '10px' }}>
                                                            <input
                                                                type="checkbox"
                                                                className="radio-as-checkbox-answer"
                                                                id={`model-choice${i + 1}`}
                                                                name="model-answer"
                                                                value={`c${i + 1}`}
                                                                checked={data.answer.split(',').includes((i + 1).toString())}
                                                                readOnly
                                                            />
                                                            <label htmlFor={`model-choice${i + 1}`}>{formatString(choice)}</label>
                                                        </div>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </div>
                                    )}
                                </BorderedComponent>
                            </Col>
                        </Row>
                    </div>
                )}


                {data.QType === 5 && (
                    <div className="question">
                        <Row>
                            <h1><strong>Question {data.questionNum}</strong></h1>

                            <div className="justify-text" style={{margin: '40px 0', }}>
                                <div style={{padding: 15}} dangerouslySetInnerHTML={{__html: data.question}}></div>
                            </div>
                        </Row>
                        <Row>
                            <Col xs={12} md={6} xl={6} className={"column-left"}>
                                <BorderedComponent name="Candidate's answers">
                                    <ul>
                                        {Array.from({length: 20}).map((_, index) => {
                                            const key = `c${index + 1}`;
                                            if (data.studentAnswer[key]) {
                                                return <li key={index}>{data.studentAnswer[key]}</li>;
                                            }
                                            return null;
                                        }).filter(item => item !== null)}
                                    </ul>
                                </BorderedComponent>
                            </Col>
                            <Col xs={12} md={6} xl={6} className={"column-right"}>
                                <BorderedComponent name="Correct order">
                                    <ul>
                                        {Array.from({ length: 20 }).map((_, index) => {
                                            const key = `c${index + 1}`;
                                            if (data[key]) {
                                                return <li key={index} style={{ color: 'darkred' }}>{data[key]}</li>;
                                            }
                                            return null;
                                        }).filter(item => item !== null)}
                                    </ul>
                                </BorderedComponent>
                            </Col>
                        </Row>
                    </div>
                )}


                {data.QType === 6 && (
                    <div className="fill-in-the-gaps">
                        <h1><strong>Question {data.questionNum}</strong></h1>
                        <div className="justify-text" style={{ margin: '40px 0' }}>
                            <div dangerouslySetInnerHTML={{ __html: data.question }} style={{ marginBottom: '50px' }} />

                            <Row>
                                <Col xs={12} md={6} xl={6} className={"column-left"}>
                                    <BorderedComponent name="Candidate's answer">
                                        {Array.from({ length: 20 }).map((_, i) => {
                                            const key = `c${i + 1}`;
                                            const modelValue = data[key];
                                            const studentValue = data.studentAnswer[key];
                                            return (modelValue && studentValue) && (
                                                <div key={i} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                                    <strong>{modelValue[0]}</strong> {/* First character of the model's answer */}
                                                    <input
                                                        className="gap-input"
                                                        value={studentValue} // The complete student's answer
                                                        readOnly
                                                        style={{
                                                            fontFamily: "'Patrick Hand', cursive",
                                                            fontSize: '24px',
                                                            // marginLeft: '1px',
                                                            width: '100%', // Set input width to 100%
                                                            border: 'none', // Remove border
                                                            outline: 'none', // Remove focus outline
                                                            backgroundColor: 'transparent' // Make background transparent
                                                        }}
                                                    />
                                                    <ToggleCheckbox
                                                        questionId={`A${i + 1}`}  // Ensure `i` is defined in the context
                                                        initialState={microMarking[`A${i + 1}`] || null}  // Provide a default state if undefined
                                                        updateMicroMarking={updateMicroMarking}
                                                    />
                                                </div>
                                            );
                                        })}
                                    </BorderedComponent>
                                </Col>
                                <Col xs={12} md={6} xl={6} className={"column-right"}>
                                    <BorderedComponent name="Model answer">
                                        {Array.from({ length: 20 }).map((_, i) => {
                                            const value = data[`c${i + 1}`];
                                            return value && (
                                                <div key={i} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                                    <strong style={{ color: 'darkred' }}>{value[0]}</strong>
                                                    <span style={{
                                                        fontFamily: "'Patrick Hand', cursive",
                                                        fontSize: '24px',
                                                        color: 'darkred',
                                                        width: '100%' // Ensure the span fills the container
                                                    }}>
                                                        {value.substring(1)}
                                                    </span>
                                                </div>
                                            );
                                        })}
                                    </BorderedComponent>
                                </Col>
                            </Row>
                        </div>
                    </div>
                )}

                {data.QType === 7 && (
                    <div className="fill-in-the-gaps">
                        <h1><strong>Question {data.questionNum}</strong></h1>
                        <div className="justify-text" style={{ marginTop: 25 }}>
                            {(() => {
                                let gapCounter = 1; // Initialize a counter for the gaps
                                return formatString(data.question).split('\n').map((line, index) => (
                                    <p key={index}>
                                        {splitQuestion(line).map((segment, innerIndex) => {
                                        if (segment.type === "text") {
                                            return <span key={innerIndex}>{segment.content}</span>;
                                        } else if (segment.type === "gap") {
                                            const answer = data.studentAnswer[`c${gapCounter}`]?.toLowerCase() || "";
                                            gapCounter++; // Increment here without rendering it
                                            return (
                                                <span key={innerIndex} style={{ whiteSpace: "nowrap" }}>
                                                    <span style={{
                                                        textDecoration: "underline",
                                                        color: "#17176e", // Set to blue color
                                                        fontFamily: "'Patrick Hand', cursive", // Set the font family
                                                        fontSize: "24px", // Set the font size
                                                        display: "inline-block"
                                                    }}>
                                                        {answer || "___"}
                                                    </span>
                                                    <ToggleCheckbox
                                                        questionId={`A${gapCounter-1}`}  // Adjusted to use `gapCounter`
                                                        initialState={microMarking[`A${gapCounter-1}`] || null}
                                                        updateMicroMarking={updateMicroMarking}
                                                    />
                                                </span>
                                                );
                                        } else if (segment.type === "word_with_gap") {
                                            const answer = data.studentAnswer[`c${gapCounter}`]?.toLowerCase() || "";
                                            gapCounter++; // Increment here without rendering it
                                            return (
                                                <span key={innerIndex} style={{ whiteSpace: "nowrap" }}>
                                                    <span>{segment.prefix}</span>
                                                    <span style={{
                                                        textDecoration: "underline",
                                                        color: "#17176e", // Set to blue color
                                                        fontFamily: "'Patrick Hand', cursive", // Set the font family
                                                        fontSize: "24px", // Set the font size
                                                        display: "inline-block"
                                                    }}>
                                                        {answer || "___"}
                                                    </span>
                                                    <span>{segment.suffix}</span>
                                                    <ToggleCheckbox
                                                        questionId={`A${gapCounter-1}`}  // Adjusted to use `gapCounter`
                                                        initialState={microMarking[`A${gapCounter-1}`] || null}
                                                        updateMicroMarking={updateMicroMarking}
                                                    />
                                                </span>
                                                );
                                        }
                                        return null;
                                    })}
                                    </p>
                                ));
                            })()}
                        </div>
                        <div className="fill-in-the-gaps" style={{ marginTop: 25 }}>
                            <h2>Answer Guide</h2>
                            <div className="justify-text" style={{ marginTop: 25 }}>
                                {(() => {
                                    let gapCounter = 0;  // Re-initialize the counter for the answers in the guide
                                    const answers = extractAnswers(data.question);  // Extract answers from the question text again

                                    return formatString(data.question).split('\n').map((line, index) => (
                                        <p key={index}>
                                            {splitQuestion(line).map((segment, innerIndex) => {
                                                if (segment.type === "text") {
                                                    return <span key={innerIndex}>{segment.content}</span>;
                                                } else if (segment.type === "gap") {
                                                    return (
                                                        <span key={innerIndex} style={{ whiteSpace: "nowrap" }}>
                                                            <span className="gap-input" style={{
                                                                textDecoration: "underline",
                                                                fontFamily: "'Patrick Hand', cursive", // Set the font family
                                                                fontSize: "24px", // Set the font size
                                                                color: "red" }}>
                                                            {answers[gapCounter++] || "___"}
                                                            </span>
                                                        </span>
                                                    );
                                                } else if (segment.type === "word_with_gap") {
                                                    return (
                                                        <span key={innerIndex} style={{ whiteSpace: "nowrap" }}>
                                                    <span>{segment.prefix}</span>
                                                    <span className="" style={{
                                                        textDecoration: "underline",
                                                        fontFamily: "'Patrick Hand', cursive", // Set the font family
                                                        fontSize: "24px", // Set the font size
                                                        color: "red" }}>
                                                        {answers[gapCounter++] || "___"}
                                                    </span>
                                                    <span>{segment.suffix}</span>
                                                </span>
                                                    );
                                                }
                                            return null;
                                        })}
                                        </p>
                                    ));
                                })()}
                            </div>
                        </div>
                    </div>
                )}

                {/*... other rendering logic for other QTypes can go here */}

                <form ref={formRef} onSubmit={ () => handleFormSubmit(selectedOutcome)}>

                    {/* The Button... OF DOOOOOM!!! */}
                    {!isValid && <p>You must select at least one option!</p>}
                    <div style={{marginLeft: '25px', display: 'flex', alignItems: 'start', justifyContent: 'start'}}>
                        <label className="label-criteria">Marking Criteria Here</label>
                    </div>

                    {/*<div className="marking-container">*/}

                    {data.QType !== -1 && (

                        <Row>

                            <Col xs={12} md={8} xl={8} style={{
                                textAlign: 'left',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-center',
                                alignItems: 'flex-center'
                            }}>
                                {/*<div className="left-column">*/}
                                <textarea
                                    ref={feedbackRef}
                                    name="feedback"
                                    className="textarea-feedback"
                                    placeholder="Feedback if not satisfactory..."
                                    onKeyDown={handleKeyDown}  // Attach the onKeyDown event handler
                                />
                                {/*</div>*/}
                            </Col>

                            <Col xs={12} md={4} xl={4} style={{
                                textAlign: 'left',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-center',
                                alignItems: 'flex-center'
                            }}>

                                <div>
                                    <div style={{display: 'flex', alignItems: 'end'}}>
                                        <input
                                            type="radio"
                                            className="radio-as-checkbox-marking-SA"
                                            id="satisfactory"
                                            name="satisfactory"
                                            value="S"
                                            checked={selectedOutcome === 'S'}
                                            onChange={handleOutcomeChange}
                                        />
                                        <label htmlFor="satisfactory">Satisfactory</label>
                                    </div>
                                    <div style={{display: 'flex', alignItems: 'left', marginTop: 15}}>
                                        <input
                                            type="radio"
                                            className="radio-as-checkbox-marking-NS"
                                            id="not-satisfactory"
                                            name="satisfactory"
                                            value="NS"
                                            checked={selectedOutcome === 'NS'}
                                            onChange={handleOutcomeChange}
                                        />
                                        <label htmlFor="not-satisfactory">Not Satisfactory</label>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    )}

                    <div className="btn-container" style={{marginTop: 25, width: '100%'}}>
                        <button className={"btn-primary"} type="submit">{buttonText}</button>
                    </div>
                </form>
            </div>
        );
    }

    const MemoizedPageComponent = React.memo(PageComponent);

    return (
        <div>
            <Bluey />

            <div className={"page-common"} style={{ marginTop: 100, zIndex: 1 }}>

                {completed ? (
                    <div>
                        <h1>You have now finished marking this assessment!</h1>
                        <SignaturePanel/>
                        <div className="btn-container" style={{ marginTop: '20px' }}>
                            <button className="btn-primary" onClick={() => navigate('/classroom')}>Complete</button>
                        </div>
                    </div>
                ) : (
                    assessmentData.length > 0 &&
                    <MemoizedPageComponent

                        data={assessmentData[currentQuestionIndex]}
                        currentQuestionIndex={currentQuestionIndex}
                    />
                )}
            </div>
        </div>

    );
}

// =============================== To Do =================================== //

// Short cut keys for marking criteria
// Flag Question
// Micro Marking Criteria
// Auto Marking
// Cheat codes

// =============================== Bugs =================================== //

// Fix issue with student feedback being cleared when marking criteria is selected