import { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { showRefreshLogin } from 'State/Reducers/refreshLoginSlice';
import { clearEncryptionKey, setEncryptionKey } from 'State/Reducers/keySlice';
import { useUserField } from './useUserDeets';
import CryptoJS from 'crypto-js';
import { MAX_FAILURES, HEARTBEAT_INTERVAL, KEY_REFRESH_INTERVAL } from 'Constants/constants';


export const useHeartbeat = () => {
  const [failures, setFailures] = useState(0);
  const dispatch = useDispatch();
  const heartbeatInterval = useRef<NodeJS.Timeout | null>(null);
  const isLoggedIn = useUserField('loggedIn');
  const lastKeyRefresh = useRef<number>(Date.now());

  // REMOVE: This is for testing only the key will be generated by the server 
  const generateRandomKey = () => {
    const randomKey = CryptoJS.lib.WordArray.random(16).toString(CryptoJS.enc.Hex); // 128-bit key
    return randomKey;
  };

  const heartbeat = useCallback(async () => {
    // TO DO: Replace this with the Fetch to /users/heartbeat
    try {
      const shouldFail = false; // Simulate a successful heartbeat
      // const shouldFail = true; // Simulate a successful heartbeat
      // const shouldFail = Math.random() < 0.3; // 30% chance of failure

      if (shouldFail) {
        throw new Error('Simulated heartbeat failure');
      }

      // Is it time to refresh the key?
      if (Date.now() - lastKeyRefresh.current > KEY_REFRESH_INTERVAL) {

        // TO DO: Replace this with a call to the server to get the new key
        const newKey = generateRandomKey();
        dispatch(setEncryptionKey(newKey));
        // console.log('Refreshing the encryption key');
        lastKeyRefresh.current = Date.now();
      }

      // Simulate a successful fetch response
      const response = { ok: true };

      if (!response.ok) throw new Error('Failed heartbeat');

      // Reset failure count if successful
      setFailures(0);
      // console.log('%cYour heart is beating', 'color: green');
    } catch (error) {
      setFailures((prevFailures) => {
        const newFailures = prevFailures + 1; 
        // console.error('Your heart has stopped', newFailures)
        if (newFailures >= MAX_FAILURES) {
          dispatch(showRefreshLogin());
          if (heartbeatInterval.current) {
            clearInterval(heartbeatInterval.current);
          }
        }
        return newFailures;
      });
    }
  }, [dispatch]);

  const restartHeartbeat = useCallback(() => {
    if (heartbeatInterval.current) {
      clearInterval(heartbeatInterval.current);
    }
    heartbeatInterval.current = setInterval(heartbeat, HEARTBEAT_INTERVAL);
  }, [heartbeat]);


  useEffect(() => {
    if (isLoggedIn) {
      restartHeartbeat();
    } else {
      if (heartbeatInterval.current) {
        clearInterval(heartbeatInterval.current);
        dispatch(clearEncryptionKey());
      }
    }

    return () => {
      if (heartbeatInterval.current) {
        clearInterval(heartbeatInterval.current);
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, restartHeartbeat]);

  return { failures };
};
