import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './Styling/index.css';
import { BrowserRouter as Router, Routes ,Route } from "react-router-dom";
import { ConstProvider } from './ConstLink';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { restoreUserWithDecryption } from './State/Reducers/userSlice';
import { useActivityLogger, useHeartbeat, useUserField } from 'CustomHooks';

// Pages
import * as Pages from './Pages';

// Components
import { ProtectedRoute, ConstructionRoute, Header, Footer, RefreshLogin, InactivityCheck, FooterMenu } from "./Components";


function App() {
  const dispatch = useDispatch();
  const isLoggedIn = useUserField('loggedIn');
  const noFooterMenu = window.location.pathname === '/login' || window.location.pathname === '/register';


  // Restore the user from encrypted session storage after a refresh
  useEffect(() => {
    dispatch(restoreUserWithDecryption());
  }, [dispatch]);

  // Used to call the refresh login on a request that returns an expired token
  const showRefreshLogin = useSelector((state) => state.refreshLogin.showRefreshLogin);

  // Logs user activity and logs out the user if they are inactive for 20 minutes
  useActivityLogger();

  // Sends a heartbeat to the server every 15 seconds
  useHeartbeat();


  return (
    <Router>
      <div className="App">
      <RefreshLogin show={showRefreshLogin} />

      <InactivityCheck />

        {/*<head>*/}

        {/*  <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css"></link>*/}
        {/*  <script src="https://code.jquery.com/jquery-3.5.1.slim.min.js"></script>*/}
        {/*  <script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.5.4/dist/umd/popper.min.js"></script>*/}
        {/*  <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/js/bootstrap.min.js"></script>*/}

        {/*</head>*/}

        <ConstProvider>
          <header className="App-header">
            <div className="header-overflow"><Header /></div>
          </header>

          {/*<body>*/}

          <Routes>
              {/* TO BE REMOVED: Temp testing routes */}
              <Route path='/profile' element={<Pages.PersonalProfile />} />
              {/* Public Routes */}
              <Route path="/login" element={<Pages.Login />} />
              <Route path="/register" element={<Pages.Register />} />
              <Route path="/" element={isLoggedIn? <Pages.Home /> : <Pages.Login />} />

              {/* Under Construction Routes can be added to the list in ConstructionRoute.tsx */}
              <Route element={<ConstructionRoute />}>
                {/* BDM. Minimum auth level for access to the trainer portal */}
                <Route element={<ProtectedRoute requiredLevel={4} />}>
                  <Route path="/template" element={<Pages.Template />} />
                  <Route path="/contact" element={<Pages.Contact />} />
                  <Route path="/employment" element={<Pages.Employment />} />
                  <Route path="/classroom" element={<Pages.Classroom />} />
                  <Route path="/calendar" element={<Pages.Calendar />} />
                  <Route path="/calendar/class-edit" element={<Pages.ClassEditNew/>} />
                  <Route path="/calendar/class-edit-old" element={<Pages.ClassEdit/>} />
                  <Route path="/calendar/my-classes" element={<Pages.MyClasses />} />
                  <Route path="/personal-profile" element={<Pages.Profile />} />
                  <Route path="/register-interest" element={<Pages.RegisterInterest />} />
                  <Route path="/businesses" element={<Pages.Businesses />} />
                  <Route path="/businesses/venues-view" element={<Pages.VenuesView />} />
                  <Route path="/businesses/business-profile" element={<Pages.BusinessProfile />} />
                  <Route path="/document-centre" element={<Pages.DocumentCentre />} />
                  <Route path="/document-creator" element={<Pages.DocumentCreator />} />
                  <Route path="/documentation" element={<Pages.Documentation />} />
                  <Route path="/drive" element={<Pages.Drive />} />
                  <Route path="/review-centre" element={<Pages.ReviewCentre />} />
                  <Route path="/setup-account" element={<Pages.SetupAccount />} />
                  <Route path="/tasks" element={<Pages.Tasks />} />
                  <Route path="/templateSteps" element={<Pages.TemplateSteps />} />
                  <Route path="/templateBoxes" element={<Pages.TemplateBoxes />} />
                  <Route path="/test" element={<Pages.Test />} />
                  <Route path='/activityLogger' element={<Pages.ActivityLogger />} />
                  <Route path="/people" element={<Pages.People />} />
                  <Route path='/report-a-bug' element={<Pages.BugReport />} />
                </Route>

                {/* Trainer */}
                <Route element={<ProtectedRoute requiredLevel={5} />}>
                  <Route path="/courses-training" element={<Pages.CoursesTraining />} />
                  <Route path="/tasks/new-task" element={<Pages.NewTask />} />
                  <Route path="/tasks/marking" element={<Pages.Marking />} />
                  <Route path="/tasks/unassigned-tasks" element={<Pages.UnassignedTasks />} />
                  <Route path="/certificates-modules/class-attendance" element={<Pages.ClassAttendance />} />
                  <Route path="/certificates-modules/certificates" element={<Pages.Certificates />} />
                  <Route path="/certificates-modules/certificates-templates" element={<Pages.Certificates />} />
                  <Route path="/certificates-modules/modules" element={<Pages.Modules />} />
                  <Route path="/certificates-modules/module-billing" element={<Pages.Modules />} />
                  <Route path="/certificates-modules/module-visibility-current" element={<Pages.ModuleVisibilityCurrent />} />
                  <Route path="/certificates-modules/module-visibility-old" element={<Pages.ModuleVisibilityOld />} />
                  <Route path="/competencies" element={<Pages.Competencies />} />
                  <Route path="/assessment-marking" element={<Pages.AssessmentMarking />} />
                  <Route path="/c3g-feedback" element={<Pages.C3gFeedback />} />
                  <Route path="/history" element={<Pages.History />} />
                  
                </Route>

                {/* Support Trainer */}
                <Route element={<ProtectedRoute requiredLevel={6} />}>
                  <Route path="/assessment-review" element={<Pages.AssessmentReview />} />  
                  <Route path="/event-viewer" element={<Pages.EventViewer />} />  
                </Route>

                {/* Admin Officer */}
                <Route element={<ProtectedRoute requiredLevel={7} />}>
                  <Route path="/administration" element={<Pages.Administration />} />
                  <Route path="/review-centre/qa-module-review" element={<Pages.QaModuleReview />} />
                  <Route path="/review-centre/qa-review-list" element={<Pages.QaReviewList />} />
                  <Route path="/review-centre/pending-event-action-submissions" element={<Pages.PendingEventActionSubmissions />} />
                  <Route path="/review-centre/learner-resource-review" element={<Pages.LearnerResourceReview />} />
                  <Route path="/review-centre/Pending-Template-Submissions" element={<Pages.PendingTemplateSubmissions />} />
                  <Route path="/certificatesModules/module-billing" element={<Pages.ModuleBilling />} />
                  <Route path="/certificatesModules/certificate-template" element={<Pages.CertificateTemplate />} />
                  <Route path="/certificatesModules/certificate-templates" element={<Pages.CertificateTemplates />} />
                  <Route path="/certificatesModules/certificate-create" element={<Pages.CertificateCreation />} />
                  <Route path="/documentation" element={<Pages.Construction />} />
                  <Route path="/documentation/document-repository" element={<Pages.DocumentRepository />} />
                  <Route path="/documentation/policies-procedures" element={<Pages.PoliciesProcedures />} />
                  <Route path="/documentation/compliance-auditing" element={<Pages.ComplianceAuditing />} />
                  <Route path="/documentation/marketing" element={<Pages.Marketing />} />
                  <Route path="/documentation/S-Q-E-M-P" element={<Pages.SQEMP />} />
                </Route>

                {/* Administrator */}
                <Route element={<ProtectedRoute requiredLevel={8} />}>
                  <Route path="/verify" element={<Pages.Verify />} />
                  <Route path="/administration/metrics" element={<Pages.Metrics />} />
                  <Route path="/administration/reports" element={<Pages.Reports />} />
                  <Route path="/administration/setup" element={<Pages.Setup />} />
                  <Route path="/administration/tools" element={<Pages.Tools />} />
                  <Route path="/administration/uploads" element={<Pages.Uploads />} />
                  <Route path="/administration/reports/lui-report" element={<Pages.LuiReport />} />
                  <Route path="/administration/reports/view-funding-prices" element={<Pages.ViewFundingPrices />} />
                  <Route path="/administration/reports/superseded-module-report" element={<Pages.SupersededModuleReport />} />
                  <Route path="/administration/reports/view-payment-data" element={<Pages.ViewPaymentData />} />
                  <Route path="/administration/reports/invalid-usi-report" element={<Pages.InvalidUsiReport />} />
                  <Route path="/administration/reports/outstanding-invoices-report" element={<Pages.OutstandingInvoicesReport />} />
                  <Route path="/administration/reports/module-kpis" element={<Pages.ModuleKpis />} />
                  <Route path="/administration/reports/registered-interest-report" element={<Pages.RegisteredInterestReport />} />
                  <Route path="/administration/reports/active-certificate-report" element={<Pages.ActiveCertificateReport />} />
                  <Route path="/administration/reports/report-settings" element={<Pages.ReportSettings />} />
                  <Route path="/administration/reports/module-reports" element={<Pages.ModuleReports />} />
                  <Route path="/administration/reports/white-card-report" element={<Pages.WhiteCardReport />} />
                  <Route path="/administration/reports/backup-report" element={<Pages.BackupReport />} />
                  <Route path="/administration/reports/certificates-unassigned-report" element={<Pages.CertificatesUnassignedReport />} />
                  <Route path="/administration/reports/qi-ccos" element={<Pages.QiCcos />} />
                  <Route path="/administration/reports/billing-report" element={<Pages.BillingReport />} />
                  <Route path="/administration/reports/avetmiss-report" element={<Pages.AvetmissReport />} />
                  <Route path="/administration/reports/email-report" element={<Pages.EmailReport />} />
                  <Route path="/administration/reports/event-reports" element={<Pages.EventReports />} />
                  <Route path="/administration/reports/usi-report" element={<Pages.UsiReport />} />
                  <Route path="/administration/reports/invoice-reminders" element={<Pages.InvoiceReminders />} />
                  <Route path="/administration/setup/task-group-edit" element={<Pages.TaskGroupEdit />} />
                  <Route path="/administration/setup/permissions" element={<Pages.Permissions />} />
                  <Route path="/administration/setup/integration-settings" element={<Pages.IntegrationSettings />} />
                  <Route path="/administration/setup/branding" element={<Pages.Branding />} />
                  <Route path="/administration/setup/search-event-actions" element={<Pages.SearchEventActions />} />
                  <Route path="/administration/setup/event-action-editor" element={<Pages.EventActionEditor />} />
                  <Route path="/administration/setup/accounting" element={<Pages.Accounting />} />
                  <Route path="/administration/setup/create-funding-prices" element={<Pages.CreateFundingPrices />} />
                  <Route path="/administration/setup/templates" element={<Pages.Templates />} />
                  <Route path="/administration/tools/usi-generator" element={<Pages.UsiGenerator />} />
                  <Route path="/administration/tools/statement-fix-name" element={<Pages.StatementFixName />} />
                  <Route path="/administration/tools/billing-fix-reports-tool" element={<Pages.BillingFixReportsTool />} />
                  <Route path="/administration/tools/credit-transfer-tool" element={<Pages.CreditTransferTool />} />
                  <Route path="/administration/tools/bulk-verify-usis" element={<Pages.BulkVerifyUsis />} />
                  <Route path="/administration/tools/bulk-upload-tas" element={<Pages.BulkUploadTas />} />
                  <Route path="/administration/tools/certificate-transition-tool" element={<Pages.CertificateTransitionTool />} />
                  <Route path="/administration/tools/dev-tools" element={<Pages.DevTools />} />
                  <Route path="/administration/uploads/upload-approved-training-snapshot" element={<Pages.UploadApprovedTrainingSnapshot />} />
                  <Route path="/administration/uploads/upload-det-payment" element={<Pages.UploadDetPayment />} />
                  <Route path="/administration/uploads/upload-det-modules-snapshot" element={<Pages.UploadDetModulesSnapshot />} />
                  <Route path="/administration/uploads/schedule-a-uploadDropzone" element={<Pages.ScheduleAUploader />} />
                  <Route path="/administration/uploads/upload-legacy-assessment" element={<Pages.UploadLegacyAssessment />} />
                  <Route path="/administration/uploads/upload-det-data-error-validation" element={<Pages.UploadDetDataErrorValidation />} />
                  <Route path="/administration/uploads/upload-nominal-hours" element={<Pages.UploadNominalHours />} />
                  <Route path="/administration/uploads/upload-user-choice-price-list" element={<Pages.UploadUserChoicePriceList />} />
                  <Route path="/administration/uploads/upload-avetmiss-report" element={<Pages.UploadAvetmissReport />} />
                  <Route path="/administration/uploads/upload-assessment" element={<Pages.UploadAssessment />} />
                </Route>

                {/* Super User  */}
                <Route element={<ProtectedRoute requiredLevel={9} />}>
                  <Route path="/site-management-system" element={<Pages.SiteManagementSystem />} />
                  <Route path="/changelog" element={<Pages.Changelog />} />
                  <Route path="/scraper" element={<Pages.Scraper />} />
                </Route>

                {/* God */}
                <Route element={<ProtectedRoute requiredLevel={10} />}>

                </Route>
                {/* Catch all routes to the 404 page when a page doesn't exist */}
                <Route path="*" element={<Pages.Error />} />
                <Route path='/under-construction' element={<Pages.Construction />} />
              </Route>
          </Routes>

          <footer>
            <Footer />
          </footer>
          {!noFooterMenu && <FooterMenu />}
        </ConstProvider>
      </div>
    </Router>
  );
}

export default App;