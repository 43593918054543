/* eslint-disable */
import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ConstLink } from "ConstLink";
import { Document, Page, pdfjs } from "react-pdf";
import Bluey from "Components/Bluey";
import data from 'Components/data.json';
import List, { ItemDragging } from "devextreme-react/list";
import { useUserField } from 'CustomHooks';


export default function AssessmentMarking() {
    const { setQuickMenu, setStepButtons } = useContext(ConstLink);
    const navigate = useNavigate();
    const isLoggedIn = useUserField('loggedIn');
    // const [pageName, setPageName] = useState('');
    const [assessmentData, setAssessmentData] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [ questionNumFix , setQuestionNumFix] = useState(2);
    // const [ buttonText, setButtonText ] = useState("Begin Assignment")
    const [ completed, setCompleted ] = useState(false);
    const [module, setModule] = useState("45");
    const [msg, setMsg] = useState(window.sessionStorage.getItem("message"));
    const [msgCode, setMsgCode] = useState(0)
    const [loading, setLoading] = useState(false);

    if (!isLoggedIn) {
        navigate('/login')
    }

    const handleNextQuestion = () => {
        let incrementQuestionNum = questionNumFix;
        let incrementValue = 1; // default increment

        // if ( currentQuestionIndex + incrementValue === assessmentData.length) {
        //     console.log(" Set Completed to True" )
        //     setCompleted(true)
        // }

        if (assessmentData[currentQuestionIndex]?.QType === -1 &&
            assessmentData[currentQuestionIndex + 1]?.QType === 13 &&
            assessmentData[currentQuestionIndex + 2]?.QType === 14) {
            incrementValue = 3; // skip the next 2 questions
        }

        // Ensure we don't go beyond the assessmentData length
        while (currentQuestionIndex + incrementValue < assessmentData.length &&
        [4, 9, 10].includes(assessmentData[currentQuestionIndex + incrementValue]?.QType)) {
            incrementQuestionNum++;
            incrementValue++;
        }

        // let test = currentQuestionIndex + incrementValue
        if (currentQuestionIndex + incrementValue >= assessmentData.length) {
            // console.log("Current: " + test + " of: " + assessmentData.length);
            console.log('Reached the end! Go do sum other stuff!');
            setCompleted(true);
            // navigate('/');
            // I Can add submission logic here... hopefully!

            return;
        }

        setQuestionNumFix(incrementQuestionNum);
        setCurrentQuestionIndex(prevIndex => prevIndex + incrementValue);
    };


    async function fetchData(moduleID) {
        setLoading(true);  // Set loading to true when the fetch starts
        try {
            // const response = await fetch('https://api.trainingprofessionals.com.au/student/assess_questions', {
            const response = await fetch('https://localhost:3001/student/assess_questions', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify({'moduleID': moduleID})
            });

            const apiData = await response.json(); // Wait for the JSON data

            // First check what type of data is received and log it
            // console.log("Raw API Data:", apiData);

            // Handle response based on the status code
            switch (response.status) {
                case 200:
                    // setAssessmentData(apiData.data);
                    const convertedArray = Object.values(apiData.data || {});
                    setAssessmentData(convertedArray);
                    // if (Array.isArray(apiData.data)) {
                    //     setAssessmentData(apiData.data);
                    // } else {
                    //     console.error("Data received is not an array:", apiData.data);
                    //     // Convert to array if it's an object and you're certain it should be an array
                    //     const convertedArray = Object.values(apiData.data || {});
                    //     setAssessmentData(convertedArray);
                    // }
                    setMsgCode(4);
                    break;
                case 201:
                    setMsgCode(4);
                    break;
                case 400:
                    setMsgCode(1);
                    break;
                case 401:
                    console.log('Unauthorized access');
                    setMsg('Unauthorized');
                    setMsgCode(1);
                    break;
                case 429:
                    console.log('Too many requests');
                    alert('Too many requests, please try again later');
                    setMsg('Rate limit exceeded');
                    setMsgCode(2);
                    break;
                default:
                    console.log('Unexpected error');
                    break;
            }
        } catch (err) {
            console.error('Error fetching data:', err);
            setMsgCode(0);
        } finally {
            setLoading(false);  // Set loading to false when fetch completes
        }
    }



    useEffect(() => {
        // fetchData().then((data) => {
        //     setAssessmentData(data);
        // });

        fetchData(module);

        updateQuickMenu();
        pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
        const pathArray = window.location.pathname.split('/');
        const pageParam = pathArray[pathArray.length - 1];
        // setPageName(pageParam || 'Default Page');

    }, []);

    // useEffect(() => {
    //     if (data && data.answer) {
    //         const newSteps = data.answer.split(',').map(order => data[`c${order}`]);
    //         console.log("Steps:", newSteps); // This should display an array of strings
    //         // setSteps(newSteps);
    //     }
    // }, [data]);


    // function formatString(str) {
    //     if (!str) return str;
    //
    //     let formattedStr = str;
    //
    //     // Replace &apos; with regular apostrophe
    //     formattedStr = formattedStr.replace(/&apos;/g, "'");
    //
    //     // Replace ~number with a dash
    //     formattedStr = formattedStr.replace(/~\d+/g, '-');
    //
    //     // Existing logic for <br> and #.
    //     formattedStr = formattedStr.split('<br>').map(paragraph => {
    //         // Check if the paragraph contains #. pattern
    //         if (paragraph.includes('#.')) {
    //             const parts = paragraph.split('#.');
    //             const intro = parts.shift(); // text before the first #.
    //             const bullets = parts.map(item => '• ' + item.trim()).join('\n');
    //             return `${intro}\n${bullets}`;
    //         }
    //         return paragraph;
    //     }).join('\n');
    //
    //     return formattedStr;
    // }

    function formatString(str) {
        if (!str) return str;

        let formattedStr = str;

        // Replace &apos; with regular apostrophe
        formattedStr = formattedStr.replace(/&apos;/g, "'");

        // Replace ~number with a dash
        formattedStr = formattedStr.replace(/~\d+/g, '-');

        // Remove <text:s/>
        formattedStr = formattedStr.replace(/<text:s\/>/g, '');

        // Existing logic for <br> and #.
        formattedStr = formattedStr.split('<br>').map(paragraph => {
            // Check if the paragraph contains #. pattern
            if (paragraph.includes('#.')) {
                const parts = paragraph.split('#.');
                const intro = parts.shift(); // text before the first #.
                const bullets = parts.map(item => '• ' + item.trim()).join('\n');
                return `${intro}\n${bullets}`;
            }
            return paragraph;
        }).join('\n');

        return formattedStr;
    }




    const splitQuestion = (text) => {
        const segments = [];
        let lastIndex = 0;

        const regex = /\[([^\]]+)\]/g;
        let match;

        while ((match = regex.exec(text)) !== null) {
            segments.push({ type: "text", content: text.substring(lastIndex, match.index) });
            segments.push({ type: "gap", content: match[1] });
            lastIndex = match.index + match[0].length;
        }

        if (lastIndex < text.length) {
            segments.push({ type: "text", content: text.substring(lastIndex) });
        }

        return segments;
    };

    function numberToWord(number) {
        const words = [
            "first", "second", "third", "fourth", "fifth",
            "sixth", "seventh", "eighth", "ninth", "tenth",
            "eleventh", "twelfth", "thirteenth", "fourteenth", "fifteenth",
            "sixteenth", "seventeenth", "eighteenth", "nineteenth", "twentieth"
        ];

        return words[number - 1] || "";
    }

    function PageComponent({ data, onNext, currentQuestionIndex, totalQuestions }) {

        const [formData, setFormData] = useState({});
        // const [ questionNumFix , setQuestionNumFix] = useState(-2);

        function convertToAnswers(originalObject) {
            const steps = [];
            for (let i = 1; i <= 5; i++) {
                const key = `c${i}`;
                if (originalObject[key]) {
                    steps.push({ id: i, text: originalObject[key] });
                }
            }
            return steps;
        }

        const [testData, setTestData] = useState(convertToAnswers(data))

        // Function to handle form input changes
        const handleInputChange = (name, value) => {
            setFormData(prev => ({
                ...prev,
                [name]: value
            }));
        };

        const handleFormSubmit = (e) => {
            e.preventDefault();  // Prevent default form submission behavior

            onNext();  // Call onNext immediately when the form is submitted

            // Then make a fetch call or some other API call to post the answers
            fetch('/api/submitAnswers', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ /* answers and other data */ })
            })
                .then(response => response.json())
                .then(data => {
                    // Handle the response, possibly moving to the next question or whatever else needs to be done
                    // (The onNext is already called, so you don't need to call it here again.)
                })
                .catch(error => {
                    console.error("Error submitting answers:", error);
                });
        }

        let buttonText = "Next Question";  // default button text

        // First question (title/intro page)
        if (currentQuestionIndex === 0) {
            buttonText = "Begin";
        }

        // Last question
        else if (completed) {
            buttonText = "Finish";
        }

        // console.log("Current: " + currentQuestionIndex + " of: " + totalQuestions);
        // console.log("Total: " + totalQuestions);

        function adjustTextareaHeight(event) {
            const textarea = event.target;
            textarea.style.height = 'auto'; // Reset height to shrink if needed
            textarea.style.height = `${textarea.scrollHeight}px`; // Expand to the scroll height
        }

        const handleReorder = (e) => {
            const updatedTestData = [...testData];
            const [removed] = updatedTestData.splice(e.fromIndex, 1);
            updatedTestData.splice(e.toIndex, 0, removed);
            setTestData(updatedTestData);
            // console.log(updatedTestData);
        };

        return (
            <div>

                <form onSubmit={handleFormSubmit}>

                    {data.QType === -1 && (
                        <div className="intro-page">
                            <h1><strong>Assessment Task {data.section}</strong></h1>
                            <div style={{ margin: '40px 0' }}>
                                <h2 style={{ textAlign: 'left' }}>{data.question}</h2>
                            </div>

                            {/* Check if the next QType is 13 and the one after is 14 */}
                            {assessmentData[currentQuestionIndex + 1]?.QType === 13 &&
                                assessmentData[currentQuestionIndex + 2]?.QType === 14 && (
                                    <div>

                                        <div style={{ padding: '15px', textAlign: 'left'  }}>
                                            <h2 style={{paddingBottom: 15}}><strong>Information on Assessment Task</strong></h2>
                                            <h3 style={{ whiteSpace: 'pre-line' }}>
                                                {formatString(assessmentData[currentQuestionIndex + 1].question)}
                                            </h3>
                                        </div>

                                        <div style={{ padding: '15px', textAlign: 'left'  }}>
                                            <h3 style={{ whiteSpace: 'pre-line' }}>
                                                {formatString(assessmentData[currentQuestionIndex + 2].question)}
                                            </h3>
                                        </div>
                                    </div>
                                )}
                        </div>
                    )}

                    {data.QType === 0 && (
                        <div className="free-range-answer">

                            <h1><strong>Question {data.questionNum - questionNumFix}</strong></h1>

                            <div style={{ margin: '40px 0' }}>
                                <h2 className="justify-text" >{formatString(data.question)}</h2>
                            </div>

                            <textarea
                                className="textarea-answer"
                                rows={data.evidence - 1}
                                placeholder="Type your answer here..."
                                onChange={(e) => handleInputChange("freeRangeAnswer", e.target.value)}
                                onInput={adjustTextareaHeight}
                                // required
                                // style={{ width: '90%', padding: '25px' }}
                            />

                        </div>
                    )}

                    {data.QType === 2 && (
                        <div className="question">

                            <h1><strong>Question {data.questionNum - questionNumFix}</strong></h1>

                            <div style={{ margin: '40px 0' }}>
                                <h2 className="justify-text">{data.question}</h2>
                            </div>

                            {/* Check if it's a True/False question */}
                            {(data.c1 === "True." && data.c2 === "False.") || (data.c1 === "False." && data.c2 === "True.") ? (
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'start' }}>
                                    <div style={{ marginRight: '20px', display: 'flex', alignItems: 'center' }}>
                                        <input
                                            type="radio"
                                            className="radio-as-checkbox"
                                            id="trueOption"
                                            name="answer"
                                            value="True"
                                        />
                                        <label htmlFor="trueOption">{data.c1}</label>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', marginTop: 15 }}>
                                        <input
                                            type="radio"
                                            className="radio-as-checkbox"
                                            id="falseOption"
                                            name="answer"
                                            value="False"
                                        />
                                        <label htmlFor="falseOption">{data.c2}</label>
                                    </div>
                                </div>
                            ) : data.c1.includes("____________") ? (
                                    // Multi answer question with possible dual-input
                                    <div>
                                        {Array.from({ length: 20 }).map((_, i) => {
                                            const choice = data[`c${i + 1}`];
                                            if (choice) {
                                                const parts = choice.split("~3");
                                                return (
                                                    <div key={i} style={{ display: 'flex', alignItems: 'center', marginBottom: '0px', width: '100%' }}>
                                                        <label style={{ marginRight: '10px', fontSize: 30, marginBottom: 15 }}>{i + 1}</label>

                                                        {/* Check if this is a dual-input choice */}
                                                        {parts.length > 1 ? (
                                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px', width: '100%' }}>
                                                                <input
                                                                    type="text"
                                                                    className="multi-answer"
                                                                    placeholder="Part 1..."
                                                                    style={{ flex: 1 }}
                                                                />
                                                                <span style={{ margin: '0 5px', alignSelf: 'center' }}></span>
                                                                <input
                                                                    type="text"
                                                                    className="multi-answer"
                                                                    placeholder="Part 2..."
                                                                    style={{ flex: 1 }}
                                                                />
                                                            </div>
                                                        ) : (
                                                            <input
                                                                type="text"
                                                                className="multi-answer"
                                                                placeholder={`Type your ${numberToWord(i + 1)} answer here...`}
                                                                style={{ width: '100%' }}
                                                                // required
                                                            />
                                                        )}
                                                    </div>
                                                );
                                            }
                                            return null;
                                        })}
                                    </div>
                                )
                                : (
                                    // Multiple choices question
                                    <div>
                                        {Array.from({ length: 20 }).map((_, i) => {
                                            const choice = data[`c${i + 1}`];
                                            if (choice) {
                                                return (
                                                    <div key={i} style={{ display: 'flex', alignItems: 'start', marginBottom: '10px' }}>
                                                        <input
                                                            type="checkbox"
                                                            className="radio-as-checkbox"
                                                            id={`choice${i + 1}`}
                                                            name="answer"
                                                            value={`c${i + 1}`}
                                                        />
                                                        <label htmlFor={`choice${i + 1}`}>{formatString(choice)}</label>
                                                    </div>
                                                );
                                            }
                                            return null;
                                        })}
                                    </div>
                                )}
                        </div>
                    )}

                    {data.QType === 5 && (
                        <div className="sequencing-question">
                            <h1><strong>Question {data.questionNum - questionNumFix}</strong></h1>
                            <div className="justify-text" style={{ margin: '40px 0' }}>

                                <div dangerouslySetInnerHTML={{ __html: data.question }}></div>

                                <div className="widget-container" style={{marginTop: 25}}>

                                    <List items={testData}
                                          selectionMode="none"
                                          keyExpr="id"
                                        // disabled={true}
                                          focusedRowEnabled={false}
                                          activeStateEnabled={false}
                                          focusStateEnabled={false}
                                          repaintChangesOnly={false}>

                                        <ItemDragging
                                            draggable={true}
                                            onReorder={handleReorder}
                                            selectionMode="none"
                                            focusedRowEnabled={false}
                                            activeStateEnabled={false}
                                            allowReordering={true}
                                            repaintChangesOnly={false}
                                            group="tasks"
                                            data="doingTasks"
                                            // onDragStart={onDragStart}
                                            // onAdd={onAdd}
                                            // onRemove={onRemove}
                                        />
                                    </List>
                                </div>
                            </div>
                        </div>
                    )}

                    {data.QType === 7 && (
                        <div className="fill-in-the-gaps">
                            <h1><strong>Question {data.questionNum - questionNumFix}</strong></h1>

                            <div className="justify-text" style={{marginTop: 25}}>
                                {splitQuestion(data.question).map((segment, index) => {
                                    if (segment.type === "text") {
                                        return <span key={index}>{segment.content}</span>;
                                    } else if (segment.type === "gap") {
                                        return <input
                                            key={index}
                                            className="gap-input"
                                            placeholder="..."
                                            style={{width: `${segment.content.length * 20}px`}} // Adjust width based on content length
                                            // required
                                        />;
                                    }
                                    return null;
                                })}
                            </div>
                        </div>
                    )}

                    {data.QType === 6 && (
                        <div className="fill-in-the-gaps">
                            <h1><strong>Question {data.questionNum - questionNumFix}</strong></h1>

                            <div className="justify-text" style={{ margin: '40px 0' }}> {/* Adjusting bottom and left margins */}
                                <div dangerouslySetInnerHTML={{ __html: data.question }} style={{ marginBottom: '50px' }} /> {/* Gap between question and answers */}

                                {Array.from({ length: 20 }, (_, i) => `c${i + 1}`).map((field, index) => {
                                    const value = data[field];
                                    return value ? (
                                        <div key={index} style={{marginLeft: 40}}>
                                            <strong>{value[0]}</strong>
                                            <input
                                                className="gap-input"
                                                placeholder="..."
                                                style={{width: `${(value.length - 1) * 20}px`}} // Adjust width based on content length minus the first character
                                            />
                                        </div>
                                    ) : null;
                                })}
                            </div>
                        </div>
                    )}

                    {/*... other rendering logic for other QTypes can go here */}

                    {/* The Button */}
                    <div className="btn-container" style={{ marginTop: '20px' }}>
                        <button className={"btn-primary"} type="submit">{buttonText}</button>
                    </div>

                </form>
            </div>
        );
    }


    const updateQuickMenu = () => {
        setQuickMenu("Item 1", "Item 2", "Item 3", "Item 4", "Item 5", "Item 6");
    };

    return (

        <div>
            <Bluey />
            <div className={"page-common"} style={{ marginTop: 100, zIndex: 1 }}>

                {completed ? (
                    <div>
                        <h1>Thank you. You have now finished this assessment!</h1>
                        <div className="btn-container" style={{ marginTop: '20px' }}>
                            <button className="btn-primary" onClick={() => navigate('/')}>Complete</button>
                        </div>
                    </div>
                ) : (
                    // Your existing code for rendering the questions goes here
                    assessmentData.length > 0 &&
                    <PageComponent
                        data={assessmentData[currentQuestionIndex]}
                        onNext={handleNextQuestion}
                        currentQuestionIndex={currentQuestionIndex}
                        totalQuestions={assessmentData.length}
                    />
                )}
            </div>
        </div>
    );
}


// =============================== To Do =================================== //



// =============================== Bugs =================================== //