import React from 'react';
import {
    styled, darken, alpha, lighten,
} from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Search from 'Components/Search';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import { ViewState, EditingState } from '@devexpress/dx-react-scheduler';
import classNames from 'clsx';
import {
    Scheduler,
    MonthView,
    Appointments,
    Toolbar,
    DateNavigator,
    AppointmentTooltip,
    AppointmentForm,
    EditRecurrenceMenu,
    TodayButton,
    Resources,
    DragDropProvider,
} from '@devexpress/dx-react-scheduler-material-ui';
// import WbSunny from '@mui/icons-material/WbSunny';
// import FilterDrama from '@mui/icons-material/FilterDrama';
// import Opacity from '@mui/icons-material/Opacity';
// import ColorLens from '@mui/icons-material/ColorLens';
import { owners } from 'demo-data/tasks';
// import { useUserField } from 'CustomHooks'; // Uncomment if logged in functionality is needed


const PREFIX = 'Calendar';
// const navigate = useNavigate();

const classes = {
    cell: `${PREFIX}-cell`,
    content: `${PREFIX}-content`,
    text: `${PREFIX}-text`,
    // sun: `${PREFIX}-sun`,
    // cloud: `${PREFIX}-cloud`,
    // rain: `${PREFIX}-rain`,
    // sunBack: `${PREFIX}-sunBack`,
    // cloudBack: `${PREFIX}-cloudBack`,
    // rainBack: `${PREFIX}-rainBack`,
    opacity: `${PREFIX}-opacity`,
    appointment: `${PREFIX}-appointment`,
    apptContent: `${PREFIX}-apptContent`,
    flexibleSpace: `${PREFIX}-flexibleSpace`,
    flexContainer: `${PREFIX}-flexContainer`,
    tooltipContent: `${PREFIX}-tooltipContent`,
    tooltipText: `${PREFIX}-tooltipText`,
    title: `${PREFIX}-title`,
    icon: `${PREFIX}-icon`,
    circle: `${PREFIX}-circle`,
    textCenter: `${PREFIX}-textCenter`,
    dateAndTitle: `${PREFIX}-dateAndTitle`,
    titleContainer: `${PREFIX}-titleContainer`,
    container: `${PREFIX}-container`,
};

const getBorder = theme => (`1px solid ${
    theme.palette.mode === 'light'
        ? lighten(alpha(theme.palette.divider, 1), 0.88)
        : darken(alpha(theme.palette.divider, 1), 0.68)
}`);

const DayScaleCell = props => (
    <MonthView.DayScaleCell {...props} style={{ textAlign: 'center', fontWeight: 'bold', backgroundColor: '#797979 ' }} />
);

// // #FOLD_BLOCK
// const StyledOpacity = styled(Opacity)(() => ({
//     [`&.${classes.rain}`]: {
//         color: '#4FC3F7',
//     },
// }));
// // #FOLD_BLOCK
// const StyledWbSunny = styled(WbSunny)(() => ({
//     [`&.${classes.sun}`]: {
//         color: '#FFEE58',
//     },
// }));
// // #FOLD_BLOCK
// const StyledFilterDrama = styled(FilterDrama)(() => ({
//     [`&.${classes.cloud}`]: {
//         color: '#90A4AE',
//     },
// }));

// #FOLD_BLOCK
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${classes.cell}`]: {
        color: '#78909C!important',
        position: 'relative',
        userSelect: 'none',
        verticalAlign: 'top',
        paddingTop: 0,
        height: 200,
        borderLeft: getBorder(theme),
        '&:first-of-type': {
            borderLeft: 'none',
        },
        '&:last-child': {
            paddingRight: 0,
        },
        'tr:last-child &': {
            borderBottom: 'none',
        },
        '&:hover': {
            backgroundColor: 'white',
        },
        '&:focus': {
            backgroundColor: alpha(theme.palette.primary.main, 0.15),
            outline: 0,
        },
    },
    // [`&.${classes.sunBack}`]: {
    //     backgroundColor: '#FFFDE7',
    // },
    // [`&.${classes.cloudBack}`]: {
    //     backgroundColor: '#ECEFF1',
    // },
    // [`&.${classes.rainBack}`]: {
    //     backgroundColor: '#E1F5FE',
    // },
    // [`&.${classes.opacity}`]: {
    //     opacity: '0.5',
    // },
}));
// #FOLD_BLOCK
const StyledDivText = styled('div')(() => ({
    [`&.${classes.text}`]: {
        padding: '0.5em',
        textAlign: 'center',
        // color: 'white' // this is the text color for the cells
    },
}));
// #FOLD_BLOCK
const StyledDivContent = styled('div')(() => ({
    [`&.${classes.content}`]: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        position: 'absolute',
        alignItems: 'center',
    },
}));

// #FOLD_BLOCK
const StyledAppointmentsAppointment = styled(Appointments.Appointment)(() => ({
    [`&.${classes.appointment}`]: {
        borderRadius: '5px',
        height: 'auto',
        '&:hover': {
            opacity: 0.6,
        },
    },
}));

// #FOLD_BLOCK
const StyledToolbarFlexibleSpace = styled(Toolbar.FlexibleSpace)(() => ({
    [`&.${classes.flexibleSpace}`]: {
        flex: 'none',
    },
    [`& .${classes.flexContainer}`]: {
        display: 'flex',
        alignItems: 'center',
    },
}));
// #FOLD_BLOCK
const StyledAppointmentsAppointmentContent = styled(Appointments.AppointmentContent)(() => ({
    [`&.${classes.apptContent}`]: {
        '&>div>div': {
            whiteSpace: 'normal !important',
            lineHeight: 1.2,
        },
    },
}));


// To get from database
const appointments = [
    {
        id: 0,
        title: 'Diggin Holes with Des',
        startDate: new Date(2023, 6, 23, 9, 30),
        endDate: new Date(2023, 6, 23, 11, 30),
        ownerId: 2,
    }, {
        id: 1,
        title: 'Landscaping',
        startDate: new Date(2023, 5, 28, 9, 30),
        endDate: new Date(2023, 5, 28, 11, 30),
        ownerId: 1,
    }, {
        id: 2,
        title: 'First Aid',
        startDate: new Date(2023, 6, 9, 12, 0),
        endDate: new Date(2023, 6, 9, 13, 0),
        ownerId: 5,
    }, {
        id: 3,
        title: 'Fixin Boo Boos with Big Steve',
        startDate: new Date(2023, 6, 18, 14, 30),
        endDate: new Date(2023, 6, 18, 15, 30),
        ownerId: 5,
    }, {
        id: 4,
        title: 'Zombie Apocalypse Prepping',
        startDate: new Date(2023, 6, 20, 12, 0),
        endDate: new Date(2023, 6, 20, 13, 35),
        ownerId: 1,
    }, {
        id: 5,
        title: 'Machine go Vroom',
        startDate: new Date(2023, 6, 6, 13, 0),
        endDate: new Date(2023, 6, 6, 14, 0),
        rRule: 'FREQ=WEEKLY;BYDAY=FR;UNTIL=20230816',
        exDate: '20230713T100000Z,20230727T100000Z',
        ownerId: 2,
    }, {
        id: 6,
        title: 'How to Train Good with Trainin Dave',
        startDate: new Date(2023, 5, 28, 12, 0),
        endDate: new Date(2023, 5, 28, 12, 30),
        rRule: 'FREQ=WEEKLY;BYDAY=TH;UNTIL=20230727',
        exDate: '20230705T090000Z,20230719T090000Z',
        ownerId: 6,
    }, {
        id: 7,
        title: 'Standing Still for Beginners',
        startDate: new Date(2023, 6, 3, 11, 0),
        endDate: new Date(2023, 6, 3, 12, 0),
        rRule: 'FREQ=WEEKLY;BYDAY=TU;UNTIL=20230801',
        exDate: '20230710T080000Z,20230724T080000Z',
        ownerId: 3,
    }, {
        id: 8,
        title: 'Avoiding Conversation',
        startDate: new Date(2023, 6, 9, 11, 0),
        endDate: new Date(2023, 6, 9, 12, 0),
        ownerId: 4,
    },
];

const resources = [{
    fieldName: 'ownerId',
    title: 'Owners',
    instances: owners,
}];

// const WeatherIcon = ({ id }) => {
//     switch (id) {
//         case 0:
//             return <StyledOpacity className={classes.rain} fontSize="large" />;
//         case 1:
//             return <StyledWbSunny className={classes.sun} fontSize="large" />;
//         case 2:
//             return <StyledFilterDrama className={classes.cloud} fontSize="large" />;
//         default:
//             return null;
//     }
// };

// #FOLD_BLOCK
const CellBase = React.memo(({
                                 startDate,
                                 formatDate,
                                 otherMonth,
                                 // #FOLD_BLOCK
                             }) => {
    // const iconId = Math.abs(Math.floor(Math.sin(startDate.getDate()) * 10) % 3);
    const isFirstMonthDay = startDate.getDate() === 1;
    const formatOptions = isFirstMonthDay
        ? { day: 'numeric', month: 'long' }
        : { day: 'numeric' };
    return (
        <StyledTableCell
            tabIndex={0}
            className={classNames({
                [classes.cell]: true,
                // [classes.rainBack]: iconId === 0,
                // [classes.sunBack]: iconId === 1,
                // [classes.cloudBack]: iconId === 2,
                [classes.opacity]: otherMonth,
            })}
        >
            <StyledDivContent className={classes.content}>
                {/*<WeatherIcon classes={classes} id={iconId} />*/}
            </StyledDivContent>
            <StyledDivText className={classes.text} style={{color: 'white !important'}}>
                {formatDate(startDate, formatOptions)}
            </StyledDivText>
        </StyledTableCell>
    );
});

const TimeTableCell = (CellBase);

// const TimeTableCell = ({ ...restProps }) => (
//     <CellBase
//         {...restProps}
//         style={{
//             width: `${100 / 7}%`, // Adjust the width based on the number of days in a week
//             height: '250px !important', // Make the height flexible
//         }}
//     />
// );


const Appointment = (({ ...restProps }) => (
    <StyledAppointmentsAppointment
        {...restProps}
        className={classes.appointment}
    />
));

const AppointmentContent = (({ ...restProps }) => (
    <StyledAppointmentsAppointmentContent {...restProps} className={classes.apptContent} />
));

const FlexibleSpace = (({ ...restProps }) => (
    <StyledToolbarFlexibleSpace {...restProps} className={classes.flexibleSpace}>
        <div className={classes.flexContainer}>
            {/*<ColorLens fontSize="large" htmlColor="#FF7043" />*/}
            <Typography variant="h5" style={{ marginLeft: '10px' }}>CALENDAR</Typography>
        </div>
    </StyledToolbarFlexibleSpace>
));

const NewClassButton = () => {
    const navigate = useNavigate();

    const handleNewClass = () => {
        // Logic here
        navigate('/calendar/class-edit');
    };

    return (
        <div style={{ textAlign: 'right', padding: '10px' }}>
            <button variant="contained" className={"btn-primary"} color="primary" onClick={handleNewClass}>
                NEW CLASS
            </button>
        </div>
    );
};

// const FlexibleSpace = ({ currentDate, onYearChange }) => (
//     <StyledToolbarFlexibleSpace className={classes.flexibleSpace}>
//         <div className={classes.flexContainer}>
//             <Typography variant="h5" style={{ marginLeft: '10px' }}>{currentDate}</Typography>
//             <button variant="outlined" onClick={onYearChange}>Change Year</button>
//         </div>
//     </StyledToolbarFlexibleSpace>
// );

function handleYearChange() {
    return '2022';
}

function getFormattedDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}


export default class Calendar extends React.PureComponent {
    // #FOLD_BLOCK
    constructor(props) {
        super(props);

        this.state = {
            data: appointments,
        };

        this.commitChanges = this.commitChanges.bind(this);
    }

    handleNewClass = () => {
        const { history } = this.props;
        // Logic here
        history.push('/calendar/class-edit/');
    };

    // #FOLD_BLOCK
    commitChanges({ added, changed, deleted }) {
        this.setState((state) => {
            let { data } = state;
            if (added) {
                const startingAddedId = data.length > 0 ? data[data.length - 1].id + 1 : 0;
                data = [...data, { id: startingAddedId, ...added }];
            }
            if (changed) {
                data = data.map(appointment => (
                    changed[appointment.id] ? { ...appointment, ...changed[appointment.id] } : appointment));
            }
            if (deleted !== undefined) {
                data = data.filter(appointment => appointment.id !== deleted);
            }
            return { data };
        });
    }

    render() {
        const { data } = this.state;

        // const navigate = useNavigate();
        // function handleNewClass() {
        //
        //     // Logic here
        //     navigate('calender/class-edit/')
        // }

        return (

            <div>

                <div className="container" style={{marginTop: '-10%', marginBottom: '5%'}}>
                    <Search />
                </div>

                <div style={{
                    // display: 'flex',
                    // justifyContent: 'center',
                    // alignItems: 'center',
                    // minHeight: '100vh',
                    width: '80%',
                    margin: 'auto'
                }}>

                    <Paper>
                        <Scheduler
                            data={data}
                            style={{ paddingTop: '100px', paddingRight: '5%' }}
                        >
                            <EditingState
                                onCommitChanges={this.commitChanges}
                            />
                            <ViewState
                                defaultCurrentDate={getFormattedDate()}
                            />

                            <MonthView
                                timeTableCellComponent={TimeTableCell}
                                dayScaleCellComponent={DayScaleCell}
                            />
                            <Appointments
                                appointmentComponent={Appointment}
                                appointmentContentComponent={AppointmentContent}
                            />
                            <Resources
                                data={resources}
                            />
                            {/*<Toolbar*/}
                            {/*    flexibleSpaceComponent={FlexibleSpace}*/}
                            {/*/>*/}
                            <Toolbar flexibleSpaceComponent={() => <FlexibleSpace currentDate={getFormattedDate()} onYearChange={handleYearChange} />} />
                            <DateNavigator />
                            <TodayButton />
                            <EditRecurrenceMenu />
                            <AppointmentTooltip
                                showCloseButton
                                showDeleteButton
                                showOpenButton
                            />
                            <AppointmentForm />
                            <DragDropProvider />

                            {/*<div style={{ textAlign: 'right', padding: '10px' }}>*/}
                            {/*    <button variant="contained" color="primary" onClick={this.handleNewClass}>*/}
                            {/*        NEW CLASS*/}
                            {/*    </button>*/}
                            {/*</div>*/}

                            <div style={{marginTop: 250}}>
                                {/* Rest of your component code */}
                                <NewClassButton />
                            </div>

                        </Scheduler>
                    </Paper>
                </div>
            </div>
        );
    }
}


// =============================== To Do =================================== //

// Highlight 'today' cell
// Auto Cell height
// Switch Views (Day, Week, Month)
// jump 12 months Button (Backwards & Forwards)
// Bottom Padding
// Better Mobile View
// Implement search bar filtering
// Show class on hover

// add quick menu functionality:

    // import {ConstLink} from "ConstLink";
    // const { setQuickMenu } = useContext(ConstLink);
    // const isLoggedIn = useUserField('loggedIn');
    //
    // if ( !isLoggedIn ) {
    //     navigate('/login')
    // }
    // useEffect(() => {
    //     // Run the updateQuickMenu1 function on page load
    //     updateQuickMenu();
    // }, []);
    //
    // const updateQuickMenu = () => {
    //     setQuickMenu(
    //         "Item 1",
    //         "Item 2",
    //         "Item 3",
    //         "Item 4",
    //         "Item 5",
    //         "Item 6" );
    // };

// =============================== Bugs =================================== //

// Title cell not blurring
// Nav menu not visible in mobile view
// Footer visible over the top of the body