import React from 'react';
import { SpacerDiv } from 'Components';
import { EducationFormData } from 'Types/formInterfaces';

interface EmploymentContentProps {
  formData: any;
  setFormData: any;
  // eslint-disable-next-line no-unused-vars
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, name: string) => void;
  // eslint-disable-next-line no-unused-vars
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  // eslint-disable-next-line no-unused-vars
  handleCheckboxGroupChange: (e: React.ChangeEvent<HTMLInputElement>, groupName: keyof EducationFormData) => void;
  // eslint-disable-next-line no-unused-vars
  handleRadioChange: (formName: string, fieldName: string, value: string) => void;
}

const EmploymentContent: React.FC<EmploymentContentProps> = ({
  formData,
  setFormData,
  handleInputChange,
  handleSubmit,
  handleCheckboxGroupChange,
  handleRadioChange,
}) => {
  return (
    <form className='form-container' onSubmit={handleSubmit} id="form-2">
    <div>
      {/* Search */}
      <p className='section-heading'>Scan or search here to add an Employer or Supervisor</p>
      <div className='form-row' style={{justifyContent: 'space-evenly'}}>
        <div className='qr-code'>QR Code</div>
        <div className="form-search-bar">
          <input
              id="employerSearch"
              type="text"
              className="form-search-input"
              placeholder="Type to search..."
          />
          <button type="submit" className="form-search-button">
              <i className="fas fa-search"></i>
          </button>
        </div>
      </div>

      <SpacerDiv rem={0.5} />

      {/* Employer */}
      <div className='section-container full-width'>
        <div className='form-grid six'>
          <p className='section-heading'>Employer</p>
          <p className='section-heading'>Current Role</p>
          <p className='section-heading'>Requester</p>
          <p className='section-heading'>Requested Time</p>
          <p className='section-heading'>Completed Time</p>
          <p className='section-heading'>Email Competencies</p>
        </div>
        <div className='horizontal-divider' />
        <div className='form-grid six'>
          <p>PH Data</p>
          <p>PH Data</p>
          <p>PH Data</p>
          <p>PH Data</p>
          <p>PH Data</p>
          <p>PH Data</p>
        </div>
      </div>

      <SpacerDiv rem={0.5} />

      {/* Supervisor */}
      <div className='section-container full-width'>
        <div className='form-grid five'>
          <p className='section-heading'>Employer</p>
          <p className='section-heading'>Mobile Number</p>
          <p className='section-heading'>Requester</p>
          <p className='section-heading'>Requested Time</p>
          <p className='section-heading'>Completed Time</p>
        </div>
        <div className='horizontal-divider' />
        <div className='form-grid five'>
          <p>PH Data</p>
          <p>PH Data</p>
          <p>PH Data</p>
          <p>PH Data</p>
          <p>PH Data</p>
        </div>
      </div>

      <div className='form-row'>
        {/* Current Employment Status */}
        <div className='section-container'>
          <p className='section-heading'>Which BEST describes your current employment status?</p>
          <div className='profile-input-container'>
          <div className='form-column'>
              <input
                className='radio-as-checkbox'
                type="radio"
                name="employmentStatus"
                id="employedFullTime"
                checked={formData.currentEmploymentStatus === 'employedFullTime'}
                onChange={() => handleRadioChange('employment', 'currentEmploymentStatus', 'employedFullTime')}
              />
              <label htmlFor="employedFullTime">Employed: Full-time</label>
              <input
                className='radio-as-checkbox'
                type="radio"
                name="employmentStatus"
                id="employedPartTime"
                checked={formData.currentEmploymentStatus === 'employedPartTime'}
                onChange={() => handleRadioChange('employment', 'currentEmploymentStatus', 'employedPartTime')}
              />
              <label htmlFor="employedPartTime">Employed: Part-time</label>
              <input
                className='radio-as-checkbox'
                type="radio"
                name="employmentStatus"
                id="selfEmployedNotEmployingOthers"
                checked={formData.currentEmploymentStatus === 'selfEmployedNotEmployingOthers'}
                onChange={() => handleRadioChange('employment', 'currentEmploymentStatus', 'selfEmployedNotEmployingOthers')}
              />
              <label htmlFor="selfEmployedNotEmployingOthers">Self Employed: Not Employing Others</label>
              <input
                className='radio-as-checkbox'
                type="radio"
                name="employmentStatus"
                id="selfEmployedEmployingOthers"
                checked={formData.currentEmploymentStatus === 'selfEmployedEmployingOthers'}
                onChange={() => handleRadioChange('employment', 'currentEmploymentStatus', 'selfEmployedEmployingOthers')}
              />
              <label htmlFor="selfEmployedEmployingOthers">Self Employed: Employing Others</label>
              <input
                className='radio-as-checkbox'
                type="radio"
                name="employmentStatus"
                id="employedUnpaidWorker"
                checked={formData.currentEmploymentStatus === 'employedUnpaidWorker'}
                onChange={() => handleRadioChange('employment', 'currentEmploymentStatus', 'employedUnpaidWorker')}
              />
              <label htmlFor="employedUnpaidWorker">Employed: Unpaid Worker In Family Business</label>
              <input
                className='radio-as-checkbox'
                type="radio"
                name="employmentStatus"
                id="unemployedSeekingFullTimeWork"
                checked={formData.currentEmploymentStatus === 'unemployedSeekingFullTimeWork'}
                onChange={() => handleRadioChange('employment', 'currentEmploymentStatus', 'unemployedSeekingFullTimeWork')}
              />
              <label htmlFor="unemployedSeekingFullTimeWork">Unemployed: Seeking Full-time Work</label>
              <input
                className='radio-as-checkbox'
                type="radio"
                name="employmentStatus"
                id="unemployedSeekingPartTimeWork"
                checked={formData.currentEmploymentStatus === 'unemployedSeekingPartTimeWork'}
                onChange={() => handleRadioChange('employment', 'currentEmploymentStatus', 'unemployedSeekingPartTimeWork')}
              />
              <label htmlFor="unemployedSeekingPartTimeWork">Unemployed: Seeking Part-time Work</label>
              <input
                className='radio-as-checkbox'
                type="radio"
                name="employmentStatus"
                id="unemployedNotSeekingWork"
                checked={formData.currentEmploymentStatus === 'unemployedNotSeekingWork'}
                onChange={() => handleRadioChange('employment', 'currentEmploymentStatus', 'unemployedNotSeekingWork')}
              />
              <label htmlFor="unemployedNotSeekingWork">Unemployed: Not Seeking Work</label>
            </div>
          </div>
        </div>
        {/* Why Training */}
        <div className='section-container'>
          <p className='section-heading'>Which BEST describes why you are undertaking training?</p>
          <div className='profile-input-container'>
          <div className='form-column'>
              <input
                className='radio-as-checkbox'
                type="radio"
                name="whyTraining"
                id="job"
                checked={formData.whyTraining === 'job'}
                onChange={() => handleRadioChange('employment', 'whyTraining', 'job')}
              />
              <label htmlFor="job">To get a job</label>
              <input
                className='radio-as-checkbox'
                type="radio"
                name="whyTraining"
                id="developBusiness"
                checked={formData.whyTraining === 'developBusiness'}
                onChange={() => handleRadioChange('employment', 'whyTraining', 'developBusiness')}
              />
              <label htmlFor="developBusiness">To develop my existing business</label>
              <input
                className='radio-as-checkbox'
                type="radio"
                name="whyTraining"
                id="startBusiness"
                checked={formData.whyTraining === 'startBusiness'}
                onChange={() => handleRadioChange('employment', 'whyTraining', 'startBusiness')}
              />
              <label htmlFor="startBusiness">To start my own business</label>
              <input
                className='radio-as-checkbox'
                type="radio"
                name="whyTraining"
                id="tryNewCareer"
                checked={formData.whyTraining === 'tryNewCareer'}
                onChange={() => handleRadioChange('employment', 'whyTraining', 'tryNewCareer')}
              />
              <label htmlFor="tryNewCareer">To try a different career</label>
              <input
                className='radio-as-checkbox'
                type="radio"
                name="whyTraining"
                id="promotion"
                checked={formData.whyTraining === 'promotion'}
                onChange={() => handleRadioChange('employment', 'whyTraining', 'promotion')}
              />
              <label htmlFor="promotion">To get a better job or promotion</label>
              <input
                className='radio-as-checkbox'
                type="radio"
                name="whyTraining"
                id="requirement"
                checked={formData.whyTraining === 'requirement'}
                onChange={() => handleRadioChange('employment', 'whyTraining', 'requirement')}
              />
              <label htmlFor="requirement">It is a requirement for my job</label>
              <input
                className='radio-as-checkbox'
                type="radio"
                name="whyTraining"
                id="developSkills"
                checked={formData.whyTraining === 'developSkills'}
                onChange={() => handleRadioChange('employment', 'whyTraining', 'developSkills')}
              />
              <label htmlFor="developSkills">I wanted extra skills for my job</label>
              <input
                className='radio-as-checkbox'
                type="radio"
                name="whyTraining"
                id="otherStudy"
                checked={formData.whyTraining === 'otherStudy'}
                onChange={() => handleRadioChange('employment', 'whyTraining', 'otherStudy')}
              />
              <label htmlFor="otherStudy">To get into another course of study</label>
              <input
                className='radio-as-checkbox'
                type="radio"
                name="whyTraining"
                id="otherReason"
                checked={formData.whyTraining === 'otherReason'}
                onChange={() => handleRadioChange('employment', 'whyTraining', 'otherReason')}
              />
              <label htmlFor="otherReason">Other reasons</label>
              <input
                className='radio-as-checkbox'
                type="radio"
                name="whyTraining"
                id="personal"
                checked={formData.whyTraining === 'personal'}
                onChange={() => handleRadioChange('employment', 'whyTraining', 'personal')}
              />
              <label htmlFor="personal">For personal interest or self-develpoment</label>
              <input
                className='radio-as-checkbox'
                type="radio"
                name="whyTraining"
                id="volunteer"
                checked={formData.whyTraining === 'volunteer'}
                onChange={() => handleRadioChange('employment', 'whyTraining', 'volunteer')}
              />
              <label htmlFor="volunteer">To get skills for community / volunteer work</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  );
};

export default EmploymentContent;
