import React, { useState, useEffect, useRef, useContext } from 'react';
import {Row, Col, Modal } from "react-bootstrap";
import BorderedComponent from "./BorderedComponent";
import CustomAlert from "./CustomAlert";
import { useHotkeys } from 'react-hotkeys-hook';
import useFetchApi from "Services/FetchApi";
import SignaturePanel from "./SignaturePanelPC";
import { annotations } from "TestingDataObjects/annotations";
import {useUserDeets} from 'CustomHooks'
import {useDispatch} from 'react-redux';
import { saveAnswer, saveFeedback, clearAssessment, saveOutcome } from 'State/Reducers/assessmentSlice';

const MarkingGuide = ({ show, onHide, classId, certCode, modCode, moduleId, studentId, apprenticeModsId }) => {



    // const { all } = useContext(ConstLink);
    const user = useUserDeets();
    const fetchApi = useFetchApi();
    const dispatch = useDispatch();
    const [msg, setMsg] = useState(window.sessionStorage.getItem("message"));
    const [msgCode, setMsgCode] = useState(0)
    const [totalQuestions, setTotalQuestions] = useState(0);
    const [totalObservations, setTotalObservations] = useState(0);
    const [mode, setMode] = useState('intro');
    const [currentTaskKey, setCurrentTaskKey] = useState('assessmentTask1');
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [introData, setIntroData] = useState([]);
    const [actualAssessmentData, setActualAssessmentData] = useState({});
    const [supplementaryData, setSupplementaryData] = useState([]);
    const [practicalData, setPracticalData] = useState([]);
    const [assessmentTaskCount, setAssessmentTaskCount] = useState(0); // <--- This will be used in future development
    const [mappingData, setMappingData] = useState([]);
    // const [isCompetent, setIsCompetent] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const signaturePanelRef = useRef(null);

    const print = ( variable ) => {
        console.log( variable );
    }

    const log = (vars) => {
        Object.entries(vars).forEach(([key, value]) => console.log(`${key}:`, value));
    };

    // log({ show, onHide, classId, certCode, modCode, moduleId, studentId, apprenticeModsId })

    // useEffect(() => {
    //     log({ practicalData})
    // }, [practicalData]);

    // Add the user's full name to the annotations array
    useEffect(() => {
        // Prefill the assessor's name in the annotations array
        annotations.forEach((annotation) => {
            if (annotation.name === "AssessorName") {
                annotation.value = user.fullName || "Assessor Name"; // Set the user full name if available
            }
        });
        // console.log(annotations)
    }, [user, annotations]);

    // Reset states related to assessment when the modal is shown or when student changes
    useEffect(() => {
        // print('Marking Guide is mounted');
        if (show) {

            // log({ classId, certCode, modCode, moduleId, studentId, apprenticeModsId })
            setDataLoaded(false);
            setCurrentQuestionIndex(0);
            setMode('assessment'); // Skip intro
            setActualAssessmentData([]);
            fetchData(apprenticeModsId, studentId);

        }
    }, [show, apprenticeModsId, studentId]);  // Trigger when `show`, `apprenticeModsId`, or `studentId` changes

    const handleData = (apiData) => {

        if (!apiData) return;

        console.log('Data from Api:', apiData);

        // === Separate the data for easier handling === //

        const assessmentData = apiData.assessmentData;
        const combinedData = apiData.combinedData;
        const practicalData = apiData.practicalData;
        const supplementaryData = apiData.supplementaryData;
        const introData = apiData.introData;
        const performanceCriteria = apiData.mappedPerformanceCriteria

        // === Get/Set things like question counters and section counts === //

        // === Calculate the number of real questions for each taskKey === //
        let totalQuestions = {}; // Create an object to store the total questions for each taskKey
        Object.keys(assessmentData).forEach(taskKey => {
            const realQuestions = assessmentData[taskKey].filter(item =>
                ![-1, 13, 14].includes(item.QType)
            );
            totalQuestions[taskKey] = realQuestions.length; // Store the number of real questions for each taskKey
        });

        // Count how many assessment tasks there are based on combinedData keys
        const assessmentTaskCount = Object.keys(assessmentData).length;

        // === Apply filtering for each task === //
        const filteredCombinedData = {};
        let filteredOutQuestions = false; // Flag to track if any questions were filtered out

        Object.keys(combinedData).forEach(taskKey => {
            const originalLength = combinedData[taskKey].length; // Get the original number of questions
            filteredCombinedData[taskKey] = combinedData[taskKey].filter(item =>
                item.outcome !== 'S' && item.outcome !== 'SUP'
            );
            const filteredLength = filteredCombinedData[taskKey].length; // Get the new number of questions

            // If the lengths are different, it means some questions were filtered out
            if (originalLength !== filteredLength) {
                filteredOutQuestions = true;
            }
        });

        // === Apply filtering for supplementaryData data === //
        const filteredSupplementaryData = {};
        Object.keys(supplementaryData).forEach(taskKey => {
            filteredSupplementaryData[taskKey] = supplementaryData[taskKey].filter(item =>
                    item.outcome !== 'S'
                );
            });

        // Check if all tasks are empty
        const allTasksEmpty = Object.keys(filteredCombinedData).every(taskKey =>
            filteredCombinedData[taskKey].length === 0
        );

        // === Count and set practical observations === //
        setTotalObservations(practicalData.length)

        // === Apply filtering for practical data === //
        const filteredPracticalData = practicalData.filter(item => item.prevObservation?.outcome !== 'S');

        // Check if practicalData is empty
        const isPracticalDataEmpty = filteredPracticalData.length === 0;

        // check if there are supplementary questions
        // const hasSupplementaryQuestions = filteredSupplementaryData[taskKey].length !== 0;
        const hasSupplementaryQuestions = Object.keys(filteredSupplementaryData).every(taskKey =>
            filteredSupplementaryData[taskKey].length !== 0
        );

        // log({ hasSupplementaryQuestions })

        // Check if at least one question remains in the assessment data after filtering
        const hasRemainingAssessmentQuestions = Object.keys(filteredCombinedData).some(taskKey =>
            filteredCombinedData[taskKey].length > 0
        );


        // === Set the states based on conditions === //

        // >--- DELETE ONCE PDF CREATE DEBUGGING IS COMPLETED ---< //
        // if (!allTasksEmpty && isPracticalDataEmpty) {
        // >------------------------END--------------------------< //

        if (allTasksEmpty && isPracticalDataEmpty && !hasSupplementaryQuestions) {
            // If both assessment data and practical data are empty, set mode to 'completed'
            // console.log('All assessment and practical data are empty');
            print('mode = completed')
            setMode('completed');
        } else if (hasRemainingAssessmentQuestions && filteredOutQuestions) {
            // If there are assessment questions remaining, skip the intro and go straight to assessment
            // console.log('Some assessment data exists. Skipping intro.');
            print('mode = assessment')
            setMode('assessment');
        } else if (allTasksEmpty && hasSupplementaryQuestions) {
            // If there are supplementary questions then show them
            // console.log('');
            print('mode = supplementary')
            setMode('supplementary');
        } else if (allTasksEmpty && !hasSupplementaryQuestions) {
             // If only the practical data remains (assessment tasks are empty), go to 'practical'
             // console.log('Assessment data is empty, going to practical mode');
            print('mode = practical')
             setMode('practical');
        }

        // log({filteredPracticalData, filteredSupplementaryData})


        // Set the number of assessment tasks and real questions count
        setAssessmentTaskCount(assessmentTaskCount);
        setTotalQuestions(totalQuestions);

        // Set the filtered data for assessments, practical, and supplementary sections
        setActualAssessmentData(filteredCombinedData);
        setPracticalData(filteredPracticalData);
        setSupplementaryData(filteredSupplementaryData);
        setIntroData(introData);
        setMappingData(performanceCriteria)
        setDataLoaded(true);
    };

    async function fetchData( apprenticeModID, studentID ) {

        fetchApi('assessment/assess_answers', { 'apprenticeModID': apprenticeModID, 'studentID': studentID, modID: moduleId })

            .then(response => {

                switch ( true ) {
                    case (response.status >= 200 && response.status < 300):
                        handleData(response.responseData);
                        setMsgCode(0);
                        break;
                    default:
                        setMsg(response.msg);
                        setMsgCode(4);
                        break;
                }
            })
            .catch(error => {
                console.error('Fetch failed or fetch function is unreachable:', error);
                setMsg('An unexpected error occurred. Contact your administrator' + error);
                setMsgCode(4);
            }
        );

    }

    // const FeedbackComponent = ({ mapping, mappingData }) => {
    //
    //     // console.log('mapping: ', mapping)
    //     // console.log('perfCriteria: ', mappingData)
    //     // Function to format the list
    //     const getFormattedList = (mappingString, criteria) => {
    //         if (!mappingString || !criteria) return [];
    //
    //         // Split the mapping string into an array
    //         const mappingArray = mappingString.split(", ").map((item) => item.trim());
    //
    //         // Build the formatted list
    //         return mappingArray
    //             .map((map) => {
    //                 const found = criteria.find((entry) => entry.mapped === map);
    //                 return found ? `${map} - ${found.name}` : null;
    //             })
    //             .filter(Boolean); // Remove null values if no match is found
    //     };
    //
    //     // Get the formatted list based on the current data.mapping
    //     const formattedList = getFormattedList(mapping, mappingData)
    //
    //     console.log('formattedList: ', formattedList)
    //
    //     return (
    //         <div>
    //             {/*<div style={{ marginLeft: "25px", display: "flex", alignItems: "start", justifyContent: "start" }}>*/}
    //             {/*    <label className="label-criteria">{mapping}</label>*/}
    //             {/*</div>*/}
    //
    //             {/* Display the formatted list */}
    //             {formattedList.length > 0 && (
    //                 <div style={{ marginTop: "15px", fontSize: "14px" }}>
    //                     <p>This question assesses the learner's understanding of:</p>
    //                     <ul>
    //                         {formattedList.map((item, index) => (
    //                             <li key={index}>{item}</li>
    //                         ))}
    //                     </ul>
    //                 </div>
    //             )}
    //         </div>
    //     );
    // };

    const FeedbackComponent = ({ mapping, mappingData }) => {
        // Function to clean the `name` by removing leading numbers
        const cleanName = (name) => name.replace(/^\d+\s*[-:.\)]*\s*/, "");

        // Function to format the list
        const getFormattedList = (mappingString) => {
            if (!mappingString || !mappingData) return [];

            // Split the mapping string into an array and handle trailing commas
            const mappingArray = mappingString.split(",").map((item) => item.trim()).filter(Boolean);

            // Build the formatted list
            return mappingArray
                .map((map) => {
                    const found = mappingData.find((entry) => entry.mapped === map);
                    // Clean the name and include only `mapped` and the cleaned `name`
                    return found ? `${map} - ${cleanName(found.name)}` : null;
                })
                .filter(Boolean); // Remove null values if no match is found
        };

        // Get the formatted list based on the current data.mapping
        const formattedList = getFormattedList(mapping);

        return (
            <div>
                {/* Display the formatted list */}
                {formattedList.length > 0 && (
                    <div style={{ marginTop: "15px", fontSize: "14px" }}>
                        <p>This question assesses the learner's understanding of:</p>
                        <ul>
                            {formattedList.map((item, index) => (
                                <li key={index}>{item}</li>
                            ))}
                        </ul>

                    </div>
                )}
            </div>
        );
    };



    function formatString(str) {
        if (!str) return str;

        // Use DOMParser to convert HTML into plain text
        const parser = new DOMParser();
        const parsedDoc = parser.parseFromString(str, 'text/html');
        // let formattedStr = parsedDoc.body.innerHTML; // Maintain HTML structure for further custom transformations
        let formattedStr = parsedDoc.body.textContent || ''; // Extract plain text content

        // Handle the special case for "(Fill in the blanks) - "
        const fillInTheBlanksIndex = formattedStr.indexOf('(Fill in the blanks) - ');
        if (fillInTheBlanksIndex !== -1) {
            const beforeBlanks = formattedStr.substring(0, fillInTheBlanksIndex + '(Fill in the blanks)'.length);
            const afterBlanks = formattedStr.substring(fillInTheBlanksIndex + '(Fill in the blanks)'.length).replace(/-/g, ' '); // Replace '-' with ' '
            formattedStr = `${beforeBlanks}\n${afterBlanks}`;
        }

        // Replace "Â" with "-"
        formattedStr = formattedStr.replace(/Â/g, '');

        // Add a space after every period, if it is not already followed by a space or end of string
        formattedStr = formattedStr.replace(/(\.)(?!\s|$)/g, '. ');

        // Add a newline character after every colon
        formattedStr = formattedStr.replace(/:/g, ':\n');

        // Add a newline character after every semi-colon
        // formattedStr = formattedStr.replace(/;/g, ';\n');

        // Replace quot; with ""
        formattedStr = formattedStr.replace(/quot;/g, '"');

        // Replace <li> and </li> with line breaks
        formattedStr = formattedStr.replace(/<\/?li>/g, '\n');

        // Replace &apos; with regular apostrophe
        formattedStr = formattedStr.replace(/&apos;/g, "'");

        // Replace ~number with a dash
        formattedStr = formattedStr.replace(/~\d+/g, '-');

        // Remove <text:s/>
        formattedStr = formattedStr.replace(/<text:s\/>/g, '');

        // Remove <br> tags
        formattedStr = formattedStr.replace(/<br>/g, '\n');

        // Remove any underscores meant as placeholders (e.g., "________")
        formattedStr = formattedStr.replace(/_+/g, '');

        // Existing logic for handling #. pattern (bullet points)
        formattedStr = formattedStr.split('<br>').map(paragraph => {
            // Check if the paragraph contains #. pattern
            if (paragraph.includes('#.')) {
                const parts = paragraph.split('#.');
                const intro = parts.shift(); // text before the first #.
                const bullets = parts.map(item => '• ' + item.trim()).join('\n');
                return `${intro}\n${bullets}`;
            }
            return paragraph;
        }).join('\n');

        return formattedStr;
    }


    const splitQuestion = (text) => {
        const segments = [];
        let lastIndex = 0;

        const regex = /(\S*?)\[([^\]]+)\](\S*?)(?=\s|$)/g; // Matches word, gap, and following word
        let match;

        while ((match = regex.exec(text)) !== null) {
            // Add any text that precedes the match
            if (lastIndex !== match.index) {
                segments.push({ type: "text", content: text.substring(lastIndex, match.index) });
            }
            // Combine prefix, gap, and suffix into a single segment
            segments.push({ type: "word_with_gap", prefix: match[1], gap: match[2], suffix: match[3] });

            lastIndex = match.index + match[0].length;
        }

        // Add any remaining text that follows the last match
        if (lastIndex < text.length) {
            segments.push({ type: "text", content: text.substring(lastIndex) });
        }

        return segments;
    };

    function numberToWord(number) {
        const words = [
            "first", "second", "third", "fourth", "fifth",
            "sixth", "seventh", "eighth", "ninth", "tenth",
            "eleventh", "twelfth", "thirteenth", "fourteenth", "fifteenth",
            "sixteenth", "seventeenth", "eighteenth", "nineteenth", "twentieth"
        ];

        return words[number - 1] || "";
    }

    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    function PageComponent({ data, currentQuestionIndex ,totalQuestions, dataLength}) {

        // === Safety catch for blank data recovery === //

        let safeMode = mode;

        switch (mode) {
            case 'intro':
                safeMode = 'assessment';
                break;
            case 'assessment':
                safeMode = 'practical';
                break;
            case 'practical':
                safeMode = 'supplementary';
                break;
            case 'supplementary':
                safeMode = 'completed';
                break;
            default:
                break;
        }

        if (!data) {
            setMode(safeMode)
        }

        // === END === //

        const formRef = useRef(null);
        const [selectedOutcome, setSelectedOutcome] = useState(data.outcome);
        const feedbackRef = useRef(null);
        const [feedback, setFeedback] = useState(data.feedback);
        const [microMarking, setMicroMarking] = useState({});

        const animationRef = useRef(null);
        const [checkedBoxes, setCheckedBoxes] = useState(Array(20).fill(null));
        const [microMarkingUpdated, setMicroMarkingUpdated] = useState(false);
        const [submitReady, setSubmitReady] = useState(false);
        const [textInputValue, setTextInputValue] = useState("");
        const isSatisfactory = selectedOutcome === 'S';
        const backgroundColor = isSatisfactory ? 'rgba(0, 100, 0, 0.3)' : 'rgba(255, 0, 0, 0.3)'; // Green for 'S', Red for 'NS'
        const icon = isSatisfactory ? '✓' : 'X';  // Tick for 'S', Cross for 'NS'
        const [inputValues, setInputValues] = useState({});

        // Create a ref for the button
        const submitButtonRef = useRef(null);

        const handleFeedbackChange = (e) => {
            setFeedback(e.target.value);
        };

        // const handleNS = () => {
        //     // if (selectedOutcome === 'NS' && feedbackRef.current) {
        //     //     feedbackRef.current.focus();
        //     // }
        //     feedbackRef.current.focus();
        // }

        // useEffect(() => {
        //     if (selectedOutcome === 'NS' && feedbackRef.current) {
        //         feedbackRef.current.focus();
        //     }
        // }, [selectedOutcome]);

        // useEffect(() => {
        //     // Only set initial feedback when it's defined and not already modified
        //     if (data.feedback && !feedback) {
        //         setFeedback(data.feedback);
        //     }
        // }, [data.feedback]); // Runs only when `data.feedback` changes


        useEffect(() => {
            // Once microMarking is updated, set the flag to true
            if (Object.keys(microMarking).length > 0) {
                setMicroMarkingUpdated(true);
            }
        }, [microMarking]);

        useEffect(() => {
            if (microMarkingUpdated && selectedOutcome && submitReady) {

                // Call handleFormSubmit or other functions here safely
                handleFormSubmit(null, selectedOutcome);

                // Reset the flag after processing
                setMicroMarkingUpdated(false);

            }
        }, [microMarkingUpdated, submitReady]);

        useEffect(() => {

            // console.log(data.microMarking)
            const initialMicroMarking = {};
            // Regex to match keys of the form 'c' followed by one or more digits.
            const answerKeyRegex = /^c\d+$/;

            if (data.QType === 7 || data.QType === 6 || data.QType === 2) {

                if (data.QType === 7) {
                    // For qType=7, count the gaps directly from the question content
                    const gapCount = (formatString(data.question).match(/\[([^\]]+)\]/g) || []).length;
                    for (let i = 1; i <= gapCount; i++) {
                        initialMicroMarking[`A${i}`] = null; // Initialize marking state for each gap
                    }
                } else {
                    // For other question types, initialize based on available 'c' keys
                    Object.keys(data).forEach((key) => {
                        if (answerKeyRegex.test(key) && data[key] !== null) {
                            const questionIndex = key.substring(1); // Isolate the number after 'c'
                            initialMicroMarking[`A${questionIndex}`] = null;
                        }
                    });
                }

            }

            setMicroMarking(data.microMarking || initialMicroMarking);

        }, [data]);

        const handleCheckboxChange = (index, value, column = null) => {
            setCheckedBoxes((prev) => {
                const updatedCheckedBoxes = [...prev];

                // Check if it's Question Type 11 with columns (must/must-not)
                if (data.QType === 11) {
                    const mustNotOffset = data.c2.split('#').length; // Offset for must not column

                    if (column === 'must') {
                        // Update only the 'must' column checkbox without affecting 'must not'
                        updatedCheckedBoxes[index] = value === 'S' ? 'S' : 'NS';
                    } else if (column === 'must-not') {
                        // Update only the 'must not' column checkbox without affecting 'must'
                        updatedCheckedBoxes[index + mustNotOffset] = value === 'S' ? 'S' : 'NS';
                    }

                    // Handle Question Type 15: No columns, checkboxes toggle independently
                } else if (data.QType === 15) {
                    updatedCheckedBoxes[index] = value === 'S' ? 'S' : 'NS'; // Simple toggle for QType 15
                }

                return updatedCheckedBoxes;
            });
        };

        // Reset the state when movin to a different question
        const resetState = () => {

            setSelectedOutcome(null);
            setCheckedBoxes(Array(20).fill(null));
            setMicroMarking({})
            setMsgCode(0)
            setSubmitReady(false);
        }
        const updateMicroMarking = (questionId, value) => {

            // print('microMarking was updated!')

            setMicroMarking(prev => ({  // <--- This works fine
                ...prev,
                [questionId]: value
            }));

        };

        const handleOutcomeChange = (event) => {
            setSelectedOutcome(event.target.value);
            populateMicroMarking(event.target.value);

            if (event.target.value === 'S') {
                checkAllBoxes();
            }

            if (event.target.value === 'NS') {
                feedbackRef.current.focus();
            }

        };

        const handleNextQuestion = () => {
            let newMode = mode;

            switch (mode) {
                case 'intro':
                    newMode = 'assessment';
                    break;

                case 'assessment':
                    if (currentQuestionIndex === dataLength - 1) {
                        if (currentTaskKey < assessmentTaskCount) {
                            setCurrentTaskKey((prevKey) => prevKey + 1);
                            setCurrentQuestionIndex(0);
                        } else if (Object.keys(supplementaryData[currentTaskKey]).length > 0) {
                            newMode = 'supplementary';
                        } else if (Object.keys(practicalData).length > 0) {
                            newMode = 'practical';
                        } else {
                            newMode = 'completed';
                        }
                    } else {
                        setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
                    }
                    break;

                case 'supplementary':
                    if (currentQuestionIndex === dataLength - 1) {
                        if (currentTaskKey < assessmentTaskCount) {
                            setCurrentTaskKey((prevKey) => prevKey + 1);
                            setCurrentQuestionIndex(0);
                        } else if (Object.keys(practicalData).length > 0) {
                            newMode = 'practical';
                        } else {
                            newMode = 'completed';
                        }
                    } else {
                        setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
                    }
                    break;

                case 'practical':
                    if (currentQuestionIndex === dataLength - 1) {
                        newMode = 'completed';
                    } else {
                        setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
                    }
                    break;

                case 'completed':
                    // Final state, no further changes
                    return;

                default:
                    console.warn(`Unknown mode: ${mode}`);
                    return;
            }

            // Update mode and reset states if necessary
            setMode(newMode);

            if (newMode !== mode) {
                setCurrentQuestionIndex(0);
                resetState();
            }
        };

        // const handleNextQuestion2 = () => {
        //
        //     // let newMode = mode;
        //     //
        //     // switch (mode) {
        //     //     case 'intro':
        //     //         newMode = 'assessment';
        //     //         break;
        //     //     case 'assessment':
        //     //         newMode = 'practical';
        //     //         break;
        //     //     case 'practical':
        //     //         newMode = 'supplementary';
        //     //         break;
        //     //     case 'supplementary':
        //     //         newMode = 'completed';
        //     //         break;
        //     //     default:
        //     //         break;
        //     // }
        //     //
        //     // if (!data) {
        //     //     setMode(newMode)
        //     // }
        //
        //     if (mode === 'intro') {
        //         // print('Changed to assessment')
        //         setMode('assessment');
        //         return
        //     }
        //
        //     if (currentQuestionIndex === dataLength - 1) {
        //
        //         if (mode === 'assessment') {
        //
        //             if ( currentTaskKey < assessmentTaskCount ) {
        //                 setCurrentTaskKey(prevIndex => prevIndex + 1)
        //                 setCurrentQuestionIndex(0)
        //
        //             } else {
        //
        //                 if (Object.keys(supplementaryData).length > 0) {
        //                     // log({supplementaryData})
        //                     // print('Changed to supplementary')
        //                     setMode('supplementary');
        //                     setCurrentQuestionIndex(0);
        //                     return;
        //                 } else if (Object.keys(practicalData).length > 0) {
        //                     // print('Changed to practical')
        //                         setMode('practical');
        //                         setCurrentQuestionIndex(0);
        //                         return;
        //                 }
        //             }
        //
        //         } else if (mode === 'supplementary') {
        //
        //             if ( currentTaskKey < assessmentTaskCount ) {
        //                 setCurrentTaskKey(prevIndex => prevIndex + 1)
        //                 setCurrentQuestionIndex(0)
        //             } else {
        //                 // print('Changed to practical')
        //                 setMode('practical');
        //                 setCurrentQuestionIndex(0);
        //                 return;
        //             }
        //
        //         } else {
        //             // print('Changed to assessment')
        //             setMode('completed');
        //         }
        //
        //         return;
        //     }
        //
        //     setCurrentQuestionIndex(prevIndex => prevIndex + 1);
        //     resetState()
        // };

        const handlePreviousQuestion = () => {

            if (currentQuestionIndex > 0) {
                setCurrentQuestionIndex(prevIndex => prevIndex - 1);
            }
            resetState()
        };

        const populateMicroMarking = async (outcome) => {

            setMicroMarkingUpdated(false);

            const newValues = {};
            let anyUpdates = false;

            if (outcome === 'S' && Object.keys(microMarking).length > 0) {

                // Iterate over each key in microMarking
                Object.entries(microMarking).forEach(([key, value]) => {

                    if (value !== 'S') {
                        newValues[key] = 'S';  // Set to 'tick' if selectedOutcome is 'S'
                        anyUpdates = true;  // Flag that we have updates to apply
                    } else {
                        newValues[key] = value;  // Maintain current value
                    }
                });

            } else if (outcome === 'NS' && Object.keys(microMarking).length > 0) {

                // Iterate over each key in microMarking
                Object.entries(microMarking).forEach(([key, value]) => {

                    if (value === null) {
                        newValues[key] = 'NS';  // Set to 'cross' if selectedOutcome is 'NS'
                        anyUpdates = true;  // Flag that we have updates to apply
                    } else {
                        newValues[key] = value;  // Maintain current value if already 'cross'
                    }
                });
            }

            if (anyUpdates) {
                setMicroMarking(newValues);

            }

            setMicroMarkingUpdated(true);



            // else {
            //     // handleFormSubmit(null, outcome); // <---- this is the original fix to allow text input questions to submit on upArrow. But causes practical tasks to submit prematurely
            //
            // }

            return
        }

        const checkAllBoxes = () => {
            return new Promise((resolve) => {
                setCheckedBoxes(Array(20).fill('S'));
                resolve(); // Resolve the promise after the state has been set
            });
        };

        // Using hotkeys to navigate questions
        useHotkeys('ArrowRight', handleNextQuestion);
        useHotkeys('ArrowLeft', handlePreviousQuestion);

        useHotkeys('upArrow', async () => {

            setSelectedOutcome('S');

            if (mode === 'practical') {
                await checkAllBoxes();

                // === If I need form Data === //
                if (formRef.current) {
                    // Collect form data manually using FormData
                    const formData = new FormData(formRef.current);
                    const formDataObj = Object.fromEntries(formData.entries());
                    // console.log('Manually Collected Form Data:', formDataObj);
                    handleFormSubmit(null, 'S', formDataObj);
                }
                // =========================== //

            } else {
                await populateMicroMarking('S')

                // print('upArrow was pressed')
                setSubmitReady(true);
            }
            setSubmitReady(true);
        });

        useHotkeys('downArrow', () => {
            setSelectedOutcome('NS');
            populateMicroMarking('NS');
            if (feedbackRef.current) {
                feedbackRef.current.focus();  // Ensure feedback textbox stays focused
            }
        });

        const handleKeyDown = async(event) => {

            // populateMicroMarking('NS')

            // Check if the Enter key was pressed and not a Shift+Enter which is often used to enter a new line in a textarea
            if (event.key === 'Enter' && !event.shiftKey) {
                event.preventDefault();
                setSelectedOutcome('NS');

                if (mode === 'practical') {

                    // === If I need form Data === //
                    if (formRef.current) {
                        // Collect form data manually using FormData
                        const formData = new FormData(formRef.current);
                        const formDataObj = Object.fromEntries(formData.entries());
                        // console.log('Manually Collected Form Data:', formDataObj);
                        handleFormSubmit(null, 'NS', formDataObj);
                    }
                    // =========================== //

                } else {
                    handleFormSubmit(null, 'NS');
                }
            }
        };

        function extractAnswers(question) {
            const answerRegex = /\[([^\]]+)\]/g;
            let match;
            const answers = [];
            while ((match = answerRegex.exec(question)) !== null) {
                answers.push(match[1]);
            }
            return answers;
        }

        let buttonText = "Next Question";  // default button text

        if (mode === 'intro') {
            buttonText = "Begin";
        } else if (mode === 'complete') {
            buttonText = "Finish";
        } else {
            buttonText = "Next";
        }

        function adjustTextareaHeight(event) {
            const textarea = event.target;
            textarea.style.height = 'auto'; // Reset height to shrink if needed
            textarea.style.height = `${textarea.scrollHeight}px`; // Expand to the scroll height
        }

        const isTrueOrFalse = (c1, c2) => {
            const lowerC1 = c1.toLowerCase().trim();
            const lowerC2 = c2.toLowerCase().trim();
            return (lowerC1 === "true." && lowerC2 === "false.") || (lowerC1 === "false." && lowerC2 === "true.");
        };

        const ToggleCheckbox = ({ questionId, initialState, updateMicroMarking }) => {

            const [checked, setChecked] = useState(initialState);

            const toggleCheck = () => {
                // Toggle between 'tick' and 'cross', ignoring null after initial setup
                const newValue = checked === 'S' ? 'NS' : 'S';
                setChecked(newValue);
                updateMicroMarking(questionId, newValue);

                if (newValue === 'NS') {
                    setSelectedOutcome('NS');
                }

            };

            const boxStyle = {
                display: 'inline-block',
                width: '24px',
                height: '24px',
                marginRight: '0px',
                // marginBottom: '5px',
                border: '2px solid #000',
                borderRadius: '5px',
                verticalAlign: 'middle',
                textAlign: 'center',
                lineHeight: '10px',
                fontSize: '30px',
                cursor: 'pointer',
                backgroundColor: '#fff',
                color: checked ? (checked === 'S' ? 'green' : 'red') : 'transparent',  // Transparent when null
                userSelect: 'none',
                WebkitUserSelect: 'none',
                MozUserSelect: 'none',
                msUserSelect: 'none'
            };

            const textStyle = {
                display: 'inline-flex',
                alignItems: 'center',
                fontSize: '30px',
                marginLeft: '5px',
                userSelect: 'none',
                WebkitUserSelect: 'none',
                MozUserSelect: 'none',
                msUserSelect: 'none'
            };

            return (
                <label style={textStyle} onClick={toggleCheck}>
            <span style={boxStyle}>
                {checked === 'S' ? '✓' : checked === 'NS' ? '✗' : ''}
            </span>
                </label>
            );
        };

        const handleFormSubmit = async (event = null, outcome, pracData) => {

            let isSupplementary = false;
            let formData;
            let formDataObj = {};

            if ( pracData ) {

                formDataObj = pracData;

            } else {

                // Check if an event is passed and it contains the target (i.e., it's a form submission)
                if (event && event.target) {
                    event.preventDefault();  // Prevent default form submission if event exists
                    formData = new FormData(event.target); // Extract form data from the event's form

                    // Only process the form data if there is any
                    if (formData) {
                        formData.forEach((value, key) => {
                            formDataObj[key] = value;
                        });
                    }
                }

            }

            if (mode === 'supplementary') {
                isSupplementary = true;
            }

            if ( !data.questionNum || (!outcome && mode !== 'practical') ) {
                handleNextQuestion();
                return;
            }

            const needsMicroMarking = data.c1?.includes("____________") || false;

            let microMarkingData = JSON.stringify(microMarking);

            if (data.QType === 2 && needsMicroMarking === false) {
                microMarkingData = null
                print('MicroMarkingData was set to null')
            }

            const feedbackValue = feedbackRef.current ? feedbackRef.current.value : '';
            console.log('Feedback:', feedbackValue);

            // ==== Marking animation ==== //

            if (animationRef.current) {
                animationRef.current.classList.add('fade-in');

                setTimeout(() => {
                    if (animationRef.current) {
                        animationRef.current.classList.remove('fade-in');
                    }
                }, 300);
            }

            await delay(500)

            // ==== End loading animation ==== //
            console.log('formDataObj:', formDataObj);   

            const postData = {
                studentId,
                moduleId,
                classId,
                certId: data.certId,
                qNum: data.questionNum,
                qType: data.QType,
                section: data.section,
                questionCount: totalQuestions,
                outcome,
                isSupplementary,
                feedback: feedbackValue,
                apprenticeModsId,
                microMarking: microMarkingData,
                formData: formDataObj, // <--- This is the practical form data
            }

            // Object.entries(postData.microMarking).forEach(([key, value]) => {
            //     dispatch(
            //       saveAnswer({
            //         qNum: postData.qNum,
            //         key,
            //         value,
            //       })
            //     );
            //   });
            
              
            dispatch(saveFeedback({ qNum: postData.qNum, feedback: postData.feedback }));
            
            dispatch(saveOutcome({ qNum: postData.qNum, outcome: postData.outcome }));
            // console.log('Post Data Feedback:', postData.feedback);
            // console.log('Post Data MicroMarking:', postData.microMarking);
            // console.log('Post Data qNum:', postData.qNum);
            // console.log('Post Data outcome:', postData.outcome);

            // log({postData});

            let endpoint;

            if ( outcome || mode === 'practical' ) {

                if (mode === 'practical') {
                    endpoint = 'assessment/assess_practical';
                } else {
                    endpoint = 'assessment/assess_outcome';
                }
                // console.log('Post data:', postData);

                fetchApi(endpoint, postData)

                    .then(response => {
                        switch ( true ) {
                            case (response.status >= 200 && response.status < 300):
                                handleNextQuestion();
                                break;
                            default:
                                console.error("Error submitting outcome");
                                setMsg(response.msg || 'An unexpected error occurred. The outcome was not submitted.');
                                setMsgCode(4);
                                break;
                        }
                    })
            }

        };

        const handleInputChange = (index, value) => {
            setInputValues(prevState => ({
                ...prevState,
                [`c${index + 1}`]: value, // Update the value for the specific input
            }));
        };

        const DynamicInput = ({ value, placeholder, questionId, microMarking, updateMicroMarking }) => {
            const inputRef = useRef();

            useEffect(() => {
                if (inputRef.current) {
                    inputRef.current.style.height = "auto"; // Reset height
                    inputRef.current.style.height = `${inputRef.current.scrollHeight}px`; // Adjust based on content
                }
            }, [value]); // Re-run whenever `value` changes

            return (
                <>
                    <textarea
                        ref={inputRef}
                        className="multi-answer"
                        placeholder={placeholder}
                        style={{
                            width: "100%",
                            // marginTop: -15,
                            marginRight: 5,
                            userSelect: "none",
                            pointerEvents: "none",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                            padding: "3px",
                            boxSizing: "border-box",
                            resize: "none",
                            overflow: "hidden", // Hide scrollbars
                            whiteSpace: "pre-wrap", // Preserve newlines and wrapping
                            wordWrap: "break-word", // Break long words
                        }}
                        rows={1} // Default to a single line
                        value={value}
                        readOnly
                    />
                    <ToggleCheckbox

                        questionId={questionId}
                        initialState={microMarking[questionId] || null}
                        updateMicroMarking={updateMicroMarking}
                    />
                </>
            );
        };

        return (
            <div>

                {data.QType !== -1 && data.QType !== 15 && (
                    <h1 style={{width: '100%'}}><strong>Question {data.questionNum}
                        <span style={{fontSize: 32, fontWeight: 'normal', color: '#000'}}><em> of  </em></span>
                        {totalQuestions}</strong>
                    </h1>
                )}

                {data.QType === 15 && (
                    <h1 style={{width: '100%'}}><strong>{data.questionNum}
                        <span style={{fontSize: 32, fontWeight: 'normal', color: '#000'}}><em> of  </em></span>
                        {totalQuestions}</strong>
                    </h1>
                )}

                {data.QType === -1 && (
                    <div style={{padding: 25}} className="intro-page">

                        <h1><strong>Assessment Task {data.section}</strong></h1>
                        <div style={{margin: '40px 0'}}>
                            <h2 style={{textAlign: 'left'}}>{formatString(data.question)}</h2>
                        </div>

                        {/* Render the next question (QType 13) */}
                        {introData[currentTaskKey][1] && introData[currentTaskKey][1].QType === 13 && (
                            <div style={{padding: '15px', textAlign: 'left'}}>
                                <h3 style={{whiteSpace: 'pre-line'}}>
                                    {formatString(introData[currentTaskKey][1].question)}
                                </h3>
                            </div>
                        )}

                        {/* Render the next question (QType 14) */}
                        {introData[currentTaskKey][2] && introData[currentTaskKey][2].QType === 14 && (
                            <div style={{padding: '15px', textAlign: 'left'}}>
                                <h3 style={{whiteSpace: 'pre-line'}}>
                                    {formatString(introData[currentTaskKey][2].question)}
                                </h3>
                            </div>
                        )}

                    </div>
                )}


                {data.QType === 1 && (
                    <div className="question">
                        <Row>
                            <div style={{margin: '40px 0'}}>
                                <h2 className="justify-text lead-in">{formatString(data.question)}</h2>
                            </div>
                        </Row>
                        <Row>
                            <Col xs={12} md={6} xl={6} className={"column-left"}>
                                <BorderedComponent name="Candidate's Answer">
                                    {Array.from({length: 20}).map((_, i) => {
                                        const choice = data[`c${i + 1}`];
                                        if (choice) {
                                            return (
                                                <div key={i} style={{
                                                    display: 'flex',
                                                    alignItems: 'start',
                                                    marginBottom: '10px'
                                                }}>
                                                    <input
                                                        type="checkbox"
                                                        className="radio-as-checkbox"
                                                        id={`choice${i + 1}`}
                                                        name="answer"
                                                        value={`c${i + 1}`}
                                                        checked={data.studentAnswers[`c${i + 1}`] === "1"}
                                                        onChange={(e) => handleCheckboxChange(i, e.target.checked)}
                                                    />
                                                    <label htmlFor={`choice${i + 1}`}>{formatString(choice)}</label>
                                                </div>
                                            );
                                        }
                                        return null;
                                    })}
                                </BorderedComponent>
                            </Col>
                            <Col xs={12} md={6} xl={6} className={"column-right"}>
                                <BorderedComponent name="Answer Guide">
                                    {Array.from({length: 20}).map((_, i) => {
                                        const choice = data[`c${i + 1}`];
                                        if (choice) {
                                            return (
                                                <div key={i} style={{
                                                    display: 'flex',
                                                    alignItems: 'start',
                                                    marginBottom: '10px'
                                                }}>
                                                    <input
                                                        type="checkbox"
                                                        className="radio-as-checkbox-answer"
                                                        id={`choice${i + 1}`}
                                                        name="answer"
                                                        value={`c${i + 1}`}
                                                        checked={data.answer.split(',').includes((i + 1).toString())}
                                                        readOnly
                                                    />
                                                    <label htmlFor={`choice${i + 1}`}>{formatString(choice)}</label>
                                                </div>
                                            );
                                        }
                                        return null;
                                    })}
                                </BorderedComponent>
                            </Col>
                        </Row>
                    </div>
                )}

                {data.QType === 2 && mode !== 'practical' && (
                    <div className="question">
                        <div style={{margin: '40px 0'}}>
                            <h2 className="justify-text lead-in">{formatString(data.question)}</h2>
                        </div>
                        <Row>
                            <Col xs={12} md={6} xl={6} className={"column-left"}>
                                <BorderedComponent name="Candidate's Answer">
                                    {isTrueOrFalse(data.c1, data.c2) ? (
                                        [data.c1, data.c2].map((choice, i) => { // Only iterate over true and false
                                            if (choice) {
                                                return (
                                                    <div key={i} style={{
                                                        display: 'flex',
                                                        alignItems: 'start',
                                                        marginBottom: '10px'
                                                    }}>
                                                        <input
                                                            type="radio"
                                                            className="radio-as-checkbox"
                                                            id={`student-choice${i + 1}`}
                                                            name="student-answer"
                                                            value={`c${i + 1}`}
                                                            checked={data.studentAnswers[`c${i + 1}`] === "1"}
                                                            readOnly
                                                        />
                                                        <label
                                                            htmlFor={`student-choice${i + 1}`}>{formatString(choice)}</label>
                                                    </div>
                                                );
                                            }
                                            return null;
                                        })

                                    ) : data.c1.includes("____________") ? (
                                        <div>
                                            {Array.from({ length: 20 }).map((_, i) => {
                                                const choice = data[`c${i + 1}`];
                                                if (choice) {
                                                    const parts = choice.split("~3"); // Split choice into pre-provided text and blank
                                                    const studentAnswer = data.studentAnswers?.[`c${i + 1}`] || ""; // Get student's saved answer
                                                    const answerParts = studentAnswer ? studentAnswer.split("|") : ["", ""]; // Split student's answer if dual-part

                                                    return (
                                                        <div
                                                            key={i}
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                marginBottom: "10px",
                                                                width: "100%",
                                                            }}
                                                        >
                                                            <label
                                                                style={{
                                                                    marginRight: "10px",
                                                                    fontSize: 30,
                                                                    // marginBottom: 15,
                                                                }}
                                                            >
                                                                {i + 1}
                                                            </label>

                                                            {parts.length > 1 ? (
                                                                // Dual-input with pre-provided first part
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        flexDirection: "row",
                                                                        alignItems: "center",
                                                                        gap: "10px",
                                                                        width: "100%",
                                                                    }}
                                                                >
                                                                <span
                                                                    style={{
                                                                        fontSize: "1rem",
                                                                        fontWeight: "bold",
                                                                        whiteSpace: "nowrap",
                                                                    }}
                                                                >
                                                                  {formatString(parts[0].trim())} -
                                                                    </span>
                                                                        <input
                                                                            type="text"
                                                                            className="multi-answer"
                                                                            placeholder="Part 2..."
                                                                            style={{ flex: 1 }}
                                                                            value={answerParts[0]}
                                                                            readOnly
                                                                        />
                                                                        <ToggleCheckbox
                                                                            questionId={`A${i + 1}`}
                                                                            initialState={microMarking[`A${i + 1}`] || null}
                                                                            updateMicroMarking={updateMicroMarking}
                                                                        />
                                                                    </div>
                                                            ) : (

                                                                // Single-input for blank-only fields
                                                                // <>
                                                                //     <input
                                                                //         type="text"
                                                                //         className="multi-answer"
                                                                //         placeholder={`Type your ${numberToWord(i + 1)} answer here...`}
                                                                //         style={{
                                                                //             width: "100%",
                                                                //             userSelect: "none",
                                                                //             pointerEvents: "none",
                                                                //         }}
                                                                //
                                                                //         value={data.studentAnswers[`c${i + 1}`] || ""}
                                                                //         readOnly
                                                                //     />
                                                                //     <ToggleCheckbox
                                                                //         questionId={`A${i + 1}`}
                                                                //         initialState={microMarking[`A${i + 1}`] || null}
                                                                //         updateMicroMarking={updateMicroMarking}
                                                                //     />
                                                                // </>

                                                                <DynamicInput
                                                                    value={data.studentAnswers[`c${i + 1}`] || ""}
                                                                    placeholder={`Type your ${numberToWord(i + 1)} answer here...`}
                                                                    questionId={`A${i + 1}`}
                                                                    microMarking={microMarking}
                                                                    updateMicroMarking={updateMicroMarking}
                                                                />

                                                            )}
                                                        </div>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </div>
                                    ) : (
                                        // Multiple choices question
                                        <div>
                                            {Array.from({length: 20}).map((_, i) => {
                                                const choice = data[`c${i + 1}`];
                                                if (choice) {
                                                    return (
                                                        <div key={i} style={{
                                                            display: 'flex',
                                                            alignItems: 'start',
                                                            marginBottom: '10px'
                                                        }}>
                                                            <input
                                                                type="checkbox"
                                                                className="radio-as-checkbox"
                                                                id={`student-choice${i + 1}`}
                                                                name="student-answer"
                                                                value={`c${i + 1}`}
                                                                checked={data.studentAnswers[`c${i + 1}`] === "1"}
                                                                readOnly
                                                            />
                                                            <label
                                                                htmlFor={`student-choice${i + 1}`}>{formatString(choice)}</label>
                                                        </div>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </div>
                                    )}
                                </BorderedComponent>
                            </Col>
                            <Col xs={12} md={6} xl={6} className={"column-right"}>
                                <BorderedComponent name="Answer Guide">
                                    {isTrueOrFalse(data.c1, data.c2) ? (
                                        [data.c1, data.c2].map((choice, i) => { // Only iterate over true and false
                                            if (choice) {
                                                return (
                                                    <div key={i} style={{
                                                        display: 'flex',
                                                        alignItems: 'start',
                                                        marginBottom: '10px'
                                                    }}>
                                                        <input
                                                            type="radio"
                                                            className="radio-as-checkbox-answer"
                                                            id={`model-choice${i + 1}`}
                                                            name="model-answer"
                                                            value={`c${i + 1}`}
                                                            checked={parseInt(data.answer) === i + 1}
                                                            disabled={true}
                                                        />
                                                        <label
                                                            htmlFor={`model-choice${i + 1}`}>{formatString(choice)}</label>
                                                    </div>
                                                );
                                            }
                                            return null;
                                        })
                                    ) : data.c1.includes("____________") ? (
                                        // Handling split or multiple input answer types
                                        <div>
                                            <p style={{color: 'darkred'}}>{data.answer}</p>
                                        </div>
                                    ) : (

                                        <div>
                                            {Array.from({length: 20}).map((_, i) => {
                                                const choice = data[`c${i + 1}`];
                                                if (choice) {
                                                    return (
                                                        <div key={i} style={{
                                                            display: 'flex',
                                                            alignItems: 'start',
                                                            marginBottom: '10px'
                                                        }}>
                                                            <input
                                                                type="checkbox"
                                                                className="radio-as-checkbox-answer"
                                                                id={`model-choice${i + 1}`}
                                                                name="model-answer"
                                                                value={`c${i + 1}`}
                                                                checked={data.answer.split(',').includes((i + 1).toString())}
                                                                readOnly
                                                            />
                                                            <label
                                                                htmlFor={`model-choice${i + 1}`}>{formatString(choice)}</label>
                                                        </div>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </div>
                                    )}
                                </BorderedComponent>
                            </Col>
                        </Row>
                    </div>
                )}


                {data.QType === 5 && (
                    <div className="question">
                        <Row>
                            <div className="justify-text lead-in" style={{ margin: '40px 0' }}>
                                <div style={{ padding: 15 }} dangerouslySetInnerHTML={{ __html: data.question }}></div>
                            </div>
                        </Row>
                        <Row>
                            {/*<Col xs={12} md={6} xl={6} className={"column-left"}>*/}
                            {/*    <BorderedComponent name="Candidate's Answers">*/}
                            {/*        <ul>*/}
                            {/*            {Array.from({length: 20}).map((_, index) => {*/}
                            {/*                const key = `c${index + 1}`;*/}
                            {/*                if (data.studentAnswers[key]) {*/}
                            {/*                    return <li key={index}>{data.studentAnswers[key]}</li>;*/}
                            {/*                }*/}
                            {/*                return null;*/}
                            {/*            }).filter(item => item !== null)}*/}
                            {/*        </ul>*/}
                            {/*    </BorderedComponent>*/}
                            {/*</Col>*/}
                            <Col xs={12} md={6} xl={6} className={"column-left"}>
                                <BorderedComponent name="Candidate's Answers">
                                    <ul>
                                        {Array.from({ length: 20 })
                                            .map((_, index) => {
                                                const key = `c${index + 1}`;
                                                if (data.studentAnswers[key]) {
                                                    return <li key={index}>{data.studentAnswers[key]}</li>;
                                                }
                                                return null;
                                            })
                                            .filter(item => item !== null)}
                                    </ul>
                                </BorderedComponent>
                            </Col>
                            <Col xs={12} md={6} xl={6} className={"column-right"}>
                                <BorderedComponent name="Correct order">
                                    <ul>
                                        {(() => {
                                            const answerPositions = data.answer.split(",").map(Number); // Convert "2,3,1" -> [2, 3, 1]
                                            const mappedKeys = Object.keys(data) // Filter valid cX keys
                                                .filter(key => /^c\d+$/.test(key) && data[key]) // Keep only `cX` keys with non-null values
                                                .map((key, index) => ({
                                                    key,
                                                    value: data[key],
                                                    position: answerPositions[index], // Map `cX` to positions in the answer
                                                }))
                                                .sort((a, b) => a.position - b.position); // Sort by position in the `answer`

                                            return mappedKeys.map(({ key, value }) => (
                                                <li key={key} style={{ color: 'darkred' }}>
                                                    {value}
                                                </li>
                                            ));
                                        })()}
                                    </ul>
                                </BorderedComponent>
                            </Col>
                        </Row>
                    </div>
                )}



                {data.QType === 6 && (
                    <div className="fill-in-the-gaps">
                        <div className="justify-text lead-in" style={{margin: '40px 0'}}>
                            <div dangerouslySetInnerHTML={{__html: data.question}} style={{marginBottom: '50px'}}/>

                            <Row>
                                <Col xs={12} md={6} xl={6} className={"column-left"}>
                                    <BorderedComponent name="Candidate's Answer">
                                        {Array.from({length: 20}).map((_, i) => {
                                            const key = `c${i + 1}`;
                                            const modelValue = data[key];
                                            const studentValue = data.studentAnswers[key];
                                            return (modelValue && studentValue) && (
                                                <div key={i} style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    marginBottom: '10px'
                                                }}>
                                                    <strong>{modelValue[0]}</strong> {/* First character of the model's answer */}
                                                    <input
                                                        className="gap-input"
                                                        value={studentValue} // The complete student's answer
                                                        readOnly
                                                        style={{
                                                            fontFamily: "'Patrick Hand', cursive",
                                                            fontSize: '24px',
                                                            // marginLeft: '1px',
                                                            width: '100%', // Set input width to 100%
                                                            border: 'none', // Remove border
                                                            outline: 'none', // Remove focus outline
                                                            backgroundColor: 'transparent' // Make background transparent
                                                        }}
                                                    />
                                                    <ToggleCheckbox
                                                        questionId={`A${i + 1}`}  // Ensure `i` is defined in the context
                                                        initialState={microMarking[`A${i + 1}`] || null}  // Provide a default state if undefined
                                                        updateMicroMarking={updateMicroMarking}
                                                    />
                                                </div>
                                            );
                                        })}
                                    </BorderedComponent>
                                </Col>
                                <Col xs={12} md={6} xl={6} className={"column-right"}>
                                    <BorderedComponent name="Answer Guide">
                                        {Array.from({length: 20}).map((_, i) => {
                                            const value = data[`c${i + 1}`];
                                            return value && (
                                                <div key={i} style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    marginBottom: '10px'
                                                }}>
                                                    <strong style={{color: 'darkred'}}>{value[0]}</strong>
                                                    <span style={{
                                                        fontFamily: "'Patrick Hand', cursive",
                                                        fontSize: '24px',
                                                        color: 'darkred',
                                                        width: '100%' // Ensure the span fills the container
                                                    }}>
                                                        {value.substring(1)}
                                                    </span>
                                                </div>
                                            );
                                        })}
                                    </BorderedComponent>
                                </Col>
                            </Row>
                        </div>
                    </div>
                )}

                {data.QType === 7 && (
                    <div className="fill-in-the-gaps">
                        <div className="justify-text" style={{marginTop: 25}}>
                            {(() => {
                                let gapCounter = 1;
                                return formatString(data.question).split('\n').map((line, index) => (
                                    <p key={index}>
                                        {splitQuestion(line).map((segment, innerIndex) => {
                                            if (segment.type === "text") {
                                                return <span key={innerIndex}>{segment.content}</span>;
                                            } else if (segment.type === "gap") {
                                                const answer = data.studentAnswers[`c${gapCounter}`]?.toLowerCase() || "";
                                                gapCounter++;
                                                return (
                                                    <span key={innerIndex} style={{whiteSpace: "nowrap"}}>
                                                    <span style={{
                                                        textDecoration: "underline",
                                                        color: "#17176e",
                                                        fontFamily: "'Patrick Hand', cursive", // Set the font family
                                                        fontSize: "24px",
                                                        display: "inline-block"
                                                    }}>
                                                        {answer || "___"}
                                                    </span>
                                                    <ToggleCheckbox
                                                        questionId={`A${gapCounter - 1}`}  // Adjusted to use `gapCounter`
                                                        initialState={microMarking[`A${gapCounter - 1}`] || null}
                                                        updateMicroMarking={updateMicroMarking}
                                                    />
                                                </span>
                                                );
                                            } else if (segment.type === "word_with_gap") {
                                                const answer = data.studentAnswers[`c${gapCounter}`]?.toLowerCase() || "";
                                                gapCounter++;
                                                return (
                                                    <span key={innerIndex} style={{whiteSpace: "nowrap"}}>
                                                    <span>{segment.prefix}</span>
                                                    <span style={{
                                                        textDecoration: "underline",
                                                        color: "#17176e",
                                                        fontFamily: "'Patrick Hand', cursive",
                                                        fontSize: "24px",
                                                        display: "inline-block"
                                                    }}>
                                                        {answer || "___"}
                                                    </span>
                                                    <span>{segment.suffix}</span>
                                                    <ToggleCheckbox
                                                        questionId={`A${gapCounter - 1}`}  // Adjusted to use `gapCounter`
                                                        initialState={microMarking[`A${gapCounter - 1}`] || null}
                                                        updateMicroMarking={updateMicroMarking}
                                                    />
                                                </span>
                                                );
                                            }
                                            return null;
                                        })}
                                    </p>
                                ));
                            })()}
                        </div>
                        <div className="fill-in-the-gaps" style={{marginTop: 25}}>
                            <h2>Answer Guide</h2>
                            <div className="justify-text" style={{marginTop: 25}}>
                                {(() => {
                                    let gapCounter = 0;
                                    const answers = extractAnswers(data.question);

                                    return formatString(data.question).split('\n').map((line, index) => (
                                        <p key={index}>
                                            {splitQuestion(line).map((segment, innerIndex) => {
                                                if (segment.type === "text") {
                                                    return <span key={innerIndex}>{segment.content}</span>;
                                                } else if (segment.type === "gap") {
                                                    return (
                                                        <span key={innerIndex} style={{whiteSpace: "nowrap"}}>
                                                            <span className="gap-input" style={{
                                                                textDecoration: "underline",
                                                                fontFamily: "'Patrick Hand', cursive", // Set the font family
                                                                fontSize: "24px", // Set the font size
                                                                color: "red"
                                                            }}>
                                                            {answers[gapCounter++] || "___"}
                                                            </span>
                                                        </span>
                                                    );
                                                } else if (segment.type === "word_with_gap") {
                                                    return (
                                                        <span key={innerIndex} style={{whiteSpace: "nowrap"}}>
                                                    <span>{segment.prefix}</span>
                                                    <span className="" style={{
                                                        textDecoration: "underline",
                                                        fontFamily: "'Patrick Hand', cursive", // Set the font family
                                                        fontSize: "24px", // Set the font size
                                                        color: "red"
                                                    }}>
                                                        {answers[gapCounter++] || "___"}
                                                    </span>
                                                    <span>{segment.suffix}</span>
                                                </span>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </p>
                                    ));
                                })()}
                            </div>
                        </div>
                    </div>
                )}

                <form ref={formRef} onSubmit={(event) => {
                    event.preventDefault();  // Prevent the form's default submission behavior
                    handleFormSubmit(event, selectedOutcome);  // Your existing form submission logic
                }}>

                    {data.QType === 0 && (
                        <div className="free-range-answer">
                            {mode === 'practical' ? (
                                <div style={{margin: '10px 0'}}>
                                    <h2 className="justify-text">{formatString(data.question)}</h2>
                                    <input
                                        type="text"
                                        name="c1"
                                        className="practical-input"
                                        placeholder="Type your practical response here..."
                                        value={textInputValue || data.prevObservation?.cValues[0] || ""} // Populate with prevObservation.cValues[0] if exists
                                        onChange={(e) => setTextInputValue(e.target.value)} // Handle text input changes here
                                        style={{
                                            margin: 25,
                                            width: '95%',
                                            fontSize: '16px',
                                            padding: '10px',
                                            borderRadius: '4px',
                                            border: '1px solid #ccc',
                                        }}
                                    />
                                </div>
                            ) : (
                                <Row>
                                    <div style={{margin: '10px 0'}}>
                                        <h2 className="justify-text lead-in">{formatString(data.question)}</h2>
                                    </div>
                                    <Row>
                                        <Col xs={12} md={6} xl={6} className={"column-left"}>
                                            <BorderedComponent name="Candidate's Answer">
                                                <textarea
                                                    className="textarea-answer"
                                                    rows={data.studentAnswers?.c1 ? data.studentAnswers.c1.split('\n').length : 2}
                                                    placeholder="Type your answer here..."
                                                    onInput={adjustTextareaHeight}
                                                    value={textInputValue || data.studentAnswers?.c1 || data.prevObservation?.cValues[0] || ""}
                                                    onChange={(e) => setTextInputValue(e.target.value)} // Handle changes for textarea input
                                                    readOnly
                                                />
                                            </BorderedComponent>
                                        </Col>
                                        <Col xs={12} md={6} xl={6} className={"column-right"}>
                                            <BorderedComponent name="Answer Guide">
                                                <textarea
                                                    className="textarea-correct-answer"
                                                    rows={data.answer?.split('\n').length || 1}
                                                    placeholder="Type your answer here..."
                                                    onInput={adjustTextareaHeight}
                                                    value={data.answer || ""}
                                                    readOnly
                                                />
                                            </BorderedComponent>
                                        </Col>
                                    </Row>
                                </Row>
                            )}
                        </div>
                    )}

                    {data.QType === 2 && mode === 'practical' && (
                        <div className="qtype-2-answer">
                            <div style={{margin: '10px 0'}}>
                                <h2 className="justify-text">{formatString(data.question)}</h2> {/* Format the string */}
                            </div>
                            <Row>
                                <Col xs={12} md={6} xl={6} className={"column-left"}>
                                    <BorderedComponent name="Candidate's Response">
                                        {Array.from({length: 4}).map((_, i) => {
                                            const value = data[`c${i + 1}`]; // Get the dynamic text inputs based on c1, c2, etc.
                                            return (
                                                value && (
                                                    <div key={i} style={{marginBottom: '10px'}}>
                                                        <label>{formatString(value)}</label> {/* Format the string */}
                                                        <input
                                                            type="text"
                                                            name={`c${i + 1}`}
                                                            className="qtype-2-input"
                                                            placeholder="Enter response here..."
                                                            value={inputValues[`c${i + 1}`] || data.studentAnswers?.[`c${i + 1}`] || data.prevObservation?.cValues[i] || ""}
                                                            onChange={(e) => handleInputChange(i, e.target.value)} // Use index to identify the specific input
                                                            style={{
                                                                marginTop: '10px',
                                                                width: '100%',
                                                                fontSize: '16px',
                                                                padding: '10px',
                                                                borderRadius: '4px',
                                                                border: '1px solid #ccc',
                                                            }}
                                                        />
                                                    </div>
                                                )
                                            );
                                        })}
                                    </BorderedComponent>
                                </Col>
                                <Col xs={12} md={6} xl={6} className={"column-right"}>
                                    <BorderedComponent name="Answer Guide">
                                    <textarea
                                        className="textarea-correct-answer"
                                        rows={data.answer?.split('\n').length || 1}
                                        value={formatString(data.answer) || ""} // Format the answer string
                                        readOnly
                                    />
                                    </BorderedComponent>
                                </Col>
                            </Row>
                        </div>
                    )}

                    {data.QType === 8 && (
                        <div className="qtype-8-answer">
                            <div style={{margin: '10px 0'}}>
                                <h2 className="justify-text">{formatString(data.question)}</h2> {/* Format the string */}
                                {/*<p>{formatString("Please fill out all the required fields below.")}</p>*/}
                            </div>
                            <Row>
                                <Col xs={12} md={12} xl={12} className={"column-left"}>
                                    <BorderedComponent name="Assessor Observation">
                                        <textarea
                                            name="c1"
                                            className="textarea-answer"
                                            placeholder="Enter observation here..."
                                            rows={4}
                                            value={textInputValue || data.prevObservation?.cValues[0] || ""}
                                            onChange={(e) => setTextInputValue(e.target.value)}
                                            style={{
                                                width: '100%',
                                                fontSize: '16px',
                                                padding: '10px',
                                                borderRadius: '4px',
                                                border: '1px solid #ccc',
                                            }}
                                        />
                                    </BorderedComponent>
                                </Col>
                            </Row>
                        </div>
                    )}

                    {data.QType === 11 && (
                        <div className="question">
                            <div style={{margin: '40px 0'}}>
                                <h2 className="justify-text">{formatString(data.question)}</h2>
                            </div>
                            <Row>
                                {/* Left Column: This evidence must */}
                                <Col xs={12} md={6} xl={6} className={"column-left"}>
                                    <BorderedComponent name="This evidence must:">
                                        {data.c2 ? (
                                            data.c2.split('#').map((item, i) => {
                                                const prevValue = data.prevObservation?.cValues[0] ? JSON.parse(data.prevObservation.cValues[0])[i] : 'NS';
                                                return (
                                                    <div key={i} style={{
                                                        display: 'flex',
                                                        alignItems: 'start',
                                                        marginBottom: '10px'
                                                    }}>
                                                        <input
                                                            type="checkbox"
                                                            className="radio-as-checkbox"
                                                            id={`evidence-must-${i + 1}`}
                                                            name={`a${i + 1} of ${data.c2.split('#').length}`}
                                                            value="S"
                                                            checked={checkedBoxes[i] === 'S' || prevValue === 'S'} // Populate with prevObservation if exists
                                                            onChange={(e) => handleCheckboxChange(i, checkedBoxes[i] === 'S' ? 'NS' : 'S', 'must')}
                                                        />
                                                        <label htmlFor={`evidence-must-${i + 1}`}>
                                                            {formatString(item)}
                                                        </label>
                                                    </div>
                                                );
                                            })
                                        ) : (
                                            <p>No items found</p>
                                        )}
                                    </BorderedComponent>
                                </Col>

                                {/* Right Column: This evidence must not */}
                                <Col xs={12} md={6} xl={6} className={"column-right"}>
                                    <BorderedComponent name="This evidence must not:">
                                        {data.c3 ? (
                                            data.c3.split('#').map((item, i) => {
                                                const prevValue = data.prevObservation?.cValues[1] ? JSON.parse(data.prevObservation.cValues[1])[i] : 'NS';
                                                return (
                                                    <div key={i} style={{
                                                        display: 'flex',
                                                        alignItems: 'start',
                                                        marginBottom: '10px'
                                                    }}>
                                                        <input
                                                            type="checkbox"
                                                            className="radio-as-checkbox"
                                                            id={`evidence-must-not-${i + 1}`}
                                                            name={`b${i + 1} of ${data.c3.split('#').length}`}
                                                            value="S"
                                                            checked={checkedBoxes[i + data.c2.split('#').length] === 'S' || prevValue === 'S'} // Populate with prevObservation if exists
                                                            onChange={(e) => handleCheckboxChange(i, checkedBoxes[i + data.c2.split('#').length] === 'S' ? 'NS' : 'S', 'must-not')}
                                                        />
                                                        <label htmlFor={`evidence-must-not-${i + 1}`}>
                                                            {formatString(item)}
                                                        </label>
                                                    </div>
                                                );
                                            })
                                        ) : (
                                            <p>No items found</p>
                                        )}
                                    </BorderedComponent>
                                </Col>
                            </Row>
                        </div>
                    )}

                    {data.QType === 15 && (
                        <div>
                            <div style={{margin: '40px 0'}}>
                                <h2 className="justify-text">{formatString(data.question)}</h2>
                            </div>

                            {Array.from({length: 20}).map((_, i) => {
                                const choice = data[`c${i + 1}`]; // The label for the checkbox
                                const prevValue = data.prevObservation?.cValues[i]; // Previous observation value, if it exists

                                // Check if the checkbox should be marked as checked
                                const isChecked = checkedBoxes[i] === 'S' || prevValue === 'S';

                                if (choice) {
                                    return (
                                        <div
                                            key={i}
                                            style={{
                                                display: 'flex',
                                                alignItems: 'start',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            <input
                                                type="checkbox"
                                                className="radio-as-checkbox"
                                                id={`choice${i + 1}`}
                                                name={`c${i + 1}`}
                                                value="S"
                                                checked={isChecked} // Either from checkedBoxes or prevObservation
                                                onChange={() => handleCheckboxChange(i, isChecked ? 'NS' : 'S')} // Toggle between 'S' and 'NS'
                                            />
                                            <label htmlFor={`choice${i + 1}`}>{formatString(choice)}</label>
                                        </div>
                                    );
                                }
                                return null;
                            })}
                        </div>
                    )}

                    <div style={{marginLeft: '25px', height:'25px', display: 'flex', alignItems: 'start', justifyContent: 'start'}}>
                        {/*<label className="label-criteria">{data.mapping}</label>*/}
                    </div>

                    {/*<div className="marking-container">*/}

                    {data.QType !== -1 && (

                        <>
                            <Row>

                                <Col xs={12} md={8} xl={8} style={{
                                    textAlign: 'left',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-center',
                                    alignItems: 'flex-center'
                                }}>
                                    <div className="left-column">
                                    <textarea
                                        ref={feedbackRef}
                                        name="feedback"
                                        style={{width: '100%', margin: 0}}
                                        className="textarea-feedback"
                                        placeholder="Feedback to student..."
                                        onKeyDown={handleKeyDown}
                                        value={feedback}
                                        onChange={handleFeedbackChange}
                                    />
                                        {data.reasoning && (
                                            <h5 style={{color: 'darkred', marginTop: 25}} >Ai Reasoning: {data.reasoning}</h5>
                                        )}

                                    </div>
                                </Col>

                                <Col xs={12} md={4} xl={4} style={{
                                    textAlign: 'left',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-center',
                                    alignItems: 'flex-center'
                                }}>

                                    <div>
                                        <div style={{display: 'flex', alignItems: 'end'}}>
                                            <input
                                                type="radio"
                                                className="radio-as-checkbox-marking-SA"
                                                id="satisfactory"
                                                name="outcome"
                                                value="S"
                                                checked={selectedOutcome === 'S'}
                                                onChange={handleOutcomeChange}
                                            />
                                            <label htmlFor="satisfactory">Satisfactory</label>
                                        </div>
                                        <div style={{display: 'flex', alignItems: 'left', marginTop: 15}}>
                                            <input
                                                type="radio"
                                                className="radio-as-checkbox-marking-NS"
                                                id="not-satisfactory"
                                                name="outcome"
                                                value="NS"
                                                checked={selectedOutcome === 'NS'}
                                                // onClick={() => handleNS }
                                                onChange={handleOutcomeChange}
                                            />
                                            <label htmlFor="not-satisfactory">Not Satisfactory</label>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Row>

                                <Col xs={12} md={8} xl={8} style={{
                                    textAlign: 'left',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-center',
                                    alignItems: 'flex-center'
                                }}>
                                    <div className="left-column">
                                        <FeedbackComponent mapping={data.mapping} mappingData={mappingData} />
                                    </div>

                                </Col>
                            </Row>


                        </>
                    )}

                    {/* Success / Error Animation */}
                    <div
                        ref={animationRef}
                        className="success-animation"
                        style={{
                            // position: 'fixed',
                            // top: '30%',
                            // left: '50%',
                            width: 'auto',
                            height: 150,
                            padding: 25,
                            margin: 25,
                            // transform: 'translate(-50%, -50%)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: backgroundColor,
                            borderRadius: '10px',
                            padding: '20px',
                            zIndex: 1
                        }}
                        ><span
                            style={{
                                fontSize: '120px',
                                fontWeight: 'bold',
                                color: 'white',
                                marginLeft: '10px'
                            }}
                        >
                            {icon} {/* Dynamic icon (Tick or Cross) */}
                        </span>
                    </div>

                    <div className="btn-container"
                         style={{marginTop: 25, width: '100%'}}>
                        <button
                            className={"btn-primary"}
                            type="submit"
                            ref={submitButtonRef}>{buttonText}</button>

                    </div>

                    {/*<input type="hidden" name="assessorId" value={user.roleId}/>*/}

                </form>



            </div>
        );
    }

    return (

        <>
            {show && dataLoaded && (

                <Modal style={{overflow: "hidden"}} show={show} onHide={onHide} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Marking Guide</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        {dataLoaded ? (
                            <div className={"page-common"} style={{marginTop: 20, zIndex: 1}}>

                                <CustomAlert msg={msg} msgCode={msgCode}/>

                                {mode === 'completed' ? (
                                    <div>
                                        <h1>You have now finished marking this assessment!</h1>
                                        {/*<SignaturePanel/>*/}
                                        <SignaturePanel
                                            ref={signaturePanelRef}
                                            annotations={annotations}
                                            certCode={certCode}
                                            modCode={modCode}
                                            studentId={studentId}
                                            modId={moduleId}
                                            apprenticeModsId={apprenticeModsId}
                                        />
                                        <div className="btn-container" style={{marginTop: '20px'}}>
                                            {/*<button className="btn-primary" onClick={() => handleFinish('/classroom')}>Complete*/}
                                            {/*</button>*/}
                                        </div>
                                    </div>
                                ) : mode === 'practical' ? (
                                    <div>
                                        <h1>Practical Task</h1>
                                        {Object.keys(practicalData).length > 0 ? (
                                            <PageComponent
                                                data={practicalData[currentQuestionIndex]}
                                                currentQuestionIndex={currentQuestionIndex}
                                                totalQuestions={totalObservations}
                                                dataLength={practicalData.length}
                                            />
                                        ) : (
                                            <p>No practical data available</p>
                                        )}
                                    </div>
                                ) : mode === 'assessment' ? (
                                    <div>
                                        <h1>Assessment Task</h1>
                                        {Object.keys(actualAssessmentData).length > 0 ? (
                                            <PageComponent
                                                data={actualAssessmentData[currentTaskKey][currentQuestionIndex]}
                                                currentQuestionIndex={currentQuestionIndex}
                                                totalQuestions={totalQuestions[currentTaskKey]}
                                                dataLength={actualAssessmentData[currentTaskKey].length}
                                            />
                                        ) : (
                                            <p>No assessment data available</p>
                                        )}
                                    </div>
                                ) : mode === 'supplementary' ? (
                                    <div>
                                        <h1>Supplementary Questions</h1>
                                        {Object.keys(supplementaryData).length > 0 ? (
                                            <PageComponent
                                                data={supplementaryData[currentTaskKey][currentQuestionIndex]}
                                                currentQuestionIndex={currentQuestionIndex}
                                                totalQuestions={totalQuestions[currentTaskKey]}
                                                dataLength={supplementaryData[currentTaskKey].length}
                                            />
                                        ) : (
                                            <p>No supplementary questions recorded</p>
                                        )}
                                    </div>
                                ) : mode === 'intro' ? (
                                    <div>
                                        <h1>Introduction</h1>
                                        {Object.keys(actualAssessmentData).length > 0 ? (
                                            <PageComponent
                                                data={introData[currentTaskKey][0]}
                                                dataLength={introData[currentTaskKey].length}
                                            />
                                        ) : (
                                            <p>No introduction data available</p>
                                        )}
                                    </div>
                                ) : (
                                    <div>
                                        <h1>Unknown Mode</h1>
                                        <p>Please select a valid mode.</p>
                                    </div>
                                )}

                            </div>
                        ) : (
                            <p>Loading data, please wait...</p>
                        )}


                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn-primary" onClick={onHide}>Close</button>
                    </Modal.Footer>
                </Modal>
            )}

        </>
    );
};

export default MarkingGuide;

// =============================== To Do =================================== //

// Flag Question
// Auto Marking

// =============================== Bugs =================================== //

// Goes straight to feedback if the student gets marked wrong twice
// Qtype 11 not rendering correctly