import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../Reducers';
import { AppDispatch } from '../store'; 
import UserInterface from '../../Types/userInterface';
import CryptoJS from 'crypto-js';

// Get the dynamic key from state
const getEncryptionKey = (state: RootState) => state.encryptionKey.encryptionKey;

// Helper functions for encryption and decryption
const encryptData = (data: any, key: string) => {
  try {
    return CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
  } catch (error) {
    console.error('Encryption error:', error);
    return '';
  }
};

const decryptData = (cipherText: string, key: string) => {
  try {
    const bytes = CryptoJS.AES.decrypt(cipherText, key);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (error) {
    console.error('Decryption error:', error);
    return null;
  }
};

export const initialState: UserInterface = {
  email: '',
  ipAddress: '',
  username: 'Guest',
  fullName: '',
  phoneNumber: '',
  user_id: '',
  roleId: null,
  accessLevel: null,
  roles: [],
  loggedIn: false,
  isRestoring: true,
};

// Reducer slice
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserInterface>) => {
      const userData = action.payload;
      state.email = userData.email;
      state.ipAddress = userData.ipAddress;
      state.username = userData.username;
      state.fullName = userData.fullName;
      state.user_id = userData.user_id;
      state.roleId = userData.roleId;
      state.phoneNumber = userData.phoneNumber;
      state.accessLevel = userData.accessLevel;
      state.roles = userData.roles;
      state.loggedIn = true;
      state.isRestoring = true;
    },
    restoreUser: (state, action: PayloadAction<UserInterface>) => {
      const decryptedUser = action.payload;
      if (decryptedUser) {
        state.email = decryptedUser.email;
        state.ipAddress = decryptedUser.ipAddress;
        state.username = decryptedUser.username;
        state.fullName = decryptedUser.fullName;
        state.user_id = decryptedUser.user_id;
        state.roleId = decryptedUser.roleId;
        state.phoneNumber = decryptedUser.phoneNumber;
        state.accessLevel = decryptedUser.accessLevel;
        state.roles = decryptedUser.roles;
        state.loggedIn = true;
      }
      state.isRestoring = false;
    },
    clearUser: () => {
      sessionStorage.removeItem('user');
      return {
        ...initialState,
        loggedIn: false,
      };
    },
    updateUserField: <K extends keyof UserInterface>(
      state: UserInterface,
      action: PayloadAction<{ field: K; value: UserInterface[K] }>
    ) => {
      state[action.payload.field] = action.payload.value;
    },
  },
});

export const { setUser, restoreUser, clearUser, updateUserField } = userSlice.actions;
export default userSlice.reducer;

// Thunk for setting user with encryption
export const setUserWithEncryption = (user: UserInterface) => (dispatch: AppDispatch, getState: () => RootState) => {
  const encryptionKey = getEncryptionKey(getState());
  if (!encryptionKey) {
    console.error('No encryption key found!');
    return;
  }
  const encryptedUser = encryptData(user, encryptionKey);
  sessionStorage.setItem('user', encryptedUser);
  dispatch(setUser(user));
};

// Thunk for restoring user from encrypted data
export const restoreUserWithDecryption = () => (dispatch: AppDispatch, getState: () => RootState) => {
  const encryptionKey = getEncryptionKey(getState());
  if (!encryptionKey) {
    console.error('No encryption key found for decryption!');
    return;
  }

  const encryptedUser = sessionStorage.getItem('user');
  if (encryptedUser) {
    const decryptedUser = decryptData(encryptedUser, encryptionKey);
    if (decryptedUser) {
      dispatch(restoreUser(decryptedUser));
    } else {
      console.error('Decryption failed!');
    }
  }
};
