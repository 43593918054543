import React, { useEffect, useRef, useState } from "react";
import { 
  TabbedContainer, 
  ClassDetailsContent,
  ClassModulesContent,
  ClassCalendarContent, 
  ClassVenueContent,
} from "Components";
import QRCodeStyling from "qr-code-styling";
import { handleSubmit, handleInputChange, handleSelectChange, handleCheckboxChange } from "HelperFunctions/Form";
import QRLogo from 'Images/QRLogo.png';
import "../Styles/profile.scss";

interface ClassEditProps {}

const tabs = [
  'Details',
  'Modules',
  'Calendar',
  'Venue',
];

// TO DO: Get these from the server
const statuses = [0, 0, 0, 0];

const ClassEdit: React.FC<ClassEditProps> = () => {
  const [url, setUrl] = useState("QR Code Value will link to class details");
  const [isChecked, setIsChecked] = useState(true);
  const [isPrivateMsg, setIsPrivateMsg] = useState('Class is Private');
  const QRCode = useRef(null);
  const qrCodeInstance = useRef<QRCodeStyling | null>(null);

  /////////////////////////////// FORM STATE MANAGEMENT //////////////////////////////////////
  const [detailsFormData, setDetailsFormData] = useState({
    template: null,
    startDate: '',
    finishDate: '',
    startTime: '',
    finishTime: '',
    classMinimum: '',
    classMaximum: '',
    courseCost: '',
    isprivate: true,
    venue: null,
    trainer: null,
    business: null,
    contract: null,
    aenNumber: '',  
    assMethod: null,
    deliveryMode: null,
  });

  const [modulesFormData, setModulesFormData] = useState({
    statementType: null,
    template: null,
    customText: '',
    modulesList: null,
  });

  const [calendarFormData, setCalendarFormData] = useState({
    startDate: '',
    endDate: '',
  });

  const [venueFormData, setVenueFormData] = useState({
    venueDescription: null,
    venueAddressLine1: '',
    venueAddressLine2: '',
    venueCity: '',
    venueState: '',
    venuePostcode: '',
    venuePhone: '',
    venueUrl: '',
    parking: '',
    publicTransport: '',
    disabledAccess: '',
    meals: '',
    auditDate: '',
    housekeeping: '',
    lightingSuitable: '',
    lighting: '',
    ventilation: '',
    ventitliationSuitable: '',
    noise: '',
    noiseSuitable: '',
    hygiene: '',
    hygieneSuitable: '',
    firstAid: '',
    firstAidSuitable: '',
    fireRisk: '',
    fireRiskSuitable: '',
    smokingPolicy: '',
    smokingPolicySuitable: '',
    notes: '',
  });

  const formStates: any = {
    details: setDetailsFormData,
    modules: setModulesFormData,
    calendar: setCalendarFormData,
    venue: setVenueFormData,
  };
  
  const formData: { [key: string]: any } = {
    details: detailsFormData,
    modules: modulesFormData,
    calendar: calendarFormData,
    venue: venueFormData,
  };
  
  // console.log(detailsFormData);
  // console.log(modulesFormData);
  // console.log(calendarFormData);
  // console.log(venueFormData);

  // This function is non-generic and is specific to the ClassEdit page
  const handlePrivateChange = () => {
    setDetailsFormData((prev: any) => ({
      ...prev,
      isprivate: !prev.isprivate, 
    }));
    setIsChecked((prevChecked) => !prevChecked);
    setIsPrivateMsg(isChecked ? 'Class is Public' : 'Class is Private');
  };


  /////////////////////////////// QR CODE GENERATION //////////////////////////////////////
  useEffect(() => {
    qrCodeInstance.current = new QRCodeStyling({
      width: 300,
      height: 300,
      image: QRLogo,
      dotsOptions: {
        color: "#393939",
        type: "classy",
      },
      imageOptions: {
        imageSize: 0.4,
        crossOrigin: "anonymous",
        margin: 5,
      },
      backgroundOptions: {
        color: "transparent",
      },
      cornersSquareOptions: {
        type: "square",
        color: "#955CCE",
      },
    });

    if (QRCode.current && qrCodeInstance.current) {
      qrCodeInstance.current.append(QRCode.current);
    }
  }, []);

  useEffect(() => {
    if (qrCodeInstance.current) {
      qrCodeInstance.current.update({
        data: url,
      });
    }
  }, [url]);


  /////////////////////////////// SERVER INTERACTIONS //////////////////////////////////////
  // TO DO: Add server interactions here


  
  /////////////////////////////// CONTENT /////////////////////////////////////////////////
  const content = [
    // Tab 0 - Details
    <ClassDetailsContent 
      formData= {detailsFormData}
      setFormData={setDetailsFormData}
      handleInputChange={(e) => handleInputChange(e, 'details', formStates)}
      handleSubmit={(e) => handleSubmit(e, 'details', formData)}
      url={url}
      isChecked={isChecked}
      isPrivateMsg={isPrivateMsg}
      handleCheckboxChange={handlePrivateChange}
      handleSelectChange={handleSelectChange('details', formStates)}
    />,
    // Tab 1 - Modules
    <ClassModulesContent 
      formData={modulesFormData}
      setFormData={setModulesFormData}
      handleInputChange={(e) => handleInputChange(e, 'modules', formStates)}
      handleSubmit={(e) => handleSubmit(e, 'modules', formData)}
      handleSelectChange={handleSelectChange('modules', formStates)}
    />,
    // Tab 2 - Calendar
    <ClassCalendarContent 
      formData={calendarFormData}
      setFormData={setCalendarFormData}
      handleInputChange={(e) => handleInputChange(e, 'calendar', formStates)}
      handleSelectChange={handleSelectChange('calendar', formStates)}
      handleSubmit={(e) => handleSubmit(e, 'calendar', formData)}
      url={url}
    />,
    // Tab 3 - Venue
    <ClassVenueContent 
      formData={venueFormData}
      setFormData={setVenueFormData}
      handleInputChange={(e) => handleInputChange(e, 'venue', formStates)}
      handleSelectChange={handleSelectChange('venue', formStates)}
      handleSubmit={(e) => handleSubmit(e, 'venue', formData)}
      handleCheckboxChange={handleCheckboxChange('venue', formStates)}
    />,
  ];

  return <TabbedContainer tabs={tabs} content={content} statuses={statuses} />;
};

export default ClassEdit;
