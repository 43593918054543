import React, { useEffect, useRef } from 'react';
import Select from 'react-select';
import { customSelectStyles } from "../../Pages/Styles/customSelectStyles";
import {SpacerDiv, FileUpload } from 'Components';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useUserDeets } from 'CustomHooks';

interface VenueContentProps {
  formData: any;
  setFormData: any;
  // eslint-disable-next-line no-unused-vars
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, name: string) => void;
  // eslint-disable-next-line no-unused-vars
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  // eslint-disable-next-line no-unused-vars
  handleCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  // eslint-disable-next-line no-unused-vars
  handleSelectChange: (fieldName: string, selectedOption: any) => void;
}

const VenueContent: React.FC<VenueContentProps> = ({
  formData,
  handleInputChange,
  handleSubmit,
  handleCheckboxChange,
  handleSelectChange,
}) => {
  const user = useUserDeets();
  const [venueImage, setVenueImage] = React.useState<string | null>(null);
  const [showWHS, setShowWHS] = React.useState<boolean>(false);

  const toggleWHS = () => {
    setShowWHS(!showWHS);
  }; 

  const isFalse = (fieldName: string): boolean => {
    return formData[fieldName] === false;
  };
  

  useEffect(() => {
    if (formData.auditDate === '') {
      setShowWHS(true);
    };
  }, []);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.files);
    setVenueImage(URL.createObjectURL(e.target.files![0]));
  };

  const PHOptions = [
    { value: 'Option 1', label: 'Option 1' },
    { value: 'Option 2', label: 'Option 2' },
    { value: 'Option 3', label: 'Option 3' },
  ];

  return (
    <form
      className="form-container"
      id="form-3"
      onSubmit={handleSubmit}
    >
      {/* Venue Image */}
      <div className='form-row'>
        <div className='form-column'>
          {venueImage ?
            <div className="venue-image" >
              <img src={venueImage} alt="Venue" />
            </div>
            :
            <div className="venue-image" >
              <FontAwesomeIcon
                icon={faHome}
                size="5x"
                style={{ color: "#393939" }}
              />
              <p className="section-heading">No Image available</p>
            </div>
          }
          <FileUpload onChange={handleImageChange} buttonLabel={venueImage ? 'Update Photo' : 'Upload Photo'} />
        </div>

        {/* Basic Venue Details */}
        <div className='full-width'>
          <label htmlFor="venueDescription" className='align-left instruction-text'>Training Venue</label>
          <Select
            id="venueDescription"
            name="venueDescription"
            isSearchable
            placeholder="Select Venue Description"
            styles={customSelectStyles}
            options={PHOptions}
            value={formData.venueDescription || ""}
            onChange={(selectedOption) => handleSelectChange('venueDescription', selectedOption)}
          />
          <SpacerDiv rem={1} />
          {/* Venue Address */}
          <label htmlFor="venueAddressLine1" className='align-left instruction-text'>Venue Address</label>
          <div className='form-row no-gap'>
            <input
              style={{ width: '110px', marginRight: '10px'}}
              id="venueAddressLine1"
              className=''
              type='text'
              name="venueAddressLine1"
              placeholder="Unit Number"
              value={formData.venueAddressLine1 || ""}
              onChange={(e) => handleInputChange(e,'venue')}
            />
           
            <input
              id="venueAddressLine2"
              className='full-width'
              type='text'
              name="venueAddressLine2"
              placeholder="Street Address"
              value={formData.venueAddressLine2 || ""}
              onChange={(e) => handleInputChange(e,'venue')}
            />
          </div>
          <SpacerDiv rem={0.5} />
          <div className='form-row no-gap'>
            <input
              style={{ marginRight: '10px' }}
              id="venueSuburb"
              className='large'
              type='text'
              name="venueSuburb"
              placeholder="Suburb or Town"
              value={formData.venueSuburb || ""}
              onChange={(e) => handleInputChange(e,'venue')}
            />
            <input
            style={{ marginRight: '10px' }}
              id="venueState"
              className='medium'
              type='text'
              name="venueState"
              placeholder="State"
              value={formData.venueState || ""}
              onChange={(e) => handleInputChange(e,'venue')}
            />
            <input
              id="venuePostcode"
              className='medium'
              type='text'
              name="venuePostcode"
              placeholder="Postcode"
              value={formData.venuePostcode || ""}
              onChange={(e) => handleInputChange(e,'venue')}
            />
          </div>
          <SpacerDiv rem={1} />
          {/* Contact Details */}
          <div className='form-row'>
            <div className='profile-input-container column'>
              <p className='instruction-text align-left'>Phone</p>
              <input
                style={{ marginLeft: 0 }}
                id="venuePhone"
                className='large'
                type='number'
                name="venuePhone"
                placeholder="Phone number"
                value={formData.venuePhone || ""}
                onChange={(e) => handleInputChange(e,'venue')}
              />
            </div>
            <SpacerDiv rem={0.5} />
            <div className='profile-input-container column full-width'>
              <p className='instruction-text align-left'>Website</p>
              <input
                id="venueUrl"
                className='full-width'
                type='text'
                name="venueUrl"
                placeholder="Website URL"
                value={formData.venueUrl || ""}
                onChange={(e) => handleInputChange(e,'venue')}
              />
            </div>
          </div>
        </div>
      </div>

      <SpacerDiv rem={1} />

      {/* Venue Access Details */}
      <div className='form-row'>
        <div className='full-width'>
          <p className='instruction-text align-left'>Parking</p>
          <input
            id="parking"
            className='full-width'
            type='text'
            name="parking"
            placeholder=""
            value={formData.parking|| ""}
            onChange={(e) => handleInputChange(e,'venue')}
          />
        </div>
        <div className='full-width'>
          <p className='instruction-text align-left'>Disabled Access</p>
          <input
            id="disabledAccess"
            className='full-width'
            type='text'
            name="disabledAccess"
            placeholder=""
            value={formData.disabledAccess || ""}
            onChange={(e) => handleInputChange(e,'venue')}
          />
        </div>
      </div>
      <SpacerDiv rem={0.5} />
      <div className='form-row'>
        <div className='full-width'>
          <p className='instruction-text align-left'>Lunch / Meals</p>
          <input
            id="meals"
            className='full-width'
            type='text'
            name="meals"
            placeholder=""
            value={formData.meals || ""}
            onChange={(e) => handleInputChange(e,'venue')}
          />
        </div>
        <div className='full-width'>
          <p className='instruction-text align-left'>Public Transport</p>
          <input
            id="publicTransport"
            className='full-width'
            type='text'
            name="publicTransport"
            placeholder=""
            value={formData.publicTransport || ""}
            onChange={(e) => handleInputChange(e,'venue')}
          />
        </div>
      </div>

      <SpacerDiv rem={1.5} />
      <div className='form-row center'>
            <label className="slide-switch" htmlFor="showWHS">
              <input
                id="showWHS"
                type="checkbox"
                checked={showWHS}
                onChange={toggleWHS}
              />
              <span className="slider"></span>
            </label>
            <div className="instruction-text">{showWHS ? 'Hide WHS Audit Details' : 'Show WHS Audit Details'}</div>
          </div>

      {/* WHS Audit */}
      <div className='section-container full-width'>
        <div className='form-row center'>
          <p className='section-heading'>Venue WHS Audit</p>
        
        </div>
        
        <div className='form-row even'>
          <p className='instruction-text'>Auditor: <span className='user deets'>{user.fullName}</span></p>
          <p className='instruction-text'>Phone: <span className='user deets'>{user.phoneNumber}</span></p>
          <div className='profile-input-container'>
          <p className='instruction-text' style={{ marginRight: '10px'}}>Date:</p>
            <input
              id="auditDate"
              type="date"
              name="auditDate"
              value={formData.auditDate}
              onChange={(e) => handleInputChange(e, "venue")}
            />
          </div>
        </div>

        <SpacerDiv rem={1} />

        <div style={{display: showWHS ? '' : 'none'}}>
          <div className='form-row'>
            <div className='profile-input-container column full-width'>
              <div className='form-grid two small-end'>
                <p className='instruction-text'>
                  Housekeeping
                  <span className='deets'>
                    (Floors, Aisles, Work Areas)
                  </span>
                </p>
                <p className='instruction-text' style={{ alignSelf: 'flex-end'}}>Suitable?</p>
              </div>
              <div className='form-grid two small-end'>
                <input
                  id="housekeeping"
                  className='full-width'
                  type='text'
                  name="housekeeping"
                  placeholder="What stood out or needs fixing?"
                  value={formData.housekeeping || ""}
                  onChange={(e) => handleInputChange(e,'venue')}
                />
                <div style={{ display: 'flex', alignContent: 'center', flexWrap: 'wrap'}}>
                  <input
                    className={`radio-as-checkbox ${isFalse('housekeepingSuitable') ? 'false' : 'true'}`}
                    type="checkbox"
                    id="housekeepingSuitable"
                    name="housekeepingSuitable"
                    checked={formData.housekeepingSuitable}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor='housekeepingSuitable'></label>
                </div>
              </div>
            </div>
          </div>
          <SpacerDiv rem={0.5} />
          <div className='form-row'>
            <div className='profile-input-container column full-width'>
              <p className='instruction-text'>
                Lighting
                <span className='deets'>
                  (Quality, Suitability)
                </span>
              </p>
              <div className='form-grid two small-end'>
                <input
                  id="lighting"
                  className='full-width'
                  type='text'
                  name="lighting"
                  placeholder="What stood out or needs fixing?"
                  value={formData.lighting || ""}
                  onChange={(e) => handleInputChange(e,'venue')}
                />
                <div style={{ display: 'flex', alignContent: 'center', flexWrap: 'wrap'}}>
                  <input
                    className={`radio-as-checkbox ${isFalse('lightingSuitable') ? 'false' : 'true'}`}
                    type="checkbox"
                    id="lightingSuitable"
                    name="lightingSuitable"
                    checked={formData.lightingSuitable}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor='lightingSuitable'></label>
                </div>
              </div>
            </div>
          </div>
          <SpacerDiv rem={0.5} />
          <div className='form-row'>
            <div className='profile-input-container column full-width'>
              <p className='instruction-text'>
                Ventilation / Air Conditioning
                <span className='deets'>
                  (Source, Adequacy, Condition)
                </span>
              </p>
              <div className='form-grid two small-end'>
                <input
                  id="ventilation"
                  className='full-width'
                  type='text'
                  name="ventilation"
                  placeholder="What stood out or needs fixing?"
                  value={formData.ventilation || ""}
                  onChange={(e) => handleInputChange(e,'venue')}
                />
                <div style={{ display: 'flex', alignContent: 'center', flexWrap: 'wrap'}}>
                  <input
                    className={`radio-as-checkbox ${isFalse('ventilationSuitable') ? 'false' : 'true'}`}
                    type="checkbox"
                    id="ventilationSuitable"
                    name="ventilationSuitable"
                    checked={formData.ventilationSuitable}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor='ventilationSuitable'></label>
                </div>
              </div>
            </div>
          </div>
          <SpacerDiv rem={0.5} />
          <div className='form-row'>
            <div className='profile-input-container column full-width'>
              <p className='instruction-text'>
                Noise
                <span className='deets'>
                  (Source, Intensity)
                </span>
              </p>
              <div className='form-grid two small-end'>
                <input
                  id="noise"
                  className='full-width'
                  type='text'
                  name="noise"
                  placeholder="What stood out or needs fixing?"
                  value={formData.noise || ""}
                  onChange={(e) => handleInputChange(e,'venue')}
                />
                <div style={{ display: 'flex', alignContent: 'center', flexWrap: 'wrap'}}>
                  <input
                    className={`radio-as-checkbox ${isFalse('noiseSuitable') ? 'false' : 'true'}`}
                    type="checkbox"
                    id="noiseSuitable"
                    name="noiseSuitable"
                    checked={formData.noiseSuitable}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor='noiseSuitable'></label>
                </div>
              </div>
            </div>
          </div>
          <SpacerDiv rem={0.5} />
          <div className='form-row'>
            <div className='profile-input-container column full-width'>
              <p className='instruction-text'>
                Hygiene / Sanitation
                <span className='deets'>
                  (Facilities, Cleaning, Disposal)
                </span>
              </p>
              <div className='form-grid two small-end'>
                <input
                  id="hygeine"
                  className='full-width'
                  type='text'
                  name="hygiene"
                  placeholder="What stood out or needs fixing?"
                  value={formData.hygiene || ""}
                  onChange={(e) => handleInputChange(e,'venue')}
                />
                <div style={{ display: 'flex', alignContent: 'center', flexWrap: 'wrap'}}>
                  <input
                    className={`radio-as-checkbox ${isFalse('hygieneSuitable') ? 'false' : 'true'}`}
                    type="checkbox"
                    id="hygieneSuitable"
                    name="hygieneSuitable"
                    checked={formData.hygieneSuitable}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor='hygieneSuitable'></label>
                </div>
              </div>
            </div>
          </div>
          <SpacerDiv rem={0.5} />
          <div className='form-row'>
            <div className='profile-input-container column full-width'>
              <p className='instruction-text'>
                Electrical
                <span className='deets'>
                  (Power Outlets, Cords, Connections, Switches)
                </span>
              </p>
              <div className='form-grid two small-end'>
                <input
                  id="electrical"
                  className='full-width'
                  type='text'
                  name="electrical"
                  placeholder="What stood out or needs fixing?"
                  value={formData.electrical || ""}
                  onChange={(e) => handleInputChange(e,'venue')}
                />
                <div style={{ display: 'flex', alignContent: 'center', flexWrap: 'wrap'}}>
                  <input
                    className={`radio-as-checkbox ${isFalse('electricalSuitable') ? 'false' : 'true'}`}
                    type="checkbox"
                    id="electricalSuitable"
                    name="electricalSuitable"
                    checked={formData.electricalSuitable}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor='electricalSuitable'></label>
                </div>
              </div>
            </div>
          </div>
          <SpacerDiv rem={0.5} />
          <div className='form-row'>
            <div className='profile-input-container column full-width'>
              <p className='instruction-text'>
                First Aid
                <span className='deets'>
                  (Suitably Located, Stocked, Clean)
                </span>
              </p>
              <div className='form-grid two small-end'>
                <input
                  id="firstAid"
                  className='full-width'
                  type='text'
                  name="firstAid"
                  placeholder="What stood out or needs fixing?"
                  value={formData.firstAid || ""}
                  onChange={(e) => handleInputChange(e,'venue')}
                />
                <div style={{ display: 'flex', alignContent: 'center', flexWrap: 'wrap'}}>
                  <input
                    className={`radio-as-checkbox ${isFalse('firstAidSuitable') ? 'false' : 'true'}`}
                    type="checkbox"
                    id="firstAidSuitable"
                    name="firstAidSuitable"
                    checked={formData.firstAidSuitable}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor='firstAidSuitable'></label>
                </div>
              </div>
            </div>
          </div>
          <SpacerDiv rem={0.5} />
          <div className='form-row'>
            <div className='profile-input-container column full-width'>
              <p className='instruction-text'>
                Fire Risk
                <span className='deets'>
                  (Extinguishers, Alarms, Flammable Materials)
                </span>
              </p>
              <div className='form-grid two small-end'>
                <input
                  id="fireRisk"
                  className='full-width'
                  type='text'
                  name="fireRisk"
                  placeholder="What stood out or needs fixing?"
                  value={formData.fireRisk || ""}
                  onChange={(e) => handleInputChange(e,'venue')}
                />
                <div style={{ display: 'flex', alignContent: 'center', flexWrap: 'wrap'}}>
                  <input
                    className={`radio-as-checkbox ${isFalse('fireRiskSuitable') ? 'false' : 'true'}`}
                    type="checkbox"
                    id="fireRiskSuitable"
                    name="fireRiskSuitable"
                    checked={formData.fireRiskSuitable}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor='fireRiskSuitable'></label>
                </div>
              </div>
            </div>
          </div>
          <SpacerDiv rem={0.5} />
          <div className='form-row'>
            <div className='profile-input-container column full-width'>
              <p className='instruction-text'>
                Smoking Policy
                <span className='deets'>
                  (Parties, Advised, Enforced)
                </span>
              </p>
              <div className='form-grid two small-end'>
                <input
                  id="smokingPolicy"
                  className='full-width'
                  type='text'
                  name="smokingPolicy"
                  placeholder="What stood out or needs fixing?"
                  value={formData.smokingPolicy || ""}
                  onChange={(e) => handleInputChange(e,'venue')}
                />
                <div style={{ display: 'flex', alignContent: 'center', flexWrap: 'wrap'}}>
                  <input
                    className={`radio-as-checkbox ${isFalse('smokingPolicySuitable') ? 'false' : 'true'}`}
                    type="checkbox"
                    id="smokingPolicySuitable"
                    name="smokingPolicySuitable"
                    checked={formData.smokingPolicySuitable}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor='smokingPolicySuitable'></label>
                </div>
              </div>
            </div>
          </div>
          
          <SpacerDiv rem={0.5} />
          <p className='instruction-text'>Special Notes / Follow Up</p>
          <div className='profile-input-container'>
            <textarea
              id='notes'
              name='notes'
              value={formData.notes || ""}
              onChange={(e: any) => handleInputChange(e, 'modules')}
              placeholder='what needs to be done or followed up on...'
            />
          </div>
                </div>
        </div>

    </form>
  );
};

export default VenueContent;