import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useFetchApi from "Services/FetchApi";
import Bluey from "Components/Bluey";
import CustomAlert from "Components/CustomAlert";
import "./logicEngine.css"; // Import new styles

export default function LogicEngine() {

    const fetchApi = useFetchApi();
    const [engineData, setEngineData] = useState([]);
    const [msg, setMsg] = useState("");
    const [msgCode, setMsgCode] = useState(0);
    const [schema, setSchema] = useState(null);
    const [engineInfo, setEngineInfo] = useState({ engineId: null, workflowId: null });
    const [unsavedRows, setUnsavedRows] = useState(new Set()); // Track unsaved row indices


    const [availableEngines, setAvailableEngines] = useState([]);
    const [selectedEngine, setSelectedEngine] = useState(1); // Default engineId

    useEffect(() => {
        fetchEngines();
    }, []);

    const fetchEngines = async () => {
        try {
            const response = await fetchApi("workflow/engines", {});

            if (response.error) {
                console.error("Failed to fetch engine list:", response.message);
                setMsg(response.message);
                setMsgCode(1);
                return;
            }

            console.log("Available Engines:", response.responseData.engines);
            setAvailableEngines(response.responseData.engines);
        } catch (error) {
            console.error("Fetch error:", error);
            setMsg("An unexpected error occurred while fetching engines.");
            setMsgCode(4);
        }
    };

    useEffect(() => {
        fetchEngineData();
    }, []);

    const resizeTextarea = (element) => {
        if (!element) return;
        element.style.height = "auto"; // Reset height to recalculate

        element.style.height = element.scrollHeight + "px"; // Set new height based on content
    };


    const fetchEngineData = async (engineId = selectedEngine) => {
        try {
            const response = await fetchApi("workflow/engine", { engineId });

            if (response.error) {
                console.error("API Error:", response.message);
                setMsg(response.message);
                setMsgCode(1);
                return;
            }

            if (!response.responseData.engine) {
                console.error("Unexpected API response:", response);
                setMsg("Invalid response from server.");
                setMsgCode(1);
                return;
            }

            console.log("Engine Data:", response.responseData.engine);

            if (response.responseData.engine.length > 0) {
                const firstEntry = response.responseData.engine[0];
                setEngineInfo({ engineId: firstEntry.engineId, workflowId: firstEntry.workflowId });
                const inferredSchema = inferSchema(firstEntry);
                setSchema(inferredSchema);
            }

            setEngineData(response.responseData.engine);
            setMsgCode(0);
        } catch (error) {
            console.error("Fetch error:", error);
            setMsg("An unexpected error occurred. Contact your administrator.");
            setMsgCode(4);
        }
    };

    // Define dropdown values for enums
    const conditionTypeOptions = ["IF", "OR", "AND", "WHILE", "ELSE", "ALWAYS"];
    const eventTypeOptions = ["FORM", "TEXT", "CHECK", "TRIGGER", "EMAIL", "LLN"];
    const logicOperatorOptions = [
        { label: "=", value: "=" },
        { label: "≠", value: "!=" },
    ];

    // Infer schema from object structure
    const inferSchema = (data) => {
        return Object.keys(data).reduce((acc, key) => {
            if (["id", "workflowId", "engineId", "archived"].includes(key)) {
                acc[key] = "hidden"; // Do not display
            } else if (key === "conditionType") {
                acc[key] = "enum-condition";
            } else if (key === "eventType") {
                acc[key] = "enum-event";
            } else if (key === "logicOperator") {
                acc[key] = "dropdown-logic";
            } else if (key === "continue") {
                acc[key] = "checkbox";
            } else if (key === "priority") {
                acc[key] = "int";
            } else if (Array.isArray(data[key])) {
                acc[key] = "array";
            } else if (typeof data[key] === "number") {
                acc[key] = "int"; // General styling for all integer inputs
            } else if (["eventDesc", "instructions", "notes", "value", "link", "eventName"].includes(key)) {
                acc[key] = "textarea"; // Convert long text fields to <textarea>
            } else if (typeof data[key] === "string") {
                acc[key] = "text";
            } else if (data[key] === null) {
                acc[key] = "nullable";
            } else {
                acc[key] = "unknown";
            }
            return acc;
        }, {});
    };

    const handleArchiveRow = async (id) => {
        const confirmDelete = window.confirm("Do you really want to remove this row?");

        if (!confirmDelete) return; // Exit if the user cancels

        try {
            const response = await fetchApi("workflow/archive", { id });

            if (response.error) {
                console.error("Failed to archive row:", response.message);
                setMsg(response.message);
                setMsgCode(1);
                return;
            }

            // Remove the row from the frontend immediately
            setEngineData((prevData) => prevData.filter(row => row.id !== id));

            setMsg("Row archived successfully.");
            setMsgCode(0);
        } catch (error) {
            console.error("Archive error:", error);
            setMsg("An unexpected error occurred while archiving.");
            setMsgCode(4);
        }
    };


    const handleChange = (index, key, value) => {
        const updatedData = [...engineData];
        updatedData[index][key] = value;

        // Mark row as unsaved if data changes
        setUnsavedRows((prev) => new Set([...prev, index]));

        if (key === "continue") {
            updatedData[index][key] = value ? 1 : 0; // Store boolean as 1/0

            if (value) {
                // If continue = true, auto-add a new row
                const nextIndex = index + 1;

                // Check if a next row already exists and shares the same conditionNum
                if (!updatedData[nextIndex] || updatedData[nextIndex].conditionNum !== updatedData[index].conditionNum) {
                    const newRow = Object.keys(schema).reduce((acc, key) => {
                        acc[key] = schema[key] === "int" ? 0 : "";
                        return acc;
                    }, {});

                    newRow.conditionNum = updatedData[index].conditionNum; // Keep the same conditionNum
                    newRow.engineId = engineInfo.engineId;
                    newRow.workflowId = engineInfo.workflowId;
                    newRow.conditionType = "AND"; // Default new row to AND
                    newRow.logicOperator = "1"; // Default to `=`
                    newRow.eventType = "FORM";

                    updatedData.splice(nextIndex, 0, newRow);
                    setUnsavedRows((prev) => new Set([...prev, nextIndex]));
                }
            } else {
                // If continue = false, remove the next row with the same conditionNum (if exists)
                const nextIndex = index + 1;
                if (updatedData[nextIndex] && updatedData[nextIndex].conditionNum === updatedData[index].conditionNum) {
                    updatedData.splice(nextIndex, 1);
                }
            }

            setUnsavedRows((prev) => new Set([...prev, index]));
            updateEngineData(index);
        }

        // If 'conditionType' is set to 'ALWAYS', clear and disable related fields
        if (key === "conditionType") {
            if (value === "ALWAYS" || value === "ELSE") {
                updatedData[index]["condition"] = "";
                updatedData[index]["logicOperator"] = "";
                updatedData[index]["value"] = "";
                updatedData[index]["eventType"] = "";
            }
        }

        setEngineData(updatedData);
    };

    const addRow = () => {
        if (!schema || engineData.length === 0) return;

        // Find the highest conditionNum in the current list
        const lastConditionNum = Math.max(...engineData.map(row => row.conditionNum || 0), 0);

        const newRow = Object.keys(schema).reduce((acc, key) => {
            // acc[key] = schema[key] === "int" ? 0 : "";
            if (schema[key] === "int") acc[key] = 0;
            else if (schema[key] === "checkbox") acc[key] = 0; // Default to unchecked
            else if (schema[key] === "dropdown-logic") acc[key] = "="; // Default to `=`
            else if (schema[key] === "enum-condition") acc[key] = "IF"; // Default logic
            else if (schema[key] === "enum-event") acc[key] = "FORM"; // Default event type
            else acc[key] = ""; // Default to empty string
            return acc;
        }, {});

        // Assign the new conditionNum (incrementing from the last)
        newRow.conditionNum = lastConditionNum + 1;
        newRow.engineId = engineInfo.engineId;
        newRow.workflowId = engineInfo.workflowId;

        setEngineData([...engineData, newRow]);

        setUnsavedRows((prev) => new Set([...prev, engineData.length]));

    };

    const updateEngineData = async (index) => {
        let rowData = { ...engineData[index] };

        // 🔍 Debug: Log before cleanup
        console.log("Before Cleanup:", JSON.stringify(rowData, null, 2));

        // Ensure IDs are properly set
        rowData.engineId = rowData.engineId || engineInfo.engineId;
        rowData.workflowId = rowData.workflowId || engineInfo.workflowId;

        // Convert empty strings to `null` where applicable
        Object.keys(rowData).forEach((key) => {
            if (rowData[key] === "") {
                rowData[key] = null;
            }
        });

        // 🔍 Debug: Log after cleanup
        console.log("After Cleanup:", JSON.stringify(rowData, null, 2));

        try {
            const response = await fetchApi("workflow/update", rowData);

            // 🔍 Debug: Log what was actually sent
            console.log("Final Payload Sent:", JSON.stringify(rowData, null, 2));

            if (response.status >= 200 && response.status < 300) {
                setUnsavedRows((prev) => {
                    const newSet = new Set(prev);
                    newSet.delete(index);
                    return newSet;
                });
                setMsgCode(0);
            } else {
                setMsg(response.msg);
                setMsgCode(1);
            }
        } catch (error) {
            console.error("Update failed:", error);
            setMsg("An unexpected error occurred. Contact your administrator.");
            setMsgCode(4);
        }
    };


    return (
        <div>
            {/*<Bluey style={{zIndex: -1}} />*/}
            <CustomAlert msg={msg} msgCode={msgCode} />

            <div className="logic-engine-container">
                <h2 className="logic-engine-title">
                    {/*Engine ID: {engineInfo.engineId} | Workflow ID: {engineInfo.workflowId}*/}

                    <select
                        id="engine-select"
                        className="logic-engine-select"
                        value={selectedEngine}
                        onChange={(e) => {
                            const newEngineId = parseInt(e.target.value, 10);
                            setSelectedEngine(newEngineId);
                            fetchEngineData(newEngineId);
                        }}
                    >
                        {availableEngines.map(({engineId}) => (
                            <option key={engineId} value={engineId}>
                                Engine {engineId}
                            </option>
                        ))}
                    </select>
                </h2>
                {schema ? (
                    <table className="logic-engine-table">
                        <thead>
                        <tr>
                            {Object.keys(schema).filter(key => schema[key] !== "hidden").map((key) => {
                                if (key === "conditionNum") return <th key={key}></th>;
                                if (key === "conditionType") return <th key={key}>Logic</th>;
                                if (key === "logicOperator") return <th key={key}></th>;
                                if (key === "condition") return <th key={key}>Condition</th>;
                                if (key === "value") return <th key={key}>Value</th>;
                                if (key === "eventName") return <th key={key}>Event name</th>;
                                if (key === "eventType") return <th key={key}>then</th>;
                                if (key === "eventDesc") return <th key={key}>Event description</th>;
                                if (key === "eventTargets") return <th key={key}>Target/s</th>;
                                if (key === "instructions") return <th key={key}>Instructions to the target</th>;
                                if (key === "priority") return <th key={key}>Priority</th>;
                                if (key === "notes") return <th key={key}>Notes</th>;
                                if (key === "link") return <th key={key}>Link address</th>;
                                // if (key === "linkName") return <th key={key}>link (Pretty Name)</th>;
                                return <th key={key}>{key}</th>;
                            })}
                            <th>actions</th>
                            {/* Keep Actions column for Save/Add Row */}
                        </tr>
                        </thead>
                        <tbody>
                        {engineData.map((row, index) => (
                            <tr key={index}>

                                <input type="hidden" name={`engineId-${index}`} value={row.engineId}/>
                                <input type="hidden" name={`workflowId-${index}`} value={row.workflowId}/>
                                <input type="hidden" name={`conditionNum-${index}`} value={row.conditionNum}/>

                                {Object.keys(schema).filter(key => schema[key] !== "hidden").map((key) => {
                                    const actualConditionNum = row.conditionNum;
                                    const hideConditionNum = key === "conditionNum" && index > 0 && engineData[index - 1].continue === 1;
                                    const isDisabled = (
                                        row.conditionType === "ALWAYS" && ["condition", "logicOperator", "value", "eventType"].includes(key) ||
                                        row.conditionType === "ELSE" && ["condition", "logicOperator", "value", "eventType"].includes(key)
                                    );
                                    return (
                                        <td key={key} style={{visibility: hideConditionNum ? "hidden" : "visible"}}>
                                            {key === "conditionNum" ? (
                                                <span>{actualConditionNum}</span>
                                            ) : schema[key] === "enum-condition" ? (
                                                <select className="logic-engine-select"
                                                        value={row[key] || logicOperatorOptions[0].value}
                                                        onChange={(e) => handleChange(index, key, e.target.value)}
                                                >
                                                    {conditionTypeOptions.map(option => (
                                                        <option key={option} value={option}>{option}</option>
                                                    ))}
                                                </select>
                                            ) : schema[key] === "enum-event" ? (
                                                <select className="logic-engine-select"
                                                        value={row[key] || logicOperatorOptions[0].value}
                                                        onChange={(e) => handleChange(index, key, e.target.value)}
                                                >
                                                    {eventTypeOptions.map(option => (
                                                        <option key={option} value={option}>{option}</option>
                                                    ))}
                                                </select>
                                            ) : schema[key] === "dropdown-logic" ? (
                                                <select className="logic-engine-select"
                                                        value={row[key] || logicOperatorOptions[0].value}
                                                        onChange={(e) => handleChange(index, key, e.target.value)}
                                                        disabled={isDisabled} // Disable if 'ALWAYS' is selected
                                                >
                                                    {logicOperatorOptions.map(({label, value}) => (
                                                        <option key={value} value={value}>{label}</option>
                                                    ))}
                                                </select>
                                            ) : schema[key] === "checkbox" ? (
                                                <input className="logic-engine-checkbox"
                                                       type="checkbox"
                                                       checked={!!row[key]}
                                                       onChange={(e) => handleChange(index, key, e.target.checked ? 1 : 0)}
                                                />
                                            ) : schema[key] === "int" ? ( // Now applies to all int fields
                                                <input className="logic-engine-int"
                                                       type="number"
                                                       value={row[key]}
                                                       onChange={(e) => handleChange(index, key, e.target.value)}
                                                />
                                            ) : schema[key] === "textarea" ? (
                                                <textarea
                                                    className="logic-engine-textarea"
                                                    ref={(el) => resizeTextarea(el)} // Resize on load
                                                    value={row[key] || ""}
                                                    onChange={(e) => {
                                                        handleChange(index, key, e.target.value);
                                                        resizeTextarea(e.target); // Resize dynamically on change
                                                    }}
                                                    disabled={isDisabled} // Disable if 'ALWAYS' is selected
                                                />

                                            ) : (
                                                <input className="logic-engine-input"
                                                       type="text"
                                                       value={row[key] || ""}
                                                    // onChange={(e) => handleChange(index, key, e.target.value)}
                                                    // style={{ minWidth: "250px", maxWidth: "300px", height: "auto", wordWrap: "break-word", whiteSpace: "normal" }}
                                                       onChange={(e) => handleChange(index, key, e.target.value)}
                                                       disabled={isDisabled} // Disable if 'ALWAYS' is selected
                                                />
                                            )}
                                        </td>
                                    );
                                })}

                                <td className="logic-engine-action-buttons">
                                    <button
                                        onClick={() => updateEngineData(index)}
                                        style={{
                                            backgroundColor: unsavedRows.has(index) ? "purple" : "grey",
                                            color: "white",
                                            cursor: unsavedRows.has(index) ? "pointer" : "default",
                                            opacity: unsavedRows.has(index) ? 1 : 0.5
                                        }}
                                    >
                                        Save
                                    </button>

                                    <button className="logic-engine-delete-btn"
                                            onClick={() => handleArchiveRow(row.id)}>
                                        <span role="img" aria-label="delete">🗑️</span>
                                    </button>
                                </td>

                            </tr>
                        ))}
                        <tr>
                            <td colSpan={Object.keys(schema).length + 1}>
                                <button onClick={addRow}>Add Row</button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                ) : <p>Loading schema...</p>}
            </div>
        </div>
    );
}
