import React from 'react';
import { matchPath, Outlet, useLocation  } from 'react-router-dom';
import * as Pages from '../../Pages';

const UnderConstructionWrapper: React.FC = () => {
 
  // List of pages that are under construction
  const underConstructionPages = [
    '/people',
    '/businesses',
    '/register-interest',
    '/calendar/my-classes',
    '/certificates-modules/certificates-templates',
    '/certificates-modules/module-visibility-current',
    '/certificates-modules/module-billing',
    '/documentation/document-repository',
    '/documentation/policies-procedures',
    '/documentation',
    '/documentation/marketing',
    '/documentation/compliance-auditing',
    '/tasks/new-task',
    '/tasks/unassigned-tasks',
    '/tasks/marking',
    '/administration/reports/*',
    '/administration/uploads/*',
  ];
  const location = useLocation();

  const isUnderConstruction = underConstructionPages.some((path) =>
    matchPath(path, location.pathname) // Match using matchPath
  );

  if (isUnderConstruction) {
    return <Pages.Construction route={location.pathname} />;
  }

  return <Outlet />;
};

export default UnderConstructionWrapper;
