import React, {useContext, useState, useRef, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import {ConstLink} from "ConstLink";
import Select from 'react-select';
import BorderedComponent from 'Components/BorderedComponent';
// import CurrencyInput from 'react-currency-input-field';
// import QRCode from "react-qr-code"
import QRCodeStyling from "qr-code-styling";
// import UploadDropzone from 'Components/UploadDropzone';  No longer Used
import Upload from "Components/Dropzone/upload/Upload";
import {Container, Row, Col } from 'react-bootstrap';
import { useUserField } from 'CustomHooks';

// import FileUpload from 'Components/FileUpload';


import QRLogo from 'Images/QRLogo.png';
// import ModuleTable from "Components/ModuleTable";
// export default EmploymentStatusRadio;
export default function TemplateSteps() {
    // const [EmploymentStatusRadio] = useState([default])
    const [studentList, setStudentList] = useState([]); //This is for the accordion Function as well
    const navigate = useNavigate();
    const isLoggedIn = useUserField('loggedIn');
    const { setQuickMenu, _setStepButtons } = useContext(ConstLink);
    const [_pageName, setPageName] = useState('');
    // const [QRCodeVal, setQRCodeVal] = useState("QR Code Value will link to class details");
    const [activeTab, setActiveTab] = useState('Tab 1');
    const [url, _setUrl] = useState("QR Code Value will link to class details");
    // const [QRCode, QRCode2] = useRef(null);
    const QRCode = useRef(null)
    const QRCode2 = useRef(null)
    const [ chkPhysical, _setChkPhysical ] = useState(false); // <------------- This will be used for selecting checked values from the DB.


    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(formData);

        switch (activeTab) {
            case 'Tab 1':
                setActiveTab('Tab 2');
                break;
            case 'Tab 2':
                setActiveTab('Tab 3');
                break;
            case 'Tab 3':
                setActiveTab('Tab 4');
                break;
            case 'Tab 4':
                setActiveTab('Tab 5');
                break;
            case 'Tab 5':
                setActiveTab('Tab 6');
                break;
            case 'Tab 6':
                setActiveTab('Tab 7');
                break;
            case 'Tab 7':
                setActiveTab('Tab 8');
                break;
            default:
                break;
        }
    };
    const handleChangeTab = (tabIndex) => {
        handleTabClick(tabIndex)
    }


    if ( !isLoggedIn ) {
        navigate('/login')
    }
    function cloneQRCode(qrCode) {
        const config = JSON.parse(JSON.stringify(qrCode._options));
        return new QRCodeStyling(config);
    }

    useEffect(() => {
        updateQuickMenu();
        const pathArray = window.location.pathname.split('/');
        const pageParam = pathArray[pathArray.length - 1];
        setPageName(pageParam || 'Default Page');
        qrCode.append(QRCode.current);

        const qrCodeClone = cloneQRCode(qrCode);
        qrCodeClone.append(QRCode2.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        qrCode.update({
            data: url
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [url]);

    // const onUrlChange = (event) => {
    //     event.preventDefault();
    //     setUrl(event.target.value);
    // };

    const updateQuickMenu = () => {
        setQuickMenu(
            ["Item 1", 'route'],
            ["Item 2", 'route'],
            ["Item 3", 'route'],
            ["Item 4", 'route'],
            ["Item 5", 'route'],
            ["Item 6", 'route'], );
    };

    // Helper function to get a formatted date in the format "YYYY-MM-DD"
    const getFormattedDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    // const [cboBox1, setCboBox1] = useState('');

    const options = [
        { value: 'option1', label: 'Option 1' },
        { value: 'option2', label: 'Option 2' },
        { value: 'option3', label: 'Option 3' },
        // Add more options as needed as this is your dropdown choices and would normally be pulled from the DBase
    ];

    // const noteType = [
    //     { value: '01', label: 'Complaint about Student' },
    //     { value: '02', label: 'Complaint from Student' },
    //     { value: '03', label: 'Explained Absence' },
    //     { value: '04', label: 'Note' },
    //     { value: '05', label: 'Phone from Parent' },
    //     { value: '06', label: 'Phone from student' },
    //     { value: '07', label: 'Phone to Parent' },
    //     { value: '08', label: 'Phone to Student' },
    //     { value: '09', label: 'SMS' },
    //     // Add more options as needed as this is your dropdown choices and would normally be pulled from the DBase
    // ];




    // ========== This will create the variable and set its default at the same time... also you have a list. If you want to map it like you
    // have on line 1488 then you need to create a JSON object with the value and label as the keys.
    const [selectedEmploymentStatus, _setSelectedEmploymentStatus] = useState([  //the avetmiss number for employment
        "Employed - Full-time", // 01
        "Employed - Part-Time", // 02
        "Self Employed - not employing others", //03
        "Self Employed - employing others", //04
        "Employed - Unpaid worker in a family business", // 05
        "Unemployed - Seeking full-time work", // 06
        "Unemployed - Seeking part-time work", // 08
        "Unemployed - not seeking employment", // 09
    ]);

    // ============== This will allow you to pull the status from the db and redisplay it
    // const [currentEmploymentStatus, setCurrentEmploymentStatus] = useState("");  // or some default value


    // ============== This will cause an infinite loop (For now). You will send the option value as a form value when you submit the form.
    // ============== We will come back and use this when it's stitched to the API

    // const handleEmploymentStatusChange = (event) => {
    //     setSelectedEmploymentStatus(event.target.value);
    // };


// I have put this in here so that the base items are already made
    const [selectedTrainingReason, _setselectedTrainingReason] = useState([  //the avetmiss number for employment
        "To get a job", // 01
        "To develop my existing business", // 02
        "To start my own business", // 03
        "To try for a different career", // 04
        "To get a better job or promotion", // 05
        "It was a requirement of my job", // 06
        "I wanted extra skills for my job", // 07
        "To get into another course of study", //08
        "", //09
        "", //10
        "Other reasons", // 11
        "For personal interest or self-development", // 12
        "To get skills for community/voluntary work", // 13   <--------- Forgot the comma
    ]);

    // const [currentTrainingReason, setSelectedTrainingReason] = useState("");  // or some default value

    // const handleCombo1Change = (selectedOption) => {
    //     console.log(selectedOption); // Do something with the selected option
    // };

    useEffect(() => {
        const tabContents = document.getElementsByClassName('tabcontent');

        for (let i = 0; i < tabContents.length; i++) {
            if (tabContents[i].id === activeTab) {
                tabContents[i].style.display = 'block';
            } else {
                tabContents[i].style.display = 'none';
            }
        }
    }, [activeTab]);

    const [formData, setFormData] = useState({
        textInputVal: '',
        dropdownVal: '',
        startDateVal: getFormattedDate(new Date()),
        finishDateVal: getFormattedDate(new Date()),
        startTimeVal: '08:00',
        finishTimeVal: '15:00',
        classMinimumVal: '',
        classMaximumVal: '',
        classCostVal: '',
        isPrivateVal: 'checked',
        venueVal: '',
        borderedComponentVal: '',
    });

    // These are all of the items for the accordion function

    // Function to fetch the data from the database and populate the accordionData state
    const fetchStudentList = async () => {
        // Replace this with your actual database call logic
        // For example:
        // const response = await fetch('your-api-endpoint');
        // const data = await response.json();
        // setAccordionData(data);
        // For now, let's use a static example data:

        const exampleData = [
            {
                heading: 'Student 1',
                mobile: '123-456-7890',
                billing: 'Student',
                usi: '12345',
                competent: 'Uncehcked',
                actions: 'Approve',
                extraLineItems: [
                    { code: 'CODE-1', name: 'Item 1', evidence: 'Evidence 1' },
                    { code: 'CODE-2', name: 'Item 2', evidence: 'Evidence 2' },
                ],
            },                {
                heading: 'Student 2',
                mobile: '123-456-7890',
                billing: 'Employer',
                usi: '12345',
                competent: 'Uncehcked',
                actions: 'Approve',
                extraLineItems: [
                    { code: 'CODE-1', name: 'Item 1', evidence: 'Evidence 1' },
                    { code: 'CODE-2', name: 'Item 2', evidence: 'Evidence 2' },
                ],
            },                {
                heading: 'Student 3',
                mobile: '123-456-7890',
                billing: 'Student',
                usi: '12345',
                competent: 'Uncehcked',
                actions: 'Approve',
                extraLineItems: [
                    { code: 'CODE-1', name: 'Item 1', evidence: 'Evidence 1' },
                    { code: 'CODE-2', name: 'Item 2', evidence: 'Evidence 2' },
                ],
            },
            // Add more data objects as needed
        ];

        setStudentList(exampleData);
    };
    useEffect(() => {
        fetchStudentList();
    }, []);

    // State to manage open/closed state of each accordion row
    const [expandedRows, setExpandedRows] = useState({});
    // Function to toggle the accordion row's open/closed state
    const toggleAccordion = (index) => {
        setExpandedRows((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };
    // ========= End of Accordian Function ==========

    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;
        const newValue = type === 'checkbox' ? checked : value;
        setFormData((prevData) => ({
            ...prevData,
            [name]: newValue
        }));
    };


    const customSelectStyles = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: state.isFocused ? '#d0d0d0' : '#d0d0d0', // Border color changes on focus
            boxShadow: state.isFocused ? '0 0 5px #955cce' : 'none', // Box shadow changes on focus
            '&:hover': {
                borderColor: '#955cce', // Border color changes on hover
            },
        }),
        option: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: state.isFocused ? '#c1acd9' : 'white', // Background color changes on option hover
            color: state.isFocused ? 'white' : 'black',
        }),
    };

    const [isChecked, setIsChecked] = useState(true);
    const [_isPrivate, setIsPrivate] = useState(true)
    const [_isPrivateMsg, setIsPrivateMsg] = useState('Class is Private')

    const _handleCheckboxChange = () => {
        if (isChecked) {
            // setIsChecked((prevIsChecked) => !prevIsChecked);
            setIsChecked(false);
            setIsPrivate(false);
            setIsPrivateMsg('Class is Public')
        } else {
            setIsChecked(true);
            setIsPrivate(true);
            setIsPrivateMsg('Class is Private')
        }

    };

    // Style the QR Code
    const qrCode = new QRCodeStyling({
        width: 300,
        height: 300,
        image: QRLogo,
        dotsOptions: {
            color: "#393939",
            type: "classy"
        },
        imageOptions: {
            imageSize: 0.4,
            crossOrigin: "anonymous",
            margin: 5
        },
        backgroundOptions: {
            color: "transparent"
        },
        cornersSquareOptions: {
            type: "square",
            color: "#955CCE",
        }
    });

    return (

        <div>

            <div
                style={{
                    height: '100px',
                    width: '100%',
                }}
            ></div>
            {/* ======================================================This is where the number of arrowed tabs are set================================================================== */}

            <div className="container justify-content-left align-items-center" style={{ position: 'relative', zIndex: 0}}>
                <div className="col-12">
                    <div className="tab" style={{ display: 'flex'}}>
                        <div className="arrow-tab2" style={{ display: 'flex', justifyContent: 'flex-start', zIndex: 10 }}>
                            <div className={activeTab === 'Tab 1' ? 'arrow-tab active' : 'arrow-tab'}
                                 onClick={() => handleTabClick('Tab 1')}
                                // style={{zIndex: 94}}
                            >
                                Main Details
                            </div>

                        </div>

                        <div className="arrow-tab2" style={{ display: 'flex', justifyContent: 'flex-start', zIndex: 9  }}>
                            <div className={activeTab === 'Tab 2' ? 'arrow-tab active' : 'arrow-tab'}
                                 onClick={() => handleTabClick('Tab 2')}
                                // style={{zIndex: 94}}
                            >
                                Education
                            </div>

                        </div>

                        <div className="arrow-tab2" style={{ display: 'flex', justifyContent: 'flex-start', zIndex: 8 }}>
                            <div className={activeTab === 'Tab 3' ? 'arrow-tab active' : 'arrow-tab'}
                                 onClick={() => handleTabClick('Tab 3')}
                                // style={{zIndex: 94}}
                            >
                                Employment
                            </div>
                        </div>
                        <div className="arrow-tab2" style={{ display: 'flex', justifyContent: 'flex-start', zIndex: 7  }}>
                            <div className={activeTab === 'Tab 4' ? 'arrow-tab active' : 'arrow-tab'}
                                 onClick={() => handleTabClick('Tab 4')}
                                // style={{zIndex: 94}}
                            >
                                Authorisations
                            </div>
                        </div>
                        <div className="arrow-tab2" style={{ display: 'flex', justifyContent: 'flex-start', zIndex: 6 }}>
                            <div className={activeTab === 'Tab 5' ? 'arrow-tab active' : 'arrow-tab'}
                                 onClick={() => handleTabClick('Tab 5')}
                                // style={{zIndex: 94}}
                            >
                                Achievements
                            </div>
                        </div>
                        <div className="arrow-tab2" style={{ display: 'flex', justifyContent: 'flex-start', zIndex: 5  }}>
                            <div className={activeTab === 'Tab 6' ? 'arrow-tab active' : 'arrow-tab'}
                                 onClick={() => handleTabClick('Tab 6')}
                                // style={{zIndex: 94}}
                            >
                                In Progress
                            </div>
                        </div>
                        <div className="end-tab2" style={{ zIndex: 4 }}>
                            <div className={activeTab === 'Tab 7' ? 'arrow-tab active' : 'arrow-tab'}
                                 onClick={() => handleTabClick('Tab 7')}
                                // style={{zIndex: 94}}
                            >
                                Notes
                            </div>
                        </div>
                    </div>


                    {/* ===================================================================== TAB 1 =========================================================================*/}
                    {/* each arrowed Tab has its own master DIV with the id named there and refering to the tab setup above the TAB 1 marker.  All div rules are respected   */}

                    <div id="Tab 1" className="tabcontent">

                        <form onSubmit={handleSubmit}>

                            <Container style={{width:'100%'}}>

                                <Row style={{marginTop: 15, width:'100%' }}>  {/* This is the first row and start of the tab */}

                                    <Col xs={5} md={5} xl={5} style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'center' }}>
                                        <div>
                                            <label htmlFor="textInput">I prefer to be called</label>
                                            <input
                                                id="textInput"
                                                className="inputText"
                                                type="text"
                                                name="textInput"
                                                placeholder="Prefered Name"
                                                value={formData.inputTextVal}
                                                onChange={handleInputChange}
                                                title="This should be the Name a person would
                                                            prefer to be called."
                                                // required
                                            /><br/><br/><br/>
                                        </div>
                                        <div >Selfie</div>
                                    </Col>

                                    <Col xs={7} md={7} xl={7} style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                        <Row>
                                            <Col xs={2} md={2} xl={2} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>

                                                {/*<label htmlFor="textInput">Title</label>*/}
                                                <input
                                                    id="textInput"
                                                    className="inputText"
                                                    type="text"
                                                    name="textInput"
                                                    placeholder="Title"
                                                    value={formData.inputTextVal}
                                                    onChange={handleInputChange}
                                                    title="This is the persons title
                                                            eg. Mr  Mrs  Dr"
                                                    // required
                                                />
                                            </Col>
                                            <Col xs={10} md={5} xl={5} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>

                                            {/*<label htmlFor="textInput">First Name</label>*/}
                                            <input
                                                id="textInput"
                                                className="inputText"
                                                type="text"
                                                name="textInput"
                                                placeholder="First Name"
                                                value={formData.inputTextVal}
                                                onChange={handleInputChange}
                                                title="This should be the First Name as listed
                                                            on the uploaded Identification."
                                                required
                                            />
                                        </Col>
                                            <Col xs={12} md={5} xl={5} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                            {/*<label htmlFor="textInput">Middle Name</label>*/}
                                                <input
                                                    id="textInput"
                                                    className="inputText"
                                                    type="text"
                                                    name="textInput"
                                                    placeholder="Middle Name"
                                                    value={formData.inputTextVal}
                                                    onChange={handleInputChange}
                                                    title="This should be the Middle Name as listed
                                                            on the uploaded Identification."
                                                    // required
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={9} xl={9} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>

                                                {/*<label htmlFor="textInput">Last Name</label>*/}
                                                <input
                                                    id="textInput"
                                                    className="inputText"
                                                    type="text"
                                                    name="textInput"
                                                    placeholder="Last Name"
                                                    value={formData.inputTextVal}
                                                    onChange={handleInputChange}
                                                    title="This should be the Last Name as listed
                                                            on the uploaded Identification."
                                                    required
                                                />
                                            </Col>
                                            <Col xs={6} md={3} xl={3} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                {/*<label htmlFor="comboBox" >Gender</label>*/}
                                                <select
                                                    name="statementType"
                                                    value={formData.statementType}
                                                    onChange={handleInputChange}
                                                    title="This is the way the class has been delivered.
                                                        Workplace is when delivered in conjunction with an employer
                                                        Classroom Based is when they are only doing it in a classroom
                                                        External is where they are doing it via correspondence"
                                                    required
                                                >
                                                    <option value="null">Gender</option>
                                                    <option value="Male">Male</option>
                                                    <option value="Female">Female</option>
                                                    <option value="Other">Other</option>
                                                    Required
                                                </select>
                                            </Col>
                                        </Row>
                                        <Row style={{paddingTop: '15px', paddingRight: '18px' }}>
                                            <BorderedComponent style={{margin: '0px', paddingTop:'15px'}} name="Contact Phone numbers">
                                                <Row>
                                                    <Col xs={12} md={4} xl={4} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>

                                                        {/*<label htmlFor="textInput">Mobile</label>*/}
                                                        <input
                                                            id="textInput"
                                                            className="inputText"
                                                            type="text"
                                                            name="textInput"
                                                            placeholder="Mobile Number"
                                                            value={formData.inputTextVal}
                                                            onChange={handleInputChange}
                                                            title="This is the persons Mobile Number"
                                                        />
                                                    </Col>
                                                    <Col xs={12} md={4} xl={4} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>

                                                        {/*<label htmlFor="textInput">Work</label>*/}
                                                        <input
                                                            id="textInput"
                                                            className="inputText"
                                                            type="text"
                                                            name="textInput"
                                                            placeholder="Work Number"
                                                            value={formData.inputTextVal}
                                                            onChange={handleInputChange}
                                                            title="This is used when a person has a dedicated
                                                            work phone number that is different to
                                                            their mobile number"
                                                        />
                                                    </Col>
                                                    <Col xs={12} md={4} xl={4} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                        {/*<label htmlFor="textInput">Home</label>*/}
                                                        <input
                                                            id="textInput"
                                                            className="inputText"
                                                            type="text"
                                                            name="textInput"
                                                            placeholder="Home Number"
                                                            value={formData.inputTextVal}
                                                            onChange={handleInputChange}
                                                            title="This is used when a person has a dedicated
                                                            home phone number that is different to
                                                            their mobile number"
                                                        />
                                                    </Col>
                                                </Row>
                                            </BorderedComponent>
                                        </Row>

                                    </Col>
                                </Row>
                                <Row style={{paddingTop: '25px'}}>
                                    <Col xs={12} md={6} xl={6} style={{marginTop: 25, textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                    <BorderedComponent name={"Residential address as per your Drivers Licence"}>
                                        <Row>
                                            <Col xs={8} md={8} xl={8} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                <input
                                                    id="textInput"
                                                    className="inputText"
                                                    type="text"
                                                    name="textInput"
                                                    placeholder="Building or Property Name"
                                                    value={formData.inputTextVal}
                                                    onChange={handleInputChange}
                                                    title="This should be used if there is a Building or
                                                                    Property Name associated with the premises
                                                                    eg. Trump Tower or Nullabour Station"
                                                    // required
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={6} md={2} xl={2} style={{ paddingRight:'0px', textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                <input
                                                    id="textInput"
                                                    className="inputText"
                                                    type="text"
                                                    name="textInput"
                                                    placeholder="Unit #"
                                                    value={formData.inputTextVal}
                                                    onChange={handleInputChange}
                                                    title="This should be used if the address has several
                                                                    flats or units on the premises."
                                                    // required
                                                />
                                            </Col>
                                            <Col xs={6} md={2} xl={2} style={{ paddingRight:'0px', textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                <input
                                                    id="textInput"
                                                    className="inputText"
                                                    type="text"
                                                    name="textInput"
                                                    placeholder="Str #"
                                                    value={formData.inputTextVal}
                                                    onChange={handleInputChange}
                                                    title="This should be the Last Name as listed
                                                                on the uploaded Identification."
                                                    // required
                                                />
                                            </Col>
                                            <Col xs={10} md={6} xl={6} style={{ paddingRight:'0px', textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                <input
                                                    id="textInput"
                                                    className="inputText"
                                                    type="text"
                                                    name="textInput"
                                                    placeholder="Street Name"
                                                    value={formData.inputTextVal}
                                                    onChange={handleInputChange}
                                                    title="This should be the Last Name as listed
                                                                on the uploaded Identification."
                                                    required
                                                />
                                            </Col>
                                            <Col xs={2} md={2} xl={2} style={{ paddingRight:'6px', textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                <input
                                                    id="textInput"
                                                    className="inputText"
                                                    type="text"
                                                    name="textInput"
                                                    placeholder="St, Rd etc"
                                                    value={formData.inputTextVal}
                                                    onChange={handleInputChange}
                                                    title="This should be the Last Name as listed
                                                                on the uploaded Identification."
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={6} xl={6} style={{ paddingRight:'0px', textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                <input
                                                    id="textInput"
                                                    className="inputText"
                                                    type="text"
                                                    name="textInput"
                                                    placeholder="Suburb"
                                                    value={formData.inputTextVal}
                                                    onChange={handleInputChange}
                                                    title="This should be the Last Name as listed
                                                                on the uploaded Identification."
                                                    required
                                                />
                                            </Col>
                                            <Col xs={6} md={3} xl={3} style={{ paddingRight:'0px', textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                <input
                                                    id="textInput"
                                                    className="inputText"
                                                    type="text"
                                                    name="textInput"
                                                    placeholder="State"
                                                    value={formData.inputTextVal}
                                                    onChange={handleInputChange}
                                                    title="This should be the Last Name as listed
                                                                on the uploaded Identification."
                                                    required
                                                />
                                            </Col>
                                            <Col xs={6} md={3} xl={3} style={{ paddingRight:'6px', textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                <input
                                                    id="textInput"
                                                    className="inputText"
                                                    type="text"
                                                    name="textInput"
                                                    placeholder="PostCode"
                                                    value={formData.inputTextVal}
                                                    onChange={handleInputChange}
                                                    title="This should be the Last Name as listed
                                                                on the uploaded Identification."
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                    </BorderedComponent>
                                    </Col>
                                    <Col xs={12} md={6} xl={6} style={{marginTop: 25, textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                    <BorderedComponent name={"Postal Address ------ or check this Box if same as residential"}>
                                        <Row>
                                            <Col xs={8} md={8} xl={8} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                <input
                                                    id="textInput"
                                                    className="inputText"
                                                    type="text"
                                                    name="textInput"
                                                    placeholder="Building or Property Name"
                                                    value={formData.inputTextVal}
                                                    onChange={handleInputChange}
                                                    title="This should be used if there is a Building or
                                                                    Property Name associated with the premises
                                                                    eg. Trump Tower or Nullabour Station"
                                                    // required
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={6} md={2} xl={2} style={{ paddingRight:'0px', textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                <input
                                                    id="textInput"
                                                    className="inputText"
                                                    type="text"
                                                    name="textInput"
                                                    placeholder="Unit #"
                                                    value={formData.inputTextVal}
                                                    onChange={handleInputChange}
                                                    title="This should be used if the address has several
                                                                    flats or units on the premises."
                                                    // required
                                                />
                                            </Col>
                                            <Col xs={6} md={2} xl={2} style={{ paddingRight:'0px', textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                <input
                                                    id="textInput"
                                                    className="inputText"
                                                    type="text"
                                                    name="textInput"
                                                    placeholder="Str #"
                                                    value={formData.inputTextVal}
                                                    onChange={handleInputChange}
                                                    title="This should be the Last Name as listed
                                                                on the uploaded Identification."
                                                    // required
                                                />
                                            </Col>
                                            <Col xs={10} md={6} xl={6} style={{ paddingRight:'0px', textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                <input
                                                    id="textInput"
                                                    className="inputText"
                                                    type="text"
                                                    name="textInput"
                                                    placeholder="Street Name"
                                                    value={formData.inputTextVal}
                                                    onChange={handleInputChange}
                                                    title="This should be the Last Name as listed
                                                                on the uploaded Identification."
                                                    required
                                                />
                                            </Col>
                                            <Col xs={2} md={2} xl={2} style={{ paddingRight:'6px', textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                <input
                                                    id="textInput"
                                                    className="inputText"
                                                    type="text"
                                                    name="textInput"
                                                    placeholder="St, Rd etc"
                                                    value={formData.inputTextVal}
                                                    onChange={handleInputChange}
                                                    title="This should be the Last Name as listed
                                                                on the uploaded Identification."
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={6} xl={6} style={{ paddingRight:'0px', textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                <input
                                                    id="textInput"
                                                    className="inputText"
                                                    type="text"
                                                    name="textInput"
                                                    placeholder="Suburb"
                                                    value={formData.inputTextVal}
                                                    onChange={handleInputChange}
                                                    title="This should be the Last Name as listed
                                                                on the uploaded Identification."
                                                    required
                                                />
                                            </Col>
                                            <Col xs={6} md={3} xl={3} style={{ paddingRight:'0px', textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                <input
                                                    id="textInput"
                                                    className="inputText"
                                                    type="text"
                                                    name="textInput"
                                                    placeholder="State"
                                                    value={formData.inputTextVal}
                                                    onChange={handleInputChange}
                                                    title="This should be the Last Name as listed
                                                                on the uploaded Identification."
                                                    required
                                                />
                                            </Col>
                                            <Col xs={6} md={3} xl={3} style={{ paddingRight:'6px', textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                <input
                                                    id="textInput"
                                                    className="inputText"
                                                    type="text"
                                                    name="textInput"
                                                    placeholder="PostCode"
                                                    value={formData.inputTextVal}
                                                    onChange={handleInputChange}
                                                    title="This should be the Last Name as listed
                                                                on the uploaded Identification."
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                    </BorderedComponent>
                                    </Col>
                                </Row>
                                <Row style={{paddingTop: '15px', paddingRight: '40px', paddingLeft: '13px' }}>
                                    <BorderedComponent style={{margin: '0px', paddingTop:'15px'}} name="In Case of Emergency">
                                        <Row>
                                            <Col xs={12} md={8} xl={8} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>

                                                {/*<label htmlFor="textInput">Mobile</label>*/}
                                                <input
                                                    id="textInput"
                                                    className="inputText"
                                                    type="text"
                                                    name="textInput"
                                                    placeholder="contact person"
                                                    value={formData.inputTextVal}
                                                    onChange={handleInputChange}
                                                    title="This is the person to contact in the
                                                                unlikely event of an emergency.  You
                                                                know, like on the plane how they go
                                                                through their speel every time.  :)"
                                                />
                                            </Col>
                                            <Col xs={12} md={4} xl={4} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>

                                                {/*<label htmlFor="textInput">Work</label>*/}
                                                <input
                                                    id="textInput"
                                                    className="inputText"
                                                    type="text"
                                                    name="textInput"
                                                    placeholder="Mobile Number"
                                                    value={formData.inputTextVal}
                                                    onChange={handleInputChange}
                                                    title="This is used the best Phone
                                                                Number to call them on"
                                                />
                                            </Col>
                                        </Row>
                                    </BorderedComponent>
                                </Row>
                                <Row style={{paddingTop: '15px', paddingRight: '15px', paddingLeft: '20px' }}>
                                    <Col xs={12} md={12} xl={12} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>

                                        <label htmlFor="textInput">Previously Known by</label>
                                        <input
                                            id="textInput"
                                            className="inputText"
                                            type="text"
                                            name="textInput"
                                            placeholder="Previous Names like a maiden name or name prior to being legally changed"
                                            value={formData.inputTextVal}
                                            onChange={handleInputChange}
                                            title="This should be the any names you were known
                                                            by previously such as a maiden name or
                                                            if you have changed your name legally."
                                            // required
                                        />
                                    </Col>

                                </Row>



                            </Container>

                        </form>
                        <div style={{ display: 'flex', justifyContent: 'right', width: '100%', padding: 25}}>
                            <button type="submit"  className={"btn-primary"}>NEXT</button>

                        </div>
                    </div>



                    {/*========================================================= TAB 2 ======================================================================*/}

                    <div id="Tab 2" className="tabcontent">

                        <form onSubmit={handleSubmit}>
                            <Row style={{paddingTop: '15px', paddingRight: '40px', paddingLeft: '13px' }}>
                                <BorderedComponent style={{margin: '0px', paddingTop:'15px'}} name="Unique Student Identifier">
                                    <Row>
                                        <Col xs={12} md={3} xl={3} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>

                                            {/*<label htmlFor="textInput">Mobile</label>*/}
                                            <input
                                                id="textInput"
                                                className="inputText"
                                                type="text"
                                                name="textInput"
                                                placeholder="USI"
                                                value={formData.inputTextVal}
                                                onChange={handleInputChange}
                                                title="This is the USI as issued by the
                                                        Australian Government.  For more
                                                        information please go to
                                                        USI.gov.au"
                                            />
                                        </Col>
                                        <Col xs={12} md={4} xl={4} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-left', alignItems: 'flex-left' }}>
                                            <div>

                                            </div>
                                        </Col>
                                    </Row>
                                </BorderedComponent>
                            </Row>
                            <Row style={{paddingTop: '15px', paddingRight: '40px', paddingLeft: '13px' }}>
                                <BorderedComponent style={{margin: '0px', paddingTop:'15px'}} name="Support that may be needed">
                                     <Row >
                                        <Col xs={12} md={5} xl={5} style={{ paddingTop: '5px', paddingRight: '0px', textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                            <div>
                                                Do you have any Specific needs in relation to the following:<br/>
                                                Please leave these boxes blank if you don't have needs in that specific area
                                            </div>
                                        </Col>
                                        <Col xs={12} md={6} xl={6} style={{ paddingLeft: '0px', textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-left', alignItems: 'flex-left' }}>
                                            <Row>
                                                <Col xs={12} md={12} xl={12} style={{ paddingLeft: '20px', textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-left', alignItems: 'flex-left' }}>
                                                    <input
                                                        id="textInput"
                                                        className="inputText"
                                                        type="text"
                                                        name="textInput"
                                                        placeholder="Literacy"
                                                        value={formData.inputTextVal}
                                                        onChange={handleInputChange}
                                                        title="This is used the best Phone
                                                                Number to call them on"
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                            <Col xs={12} md={12} xl={12} style={{ paddingLeft: '20px', textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-left', alignItems: 'flex-left' }}>
                                                <input
                                                    id="textInput"
                                                    className="inputText"
                                                    type="text"
                                                    name="textInput"
                                                    placeholder="Diet"
                                                    value={formData.inputTextVal}
                                                    onChange={handleInputChange}
                                                    title="This is used the best Phone
                                                                Number to call them on"
                                                />
                                            </Col>
                                        </Row>
                                            <Row>
                                            <Col xs={12} md={12} xl={12} style={{ paddingLeft: '20px', textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-left', alignItems: 'flex-left' }}>
                                                <input
                                                    id="textInput"
                                                    className="inputText"
                                                    type="text"
                                                    name="textInput"
                                                    placeholder="Access"
                                                    value={formData.inputTextVal}
                                                    onChange={handleInputChange}
                                                    title="This is used the best Phone
                                                                Number to call them on"
                                                />
                                            </Col>
                                        </Row>
                                        </Col>
                                    </Row>
                                    <Row style={{paddingTop: '15px'}}>
                                        <Col xs={12} md={12} xl={12} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                            <div>
                                                Do you consider yourself to have any of the following challenges, impairments or long-term conditions?
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row style={{paddingTop: '5px'}}>
                                        <Col xs={6} md={4} xl={2} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                            <div>
                                                <input
                                                    type="checkbox"
                                                    name="Hearing"
                                                    // value={formData.venueHousekeepingVal}
                                                    // defaultChecked="unchecked"
                                                    onChange={handleInputChange}
                                                    title="This is to let us know if you have a diagnosed hearing
                                                            impediment or require help with alterative delivery
                                                            methods than auditory?"
                                                />
                                                Hearing
                                            </div>
                                        </Col>
                                        <Col xs={6} md={4} xl={2} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                            <div>
                                                <input
                                                    type="checkbox"
                                                    name="Physical"
                                                    // value={formData.venueHousekeepingVal}
                                                    // defaultChecked="unchecked"
                                                    checked={chkPhysical}
                                                    onChange={handleInputChange}
                                                    title="This is to let us know if you have trouble physically
                                                            accessing services.  As an example you may need access
                                                            to wheel chair facilities."
                                                />
                                                Physical
                                            </div>
                                        </Col>
                                        <Col xs={6} md={4} xl={2} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                            <div>
                                                <input
                                                    type="checkbox"
                                                    name="Intellectual"
                                                    // value={formData.venueHousekeepingVal}
                                                    // defaultChecked="unchecked"
                                                    onChange={handleInputChange}
                                                    title="This is to let us know if you have a diagnosed intellectual
                                                            disability that may require specific help to overcome."
                                                />
                                                Intellectual
                                            </div>
                                        </Col>
                                        <Col xs={6} md={4} xl={2} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                            <div>
                                                <input
                                                    type="checkbox"
                                                    name="Learning"
                                                    value={formData.venueHousekeepingVal}
                                                    defaultChecked="unchecked"
                                                    onChange={handleInputChange}
                                                    title="This is to let us know if you have a diagnosed Learning
                                                            impairment that may require alternative delivery types
                                                            to support your learning."
                                                />
                                                Learning
                                            </div>
                                        </Col>
                                        <Col xs={6} md={4} xl={2} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                            <div>
                                                <input
                                                    type="checkbox"
                                                    name="Mental Illness"
                                                    value={formData.venueHousekeepingVal}
                                                    defaultChecked="unchecked"
                                                    onChange={handleInputChange}
                                                    title="This is to let us know if you have a mental
                                                            illness that may require alternative delivery
                                                            types to support your learning."
                                                />
                                                Mental Illness
                                            </div>
                                        </Col>
                                        <Col xs={6} md={2} xl={2} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                            <div>
                                                <input
                                                    type="checkbox"
                                                    name="Vision"
                                                    value={formData.venueHousekeepingVal}
                                                    defaultChecked="unchecked"
                                                    onChange={handleInputChange}
                                                    title="This is to let us know if you have a problem with
                                                            your vision that we may have to help support
                                                            you with alternative delivery methods."
                                                />
                                                Vision
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col xs={6} md={2} xl={2} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                            <div>
                                                <input
                                                    type="checkbox"
                                                    name="Medical Condition"
                                                    value={formData.venueHousekeepingVal}
                                                    defaultChecked="unchecked"
                                                    onChange={handleInputChange}
                                                    title="This is to let us know if you have a diagnosed
                                                            Medical Condition that may require us to alter
                                                            your training experience to help support you."
                                                />
                                                Medical Condition
                                            </div>
                                        </Col>
                                        <Col xs={6} md={2} xl={2} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                            <div>
                                                <input
                                                    type="checkbox"
                                                    name="Aquired Brain Impairment"
                                                    value={formData.venueHousekeepingVal}
                                                    defaultChecked="unchecked"
                                                    onChange={handleInputChange}
                                                    title="This is to let us know if you have a diagnosed
                                                            Brain Imparement that may require us to alter
                                                            your training experience to help support you."
                                                />
                                                Aquired Brain Impairment
                                            </div>
                                        </Col>
                                        <Col xs={6} md={8} xl={8} style={{textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>

                                            <Row>
                                                <Col xs={12} md={5} xl={3} style={{textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                    <div>
                                                        Other (please specify)
                                                    </div>
                                                </Col>
                                                <Col xs={12} md={7} xl={9} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                <div>
                                                        <input
                                                            type="text"
                                                            name="Medical Condition"
                                                            value={formData.venueWebVal}
                                                            placeholder="How can we help support your training"
                                                            onChange={handleInputChange}
                                                            title="Let us know how we can help support your journey.
                                                                    Please remember that this is about supporting
                                                                    your learning, not nessecarily just making
                                                                    your life easy.  :)"
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col>

                                        </Col>
                                    </Row>
                                </BorderedComponent>
                            </Row><Row style={{paddingTop: '15px', paddingRight: '40px', paddingLeft: '13px' }}>
                            <BorderedComponent style={{margin: '0px', paddingTop:'15px'}} name="Current School Status">
                                <Row>
                                    <Col xs={12} md={8} xl={8} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                        <div>
                                            Are you still attending Secondary School
                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{ paddingTop: '15px'}}>
                                    <Col xs={12} md={6} xl={6} style={{ paddingTop: '5px', paddingRight: '0px', textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                        <div>
                                            What is your Queensland Education Learner Unique Identifier?
                                        </div>
                                    </Col>
                                    <Col xs={12} md={3} xl={3} style={{ paddingLeft: '0px', textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-left', alignItems: 'flex-left' }}>

                                        {/*<label htmlFor="textInput">Work</label>*/}
                                        <input
                                            id="textInput"
                                            className="inputText"
                                            type="text"
                                            name="textInput"
                                            placeholder="LUI assigned by Qld Education "
                                            value={formData.inputTextVal}
                                            onChange={handleInputChange}
                                            title="At some stage stage through Grade 10 in Queensland you are created a Learner Unique ID that is used to ensure that all training you undergo is available to be used for your university entry "
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={6} md={4} xl={4} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                        <div style={{paddingTop: '5px'}}>
                                            What is your highest completed School Level?
                                        </div>
                                    </Col>
                                    <Col xs={6} md={3} xl={3} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                        <select
                                            id="HighestYearLevel"
                                            name="Highest Year Level"
                                            onChange={handleInputChange}
                                            title="This is the Highest School Year you have completed.
                                                        If you are currently studying Year 11 then your
                                                        Highset Completed Year will be year 10."
                                        >
                                            <option value="8">Completed Year 8</option>
                                            <option value="9">Completed Year 9</option>
                                            <option value="10">Completed Year 10</option>
                                            <option value="11">Completed Year 11</option>
                                            <option value="12">Completed Year 12</option>
                                        </select>
                                    </Col>
                                    <Col xs={12} md={2} xl={2} style={{ textAlign: 'right', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                        <div style={{paddingTop: '5px'}}>Year Completed?</div>
                                    </Col>
                                    <Col xs={12} md={2} xl={2} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>

                                        {/*<label htmlFor="textInput">Work</label>*/}
                                        <input
                                            id="textInput"
                                            className="inputText"
                                            type="text"
                                            name="textInput"
                                            placeholder="Year Completed"
                                            value={formData.inputTextVal}
                                            onChange={handleInputChange}
                                            title="This is used the best Phone
                                                                Number to call them on"
                                        />
                                    </Col>
                                </Row>
                                <Row style={{paddingTop: '15px'}}>
                                    <Col xs={12} md={12} xl={12} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                        <div>
                                            Have you completed any of the following qualifications in the past?
                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{paddingTop: '5px'}}>
                                    <Col xs={6} md={4} xl={2} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                        <div>
                                            <input
                                                type="checkbox"
                                                name="Certificate 1"
                                                value={formData.venueHousekeepingVal}
                                                defaultChecked="unchecked"
                                                onChange={handleInputChange}
                                                title="If you have completed a Certificate 1
                                                        please tick this box."
                                            />
                                            Certificate_1
                                        </div>
                                    </Col>
                                    <Col xs={6} md={4} xl={2} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                        <div>
                                            <input
                                                type="checkbox"
                                                name="Certificate 2"
                                                value={formData.venueHousekeepingVal}
                                                defaultChecked="unchecked"
                                                onChange={handleInputChange}
                                                title="If you have completed a Certificate 2
                                                        please tick this box"
                                            />
                                            Certificate_2
                                        </div>
                                    </Col>
                                    <Col xs={6} md={4} xl={2} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                        <div>
                                            <input
                                                type="checkbox"
                                                name="Certificate 3"
                                                value={formData.venueHousekeepingVal}
                                                defaultChecked="unchecked"
                                                onChange={handleInputChange}
                                                title="If you have completed a Certificate 3
                                                        please tick this box"
                                            />
                                            Certificate_3
                                        </div>
                                    </Col>
                                    <Col xs={6} md={4} xl={2} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                        <div>
                                            <input
                                                type="checkbox"
                                                name="Certificate 4"
                                                value={formData.venueHousekeepingVal}
                                                defaultChecked="unchecked"
                                                onChange={handleInputChange}
                                                title="If you have completed a Certificate 4
                                                        please tick this box"
                                            />
                                            Certificate_4
                                        </div>
                                    </Col>
                                    <Col xs={6} md={4} xl={2} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                        <div>
                                            <input
                                                type="checkbox"
                                                name="Diploma"
                                                value={formData.venueHousekeepingVal}
                                                defaultChecked="unchecked"
                                                onChange={handleInputChange}
                                                title="If you have completed a Diploma
                                                        please tick this box"
                                            />
                                            Diploma
                                        </div>
                                    </Col>
                                    <Col xs={6} md={4} xl={2} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                        <div>
                                            <input
                                                type="checkbox"
                                                name="Advanced Diploma"
                                                value={formData.venueHousekeepingVal}
                                                defaultChecked="unchecked"
                                                onChange={handleInputChange}
                                                title="If you have completed an Advance Diploma
                                                        please tick this box"
                                            />
                                            Advanced Diploma
                                        </div>
                                    </Col>
                                </Row>
                                <Row >
                                    <Col xs={6} md={4} xl={4} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                        <div>
                                            <input
                                                type="checkbox"
                                                name="Bachelor degree or higher"
                                                value={formData.venueHousekeepingVal}
                                                defaultChecked="unchecked"
                                                onChange={handleInputChange}
                                                title="If you have completed a Bachelor Degree
                                                        or higher please tick this box."
                                            />
                                            Bachelor degree or higher
                                        </div>
                                    </Col>
                                    <Col xs={6} md={8} xl={8} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                       <Row>
                                            <Col xs={12} md={5} xl={3} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                <div>
                                                    Other (please specify)
                                                </div>
                                            </Col>
                                            <Col xs={12} md={7} xl={9} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                <div>
                                                    <input
                                                        type="text"
                                                        name="venueWeb"
                                                        value={formData.venueWebVal}
                                                        placeholder="Type in your completed qualification "
                                                        onChange={handleInputChange}
                                                        title="This is the web site for the venue"
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>

                                    </Col>
                                </Row>
                            </BorderedComponent>
                        </Row>


                        </form>
                    </div>




                    <div>



                    </div>


                    {/* =========================================================== TAB 3 ======================================================================*/}

                    <div id="Tab 3" className="tabcontent">
                        <form onSubmit={handleSubmit}>
                            <Row style={{ paddingTop: '25px'}}>
                                <Col xs={12} md={4} xl={3} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                    <h1 style={{height: '100%'}}>QRcode</h1>
                                </Col>
                                <Col xs={12} md={8} xl={9} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                <Row >
                                    <Col xs={12} md={8} xl={4} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                        <label htmlFor="comboBox">To add an Employer or Supervisor type it in to search here or get them to log into their account and scan this QR code from the app</label>
                                    </Col>
                                    <Col xs={12} md={8} xl={8} style={{ paddingTop: '15px', textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                        <Select
                                            id="comboBox"
                                            options={options}
                                            styles={customSelectStyles}
                                            isClearable
                                            isSearchable
                                            placeholder="Select or type..."
                                            title="This is where you type either a Company name or a
                                                    Supervisors name and you can include them on your
                                                    profile.  This can be good especially if you want
                                                    to share your training history with them."
                                            // required
                                        />
                                    </Col>
                                </Row>
                                </Col>
                            </Row>
                            <Row style={{marginTop: 50, paddingRight: '25px' }}>
                                <BorderedComponent name="Employment">
                                    <Col xs={12} md={12} xl={12} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                        <table>
                                            <thead >
                                            <tr className={'tr2'} >
                                                <th>Employer</th>
                                                <th>Current Role</th>
                                                <th>Requester</th>
                                                <th>DateTime Requested</th>
                                                <th>DateTime finished</th>
                                                <th>email competencies</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {studentList.map((row, index) => (
                                                <React.Fragment key={index}>
                                                    <tr  onClick={() => toggleAccordion(index)} key={index} className={index % 2 === 0 ? 'dark-grey' : 'light-grey'}>
                                                        <td>{row.employer}</td>
                                                        <td>{row.currentRole}</td>
                                                        <td>{row.requester}</td>
                                                        <td>{row.requested}</td>
                                                        <td>{row.finished}</td>
                                                        <td>{row.emailCompletion}</td>
                                                    </tr>

                                                </React.Fragment>
                                            ))}
                                            </tbody>
                                        </table>
                                    </Col>
                                </BorderedComponent>
                            </Row>
                            <Row style={{marginTop: 50, paddingRight: '25px' }}>
                                <BorderedComponent name="Supervisor">
                                    <Col xs={12} md={12} xl={12} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                        <table>
                                            <thead >
                                            <tr className={'tr2'} >
                                                <th>Supervisor</th>
                                                <th>Mobile Num</th>
                                                <th>Requester</th>
                                                <th>DateTime Requested</th>
                                                <th>DateTime finished</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {studentList.map((row, index) => (
                                                <React.Fragment key={index}>
                                                    <tr  onClick={() => toggleAccordion(index)} key={index} className={index % 2 === 0 ? 'dark-grey' : 'light-grey'}>
                                                        <td>{row.Supervisor}</td>
                                                        <td>{row.SupervisorNum}</td>
                                                        <td>{row.requester}</td>
                                                        <td>{row.requested}</td>
                                                        <td>{row.finished}</td>
                                                    </tr>

                                                </React.Fragment>
                                            ))}
                                            </tbody>
                                        </table>
                                    </Col>
                                </BorderedComponent>
                            </Row>
                            <Row style={{marginTop: '15px'}}>
                                <Col xs={12} md={12} xl={6} style={{textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                    <BorderedComponent name="Which BEST describes your current employment status?">
                                        <Row>
                                            <Col xs={12} md={12} xl={12} style={{textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                <div>
                                                    {selectedEmploymentStatus.filter(Boolean).map((status, index) => ( // "", false, null
                                                        <div key={index} className="radio-container">
                                                            <input
                                                                type="radio"
                                                                name="employmentStatus"
                                                                className="radio-input"
                                                                // your attributes here, be they commented or not
                                                                // value={status}     // <---- If you use these 3 without a db connection they will prevent you from making a selection
                                                                // checked={selectedEmploymentStatus === status}  // <----^
                                                                // onChange={handleEmploymentStatusChange}        // <----^
                                                            />
                                                            <span className="radio-label">{status}</span>
                                                        </div>
                                                    ))}
                                                    {/*{selectedEmploymentStatus && <p> Selected: {selectedEmploymentStatus}</p>}*/}
                                                </div>
                                            </Col>
                                        </Row>
                                    </BorderedComponent>
                                </Col>
                                <Col xs={12} md={12} xl={6} style={{textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                    <BorderedComponent name="Which BEST describes why you are undertaking training?">
                                        <Col xs={12} md={12} xl={12} style={{textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                            {selectedTrainingReason.filter(Boolean).map((status, index) => ( // "", false, null
                                                <div key={index} className="radio-container">
                                                    <input
                                                        type="radio"
                                                        name="TrainingReason"
                                                        className="radio-input"
                                                        // your attributes here, be they commented or not
                                                        // value={status}     // <---- If you use these 3 without a db connection they will prevent you from making a selection
                                                        // checked={selectedEmploymentStatus === status}  // <----^
                                                        // onChange={handleEmploymentStatusChange}        // <----^
                                                    />
                                                    <span className="radio-label">{status}</span>
                                                </div>
                                            ))}
                                        </Col>
                                    </BorderedComponent>
                                </Col>
                            </Row>
                        </form>

                        <Row>
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', padding: 25}}>
                                <button type="button" onClick={() => handleChangeTab('Tab 1')} className={"btn-primary"}>BACK</button>
                                <button type="submit"  className={"btn-primary"}>NEXT</button>
                            </div>
                        </Row>
                    </div>

                    {/* =========================================================== TAB 4 ======================================================================*/}

                    <div id="Tab 4" className="tabcontent">
                        <form onSubmit={handleSubmit}>
                            <Row style={{ paddingTop: '25px'}}>
                                <Col xs={12} md={6} xl={6} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                    <BorderedComponent name="Send your Authorisations List">
                                        <Row>
                                            <Col xs={12} md={12} xl={6} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                <h1 style={{height: '100%'}}>QRcode</h1>
                                            </Col>
                                            <Col xs={12} md={12} xl={6} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                <Row >
                                                    <Col xs={12} md={12} xl={12} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                        <label htmlFor="comboBox">To provide a list of your Authorisations get them to scan this QR code or alternatively type an email into the box provided then hit send and it will be emailed out on your behalf.</label>
                                                    </Col>
                                                    <Col xs={12} md={12} xl={12} style={{ paddingTop: '15px', textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                        <input
                                                            id="authorisationsEmail"
                                                            type="email"
                                                            name="authorisationsEmail"
                                                            placeholder="email to send your authorisations to"
                                                            onChange={handleInputChange}
                                                            title="This is used to email someone all of the
                                                                modules you are currently authorised to
                                                                train in."
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </BorderedComponent>
                                </Col>
                                <Col xs={12} md={6} xl={6} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                <BorderedComponent name="Share your video Link">
                                    <Row>
                                        <Col xs={8} md={8} xl={8} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                            <input
                                                id="videoLink"
                                                type="text"
                                                name="videoLink"
                                                placeholder="type the link address here"
                                                onChange={handleInputChange}
                                                title="This is used to share the video link
                                                you use for delivery with your students.
                                                It will be made available to them it heir
                                                study section under the certificate you are
                                                delivering to them."
                                            />
                                        </Col>
                                        <Col xs={4} md={4} xl={4} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                            <input
                                                id="videoLinkCode"
                                                type="text"
                                                name="videoLinkCode"
                                                placeholder="Passcode"
                                                onChange={handleInputChange}
                                                title="This is used for if you set a passcode for
                                                    access to your personal video chanel.  You
                                                    can change this periodically if you want
                                                    to keep older contacts out."
                                            />
                                        </Col>
                                    </Row>
                                    <Row style={{paddingBottom: '15px'}}>
                                        <Col xs={12} md={12} xl={12} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                        <label htmlFor="videoLinkEmail">To provide a link to your students for your personal video conference room please put it in the box under video link here.  You can also type in an email address to send that link out to someone from you.</label>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={12} xl={12} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                        <input
                                                id="videoLinkEmail"
                                                type="email"
                                                name="videoLinkEmail"
                                                placeholder="email to send your video link to"
                                                onChange={handleInputChange}
                                                title="This sends an email invitation to your
                                                        personal video room where they can just
                                                        click on the link and they are in."
                                        />
                                        </Col>
                                    </Row>
                                </BorderedComponent>
                            </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={12} xl={12} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                    <BorderedComponent name="Modules Authorised to train in">
                                        <Row style={{marginTop: 50 }}>
                                            <table>
                                                <thead >
                                                <tr className={'tr2'} >
                                                    <th>Code</th>
                                                    <th>Module Name</th>
                                                    <th>Evidence</th>
                                                    <th>Date Approved</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {studentList.map((row, index) => (
                                                    <React.Fragment key={index}>
                                                        <tr  onClick={() => toggleAccordion(index)} key={index} className={index % 2 === 0 ? 'dark-grey' : 'light-grey'}>
                                                            <td>{row.moduleCode}</td>
                                                            <td>{row.moduleName}</td>
                                                            <td>{row.evidenceLink}</td>
                                                            <td>{row.dateApproved}</td>  {/*=====================I would like a button to be here that says "Approve Now" if it hasn't already been approved, then it brings up the authorisation QR Code if they don't have permissions*/}
                                                        </tr>
                                                    </React.Fragment>
                                                ))}
                                                </tbody>
                                            </table>
                                        </Row>
                                        <Row style={{paddingTop: '15px'}}>
                                            <Col xs={3} md={3} xl={3} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                <button type="submit" className={"btn-primary"} style={{width: '100%'}}>Request a Module</button>  {/*==================then once a module has been selected it should list the requested module in the button instead of saying "Request a Module"*/}
                                            </Col>
                                            <Col xs={3} md={3} xl={3} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                {/*<label htmlFor="interestedEmail">Evidence ID</label>*/}
                                                <input
                                                    id="evidenceID"
                                                    type="email"
                                                    name="evidenceID"
                                                    placeholder="evidence ID number"
                                                    onChange={handleInputChange}
                                                    title="This is used to show which of the uploaded
                                                            evidence pieces are being used to justify
                                                            being able to be authorised in this module."
                                                />
                                            </Col>
                                            <Col xs={3} md={3} xl={3} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                {/*<label htmlFor="interestedEmail">Evidence ID</label>*/}
                                                <input
                                                    id="statementID"
                                                    type="email"
                                                    name="statementID"
                                                    placeholder="SOA number for evidence"
                                                    onChange={handleInputChange}
                                                    title="This is used for when you have completed an
                                                            SOA internally and you want to use that as
                                                            justification. "
                                                />
                                            </Col>
                                            <Col xs={3} md={3} xl={3} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                <button type="submit" className={"btn-primary"} style={{width: '100%'}}>Submit Request</button>  {/*==================then once a module has been selected it should list the requested module in the button instead of saying "Request a Module"*/}
                                            </Col>
                                        </Row>
                                    </BorderedComponent>
                                </Col>

                            </Row>
                            <Row style={{ paddingTop: '25px'}}>
                                <Col xs={12} md={6} xl={6} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                <BorderedComponent name="Authorised Admin support people">
                                    <Row style={{marginTop: 50 }}>
                                        <table>
                                            <thead >
                                            <tr className={'tr2'} >
                                                <th>Name</th>
                                                <th>Mobile</th>
                                                <th>email
                                                    Certs</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {studentList.map((row, index) => (
                                                <React.Fragment key={index}>
                                                    <tr  onClick={() => toggleAccordion(index)} key={index} className={index % 2 === 0 ? 'dark-grey' : 'light-grey'}>
                                                        <td>{row.name}</td>
                                                        <td>{row.mobile}</td>
                                                        <td>email icon</td>
                                                        <td>bin</td>
                                                    </tr>
                                                </React.Fragment>
                                            ))}
                                            </tbody>
                                        </table>
                                    </Row>
                                </BorderedComponent>
                                </Col>
                                <Col xs={12} md={6} xl={6} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                    <BorderedComponent name="QA grace periods (in Days)">
                                        <Row>
                                            <Col xs={6} md={6} xl={4} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                <div>Trainer Grace Period</div>
                                            </Col>
                                            <Col xs={6} md={6} xl={8} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                <input
                                                    id="gracePeriod"
                                                    className="inputText"
                                                    type="text"
                                                    name="gracePeriod"
                                                    placeholder="days to submit paperwork"
                                                    onChange={handleInputChange}
                                                    title="This is the number of days you have to
                                                            submit your paperwork before the system
                                                            stops you from marking anyone else
                                                            competent.  If you have been locked
                                                            out please call your supervisor to get
                                                            this changed or a temporary extension
                                                            applied."
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={6} md={6} xl={4} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                <div>Temporary Extension</div>
                                            </Col>
                                            <Col xs={6} md={6} xl={8} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                <input
                                                    id="extensionPeriod"
                                                    className="inputText"
                                                    type="text"
                                                    name="extensionPeriod"
                                                    placeholder="Extra days needed"
                                                    onChange={handleInputChange}
                                                    title="This is the number of days you want to
                                                            get your self up to date while still
                                                            being able to get your old paperwork
                                                            in.  Each ofthese will require someone
                                                            of sufficient authority to allow it
                                                            to happen."
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={6} md={6} xl={4} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                <div>Exemption</div>
                                            </Col>
                                            <Col xs={6} md={6} xl={8} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                <button type="submit"
                                                        className={"btn-primary"}
                                                        style={{width: '100%'}}
                                                        title="This is to request an exemption from
                                                        having to submit paperwork.  All responsibility
                                                        for your paperwork will be placed onto the
                                                        Authoriser so you better have a REALLY GOOD
                                                        relationship with them and
                                                        not break their trust!"
                                                >Request Exemption</button>

                                            </Col>
                                        </Row>

                                    </BorderedComponent>
                                </Col>

                            </Row>
                            <Row style={{paddingRight: '50px', paddingTop: '15px', paddingLeft: '12px'}}>
                                <BorderedComponent name="Accuracy Data">
                                    <Row>
                                        <Col xs={6} md={2} xl={2} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                            <div>Moderation Percentage</div>
                                        </Col>
                                        <Col xs={6} md={2} xl={2} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                            <input
                                                id="extensionPeriod"
                                                className="inputText"
                                                type="text"
                                                name="extensionPeriod"
                                                placeholder="% of modules"
                                                onChange={handleInputChange}
                                                title="This is the percentage of modules that will
                                                        be sent to QA based on how long they have
                                                        been with the company and how many mistakes
                                                        have been made recently."
                                            />
                                        </Col>
                                        <Col xs={12} md={6} xl={6} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                        {/*</Row> */}
                                            <Row>
                                                <Col xs={6} md={6} xl={6} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                    <div>Trained Modules: </div>
                                                </Col>
                                                <Col xs={6} md={6} xl={6} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                    <div>Trained Certificates: </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                            <Row>
                                                <Col xs={12} md={12} xl={12} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                    <button type="submit" className={"btn-primary"} style={{width: '70%', }}>Assign Roles</button>  {/*==================This should only be visible for someone with Super User.  That is why it is an unusual and annoying size*/}

                                                </Col>
                                            </Row>

                                    </Row>
                                </BorderedComponent>
                            </Row>
                            <Row style={{paddingRight: '50px', paddingTop: '15px', paddingLeft: '12px'}}>
                                <BorderedComponent name="Trainer Required Documents">
                                    <Row>
                                    <Col xs={12} md={6} xl={6} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>

                                    <Row>
                                        <Col xs={4} md={3} xl={3} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                            <Row>
                                                <Col xs={10} md={6} xl={6} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                    <div>Current Training Qualifications eg. TAE40116</div>
                                                </Col>

                                            </Row>
                                        </Col>
                                        <Col xs={8} md={9} xl={9} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                            <Upload/>
                                        </Col>
                                        <Col xs={12} md={6} xl={6} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                            {/*</Row> */}
                                            <Row>

                                            </Row>
                                        </Col>
                                    </Row>
                                    </Col>
                                    <Col xs={12} md={6} xl={6} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                        <Row>
                                            <Col xs={4} md={3} xl={3} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                <Row>
                                                    <Col xs={10} md={6} xl={6} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                        <div>Industry Currency eg. Resume</div>
                                                    </Col>

                                                </Row>
                                            </Col>
                                            <Col xs={8} md={9} xl={9} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                <Upload/>
                                            </Col>
                                            {/*<Col xs={12} md={6} xl={6} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>*/}

                                            {/*</Col>*/}
                                        </Row>
                                    </Col>
                                    </Row>

                                </BorderedComponent>
                            </Row>

                        </form>
                    </div>
                    {/* =========================================================== TAB 5 ======================================================================*/}

                    <div id="Tab 5" className="tabcontent">
                        <Row style={{ paddingTop: '25px'}}>
                            <Col xs={12} md={4} xl={3} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                <h1 style={{height: '100%'}}>QRcode</h1>
                            </Col>
                            <Col xs={12} md={8} xl={9} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                <Row >
                                    <Col xs={12} md={8} xl={4} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                        <label htmlFor="comboBox">To give someone this list of your competencies and achievements you can either get them to scan this QR code or type their email address in and hit send.</label>
                                    </Col>
                                    <Col xs={12} md={8} xl={8} style={{ paddingTop: '15px', textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                        <Select
                                            id="comboBox"
                                            options={options}
                                            styles={customSelectStyles}
                                            isClearable
                                            isSearchable
                                            placeholder="email to send your competencies to"
                                            title="This is to make it easy to share all of your
                                                    competencies with a prospective employer or
                                                    other education providers.  If you just type
                                                    the email address in and hit send it will
                                                    automatically give them access to all of
                                                    the competencies you have uploaded here."
                                            // required
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row style={{paddingTop: '50px'}}>
                            <div>
                                <h2>Completed Certificates</h2>
                            </div>
                        </Row>
                        <Row style={{marginTop: 10 }}>
                            <table>
                                <thead >
                                <tr className={'tr2'} >
                                    <th>Cert Code</th>
                                    <th>Cert Name</th>
                                    <th>1st Module</th>
                                    <th>Date Completed</th>
                                    <th>DL</th>
                                </tr>
                                </thead>
                                <tbody>
                                {studentList.map((row, index) => (
                                    <React.Fragment key={index}>
                                        <tr  onClick={() => toggleAccordion(index)} key={index} className={index % 2 === 0 ? 'dark-grey' : 'light-grey'}>
                                            <td>{row.heading}</td>
                                            <td>{row.mobile}</td>
                                            <td>{row.billing}</td>
                                            <td>{row.usi}</td>
                                            <td>{row.email}</td>
                                        </tr>
                                        {expandedRows[index] && (
                                            <tr >
                                                <td colSpan={12} style={{paddingTop: 0, padding: 0}} >
                                                    <table style={{paddingTop: 5, border: 0, width: '100%', paddingLeft: 0, paddingRight: 0}} key={index} className={index % 2 === 0 ? 'dark-grey' : 'light-grey'}>
                                                        <thead className={'tr2'}  >
                                                        <tr className={'tr2'}  >
                                                            <th style={{paddingLeft: 100, width: '20%'}} >Code</th> {/*Should Link to the personal module page for this module*/}
                                                            <th style={{textAlign: 'left', paddingLeft: 50, width: '50%'}} >Module Name</th>  {/*Should Link to the personal module page for this module*/}
                                                            <th style={{textAlign: 'left', paddingLeft: 50, width: '10%'}} >Date Achieved</th>  {/*This should be a link to the Class the module is under*/}
                                                            <th style={{textAlign: 'left', paddingLeft: 50, width: '5%'}} >Class</th>  {/*This should be a link to the Class the module is under*/}
                                                            <th style={{textAlign: 'left', paddingLeft: 50, width: '5%', paddingRight: '15px'}} >DL</th> {/*Should Link to the SOA for this particular module*/}
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {row.extraLineItems.map((item) => (
                                                            <tr style={{paddingTop: 2, paddingBottom: 2}}>
                                                                <td style={{paddingLeft: 100, width: '20%'}} >{item.code}</td>
                                                                <td style={{textAlign: 'left', paddingLeft: 50, width: "40%"}} >{item.name}</td>
                                                                <td style={{textAlign: 'left', paddingLeft: 50, width: '40%'}} >{item.evidence}</td>
                                                            </tr>
                                                        ))}
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        )}
                                    </React.Fragment>
                                ))}
                                </tbody>
                            </table>
                        </Row>
                        <Row style={{paddingTop: '50px'}}>
                            <div>
                                <h2>Completed Modules</h2>
                            </div>
                        </Row>

                        <Row style={{marginTop: 10 }}>
                            <table>
                                <thead >
                                <tr className={'tr2'} >
                                    <th>Module Code</th>
                                    <th>Module Name</th>
                                    <th>Date Completed</th>
                                    <th>DL</th>
                                </tr>
                                </thead>
                                <tbody>
                                {studentList.map((row, index) => (
                                    <React.Fragment key={index}>
                                        <tr  onClick={() => toggleAccordion(index)} key={index} className={index % 2 === 0 ? 'dark-grey' : 'light-grey'}>
                                            <td>{row.heading}</td>  {/*This should link to view this particular SOA*/}
                                            <td>{row.mobile}</td>  {/*This should link to view this particular SOA*/}
                                            <td>{row.billing}</td>  {/*This should link to the class that this module was delivered under*/}
                                            <td>{row.usi}</td>  {/*This should download this particular SOA*/}
                                        </tr>

                                        {/*)}*/}
                                    </React.Fragment>
                                ))}
                                </tbody>
                            </table>
                        </Row>
                        <Row style={{paddingRight: '50px', paddingTop: '50px', paddingLeft: '12px'}}>
                            <BorderedComponent name="Licences">
                                <Row>
                                    <Col xs={12} md={12} xl={6} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>

                                        <Row>
                                            <Col xs={4} md={3} xl={3} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                <Row>
                                                    <Col xs={10} md={6} xl={6} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                        <div>Mining Induction</div>
                                                    </Col>
                                                    {/*<Row>*/}
                                                        <Col xs={12} md={12} xl={12} style={{ paddingTop: '25px', paddingLeft: '20px', textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-left', alignItems: 'flex-left' }}>
                                                            <input
                                                                id="textInput"
                                                                className="inputText"
                                                                type="text"
                                                                name="textInput"
                                                                placeholder="Card #"
                                                                value={formData.inputTextVal}
                                                                onChange={handleInputChange}
                                                                title="This is used the best Phone
                                                                Number to call them on"
                                                            />
                                                        </Col>
                                                    {/*</Row>*/}
                                                </Row>
                                            </Col>
                                            <Col xs={8} md={9} xl={9} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                <Upload/>
                                            </Col>
                                            <Col xs={12} md={6} xl={6} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                {/*</Row> */}
                                                <Row>

                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12} md={12} xl={6} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                        <Row>
                                            <Col xs={4} md={3} xl={3} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                <Row>
                                                    <Col xs={10} md={6} xl={6} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                        <div>Construction Industry White Card</div>
                                                    </Col>
                                                    <Col xs={12} md={12} xl={12} style={{ paddingTop: '25px',paddingLeft: '20px', textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-left', alignItems: 'flex-left' }}>
                                                        <input
                                                            id="textInput"
                                                            className="inputText"
                                                            type="text"
                                                            name="textInput"
                                                            placeholder="Card #"
                                                            value={formData.inputTextVal}
                                                            onChange={handleInputChange}
                                                            title="This is used the best Phone
                                                                Number to call them on"
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs={8} md={9} xl={9} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                <Upload/>
                                            </Col>
                                            {/*<Col xs={12} md={6} xl={6} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>*/}

                                            {/*</Col>*/}
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={12} xl={6} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>

                                        <Row>
                                            <Col xs={4} md={3} xl={3} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                <Row>
                                                    <Col xs={10} md={6} xl={6} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                        <div>High Risk Work Licence</div>
                                                    </Col>
                                                    <Col xs={12} md={12} xl={12} style={{ paddingTop: '25px', paddingLeft: '20px', textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-left', alignItems: 'flex-left' }}>
                                                        <input
                                                            id="textInput"
                                                            className="inputText"
                                                            type="text"
                                                            name="textInput"
                                                            placeholder="Card #"
                                                            value={formData.inputTextVal}
                                                            onChange={handleInputChange}
                                                            title="This is used the best Phone
                                                                Number to call them on"
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs={8} md={9} xl={9} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                <Upload/>
                                            </Col>
                                            <Col xs={12} md={6} xl={6} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                {/*</Row> */}
                                                <Row>

                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12} md={12} xl={6} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                        <Row>
                                            <Col xs={4} md={3} xl={3} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                <Row>
                                                    <Col xs={10} md={6} xl={6} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                        <div>Rail Industry Worker Card</div>
                                                    </Col>
                                                    <Col xs={12} md={12} xl={12} style={{ paddingTop: '25px', paddingLeft: '20px', textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-left', alignItems: 'flex-left' }}>
                                                        <input
                                                            id="textInput"
                                                            className="inputText"
                                                            type="text"
                                                            name="textInput"
                                                            placeholder="Card #"
                                                            value={formData.inputTextVal}
                                                            onChange={handleInputChange}
                                                            title="This is used the best Phone
                                                                Number to call them on"
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs={8} md={9} xl={9} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                <Upload/>
                                            </Col>
                                            {/*<Col xs={12} md={6} xl={6} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>*/}

                                            {/*</Col>*/}
                                        </Row>
                                    </Col>
                                </Row>
                            </BorderedComponent>
                        </Row>
                    </div>

                    {/* =========================================================== TAB 6 ======================================================================*/}

                    <div id="Tab 6" className="tabcontent">
                        <Row style={{paddingTop: '50px'}}>
                            <div>
                                <h2>Certificates in progress</h2>
                            </div>
                        </Row>
                        <Row style={{marginTop: 10 }}>
                            <table>
                                <thead >
                                <tr className={'tr2'} >
                                    <th>Cert Code</th>
                                    <th>Cert Name</th>
                                    <th>1st Module</th>
                                    <th>Date Completed</th>
                                    <th>Link</th>
                                </tr>
                                </thead>
                                <tbody>
                                {studentList.map((row, index) => (
                                    <React.Fragment key={index}>
                                        <tr  onClick={() => toggleAccordion(index)} key={index} className={index % 2 === 0 ? 'dark-grey' : 'light-grey'}>
                                            <td>{row.heading}</td>
                                            <td>{row.mobile}</td>
                                            <td>{row.billing}</td>
                                            <td>{row.usi}</td>
                                            <td>{row.email}</td>
                                        </tr>
                                        {expandedRows[index] && (
                                            <tr >
                                                <td colSpan={12} style={{paddingTop: 0, padding: 0}} >
                                                    <table style={{paddingTop: 5, border: 0, width: '100%', paddingLeft: 0, paddingRight: 0}} key={index} className={index % 2 === 0 ? 'dark-grey' : 'light-grey'}>
                                                        <thead className={'tr2'}  >
                                                        <tr className={'tr2'}  >
                                                            <th style={{paddingLeft: 100, width: '20%'}} >Code</th>
                                                            <th style={{textAlign: 'left', paddingLeft: 50, width: '50%'}} >Module Name</th>
                                                            <th style={{textAlign: 'left', paddingLeft: 50, width: '10%'}} >Current Progress</th>
                                                            <th style={{textAlign: 'left', paddingLeft: 50, width: '5%'}} >Link</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {row.extraLineItems.map((item) => (
                                                            <tr style={{paddingTop: 2, paddingBottom: 2}}>
                                                                <td style={{paddingLeft: 100, width: '20%'}} >{item.code}</td>
                                                                <td style={{textAlign: 'left', paddingLeft: 50, width: "40%"}} >{item.name}</td>
                                                                <td style={{textAlign: 'left', paddingLeft: 50, width: '40%'}} >{item.evidence}</td>
                                                            </tr>
                                                        ))}
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        )}
                                    </React.Fragment>
                                ))}
                                </tbody>
                            </table>
                        </Row>
                        <Row style={{paddingTop: '50px'}}>
                            <div>
                                <h2>Modules in Progress</h2>
                            </div>
                        </Row>
                        <Row style={{marginTop: 10 }}>
                            <table>
                                <thead >
                                <tr className={'tr2'} >
                                    <th>Module Code</th>
                                    <th>Module Name</th>
                                    <th>Date Completed</th>
                                    <th>DL</th>
                                </tr>
                                </thead>
                                <tbody>
                                {studentList.map((row, index) => (
                                    <React.Fragment key={index}>
                                        <tr  onClick={() => toggleAccordion(index)} key={index} className={index % 2 === 0 ? 'dark-grey' : 'light-grey'}>
                                            <td>{row.heading}</td>  {/*This should link to view this particular SOA*/}
                                            <td>{row.mobile}</td>  {/*This should link to view this particular SOA*/}
                                            <td>{row.billing}</td>  {/*This should link to the class that this module was delivered under*/}
                                            <td>{row.usi}</td>  {/*This should download this particular SOA*/}
                                        </tr>

                                        {/*)}*/}
                                    </React.Fragment>
                                ))}
                                </tbody>
                            </table>
                        </Row>

                    </div>

                    {/* =========================================================== TAB 7 ======================================================================*/}

                    <div id="Tab 7" className="tabcontent">
                        {/*<h4>Tab 7</h4>*/}
                        {/*<p>Stuff Here...</p>*/}
                        <Row style={{paddingTop: '25px', paddingRight: '25px'}}>
                            <BorderedComponent name="Create a new note ">
                                <Row>
                                    <Col xs={3} md={3} xl={3} style={{ paddingTop: '5px', paddingRight: '0px', textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                        <div>
                                            <select
                                                name="statementType"
                                                style={{paddingTop: '10px'}}
                                                value={formData.statementType}
                                                onChange={handleInputChange}
                                                title="This is the way the class has been delivered.
                                                        Workplace is when delivered in conjunction with an employer
                                                        Classroom Based is when they are only doing it in a classroom
                                                        External is where they are doing it via correspondence"
                                                required
                                            >
                                                <option value="04">Note</option>
                                                <option value="09">SMS</option>
                                                <option value="01">Complaint about Student</option>
                                                <option value="02">Complaint from Student</option>
                                                <option value="03">Explained Absence</option>
                                                <option value="05">Phone from Parent</option>
                                                <option value="06">Phone from Student</option>
                                                <option value="07">Phone to Parent</option>
                                                <option value="08">Phone to Student</option>
                                                Required
                                            </select>
                                        </div>
                                    </Col>
                                    <Col xs={9} md={9} xl={9} style={{ paddingTop: '5px', paddingRight: '0px', textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                        <button type="submit" className={"btn-primary"} style={{width: '50%'}}>Save Note</button>
                                    </Col>
                                </Row>
                                <Row style={{paddingTop: '15px'}}>
                                    <Col xs={12} md={12} xl={12} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                        <input
                                            id="gracePeriod"
                                            style={{paddingTop: '50px', textAlign: ''}}
                                            className="inputText"
                                            type="text"
                                            name="gracePeriod"
                                            placeholder="Choose the note type then type your note here and hit save"
                                            onChange={handleInputChange}
                                            title="This is the number of days you have to
                                                            submit your paperwork before the system
                                                            stops you from marking anyone else
                                                            competent.  If you have been locked
                                                            out please call your supervisor to get
                                                            this changed or a temporary extension
                                                            applied."
                                        />
                                    </Col>
                                </Row>
                            </BorderedComponent>
                        </Row>
                        <Row>
                            <Row style={{marginTop: '10px', paddingLeft: '30px' }}>
                                <table>
                                    <thead >
                                    <tr className={'tr2'} >
                                        <th style={{width: '10%', textAlign: 'left'}}>Date</th>
                                        <th style={{width: '10%', textAlign: 'left'}}>MsgType</th>
                                        <th style={{width: '70%', textAlign: 'left'}}>Subject</th>
                                        <th style={{width: '10%', textAlign: 'left'}}>Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {studentList.map((row, index) => (
                                        <React.Fragment key={index}>
                                            <tr  onClick={() => toggleAccordion(index)} key={index} className={index % 2 === 0 ? 'dark-grey' : 'light-grey'}>
                                                <td>{row.date}</td>
                                                <td>{row.msgType}</td>
                                                <td>{row.subject}</td>
                                                <td>{row.action}</td>
                                            </tr>
                                            {expandedRows[index] && (
                                                <tr >
                                                    <td colSpan={12} style={{paddingTop: 0, padding: 0}} >
                                                        <table style={{paddingTop: 5, border: 0, width: '100%', paddingLeft: 0, paddingRight: 0}} key={index} className={index % 2 === 0 ? 'dark-grey' : 'light-grey'}>
                                                            <thead className={'tr2'}  >
                                                            <tr className={'tr2'}  >
                                                                <th style={{textAlign: 'left', paddingLeft: 50, width: '10%'}} ></th>  {/*Should Link to the personal module page for this module*/}
                                                                <th style={{textAlign: 'left', paddingLeft: 50, width: '10%'}} ></th>  {/*This should be a link to the Class the module is under*/}
                                                                <th style={{textAlign: 'left', paddingLeft: 50, width: '70%'}} >Message body</th>  {/*This should be a link to the Class the module is under*/}
                                                                <th style={{textAlign: 'left', paddingLeft: 50, width: '10%', paddingRight: '15px'}} ></th> {/*Should Link to the SOA for this particular module*/}
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {row.extraLineItems.map((item) => (
                                                                <tr style={{paddingTop: 2, paddingBottom: 2}}>
                                                                    <td style={{paddingLeft: 100, width: '10%'}} ></td>
                                                                    <td style={{textAlign: 'left', paddingLeft: 50, width: "10%"}} ></td>
                                                                    <td style={{textAlign: 'left', paddingLeft: 50, width: "70%"}} >{item.body}</td>
                                                                    <td style={{textAlign: 'left', paddingLeft: 50, width: '10%'}} ></td>
                                                                </tr>
                                                            ))}
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            )}
                                        </React.Fragment>
                                    ))}
                                    </tbody>
                                </table>
                            </Row>
                        </Row>
                    </div>
                </div>

            </div>

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                    fontSize: '40px',
                    fontWeight: 'bold',
                }}
            >
                {/*{pageName}*/}


            </div>

        </div>
    );
}

// =============================== To Do =================================== //

// Rogue pixel hunt
// adjust for tablet view

// =============================== Bugs =================================== //

// Tabs need to have Z Value reset to incorporate Footer
// Not clickable under footer
