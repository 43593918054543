import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { SpacerDiv } from "Components";


interface DetailsContentProps {
  formData: any;
  setFormData: any;
  handleInputChange: (
    // eslint-disable-next-line no-unused-vars
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
  // eslint-disable-next-line no-unused-vars
  handleFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  // eslint-disable-next-line no-unused-vars
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  // eslint-disable-next-line no-unused-vars
  handleCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  sameAsResidential: boolean;
  profilePicture: File | null;
  preview: string | null;
}

const DetailsContent: React.FC<DetailsContentProps> = ({
  formData,
  setFormData,
  handleInputChange,
  handleFileChange,
  handleSubmit,
  handleCheckboxChange,
  sameAsResidential,
  profilePicture,
  preview,
}) => {
  const [addNumber, setAddNumber] = React.useState<boolean>(false);
  const [addNumber2, setAddNumber2] = React.useState<boolean>(false);

  return (
    <form className="form-container" onSubmit={handleSubmit} id="form-0">
      <div className="personal-details">
        <div className="alias form-column">
          <p className="section-heading">Profile</p>
          <input
            className="large"
            type="text"
            name="preferedName"
            placeholder="Prefered Name"
            value={formData.preferedName}
            onChange={handleInputChange}
          />
          <div>
            {preview !== null ? (
              <img src={preview} className="profile-pic" alt="Profile" />
            ) : (
              <div className="profile-pic">
                <FontAwesomeIcon
                  icon={faUser}
                  size="5x"
                  style={{ color: "#393939" }}
                />
              </div>
            )}
          </div>
          <button
            type="button"
            className="btn-file-input"
            style={{ width: "200px" }}
            onClick={() => document.getElementById("profilePicture")?.click()}
          >
            {profilePicture ? "UPDATE PICTURE" : "ADD PICTURE"}
          </button>
          <input
            type="file"
            id="profilePicture"
            name="profilePicture"
            accept="image/*"
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
        </div>

        <div className="vertical-divider" />

        <div className="name">
          <p className="section-heading">Basic Details</p>
          <div className="form-row">
            <input
              className="small"
              type="text"
              name="title"
              placeholder="Title"
              value={formData.title}
              onChange={handleInputChange}
            />
            <input
              className="large"
              type="text"
              name="firstName"
              placeholder="First Name"
              value={formData.firstName}
              onChange={handleInputChange}
            />
            <input
              className="large"
              type="text"
              name="middleName"
              placeholder="Middle Name"
              value={formData.middleName}
              onChange={handleInputChange}
            />
            <input
              className="large"
              type="text"
              name="lastName"
              placeholder="Last Name"
              value={formData.lastName}
              onChange={handleInputChange}
            />
          </div>

          <SpacerDiv rem={0.75} />

          <div className="form-row">
            <input
              className="large"
              type="text"
              name="previousName"
              placeholder="Previous Name"
              value={formData.previousName}
              onChange={handleInputChange}
            />
            <select
              className="medium"
              id="Gender"
              name="Gender"
              value={formData.gender}
              onChange={(e) =>
                setFormData((prev: any) => ({
                  ...prev,
                  gender: e.target.value,
                }))
              }
            >
              <option value="" disabled hidden>
                Select Gender
              </option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>
          </div>

          <SpacerDiv rem={0.5} />

          <div className="form-row" style={{ margin: '0 20px'}}>
            <div className="section-container" style={{ width: "50%" }}>
              <p className="section-heading">Contact Details</p>
              <input
                className="large"
                type="number"
                name="mobileNumber"
                placeholder="Mobile Number"
                value={formData.mobileNumber}
                onChange={handleInputChange}
              />
              <FontAwesomeIcon
                className="more"
                icon={faPlus}
                onClick={
                  addNumber
                    ? () => setAddNumber2(true)
                    : () => setAddNumber(true)
                }
              />
              {addNumber && (
                <div>
                  <SpacerDiv rem={0.5} />
                  <input
                    className="large"
                    type="number"
                    name="workNumber"
                    placeholder="Work Number"
                    value={formData.workNumber}
                    onChange={handleInputChange}
                  />
                  <FontAwesomeIcon
                    className="more"
                    icon={faMinus}
                    onClick={() => setAddNumber(false)}
                  />
                </div>
              )}
              {addNumber2 && (
                <div>
                  <SpacerDiv rem={0.5} />
                  <input
                    className="large"
                    type="number"
                    name="homeNumber"
                    placeholder="Home Number"
                    value={formData.homeNumber}
                    onChange={handleInputChange}
                  />
                  <FontAwesomeIcon
                    className="more"
                    icon={faMinus}
                    onClick={() => setAddNumber2(false)}
                  />
                </div>
              )}
            </div>

            <div className="section-container" style={{ width: "50%" }}>
              <p className="section-heading">Emergency Contact Details</p>
              <input
                className="large"
                type="text"
                name="contactName"
                placeholder="Contact Name"
                value={formData.contactName}
                onChange={handleInputChange}
              />
              <SpacerDiv rem={0.5} />
              <input
                className="large"
                type="number"
                name="contactNumber"
                placeholder="Contact Number"
                value={formData.contactNumber}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="form-column">
        <div className="section-container full-width">
          <p className="section-heading">Residential Address</p>
          <div className="form-row">
            <input
              style={{ width: '110px'}}
              className="medium"
              type="text"
              name="addressLine1"
              placeholder="Unit Number"
              value={formData.addressLine1}
              onChange={handleInputChange}
            />
            <input
              className="xlarge"
              type="text"
              name="addressLine2"
              placeholder="Street Number and Name"
              value={formData.addressLine2}
              onChange={handleInputChange}
            />
            <input
              className="large"
              type="text"
              name="suburb"
              placeholder="Suburb or Town"
              value={formData.suburb}
              onChange={handleInputChange}
            />
            <input
              className="medium"
              type="text"
              name="state"
              placeholder="State"
              value={formData.state}
              onChange={handleInputChange}
            />
            <input
              className="medium"
              type="number"
              name="postcode"
              placeholder="Postcode"
              value={formData.postcode}
              onChange={handleInputChange}
            />
          </div>
        </div>
      </div>

      <div className="form-column">
        <div className="section-container full-width">
          <div
            className="form-row"
            style={{ alignContent: "center", justifyContent: "center" }}
          >
            <p className="section-heading" style={{ marginRight: "20px" }}>
              Postal Address
            </p>
            <div className="form-row no-gap">
              <input
                className="radio-as-checkbox"
                type="checkbox"
                id="sameAsResidential"
                name="sameAsResidential"
                checked={sameAsResidential}
                onChange={handleCheckboxChange}
              />
              <label htmlFor="sameAsResidential" />
              <p className="instruction-text" style={{ placeSelf: 'flex-start', marginTop: '5px'}}>Same as Residential</p>
            </div>
          </div>
         {!sameAsResidential && 
            <div className="form-row">
              <input
                style={{ width: '110px'}}
                className="medium"
                type="text"
                name="addressLine1"
                placeholder="Unit Number"
                value={formData.address2Line1}
                onChange={handleInputChange}
              />
              <input
                className="xlarge"
                type="text"
                name="addressLine2"
                placeholder="Street Number and Name"
                value={formData.address2Line2}
                onChange={handleInputChange}
              />
              <input
                className="large"
                type="text"
                name="suburb"
                placeholder="Suburb or Town"
                value={formData.suburb2}
                onChange={handleInputChange}
              />
              <input
                className="medium"
                type="text"
                name="state"
                placeholder="State"
                value={formData.state2}
                onChange={handleInputChange}
              />
              <input
                className="medium"
                type="number"
                name="postcode"
                placeholder="Postcode"
                value={formData.postcode2}
                onChange={handleInputChange}
              />
            </div>
          }
        </div>
      </div>
    </form>
  );
};

export default DetailsContent;
