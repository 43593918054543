import React, { useEffect, useRef } from "react";
import Select from 'react-select';
import CurrencyInput from 'react-currency-input-field';
import QRCodeStyling from "qr-code-styling";
import QRLogo from 'Images/QRLogo.png';
import { customSelectStyles } from "../../Pages/Styles/customSelectStyles";
import { SpacerDiv } from "..";

interface DetailsComponentProps {
  formData: any;
  setFormData: any;
  // eslint-disable-next-line no-unused-vars
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, name: string) => void;
  // eslint-disable-next-line no-unused-vars
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  // eslint-disable-next-line no-unused-vars
  handleSelectChange: (fieldName: string, selectedOption: any) => void;
  url: string;
  isChecked: boolean;
  isPrivateMsg: string;
  handleCheckboxChange: () => void;
}

const customSelectStylesWithHeight = {
  ...customSelectStyles,
  control: (baseStyles: any, state: any) => ({
    ...baseStyles,
    ...customSelectStyles.control(baseStyles, state),
    minHeight: '35px',
    height: '35px',
    margin: '0',
  }),
};

const DetailsComponent: React.FC<DetailsComponentProps> = ({
  formData,
  handleInputChange,
  handleSubmit,
  url,
  isChecked,
  isPrivateMsg,
  handleCheckboxChange,
  handleSelectChange,
}) => {
  const QRCode = useRef(null);
  const qrCodeInstance = useRef<QRCodeStyling | null>(null);

  useEffect(() => {
    qrCodeInstance.current = new QRCodeStyling({
      width: 300,
      height: 300,
      image: QRLogo,
      dotsOptions: {
        color: "#393939",
        type: "classy",
      },
      imageOptions: {
        imageSize: 0.4,
        crossOrigin: "anonymous",
        margin: 5,
      },
      backgroundOptions: {
        color: "transparent",
      },
      cornersSquareOptions: {
        type: "square",
        color: "#955CCE",
      },
    });

    if (QRCode.current && qrCodeInstance.current) {
      qrCodeInstance.current.append(QRCode.current);
    }
  }, []);

  useEffect(() => {
    if (qrCodeInstance.current) {
      qrCodeInstance.current.update({
        data: url,
      });
    }
  }, [url]);

  const options = [
    { value: 'option1', label: 'Option 1' },
    { value: 'option2', label: 'Option 2' },
    { value: 'option3', label: 'Option 3' },
  ];

  return (
    <form
      className="form-container"
      id="form-0"
      onSubmit={handleSubmit}
    >
      <div className={"row w-100"} style={{ marginTop: 15 }}>
        <div
          className={"col-5"}
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-center",
            alignItems: "flex-center",
          }}
        >
          <div ref={QRCode} className={"qrCode"} />
        </div>
        <div
          className={"col-7"}
          style={{
            textAlign: "left",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <div className={"row w-100"}>
            <div
              className={"col-6"}
              style={{
                textAlign: "left",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-center",
                alignItems: "flex-start",
              }}
            >
              <label className='align-left instruction-text'  htmlFor="nameClass">Name of Class:</label>
              <input
                id="nameClass"
                className="inputText"
                type="text"
                name="nameClass"
                placeholder="Class Name"
                onChange={(e) => handleInputChange(e, "details")}
                required
              />
            </div>
            <div
              className={"col-6"}
              style={{
                textAlign: "left",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-center",
                alignItems: "flex-center",
              }}
            >
              <label className='align-left instruction-text' htmlFor="comboBox">Template:</label>
              <Select
                id="comboBox"
                options={options}
                value={formData.template}
                styles={customSelectStylesWithHeight}
                isClearable
                isSearchable
                placeholder="Select or type..."
                onChange={(selectedOption) => handleSelectChange('template', selectedOption)} 
              />
            </div>
          </div>
          <div className={"row w-100"}>
            <div
              className={"col-6"}
              style={{
                textAlign: "left",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <label className='align-left instruction-text'  htmlFor="startDate">Start Date</label>
              <input
                id="startDate"
                type="date"
                name="startDate"
                title="This is the first day you expect the class to start"
                value={formData.startDateVal}
                onChange={(e) => handleInputChange(e, "details")}
              />
            </div>
            <div
              className={"col-6"}
              style={{
                textAlign: "left",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <label className='align-left instruction-text'  htmlFor="finishDate">Finish Date</label>
              <input
                id="finishDate"
                type="date"
                name="finishDate"
                value={formData.finishDateVal}
                title="This is the last day you expect the class to run"
                onChange={(e) => handleInputChange(e, "details")}
              />
            </div>
          </div>
          <div className={"row w-100"}>
            <div
              className={"col-6"}
              style={{
                textAlign: "left",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <label className='align-left instruction-text'  htmlFor="startTime">Start Time</label>
              <input
                id={"startTime"}
                type="time"
                name="startTime"
                value={formData.startTime}
                onChange={(e) => handleInputChange(e, "details")}
                title="This is the Target Start time of the Class"
              />
            </div>
            <div
              className={"col-6"}
              style={{
                textAlign: "left",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <label className='align-left instruction-text'  htmlFor="finishTime">Finish Time</label>
              <input
                id="finishTime"
                type="time"
                name="finishTime"
                value={formData.finishTime}
                onChange={(e) => handleInputChange(e, "details")}
                title="This is the Approximate finish time of the Class"
              />
            </div>
          </div>
          <div className={"row w-100"}>
            <div
              className={"col-6"}
              style={{
                textAlign: "left",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <label className='align-left instruction-text'  htmlFor="classMinimum">Class Size</label>
              <input
                id={"classMinimum"}
                type="number"
                name="classMinimum"
                placeholder="Min"
                value={formData.classMinimumVal}
                onChange={(e) => handleInputChange(e, "details")}
                title="This is used to set the minimum number of people you want in a class
                                               to help inform you as to whether you want to cancel the class"
              />
            </div>
            <div
              className={"col-6"}
              style={{
                textAlign: "left",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <SpacerDiv rem={1.7} />
              <input
                id={"classMaximum"}
                type="number"
                name="classMax"
                placeholder="Max"
                onChange={(e) => handleInputChange(e, "details")}
                title="This is the Maximum number of people you want to be able to be booked
                                                    into this class.  This is especially useful if you have less control
                                                    of how many people may have access to the QR code etc."
              />
            </div>
          </div>
          <div className={"row w-100"}>
            <div
              className={"col-6"}
              style={{
                textAlign: "left",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <label className='align-left instruction-text'  htmlFor="classCost">Course Cost</label>
              <CurrencyInput
                className={"currencyInput"}
                id="courseCost"
                name="courseCost"
                placeholder="$0.00"
                decimalsLimit={2}
                onChange={(e) => handleInputChange(e, "details")}
                prefix={"$"}
                title="This is the amount that this course costs.  When you put this cost in here
                                                a Business or Person can pay it online depending on the payment methods you
                                                have set up.  eg. Credit Card, Stripe etc."
              />
            </div>
            <div
              className={"col-6"}
              style={{
                textAlign: "left",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <div
                className="row-container"
                style={{ display: "flex", alignItems: "center" }}
              >
                <label className='' 
                  htmlFor="isPrivate"
                  style={{ marginRight: 15, marginBottom: 15}}
                >
                <div className="instruction-text">{isPrivateMsg}</div>
                </label>
                <label className="slide-switch">
                  <input
                    id="isPrivate"
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  <span className="slider"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={"row"} style={{ width: "100%", paddingLeft: 25 }}>
        <div
          className={"col-6"}
          style={{
            textAlign: "left",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-center",
            alignItems: "flex-center",
          }}
        >
          <label className='align-left instruction-text'  htmlFor="comboBox">Venue:</label>
          <Select
            id="comboBox"
            options={options}
            styles={customSelectStylesWithHeight}
            value={formData.venue}
            isClearable
            isSearchable
            placeholder="Select or type..."
            onChange={(selectedOption) => handleSelectChange('venue', selectedOption)} 
            required
          />
          <SpacerDiv rem={0.5} />
          <label className='align-left instruction-text'  htmlFor="comboBox">Business:</label>
          <Select
            id="comboBox"
            options={options}
            styles={customSelectStylesWithHeight}
            value={formData.business}
            isClearable
            isSearchable
            placeholder="Select or type..."
            onChange={(selectedOption) => handleSelectChange('business', selectedOption)}
          />
        </div>
        <div
          className={"col-6"}
          style={{
            textAlign: "left",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-center",
            alignItems: "flex-center",
          }}
        >
          <label className='align-left instruction-text'  htmlFor="comboBox">Trainer:</label>
          <Select
            id="comboBox"
            options={options}
            styles={customSelectStylesWithHeight}
            value={formData.trainer}
            isClearable
            isSearchable
            placeholder="Select or type..."
            onChange={(selectedOption) => handleSelectChange('trainer', selectedOption)}
            required
          />
          <SpacerDiv rem={0.5} />
          <label className='align-left instruction-text'  htmlFor="comboBox">3rd Party Contract:</label>
          <Select
            id="comboBox"
            options={options}
            styles={customSelectStylesWithHeight}
            value={formData.contract}
            isClearable
            isSearchable
            placeholder="Select or type..."
            onChange={(selectedOption) => handleSelectChange('contract', selectedOption)}
          />
        </div>
      </div>
      <SpacerDiv rem={0.5} />
      <div className={"row w-100"} style={{ width: "100%", paddingLeft: 25 }}>
        <div
          className={"col-4"}
          style={{
            textAlign: "left",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <label className='align-left instruction-text'  htmlFor="aenNumber">AEN Number:</label>
          <input
            id="aenNumber"
            className="inputText"
            type="text"
            name="aenNumber"
            placeholder="HRWL Class AEN"
            onChange={(e) => handleInputChange(e, "details")}
            title="This is only used for High Risk Work Licence Classes.
                                                This number is the Class identifier as it appears in
                                               the High Risk Work Licence Assessors Portal."
          />
        </div>
        <div
          className={"col-4"}
          style={{
            textAlign: "left",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <label className='align-left instruction-text'  htmlFor="assessmentMethodr">Assessment Method:</label>
          <select
            style={{ margin: 0 }}
            id="assessmentMethod"
            name="assessmentMethod"
            onChange={(e) => handleInputChange(e, "details")}
            title="This is the Assessment Method you have used"
          >
            <option value="Full Assessment">Full Assessment</option>
            <option value="RPL">Recognition Of Prior Learning</option>
            <option value="CT">Credit Transfer</option>
          </select>
        </div>
        <div
          className={"col-4"}
          style={{
            textAlign: "left",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <label className='align-left instruction-text'  htmlFor="deliveryMode">Delivery Mode:</label>
          <select
          style={{ margin: 0 }}
            id="deliveryMode"
            name="deliveryMode"
            onChange={(e) => handleInputChange(e, "details")}
            title="This is the way the class has been delivered.
                                                Workplace is when delivered in conjunction with an employer
                                                Classroom Based is when they are only doing it in a classroom
                                                External is where they are doing it via correspondence"
          >
            <option value="workplace">Workplace-based Delivery</option>
            <option value="internal">Classroom-based Delivery</option>
            <option value="external">Correspondence-based Delivery</option>
          </select>
        </div>
      </div>
      <SpacerDiv rem={0.5} />
      

      <div className={"row w-100"} style={{ width: "100%", paddingLeft: 25 }}>
        <div
          className={"col-10"}
          style={{
            textAlign: "left",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <label className='align-left instruction-text'  htmlFor="interestedEmail" >Invite someone</label>
          <input
            id="interestedEmail"
            type="email"
            name="interestedEmail"
            placeholder="email for invitation"
            onChange={(e) => handleInputChange(e, "details")}
          />
        </div>
        <div className={"col-2"} style={{ alignSelf: 'flex-end'}}>
          <button
            type="submit"
            className={"btn-primary"}
            style={{ display: 'flex', flexWrap: 'wrap', alignContent: 'center', width: "100%", height: '35px' }}
          >
            Send Email
          </button>
        </div>
      </div>
    </form>
  );
};

export default DetailsComponent;
