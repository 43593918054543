import React, { useState, useEffect, useRef } from 'react';
import MarkingGuide from './MarkingGuide';
import { Col, Row } from "react-bootstrap";
import CustomAlert from './CustomAlert';
import useFetchApi from '../Services/FetchApi';

import io from 'socket.io-client';

const ClassroomComponent = () => {
    const fetchApi = useFetchApi();
    const [msg, setMsg] = useState(window.sessionStorage.getItem("message"));
    const [msgCode, setMsgCode] = useState(0);
    const [classId, setClassId] = useState('');
    const [certCode, setCertCode] = useState('');
    const [studentList, setStudentList] = useState([]);
    const [currentStudentId, setCurrentStudentId] = useState('');
    const [apprenticeModsId, setApprenticeModsId] = useState('');
    const [currentModuleId, setCurrentModuleId] = useState('');
    const [currentModule, setCurrentModule] = useState('');
    const [showMarkingGuide, setShowMarkingGuide] = useState(false);
    const [moduleOptions, setModuleOptions] = useState([]);
    const [error, setError] = useState('');
    const socketRef = useRef(null);  // Use ref to persist the socket connection across renders
    // const [modCode, setmodCode] = useState('');

    // After fetching class details, extract unique modules
    useEffect(() => {
        if (studentList.length > 0) {
            const uniqueModules = Array.from(
                new Set(studentList.flatMap(student => student.modules.map(module => module.name)))
            ).map(name => {
                const mod = studentList
                    .find(student => student.modules.find(m => m.name === name))
                    .modules.find(m => m.name === name);
                return { name: mod.name, modId: mod.modId };
            });
            setModuleOptions(uniqueModules);
        }
    }, [studentList]);


    useEffect(() => {

        if (showMarkingGuide && socketRef.current) {
            // console.log("Marking guide is shown, disconnecting WebSocket");
            socketRef.current.disconnect();  // Disconnect when marking guide is shown
        } else if (!showMarkingGuide && currentModuleId && studentList.length > 0 && !socketRef.current?.connected) {
            const studentIds = studentList.map(student => student.studentNumber);
            const postData = { studentIds, modId: currentModuleId, classId };

            // console.log('Reconnecting WebSocket and emitting fetchClassStatus:', postData);

            // Ensure WebSocket is connected before emitting
            if (!socketRef.current) {
                const socketServerUrl = getSocketServerUrl();
                socketRef.current = io(socketServerUrl, { withCredentials: true });
            }

            socketRef.current.on('connect', () => {
                // console.log('WebSocket connected');
                socketRef.current.emit('fetchClassStatus', postData);
            });

            socketRef.current.on('classStatusUpdate', (responseData) => {

                if (!responseData || responseData.length === 0) {
                    // console.log("No data returned from WebSocket.");
                    return;
                }

                // Update the student list based on WebSocket response
                const updatedStudentList = studentList.map(student => {
                    const status = responseData.find(s => s.studentId === student.studentNumber);
                    // console.log(status);
                    return {
                        ...student,
                        completedQuestions: status ? status.completedQuestions : student.completedQuestions,
                        totalQuestions: status ? status.totalQuestions : student.totalQuestions,
                        satisfactory: status ? status.satisfactory : student.satisfactory,
                        notYetSatisfactory: status ? status.notYetSatisfactory : student.notYetSatisfactory,
                        unmarked: status ? status.unmarked : student.unmarked,
                        apprenticeModsId: status ? status.apprenticeModsId : student.apprenticeModsId
                    };
                });

                setStudentList(updatedStudentList);
                // console.log(updatedStudentList);
            });

            socketRef.current.on('error', (error) => {
                console.error('Error from WebSocket:', error);
                setError(error.msg || 'An error occurred while fetching class status.');
            });
        }
    }, [currentModuleId, classId, studentList, showMarkingGuide]);

    // Fetch class status every 5 seconds when not showing the marking guide
    useEffect(() => {
        if (!showMarkingGuide && currentModuleId && studentList.length > 0) {
            const studentIds = studentList.map(student => student.studentNumber);
            const postData = { studentIds, modId: currentModuleId, classId };
            socketRef.current.emit('fetchClassStatus', postData);
        }
    }, [currentModuleId, classId, studentList]); // Trigger this effect when relevant data changes

    const handleMarking = (certificateCode, studentId, apprenticeModId) => {
        setCurrentStudentId(studentId);
        setApprenticeModsId(apprenticeModId);
        setCertCode(certificateCode);
        setShowMarkingGuide(true);
        console.log(`Marking module ${currentModuleId} for student ${studentId} with apprenticeModId ${apprenticeModId} in class ${classId} for ${certificateCode}`);
    };


    // Function to determine the WebSocket server URL dynamically based on the environment
    const getSocketServerUrl = () => {
        const environment = process.env.REACT_APP_ENV || 'production';
        switch (environment) {
            case 'production':
                return 'https://api.trainingprofessionals.com.au/';
            case 'development':
                return 'https://10.1.10.49:5001/';
            default:
                return 'https://localhost:3001/';
        }
    };

    const fetchClassDetails = async (classNum) => {
        fetchApi('classroom/class', { "classId": classNum })
            .then(response => {
                const students = response.responseData.students;
                console.log('Student List: ', students);
                if (response.status >= 200 && response.status < 300) {

                    setStudentList(students);
                    setClassId(classNum);
                    setMsgCode(0);
                } else {
                    setMsg(response.msg);
                    setMsgCode(4);
                }
            });
    };

    const handleClose = () => {
        setShowMarkingGuide(false);
        // Optionally reconnect WebSocket after marking guide is closed
        if (socketRef.current) {
            socketRef.current.connect();
        }
    };

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '20px',
            width: '100%',
            maxWidth: '960px',
            margin: '0 auto',
            background: '#fff',
            boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
        }}>
            <CustomAlert msg={msg} msgCode={msgCode} />

            <form onSubmit={(e) => {
                e.preventDefault();
                fetchClassDetails(e.target.elements.classId.value);
            }} style={{width: '100%'}}>
                <Row>
                    <Col xs={12} md={6} style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: '20px',
                    }}>
                        <input
                            type="text"
                            name="classId"
                            placeholder="Enter the class ID here"
                            title="This is to ensure that only the students and modules for the class are loaded"
                            style={{width: '100%', marginBottom: '20px'}}
                        />
                        <button type="submit" style={{width: '200px', marginTop: '10px'}}>Fetch Class Details</button>
                        {moduleOptions.length > 0 && (
                            <div style={{marginBottom: '20px'}}>
                                <label htmlFor="moduleDropdown">Select Module:</label>
                                <select
                                    id="moduleDropdown"
                                    value={currentModuleId} // Ensure this to make the component controlled
                                    // onChange={(e) => setCurrentModuleId(e.target.value)}
                                    onChange={(e) => {
                                        const selectedModule = moduleOptions.find(module => module.modId === parseInt(e.target.value));
                                        setCurrentModuleId(e.target.value);
                                        setCurrentModule(selectedModule.name);
                                    }}

                                    style={{marginLeft: '10px'}}
                                >
                                    <option value="">Please select a module</option>
                                    {moduleOptions.map((module) => (
                                        <option key={module.modId} value={module.modId}>
                                            {module.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        )}
                    </Col>
                </Row>
            </form>

            {error && <p style={{color: 'red', fontSize: '16px'}}>{error}</p>}

            {studentList && (
                <div style={{width: '100%', marginTop: '20px'}}>
                    <h2>Classroom Details</h2>
                    <table style={{width: '100%', marginTop: '20px'}}>
                        <thead>
                        <tr>
                            <th>Student</th>
                            <th>Status</th>
                            <th style={{color: 'green'}}>✓</th>
                            <th style={{color: 'red'}}>✘</th>
                            <th>☐</th>
                        </tr>
                        </thead>
                        <tbody>
                        {studentList.map((student, index) => (
                            <tr key={index} className={index % 2 === 0 ? 'dark-grey' : 'light-grey'}>
                                <td onClick={() => handleMarking(student.certCode, student.studentNumber, student.apprenticeModsId)}
                                    style={{cursor: 'pointer'}}>{student.name}</td>
                                <td>{`${student.completedQuestions || 0} of ${student.totalQuestions || 0} questions answered`}</td>
                                <td style={student.satisfactory > 0 ? { color: 'green' } : {}}>{student.satisfactory}</td> {/* Green if > 0 */}
                                <td style={student.notYetSatisfactory > 0 ? { color: 'red' } : {}}>{student.notYetSatisfactory}</td> {/* Red if > 0 */}
                                <td>{student.unmarked}</td> {/* Unmarked count */}
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            )}

            <MarkingGuide

                show={showMarkingGuide}
                onHide={handleClose}
                certCode={certCode}
                modCode={currentModule}
                classId={classId}
                moduleId={currentModuleId}
                studentId={currentStudentId}
                apprenticeModsId={apprenticeModsId}

            />

        </div>
    );
};

export default ClassroomComponent;
