// DISABLED LINTING FOR THIS FILE BECAUSE IT IS A TEMPLATE FILE
/* eslint-disable no-unused-vars */

import React, {useContext, useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import {ConstLink} from "ConstLink";
import Bluey from "Components/Bluey"
import CustomAlert from "Components/CustomAlert";
import useFetchApi from "Services/FetchApi";
import { useUserData } from "CustomHooks";
import {hideRefreshLogin} from "State/Reducers/refreshLoginSlice";



export default function Template() {

    const navigate = useNavigate();
    const [pageName, setPageName] = useState('');
    const [fetchData, setFetchData] = useState('');
    const fetchApi = useFetchApi();
    const [msg, setMsg] = useState(window.sessionStorage.getItem("message"));
    const [msgCode, setMsgCode] = useState(0);

    useEffect(() => {

        const pathArray = window.location.pathname.split('/');
        const pageParam = pathArray[pathArray.length - 1];
        setPageName(pageParam || 'Default Page');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const fetchDatData = async (event) => {
        event.preventDefault();

        const inputs = {

        }

        fetchApi("route/endpoint", inputs)
            .then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    // setEngineData
                    setMsgCode(0);
                } else {
                    setMsg(response.msg);
                    setMsgCode(1);
                }
            })
            .catch((error) => {
                setMsg(
                    "An unexpected error occurred. Contact your administrator" + error
                );
                setMsgCode(4);
            });
    };



    return (
        <div>

            <Bluey />

            <CustomAlert msg={msg} msgCode={msgCode} />

            <div className={"page-common"} style={{ marginTop: 100, zIndex: 1 }}>

                {pageName}

            </div>

        </div>
    );
}

// =============================== To Do =================================== //



// =============================== Bugs =================================== //