import React from 'react';
import Select from 'react-select';
import { customSelectStyles } from 'Pages/Styles/customSelectStyles';
import SpacerDiv from 'Components/SpacerDiv';

interface ModulesContentProps {
  formData: any;
  setFormData: any;
  // eslint-disable-next-line no-unused-vars
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, fieldName: string) => void;
  // eslint-disable-next-line no-unused-vars
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  // eslint-disable-next-line no-unused-vars
  handleSelectChange: (fieldName: string, selectedOption: any) => void;
}

const ModulesContent: React.FC<ModulesContentProps> = ({
  formData,
  setFormData,
  handleInputChange,
  handleSubmit,
  handleSelectChange,
}) => {

  // REMOVE: To be replaced with actual data
  const modulesList = [
    {
      code: 'Code 1',
      name: 'Module 1',
      flags: 'Flags 1',
    },
    {
      code: 'Code 2',
      name: 'Module 2',
      flags: 'Flags 2',
    },
    {
      code: 'Code 3',
      name: 'Module 3',
      flags: 'Flags 3',
    },
  ];

  const statementTypeOptions = [
    { value: 'SOA', label: 'Statement of Attainment Only (SOA is nationally Recognised Training)' },
    { value: 'SOC', label: 'Statement of Completion (SOC is NOT Nationally Recognised Training)' },
    { value: 'partial', label: 'Partial Completion of Certificate (used when 1/3 or more of a certificate is planned)' },
    { value: 'certificate', label: 'Certificate (used when delivery of a full certificate is planned)' },
  ];

  const PHOptions = [
    { value: 'Option 1', label: 'Option 1' },
    { value: 'Option 2', label: 'Option 2' },
    { value: 'Option 3', label: 'Option 3' },
  ];

  const handleAddModule = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    console.log('add module clicked');
  };

  return (
    <form 
      className="form-container"
      id="form-1"
      onSubmit={handleSubmit}
    >
      <div className="form-row"> 
        {/* Statement Type */}
        <div className='full-width'>
          <label htmlFor="statementType" className='align-left instruction-text'>Statement Type</label>
          <Select
            id="statementType"
            name="statementType"
            isSearchable={false}
            placeholder="Select Statement Type"
            styles={customSelectStyles}
            options={statementTypeOptions}
            value={formData.statementType || ""}
            onChange={(selectedOption) => handleSelectChange('statementType', selectedOption)}
          />
        </div>

        {/* Template */}
        <div className='full-width'>
          <label htmlFor="template" className='align-left instruction-text'>Template</label>
          <Select
            id="template"
            name="template"
            isSearchable
            placeholder="Select Template or type to search"
            styles={customSelectStyles}
            options={PHOptions}
            value={formData.template || ""}
            onChange={(selectedOption) => handleSelectChange('template', selectedOption)}
          />
        </div>

      </div>

      <SpacerDiv rem={1}/>

      <p className='instruction-text'>Add Custom Text to the Statement</p>
      <div className='profile-input-container'>
        <textarea
          id='customText'
          name='customText'
          value={formData.customText || ""}
          onChange={(e: any) => handleInputChange(e, 'modules')}
          placeholder='Any text entered here will appear on the statement...'
        />
      </div>

      <SpacerDiv rem={0.5}/>

      <div className='section-container full-width'>
        <p className='section-heading'>Modules List</p>
        <button 
          className='btn-add' 
          style={{ margin: 0}}
          onClick={(e) => handleAddModule(e)}
        >
          Add Module
        </button>
        <SpacerDiv rem={0.5}/>

        <div className='form-grid three big-middle'>
          <p className='instruction-text'>Code</p>
          <p className='instruction-text'>Name</p>
          <p className='instruction-text'>Flags</p>
        </div>

        <div className='horizontal-divider'/>

        {modulesList.length ?  
          <div className='alt-container'>
            {modulesList.map((data, index) => (
              <div className='form-grid three big-middle alt-row' key={index}>
                <p className='data-row'>{data.code}</p>
                <p className='data-row'>{data.name}</p>
                <p className='data-row'>{data.flags}</p>
              </div>
            ))}
         </div>
          : 
          <div className='alt-container'>
            <div className='form-grid three big-middle alt-row'>
              <p className='data-row'>No modules selected</p>
              <p className='data-row'>No modules selected</p>
              <p className='data-row'>No modules selected</p>
            </div>
          </div>
        }
      </div>

    </form>
  );
};

export default ModulesContent;
