// This file contains all the generic functions that are used in handling form components.
export const handleSubmit = (e: React.FormEvent, formName: string, formData: {[key: string]: any} ) => {
  e.preventDefault();
  const formDataToSubmit = new FormData();
  const currentFormData = formData[formName];
  if (!currentFormData) {
    console.error(`Form data for ${formName} not found.`);
    return;
  }

  Object.keys(currentFormData).forEach((key) => {
    formDataToSubmit.append(key, currentFormData[key as keyof typeof currentFormData]);
  });

  formDataToSubmit.forEach((value, key) => {
    console.log(`${key}:`, value);
  });
};

export const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,formName: string, formStates: any) => {
  const { name, value } = e.target;
  const setFormData = formStates[formName];

  if (setFormData) {
    setFormData((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  } else {
    console.error(`Form setter for ${formName} not found.`);
  }
};

export const handleSelectChange = (formName: string, formStates: any) => {
  return (fieldName: string, selectedOption: any) => {
    const setFormData = formStates[formName];

    if (setFormData) {
      setFormData((prev: any) => ({
        ...prev,
        [fieldName]: selectedOption.value || null,
      }));
    } else {
      console.error(`Form setter for ${formName} not found.`);
    }
  };
};

export const handleCheckboxChange = (formName: string, formStates: any) => {
  return (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    const setFormData = formStates[formName];

    if (setFormData) {
      setFormData((prev: any) => ({
        ...prev,
        [name]: checked,
      }));
    } else {
      console.error(`Form setter for ${formName} not found.`);
    }
  };
};


