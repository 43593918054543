import store from '../State/store';
import { setEventId } from 'State/Reducers/activitySlice';

const environment = process.env.REACT_APP_ENV;


const logError = async (errorDetails) => {

    let api;
    switch(environment) {
        case 'staging':
            api = 'https://api.trainingprofessionals.com.au/';
            break;
        case 'production':
            api = 'https://api.tp.edu.au/';
            break;
        case 'development':
            api = 'https://10.1.10.49:5001/';
            break;
        default:
            api = 'https://localhost:3001/';
    }
    const endpoint = api + 'errors/logError';
    let companyCode = sessionStorage.getItem('companyCode'); // Will need to be added at login
    if (companyCode === '') {
        companyCode = 1
    }
       // Get what we need from the store
    const state = store.getState();
    const roleId = state.user.id;
    const accessLevel = state.user.accessLevel;
    const roles = JSON.stringify(state.user.roles);
    const ipAddress = state.user.ipAddress;
    const origin = 'Student Portal'
    const requestBody = JSON.stringify({
        companyCode,
        roleId,
        accessLevel,
        roles,
        origin,
        ipAddress,
        endpoint: errorDetails.endpoint,
        page: errorDetails.page,
        dataSubmitted: errorDetails.dataSubmitted,
        dataReturned: errorDetails.dataReturned,
        errorLine: errorDetails.line,
        errorCode: errorDetails.errorCode,
        errorMsg: errorDetails.errorMsg,
        errorStack: errorDetails.errorStack,
        errorDesc: errorDetails.errorDesc,
        erroneousData: errorDetails.data,

    });

    try {
        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: 'include', // Ensure credentials are handled correctly server-side
            body: requestBody
        });

        if (response.ok) {
            const data = await response.json();
            console.log('Error logged successfully');

            // If the server responds with an eventId, store it in the activity slice
            if (data && data.eventId) {
                store.dispatch(setEventId(data.eventId));
            }
            return data;
        } else {
            throw new Error(`Failed to log error, server responded with status: ${response.status}`);
        }
    } catch (err) {
        console.error('Failed to log error:', err);
    }
};

export default logError;

