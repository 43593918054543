// noinspection ES6MissingAwait

import React, { Component } from "react";
import Dropzone from "../dropzone/Dropzone";
import "./Upload.css";
import Progress from "../progress/Progress";
import imgCheck from "../../../Images/imgCheck.svg";
import { Modal, Container, Row, Col } from 'react-bootstrap';
import { Document, Page, pdfjs } from "react-pdf";
import Tesseract from "tesseract.js";
import { v4 as uuidv4 } from 'uuid';


class Upload extends Component {

    constructor(props) {
        super(props);
        this.selectedStudents = [];
        this.selectedModules = [];
        this.state = {
            files: [],
            fileMetadata: [],
            uploading: false,
            uploadProgress: {},
            successfullyUploaded: false,
            selectedStudents: [],
            matchedStudentNames: [],
            hasUserSelected: [],
            showModal: false,
            numPages: null,
            selectedModules: [],
            pageNumber: 1,
            selectedFileForPreview: null,
            studentData: { students: [] }
        };

        this.uploadFiles = this.uploadFiles.bind(this);
        this.sendRequest = this.sendRequest.bind(this);
        this.renderActions = this.renderActions.bind(this);
        this.startActualUpload = this.startActualUpload.bind(this);
        this.onDocumentLoad = this.onDocumentLoad.bind(this);
        this.onFileSelectForPreview = this.onFileSelectForPreview.bind(this);

        pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    }

    onFileSelectForPreview(file) {
        this.setState({ selectedFileForPreview: file });
    }

    componentDidMount() {
        const classDetails = sessionStorage.getItem('classDetails');
        if (classDetails) {
            console.log('classDetails loaded:', classDetails);
            this.setState({ studentData: JSON.parse(classDetails) });



        } else {
            console.log('No classDetails found in sessionStorage.');

            // Log error
        }

        pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    }

    // extractOcrMetadata = (file) => {
    //     return new Promise((resolve, reject) => {
    //         const reader = new FileReader();
    //         reader.onload = function () {
    //             const pdfData = new Uint8Array(this.result);
    //
    //             pdfjs.getDocument({ data: pdfData }).promise.then(async (pdf) => {
    //                 const page = await pdf.getPage(1);
    //                 const textContent = await page.getTextContent();
    //                 const ocrMeta = textContent.items.map(item => item.str).join(' ');
    //
    //                 const viewport = page.getViewport({ scale: 1.0 });
    //                 const canvas = document.createElement('canvas');
    //                 const context = canvas.getContext('2d');
    //                 canvas.height = viewport.height;
    //                 canvas.width = viewport.width;
    //
    //                 const renderContext = {
    //                     canvasContext: context,
    //                     viewport: viewport,
    //                 };
    //                 await page.render(renderContext).promise;
    //
    //                 // Perform OCR with advanced settings
    //                 const { data } = await Tesseract.recognize(canvas, 'eng', {
    //                     tessedit_char_whitelist: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789',
    //                     psm: Tesseract.PSM.SINGLE_BLOCK,
    //                 });
    //
    //                 resolve({ ocrMeta, ocrText: data.text });
    //             }).catch(reject);
    //         };
    //         reader.readAsArrayBuffer(file);
    //     });
    // };

    extractOcrMetadata = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = function () {
                const pdfData = new Uint8Array(this.result);

                pdfjs.getDocument({ data: pdfData }).promise.then(async (pdf) => {
                    const page = await pdf.getPage(1);
                    const textContent = await page.getTextContent();
                    let ocrMeta = textContent.items.map(item => item.str).join(' ');

                    if (ocrMeta && ocrMeta.trim() !== '') {
                        console.log('Using existing OCR Meta:', ocrMeta);
                    } else {
                        const viewport = page.getViewport({ scale: 1.0 });
                        const canvas = document.createElement('canvas');
                        const context = canvas.getContext('2d');
                        canvas.height = viewport.height;
                        canvas.width = viewport.width;

                        const renderContext = {
                            canvasContext: context,
                            viewport: viewport,
                        };
                        await page.render(renderContext).promise;

                        // Perform OCR with Tesseract
                        const { data } = await Tesseract.recognize(canvas, 'eng', {
                            tessedit_char_whitelist: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789',
                            psm: Tesseract.PSM.SINGLE_BLOCK,
                        });

                        ocrMeta = data.text;
                        console.log('Using Tesseract OCR Meta:', ocrMeta);
                    }

                    resolve({ ocrMeta });
                }).catch(reject);
            };
            reader.onerror = function (error) {
                console.error('FileReader error:', error);
                reject(error);
            };
            reader.readAsArrayBuffer(file);
        });
    };

    // onFilesAdded = async (files) => {
    //     const newFiles = files.filter(file => !this.state.files.some(f => f.name === file.name));
    //
    //     const newFileMetadata = newFiles.map(file => ({
    //         file,
    //         studentName: '',
    //         moduleName: '',
    //         newName: file.name,
    //         studentNumber: '',
    //         certCode: ''
    //     }));
    //
    //     this.setState(prevState => ({
    //         files: prevState.files.concat(newFiles),
    //         fileMetadata: prevState.fileMetadata.concat(newFileMetadata),
    //         selectedFileForPreview: newFiles.length > 0 ? newFiles[0] : prevState.selectedFileForPreview
    //     }));
    //
    //     newFiles.forEach(async (file, index) => {
    //         try {
    //             const { ocrMeta, ocrText } = await this.extractOcrMetadata(file);
    //             const ocrData = { fileName: file.name, ocrMeta, ocrText };
    //             const apiResponse = await this.postOcrDataToApi(ocrData);
    //
    //             this.setState(prevState => {
    //                 const updatedMetadata = [...prevState.fileMetadata];
    //                 updatedMetadata[index] = {
    //                     ...updatedMetadata[index],
    //                     studentName: apiResponse.studentName,
    //                     moduleName: apiResponse.moduleName,
    //                     newName: apiResponse.newName,
    //                     studentNumber: apiResponse.studentNumber,
    //                     certCode: apiResponse.certCode
    //                 };
    //                 return { fileMetadata: updatedMetadata };
    //             });
    //         } catch (error) {
    //             console.error("Failed to process OCR metadata:", error);
    //         }
    //     });
    // };

    onFilesAdded = async (files) => {
        const newFiles = files.filter(file => !this.state.files.some(f => f.name === file.name));

        const newFileMetadata = newFiles.map(file => ({
            id: uuidv4(), // Generate a unique identifier for the file
            file,
            studentName: '',
            moduleName: '',
            newName: file.name,
            studentNumber: '',
            certCode: ''
        }));

        this.setState(prevState => ({
            files: prevState.files.concat(newFiles),
            fileMetadata: prevState.fileMetadata.concat(newFileMetadata),
            selectedFileForPreview: newFiles.length > 0 ? newFiles[0] : prevState.selectedFileForPreview
        }));

        newFiles.forEach(async (file, index) => {
            const fileId = newFileMetadata[index].id; // Retrieve the unique identifier for the file
            try {
                console.log('Processing file:', file.name);
                const { ocrMeta } = await this.extractOcrMetadata(file);
                // console.log('Extracted OCR Meta:', ocrMeta);

                const ocrData = { fileId, fileName: file.name, ocrMeta }; // Include the unique identifier
                const apiResponse = await this.postOcrDataToApi(ocrData);

                this.setState(prevState => {
                    const updatedMetadata = prevState.fileMetadata.map(meta => {
                        if (meta.id === fileId) {
                            return {
                                ...meta,
                                studentName: apiResponse.studentName,
                                moduleName: apiResponse.moduleName,
                                newName: apiResponse.newName,
                                studentNumber: apiResponse.studentNumber,
                                certCode: apiResponse.certCode
                            };
                        }
                        return meta;
                    });
                    return { fileMetadata: updatedMetadata };
                });
            } catch (error) {
                // console.error("Failed to process OCR metadata:", error);

                // Log error

            }
        });
    };



    postOcrDataToApi = async (ocrData) => {
        const classData = JSON.parse(sessionStorage.getItem('classDetails'));

        try {
            const response = await fetch('https://api.trainingprofessionals.com.au/as/processOCR', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify({ ocrMetaData: ocrData, classData })
            });

            if (!response.ok) throw new Error('Failed to fetch data');

            const data = await response.json();
            return { ...data, fileId: ocrData.fileId }; // Include the fileId in the response

        } catch (error) {

            // console.error('Failed to fetch class details:', error);

            // Log error

            throw error;


        }
    };

    handleChangeFileName = (index, event) => {
        const newFileMetadata = [...this.state.fileMetadata];
        newFileMetadata[index].newName = event.target.value;
        this.setState({ fileMetadata: newFileMetadata });
    };

    onDocumentLoad({ numPages }) {
        this.setState({ numPages });
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    };

    onStudentSelect = (index, name) => {
        this.selectedStudents[index] = name;
    };

    onModuleSelect = (index, code) => {
        this.selectedModules[index] = code;
    };

    handleStudentChange = (metaIndex, value) => {
        const newFileMetadata = [...this.state.fileMetadata];
        newFileMetadata[metaIndex].studentName = value;

        // Find the selected student's number
        const selectedStudent = this.state.studentData.students.find(student => student.name === value);
        newFileMetadata[metaIndex].studentNumber = selectedStudent ? selectedStudent.studentNumber : '';

        this.updateFileName(metaIndex); // Update filename after state update
        this.setState({ fileMetadata: newFileMetadata });
    };

    // handleStudentChange = (metaIndex, value) => {
    //     const newFileMetadata = [...this.state.fileMetadata];
    //     newFileMetadata[metaIndex].studentName = value;
    //
    //     const selectedStudent = this.state.studentData.students.find(student => student.name === value);
    //     newFileMetadata[metaIndex].studentNumber = selectedStudent ? selectedStudent.studentNumber : '';
    //
    //     this.setState({ fileMetadata: newFileMetadata }, () => {
    //         this.updateFileName(metaIndex); // Ensure filename is updated after state is set
    //     });
    // };

    handleModuleChange = (metaIndex, value) => {
        const newFileMetadata = [...this.state.fileMetadata];
        newFileMetadata[metaIndex].moduleName = value;

        this.setState({ fileMetadata: newFileMetadata }, () => {
            this.updateFileName(metaIndex);
        });
    };

    handleNameChange = (index, newName) => {
        const updatedMetadata = this.state.fileMetadata.slice(); // Clone the array to avoid direct state mutation
        updatedMetadata[index].newName = newName; // Update the newName based on the input
        this.setState({ fileMetadata: updatedMetadata });
    };

    updateFileName = (index) => {
        const meta = this.state.fileMetadata[index];
        if (meta.studentName && meta.moduleName) {
            const newName = `${meta.studentName.replace(/\s/g, "_")}_${meta.moduleName}_ma.pdf`;
            const newFileMetadata = [...this.state.fileMetadata];
            newFileMetadata[index].newName = newName;
            this.setState({ fileMetadata: newFileMetadata });
        }
    };

    // updateFileName = (index) => {
    //     const meta = this.state.fileMetadata[index];
    //     if (meta.studentName && meta.moduleName) {
    //         const newName = `${meta.studentName.replace(/\s/g, "_")}_${meta.moduleName}_ma.pdf`;
    //         const newFileMetadata = [...this.state.fileMetadata];
    //         newFileMetadata[index].newName = newName;
    //         this.setState({ fileMetadata: newFileMetadata });
    //     }
    // };

    async uploadFiles() {
        const { studentData, files } = this.state;

        this.setState({
            uploadProgress: {},
            uploading: true,
            showModal: true,
            selectedStudent: files.map(() => studentData.students[0] ? studentData.students[0].name : ''), // Use this.state.studentData
            selectedFileForPreview: files[0]
        });  // showModal set to true
    }

    startActualUpload = async () => {
        this.setState({ uploadProgress: {}, uploading: true });
        const promises = this.state.fileMetadata.map(meta => {
            const selectedStudent = this.state.studentData.students.find(student => student.name === meta.studentName);
            const studentNumber = selectedStudent ? selectedStudent.studentNumber : '';
            const certCode = selectedStudent ? selectedStudent.certCode : ''; // Get certCode

            return this.sendRequest(meta.file, meta.newName, studentNumber, meta.moduleName, certCode); // Pass certCode
        });
        try {
            await Promise.all(promises);
            this.setState({ successfullyUploaded: true, uploading: false });
            this.setState({ showModal: false });
        } catch (e) {
            this.setState({ uploading: false });
        }
    };

    handleClose = () => {
        this.setState({ showModal: false });
    };

    sendRequest = (file, newName, studentNumber, moduleCode, certCode) => {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append("file", file, newName || file.name);
            formData.append("studentNumber", studentNumber); // Add studentNumber to the form data
            formData.append("moduleCode", moduleCode); // Add moduleCode to the form data
            formData.append("certCode", certCode); // Add certCode

            // Log the metadata for debugging
            console.log("Uploading file:", {
                fileName: newName || file.name,
                studentNumber: studentNumber,
                moduleCode: moduleCode,
                certCode: certCode, // Log certCode
                originalFileName: file.name
            });

            const req = new XMLHttpRequest();

            req.upload.addEventListener("progress", event => {
                if (event.lengthComputable) {
                    const copy = { ...this.state.uploadProgress };
                    copy[file.name] = {
                        state: "pending",
                        percentage: (event.loaded / event.total) * 100
                    };
                    this.setState({ uploadProgress: copy });
                }
            });

            req.upload.addEventListener("load", () => {
                const copy = { ...this.state.uploadProgress };
                copy[file.name] = { state: "done", percentage: 100 };
                this.setState({ uploadProgress: copy });
                resolve(req.response);
            });

            req.upload.addEventListener("error", () => {
                const copy = { ...this.state.uploadProgress };
                copy[file.name] = { state: "error", percentage: 0 };
                this.setState({ uploadProgress: copy });
                reject("Failed to upload file.");
            });

            req.open("POST", "https://api.trainingprofessionals.com.au/calendar/upload");
            req.send(formData);
        });
    };

    renderProgress(file) {
        const uploadProgress = this.state.uploadProgress[file.name];
        if (this.state.uploading || this.state.successfullyUploaded) {
            return (
                <div className="ProgressWrapper">
                    <Progress progress={uploadProgress ? uploadProgress.percentage : 0} />
                    <img
                        className="CheckIcon"
                        alt="done"
                        src={imgCheck}
                        style={{
                            opacity: uploadProgress && uploadProgress.state === "done" ? 0.5 : 0
                        }}
                    />
                </div>
            );
        }
    }

    renderActions() {
        if (this.state.successfullyUploaded) {
            return (
                <button
                    onClick={() =>
                        this.setState({ files: [], successfullyUploaded: false })
                    }
                >
                    Clear
                </button>
            );
        } else {
            return (
                <button
                    disabled={this.state.files.length < 0 || this.state.uploading}
                    onClick={this.uploadFiles}
                >
                    Upload
                </button>
            );
        }
    }

    render() {
        return (
            <div className="Upload">
                <span className="Title">Upload Files</span>
                <div className="Content">
                    <div>
                        <Dropzone
                            onFilesAdded={this.onFilesAdded}
                            disabled={this.state.uploading || this.state.successfullyUploaded}
                        />
                    </div>
                    <div className="Files">
                        {this.state.files.map(file => {
                            return (
                                <div key={file.name} className="Row">
                                    <span className="Filename">{file.name}</span>
                                    {this.renderProgress(file)}
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="Actions">{this.renderActions()}</div>

                <Modal show={this.state.showModal} onHide={this.handleClose}>

                    <Modal.Header closeButton>
                        <Modal.Title>Sort Files</Modal.Title>
                    </Modal.Header>

                    <div style={{ display: 'flex' }}>
                        <Modal.Body style={{ height: 'auto', width: '50%', position: 'relative' }}>
                            <Container fluid style={{ width: '100%' }}>
                                <Row style={{ display: 'flex', flexWrap: 'nowrap' }}>
                                    <Col xs={12} style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                                        {/* File List */}
                                        <Row>
                                            <Col style={{ flex: '1 1 0' }} xs={12} md={4}><strong>Filename</strong></Col>
                                            <Col style={{ flex: '1 1 0' }} xs={12} md={4}><strong>Student Name</strong></Col>
                                            <Col style={{ flex: '1 1 0' }} xs={12} md={4}><strong>Module</strong></Col>
                                        </Row>
                                        {this.state.files.map((file, index) => {
                                            const meta = this.state.fileMetadata[index];

                                            const studentsWithIncompleteModules = this.state.studentData.students.filter(student =>
                                                student.modules.some(module => module.status !== 'Literally Anything')
                                            );

                                            return (
                                                <div key={file.name + index} onClick={() => this.onFileSelectForPreview(file)} onFocus={() => this.onFileSelectForPreview(file)} style={{ cursor: 'pointer' }}>
                                                    <Row style={{ display: 'flex' }}>
                                                        <Col xs={4}>
                                                            <input
                                                                type="text"
                                                                value={meta.newName}
                                                                onChange={(e) => this.handleNameChange(index, e.target.value)}
                                                                onBlur={(e) => this.handleNameChange(index, e.target.value)}
                                                                required
                                                            />
                                                        </Col>
                                                        <Col xs={4}>
                                                            <select
                                                                value={meta.studentName}
                                                                onChange={(e) => this.handleStudentChange(index, e.target.value)}
                                                                required
                                                            >
                                                                <option value="">Select Student</option>
                                                                {studentsWithIncompleteModules.map((student, studentIndex) => (
                                                                    <option key={studentIndex} value={student.name}>{student.name}</option>
                                                                ))}
                                                            </select>
                                                        </Col>
                                                        {/*<Col xs={4}>*/}
                                                        {/*    <select*/}
                                                        {/*        value={meta.moduleName}*/}
                                                        {/*        onChange={(e) => this.handleModuleChange(index, e.target.value)}*/}
                                                        {/*        required*/}
                                                        {/*    >*/}
                                                        {/*        /!*Filter - Change null to whatever you like*!/*/}
                                                        {/*        <option value="">Select Module</option>*/}
                                                        {/*        {this.state.studentData.students.find(student => student.name === meta.studentName)?.modules.filter(module => module.status !== null).map((module, moduleIndex) => (*/}
                                                        {/*            <option key={moduleIndex} value={module.code}>{module.code}</option>*/}
                                                        {/*        ))}*/}
                                                        {/*    </select>*/}
                                                        {/*</Col>*/}
                                                        <Col xs={4}>
                                                            <select
                                                                value={meta.moduleName}
                                                                onChange={(e) => this.handleModuleChange(index, e.target.value)}
                                                                required
                                                            >
                                                                <option value="">Select Module</option>
                                                                {this.state.studentData.students.find(student => student.name === meta.studentName)?.modules.map((module, moduleIndex) => (
                                                                    <option key={moduleIndex} value={module.name}>{module.name}</option>
                                                                ))}
                                                            </select>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            );
                                        })}
                                    </Col>
                                </Row>
                            </Container>
                        </Modal.Body>

                        {this.state.selectedFileForPreview && (
                            <div style={{
                                position: 'sticky',
                                top: '50px', // Adjust as necessary to keep it fixed from the top
                                right: '0', // Adjust as necessary to keep it fixed from the right
                                width: '50%',
                                height: '1080px',
                                padding: '25px',
                                overflow: 'hidden',
                                backgroundColor: '#fff', // To ensure visibility
                                zIndex: 2000, // Ensure it's above other elements
                                border: '1px solid #ccc', // Optional styling
                                borderRadius: 15,
                                display: 'flex',
                                justifyContent: 'center',
                            }}>
                                <Document file={this.state.selectedFileForPreview}>
                                    <Page pageNumber={1} />
                                </Document>
                            </div>
                        )}
                    </div>

                    <Modal.Footer>
                        <button onClick={this.startActualUpload}>Start Upload</button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default Upload;
