const studentData = {

    "students": [
    {
        "name": "Jonah Clancy",
        "studentNumber": 50158,
        "certCode": 5000,
        "modules": [
            {"name": "AHCLSC301", "status": "C"},
            {"name": "AHCLSC302", "status": "NYC"},
            {"name": "AHCLSC303", "status": "C"},
            {"name": "AHCLSC304", "status": "C"},
            {"name": "AHCLSC305", "status": "NYC"},
            {"name": "AHCLSC306", "status": "C"},
            {"name": "AHCLSC307", "status": "NYC"},
            {"name": "AHCLSC308", "status": "C"},
            {"name": "AHCLSC309", "status": "C"},
            {"name": "AHCLSC310", "status": "NYC"},
            {"name": "AHCLSC311", "status": "C"},
            {"name": "AHCLSC312", "status": "NYC"},
            {"name": "AHCLSC313", "status": "C"}
        ]
    },
    {
        "name": "David Bitmanis",
        "studentNumber": 60252,
        "certCode": 5000,
        "modules": [
            {"name": "AHCLSC301", "status": "NYC"},
            {"name": "AHCLSC302", "status": "C"},
            {"name": "AHCLSC303", "status": "C"},
            {"name": "AHCLSC304", "status": "NYC"},
            {"name": "AHCLSC305", "status": "C"},
            {"name": "AHCLSC306", "status": "C"},
            {"name": "AHCLSC307", "status": "NYC"},
            {"name": "AHCLSC308", "status": "C"},
            {"name": "AHCLSC309", "status": "NYC"},
            {"name": "AHCLSC310", "status": "C"},
            {"name": "AHCLSC311", "status": "C"},
            {"name": "AHCLSC312", "status": "NYC"},
            {"name": "AHCLSC313", "status": "C"}
        ]
    },
    {
        "name": "Jeremiah Tkatchenko",
        "modules": [
            {"name": "AHCLSC301", "status": "NYC"},
            {"name": "AHCLSC302", "status": "NYC"},
            {"name": "AHCLSC303", "status": "NYC"},
            {"name": "AHCLSC304", "status": "NYC"},
            {"name": "AHCLSC305", "status": "NYC"},
            {"name": "AHCLSC306", "status": "NYC"},
            {"name": "AHCLSC307", "status": "NYC"},
            {"name": "AHCPCM301", "status": "NYC"},
            {"name": "AHCPCM302", "status": "NYC"},
            {"name": "AHCPGD301", "status": "NYC"},
            {"name": "AHCSOL304", "status": "NYC"},
            {"name": "AHCDRG301", "status": "NYC"},
            {"name": "AHCCHM304", "status": "NYC"},
            {"name": "AHCCHM307", "status": "NYC"}
        ]
    },
    {
        "name": "Jett Young",
        "modules": [
            {"name": "AHCLSC301", "status": "NYC"},
            {"name": "AHCLSC302", "status": "NYC"},
            {"name": "AHCLSC303", "status": "C"},
            {"name": "AHCLSC304", "status": "C"},
            {"name": "AHCLSC305", "status": "NYC"},
            {"name": "AHCLSC306", "status": "NYC"},
            {"name": "AHCLSC307", "status": "C"},
            {"name": "AHCLSC308", "status": "C"},
            {"name": "AHCLSC309", "status": "NYC"},
            {"name": "AHCLSC310", "status": "C"},
            {"name": "AHCLSC311", "status": "NYC"},
            {"name": "AHCLSC312", "status": "C"},
            {"name": "AHCLSC313", "status": "C"}
        ]
    },
    {
        "name": "Daniel Crompton",
        "modules": [
            {"name": "AHCLSC301", "status": "C"},
            {"name": "AHCLSC302", "status": "C"},
            {"name": "AHCLSC303", "status": "NYC"},
            {"name": "AHCLSC304", "status": "C"},
            {"name": "AHCLSC305", "status": "NYC"},
            {"name": "AHCLSC306", "status": "NYC"},
            {"name": "AHCLSC307", "status": "C"},
            {"name": "AHCLSC308", "status": "NYC"},
            {"name": "AHCLSC309", "status": "C"},
            {"name": "AHCLSC310", "status": "NYC"},
            {"name": "AHCLSC311", "status": "C"},
            {"name": "AHCLSC312", "status": "C"},
            {"name": "AHCLSC313", "status": "NYC"}
        ]
    },
    {
        "name": "Liam Mcleish",
        "modules": [
            {"name": "AHCLSC301", "status": "C"},
            {"name": "AHCLSC302", "status": "C"},
            {"name": "AHCLSC303", "status": "NYC"},
            {"name": "AHCLSC304", "status": "C"},
            {"name": "AHCLSC305", "status": "C"},
            {"name": "AHCLSC306", "status": "NYC"},
            {"name": "AHCLSC307", "status": "C"},
            {"name": "AHCLSC308", "status": "C"},
            {"name": "AHCLSC309", "status": "NYC"},
            {"name": "AHCLSC310", "status": "C"},
            {"name": "AHCLSC311", "status": "C"},
            {"name": "AHCLSC312", "status": "NYC"},
            {"name": "AHCLSC313", "status": "C"}
        ]
    },
    {
        "name": "Luke Lister",
        "modules": [
            {"name": "AHCLSC301", "status": "NYC"},
            {"name": "AHCLSC302", "status": "C"},
            {"name": "AHCLSC303", "status": "NYC"},
            {"name": "AHCLSC304", "status": "C"},
            {"name": "AHCLSC305", "status": "C"},
            {"name": "AHCLSC306", "status": "NYC"},
            {"name": "AHCLSC307", "status": "NYC"},
            {"name": "AHCLSC308", "status": "C"},
            {"name": "AHCLSC309", "status": "C"},
            {"name": "AHCLSC310", "status": "NYC"},
            {"name": "AHCLSC311", "status": "C"},
            {"name": "AHCLSC312", "status": "C"},
            {"name": "AHCLSC313", "status": "NYC"}
        ]
    },
    {
        "name": "Tom Mann",
        "modules": [
            {"name": "AHCLSC301", "status": "C"},
            {"name": "AHCLSC302", "status": "NYC"},
            {"name": "AHCLSC303", "status": "C"},
            {"name": "AHCLSC304", "status": "NYC"},
            {"name": "AHCLSC305", "status": "C"},
            {"name": "AHCLSC306", "status": "C"},
            {"name": "AHCLSC307", "status": "NYC"},
            {"name": "AHCLSC308", "status": "C"},
            {"name": "AHCLSC309", "status": "NYC"},
            {"name": "AHCLSC310", "status": "C"},
            {"name": "AHCLSC311", "status": "C"},
            {"name": "AHCLSC312", "status": "NYC"},
            {"name": "AHCLSC313", "status": "C"}
        ]
    },
    {
        "name": "Jonah Clancy",
        "modules": [
            {"name": "AHCLSC301", "status": "C"},
            {"name": "AHCLSC302", "status": "NYC"},
            {"name": "AHCLSC303", "status": "C"},
            {"name": "AHCLSC304", "status": "NYC"},
            {"name": "AHCLSC305", "status": "C"},
            {"name": "AHCLSC306", "status": "C"},
            {"name": "AHCLSC307", "status": "NYC"},
            {"name": "AHCLSC308", "status": "C"},
            {"name": "AHCLSC309", "status": "NYC"},
            {"name": "AHCLSC310", "status": "C"},
            {"name": "AHCLSC311", "status": "C"},
            {"name": "AHCLSC312", "status": "NYC"},
            {"name": "AHCLSC313", "status": "C"}
        ]
    },
    {
        "name": "William Thomas",
        "modules": [
            {"name": "AHCLSC301", "status": "C"},
            {"name": "AHCLSC302", "status": "NYC"},
            {"name": "AHCLSC303", "status": "NYC"},
            {"name": "AHCLSC304", "status": "C"},
            {"name": "AHCLSC305", "status": "NYC"},
            {"name": "AHCLSC306", "status": "C"},
            {"name": "AHCLSC307", "status": "C"},
            {"name": "AHCLSC308", "status": "NYC"},
            {"name": "AHCLSC309", "status": "C"},
            {"name": "AHCLSC310", "status": "C"},
            {"name": "AHCLSC311", "status": "NYC"},
            {"name": "AHCLSC312", "status": "C"},
            {"name": "AHCLSC313", "status": "NYC"}
        ]
    },
    {
        "name": "Elizabeth Garcia",
        "modules": [
            {"name": "AHCLSC301", "status": "C"},
            {"name": "AHCLSC302", "status": "C"},
            {"name": "AHCLSC303", "status": "NYC"},
            {"name": "AHCLSC304", "status": "C"},
            {"name": "AHCLSC305", "status": "C"},
            {"name": "AHCLSC306", "status": "NYC"},
            {"name": "AHCLSC307", "status": "C"},
            {"name": "AHCLSC308", "status": "C"},
            {"name": "AHCLSC309", "status": "NYC"},
            {"name": "AHCLSC310", "status": "C"},
            {"name": "AHCLSC311", "status": "C"},
            {"name": "AHCLSC312", "status": "NYC"},
            {"name": "AHCLSC313", "status": "C"}
        ]
    }
]

};

export default studentData;