 export const customSelectStyles = {
  control: (baseStyles: any, state: any) => ({
    ...baseStyles,
    borderColor: state.isFocused ? '#d0d0d0' : '#d0d0d0',
    boxShadow: state.isFocused ? '0 0 5px #955cce' : 'none',
    '&:hover': {
      borderColor: '#955cce',
    },
    height: '45px',
    marginTop: '5px',
  }),
  option: (baseStyles: any, state: any) => ({
    ...baseStyles,
    backgroundColor: state.isFocused ? '#c1acd9' : 'white',
    color: state.isFocused ? 'white' : 'black',
    textAlign: 'left',
  }),
  placeholder: (baseStyles: any) => ({
    ...baseStyles,
    color: 'gray',
    fontStyle: 'italic',
    textAlign: 'left',
  }),
};