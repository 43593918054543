import { useDispatch } from 'react-redux';
import { setUserWithEncryption, clearUser } from '../State/Reducers/userSlice';
import UserInterface from '../Types/userInterface';
import { AppDispatch } from '../State/store';

export const useUserData = () => {
    const dispatch = useDispatch<AppDispatch>();

    const setUserData = (data: any) => {
        const userData: UserInterface = {
            email: data.email,
            ipAddress: data.ipAddress,
            username: data.username,
            fullName: data.user_name,
            phoneNumber: data.phone_number,
            accessLevel: data.access_level,
            user_id: data.user_id,
            roleId: data.role_id,
            roles: data.roles,
            loggedIn: true,
        };

        // Dispatch the thunk to handle encryption and setting the user
        dispatch(setUserWithEncryption(userData));
    };

    const clearUserData = () => {
        // Dispatch the action to clear the user
        dispatch(clearUser());
    };

    return { setUserData, clearUserData };
};
