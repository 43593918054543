export { default as Administration } from './Administration';
export { default as AssessmentMarking } from './AssessmentMarking';
export { default as AssessmentReview } from './AssessmentReview';
export { default as Businesses } from './Businesses';
export { default as C3gFeedback } from './C3gFeedback';
export { default as Calendar } from './Calendar';
export { default as CertificatesModules } from './CertificatesModules';
export { default as Changelog } from './Changelog';
export { default as Classroom } from './Classroom';
export { default as Contact } from './Contact';
export { default as CoursesTraining } from './CoursesTraining';
export { default as Competencies } from './Competencies';
export { default as Documentation } from './Documentation';
export { default as DocumentCentre } from './DocumentCentre';
export { default as DocumentCreator } from './DocumentCreator';
export { default as Drive } from './Drive';
export { default as Employment } from './Employment';
export { default as History } from './History';
export { default as Home } from './Home';
export { default as Login } from './Login';
export { default as People } from './People';
export { default as PersonalProfile } from './PersonalProfile';
export { default as Register } from './Register';
export { default as RegisterInterest } from './RegisterInterest';
export { default as ReviewCentre } from './ReviewCentre';
export { default as Scraper } from './Scraper';
export { default as SetupAccount } from './SetupAccount';
export { default as SiteManagementSystem } from './SiteManagementSystem';
export { default as Tasks } from './Tasks';
export { default as Template } from './Template';
export { default as TemplateBoxes } from './TemplateBoxes';
export { default as TemplateSteps } from './TemplateSteps';
export { default as Test } from './Test';
export { default as Verify } from './Verify';


// Businesses
export { default as BusinessProfile } from './Businesses/BusinessProfile';
export {default as VenuesView} from './Businesses/VenuesView';

// Calender
export { default as ClassEdit } from './Calender/ClassEdit';
export { default as MyClasses } from './Calender/MyClasses';

// CertificatesModules
export { default as CertificateCreation } from './CertificatesModules/CertificateCreation';
export { default as Certificates } from './CertificatesModules/Certificates';
export { default as CertificateTemplate } from './CertificatesModules/CertificateTemplate';
export { default as CertificateTemplates } from './CertificatesModules/CertificateTemplates';
export { default as ClassAttendance } from './CertificatesModules/ClassAttendance';
export { default as ModuleBilling } from './CertificatesModules/ModuleBilling';
export { default as Modules } from './CertificatesModules/Modules';
export { default as ModuleVisibilityCurrent } from './CertificatesModules/ModuleVisibilityCurrent';
export { default as ModuleVisibilityOld } from './CertificatesModules/ModuleVisibilityOld';

// Documentation
export { default as ComplianceAuditing } from './Documentation/ComplianceAuditing';
export { default as DocumentRepository } from './Documentation/DocumentRepository';
export { default as Marketing } from './Documentation/Marketing';
export { default as PoliciesProcedures } from './Documentation/PoliciesProcedures';
export { default as SQEMP } from './Documentation/SQEMP';

// ReviewCentre
export { default as LearnerResourceReview } from './ReviewCentre/LearnerResourceReview';
export { default as PendingEventActionSubmissions } from './ReviewCentre/PendingEventActionSubmissions';
export { default as PendingTemplateSubmissions } from './ReviewCentre/PendingTemplateSubmissions';
export { default as QaModuleReview } from './ReviewCentre/QaModuleReview';
export { default as QaReviewList } from './ReviewCentre/QaReviewList';

// Tasks
export { default as Marking } from './Tasks/Marking';
export { default as NewTask } from './Tasks/NewTask';
export { default as UnassignedTasks } from './Tasks/UnassignedTasks';

// Administration
export { default as Metrics } from './Administration/Metrics';
export { default as Reports } from './Administration/Reports';
export { default as Setup } from './Administration/Setup';
export { default as Tools } from './Administration/Tools';
export { default as Uploads } from './Administration/Uploads';

// Administration/Reports
export { default as ActiveCertificateReport } from './Administration/Reports/ActiveCertificateReport';
export { default as AvetmissReport } from './Administration/Reports/AvetmissReport';
export { default as BackupReport } from './Administration/Reports/BackupReport';
export { default as BillingReport } from './Administration/Reports/BillingReport';
export { default as CertificatesUnassignedReport } from './Administration/Reports/CertificatesUnassignedReport';
export { default as EmailReport } from './Administration/Reports/EmailReport';
export { default as EventReports } from './Administration/Reports/EventReports';
export { default as InvalidUsiReport } from './Administration/Reports/InvalidUsiReport';
export { default as InvoiceReminders } from './Administration/Reports/InvoiceReminders';
export { default as LuiReport } from './Administration/Reports/LuiReport';
export { default as ModuleKpis } from './Administration/Reports/ModuleKpis';
export { default as ModuleReports } from './Administration/Reports/ModuleReports';
export { default as OutstandingInvoicesReport } from './Administration/Reports/OutstandingInvoicesReport';
export { default as QiCcos } from './Administration/Reports/QiCcos';
export { default as RegisteredInterestReport } from './Administration/Reports/RegisteredInterestReport';
export { default as ReportSettings } from './Administration/Reports/ReportSettings';
export { default as SupersededModuleReport } from './Administration/Reports/SupersededModuleReport';
export { default as UsiReport } from './Administration/Reports/UsiReport';
export { default as ViewFundingPrices } from './Administration/Reports/ViewFundingPrices';
export { default as ViewPaymentData } from './Administration/Reports/ViewPaymentData';
export { default as WhiteCardReport } from './Administration/Reports/WhiteCardReport';

// Administration/Setup
export { default as Accounting } from './Administration/Setup/Accounting';
export { default as Branding } from './Administration/Setup/Branding';
export { default as LogicEngine } from './Administration/Setup/LogicEngine';
export { default as CreateFundingPrices } from './Administration/Setup/CreateFundingPrices';
export { default as EventActionEditor } from './Administration/Setup/EventActionEditor';
export { default as IntegrationSettings } from './Administration/Setup/IntegrationSettings';
export { default as Permissions } from './Administration/Setup/Permissions';
export { default as ScraperOld } from './Administration/Setup/ScraperOld';
export { default as SearchEventActions } from './Administration/Setup/SearchEventActions';
export { default as TaskGroupEdit } from './Administration/Setup/TaskGroupEdit';
export { default as Templates } from './Administration/Setup/Templates';

// Administration/Tools
export { default as BillingFixReportsTool } from './Administration/Tools/BillingFixReportsTool';
export { default as BulkUploadTas } from './Administration/Tools/BulkUploadTas';
export { default as BulkVerifyUsis } from './Administration/Tools/BulkVerifyUsis';
export { default as CertificateTransitionTool } from './Administration/Tools/CertificateTransitionTool';
export { default as CreditTransferTool } from './Administration/Tools/CreditTransferTool';
export { default as DevTools } from './Administration/Tools/DevTools';
export { default as EventViewer } from './Administration/Tools/EventViewer';
export { default as DictionaryManager } from './Administration/Tools/DictionaryManager';
export { default as StatementFixName } from './Administration/Tools/StatementFixName';
export { default as UsiGenerator } from './Administration/Tools/UsiGenerator';

// Administration/Uploads
export { default as ScheduleAUploader } from './Administration/Uploads/ScheduleAUploader';
export { default as UploadApprovedTrainingSnapshot } from './Administration/Uploads/UploadApprovedTrainingSnapshot';
export { default as UploadAssessment } from './Administration/Uploads/UploadAssessment';
export { default as UploadAvetmissReport } from './Administration/Uploads/UploadAvetmissReport';
export { default as UploadDetDataErrorValidation } from './Administration/Uploads/UploadDetDataErrorValidation';
export { default as UploadDetModulesSnapshot } from './Administration/Uploads/UploadDetModulesSnapshot';
export { default as UploadDetPayment } from './Administration/Uploads/UploadDetPayment';
export { default as UploadLegacyAssessment } from './Administration/Uploads/UploadLegacyAssessment';
export { default as UploadNominalHours } from './Administration/Uploads/UploadNominalHours';
export { default as UploadUserChoicePriceList } from './Administration/Uploads/UploadUserChoicePriceList';

// Dev Tools
export { default as BugReport } from './Dev/BugReport';
export { default as Construction } from './Dev/Construction';
export { default as Error } from './Dev/Error';

// Testing components: REMOVE BEFORE PRODUCTION
export { default as ActivityLogger } from './Dev/ActivityLogger';
export { default as Profile } from './Profile';
export { default as ClassEditNew } from './Calender/ClassEditNew';