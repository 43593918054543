import { combineReducers } from 'redux';
import userReducer from './userSlice';
import modalReducer from './refreshLoginSlice';
import activityReducer from './activitySlice';
import keyReducer from './keySlice';

const rootReducer = combineReducers({
  user: userReducer,
  refreshLogin: modalReducer,
  activityMonitor: activityReducer,
  encryptionKey: keyReducer,
});

// Define RootState without allowing undefined
export type RootState = {
  user: NonNullable<ReturnType<typeof userReducer>>;
  refreshLogin: NonNullable<ReturnType<typeof modalReducer>>;
  activityMonitor: NonNullable<ReturnType<typeof activityReducer>>;
  encryptionKey: NonNullable<ReturnType<typeof keyReducer>>;
};

export default rootReducer;
