import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import SpacerDiv from "./SpacerDiv";
import CustomAlert from "./CustomAlert";
import "../Styling/index.css";
import "./Styles/LoginComponent.scss";
import useFetchApi from "../Services/FetchApi";
import { useUserData } from "CustomHooks";
import { useDispatch } from "react-redux";
import { hideRefreshLogin } from "State/Reducers/refreshLoginSlice";

const LoginComponent = ({ isModal = false }) => {
  const navigate = useNavigate();
  const fetchApi = useFetchApi();
  const { setUserData } = useUserData();
  const [inputs, setInputs] = useState({ longExpiry: false });
  const [msg, setMsg] = useState(window.sessionStorage.getItem("message"));
  const [msgCode, setMsgCode] = useState(0);
  console.log(msgCode);
  const [showPassword, setShowPassword] = useState(false);
  const { clearUserData } = useUserData();
  const dispatch = useDispatch();
  const passwordRef = useRef(null);

  const handleLogin = (data) => {
    setUserData(data);
    navigate("/");
  };

  const handleLogout = () => {
    console.log("Logging out...");
    navigate("/login");
    clearUserData();
    dispatch(hideRefreshLogin());
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  function togglePasswordVisibility() {
    if (passwordRef.current) {
      passwordRef.current.type = showPassword ? "password" : "text";
      setShowPassword(!showPassword);
    }
  }

  const handleTPLogin = async (event) => {
    event.preventDefault();

    fetchApi("user/login", inputs)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          handleLogin(response.responseData);
          dispatch(hideRefreshLogin());
          setMsgCode(0);
        } else {
          setMsg(response.msg);
          setMsgCode(1);
        }
      })
      .catch((error) => {
        setMsg(
          "An unexpected error occurred. Contact your administrator" + error
        );
        setMsgCode(4);
      });
  };

  return (
    <div className={isModal ? 'login-container-modal' : 'login-container'}>
        <form onSubmit={handleTPLogin}>
            <div className={isModal ? 'login-box-modal' : 'login-box'}>
            {!isModal &&
            <>
              <SpacerDiv height="10vh" />
               <h1 className="login-header">
                PLEASE ENTER YOUR USERNAME AND PASSWORD
              </h1>
            </>
            }

              <CustomAlert msg={msg} msgCode={msgCode} />

              <input
                className='inputBox login-input'
                type="text"
                name="identifier"
                placeholder="USERNAME"
                onChange={handleChange}
                title="Your UserName is the email Address or Phone number you signed up with."
                required
              />

              {/* Password Input */}
              <div style={{ width: '100%', position: 'relative' }}>
                  <input
                    className='inputBox login-input'
                    type="password"
                    name="password"
                    placeholder="PASSWORD"
                    onChange={handleChange}
                    ref={passwordRef}
                    title="Ensure your password has at least 8 characters including 1 uppercase, 1 lowercase, 1 number, and 1 special character"
                  />
                  <FontAwesomeIcon
                    icon={showPassword ? faEyeSlash : faEye}
                    onClick={togglePasswordVisibility}
                    className="password-toggle"
                  />
              </div>

              <input type="hidden" name="longExpiry" value="false" />

              {/* Button Group */}
              <div className="button-group">
                <button className="btn-primary" type="submit">
                  SIGN IN
                </button>
                <button
                  className="btn-primary"
                  type="button"
                  onClick={isModal ? handleLogout : () => navigate("/")}
                >
                  CANCEL
                </button>
              </div>
            </div>
        </form>
      </div>
  );
};

// @ts-ignore
export default LoginComponent;