import { useState, useEffect } from 'react';

function debounce(func: Function, delay: number) {
  let timeout: NodeJS.Timeout;
  return (...args: any[]) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      func(...args);
    }, delay);
  };
}

// IMPORTANT: The useEffect needs to be debounced to prevent triggering renders on every change in px count
// We may need to play around with the debounce delay to find the sweet spot
export const useViewport = (breakpoint = 800, debounceDelay = 20) => {
  const [isMobileDevice, setIsMobileDevice] = useState<boolean>(window.innerWidth < breakpoint);
  const [isTouchDevice, setIsTouchDevice] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = debounce(() => {
      setIsMobileDevice(window.innerWidth < breakpoint);
    }, debounceDelay);

    const handleTouchStart = () => {
      setIsTouchDevice(true);
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('touchstart', handleTouchStart, { once: true });

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('touchstart', handleTouchStart);
    };
  }, [breakpoint, debounceDelay]);

  const showMobile = isMobileDevice || isTouchDevice;

  return { showMobile };
};
