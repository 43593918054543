export const annotations = [


    {
        name: "checkbox1",
        x: 8.5,
        y: 20.2,
        label: "✓",
        color: "dark-blue",
        fontSize: "35px",
        fontWeight: "bold",
        input: "checkbox",
        timestamp: "2024-09-12 12:34:56",
    },
    {
        name: "checkbox2",
        x: 8.5,
        y: 25,
        label: "✓",
        color: "dark-blue",
        fontSize: "35px",
        fontWeight: "bold",
        input: "checkbox",
        timestamp: "2024-09-12 12:34:56",
    },
    {
        name: "Outcome1",
        x: 86.4,
        y: 20.4,
        label: "✓",
        color: "dark-blue",
        fontSize: "35px",
        fontWeight: "bold",
        input: "checkbox",
        timestamp: "2024-09-12 12:34:56",
    },
    {
        name: "Outcome2",
        x: 86.4,
        y: 24.5,
        label: "✓",
        color: "dark-blue",
        fontSize: "35px",
        fontWeight: "bold",
        input: "checkbox",
        timestamp: "2024-09-12 12:34:56",
    },
    {
        name: "SignatureBox",
        x: 42,
        y: 57.5,
        color: "rgba(255, 255, 0, 0.3)",
        width: 33,
        height: 3,
        timestamp: "2024-09-12 12:34:56",
    },
    {
        name: "TrainerName",
        x: 12,
        y: 55.5,
        label: "Enter trainers name",
        color: "Black",
        fontSize: "14px",
        fontWeight: "bold",
        input: "text",
        timestamp: "2024-09-12 12:34:56",
    },
    {
        name: "DateInput1",
        x: 77,
        y: 55.5,
        color: "Black",
        fontSize: "14px",
        fontWeight: "bold",
        input: "date",
        timestamp: "2024-09-12 12:34:56",
        date: null,
    },
    {
        name: "AssessorName",
        x: 12,
        y: 60.2,
        label: "Enter assessors name",
        color: "Black",
        fontSize: "14px",
        fontWeight: "bold",
        input: "text",
        timestamp: "2024-09-12 12:34:56",
    },
    {
        name: "DateInput2",
        x: 77,
        y: 60.2,
        color: "Black",
        fontSize: "14px",
        fontWeight: "bold",
        input: "date",
        timestamp: "2024-09-12 12:34:56",
        date: new Date(),
    },
    {
        name: "ContentExpert",
        x: 12,
        y: 64.8,
        label: "Enter content expert",
        color: "Black",
        fontSize: "13px",
        fontWeight: "bold",
        input: "text",
        timestamp: "2024-09-12 12:34:56",
    },
    {
        name: "DateInput3",
        x: 77,
        y: 64.8,
        color: "Black",
        fontSize: "14px",
        fontWeight: "bold",
        input: "date",
        timestamp: "2024-09-12 12:34:56",
        date: null,
    },
    {
        name: "EmployerName",
        x: 12,
        y: 81.2,
        label: "Enter employers name...",
        color: "Black",
        fontSize: "14px",
        fontWeight: "bold",
        input: "text",
        timestamp: "2024-09-12 12:34:56",
    },
    {
        name: "DateInput4",
        x: 77,
        y: 81.2,
        color: "Black",
        fontSize: "14px",
        fontWeight: "bold",
        input: "date",
        timestamp: "2024-09-12 12:34:56",
        date: null,
    },
];
