import React from 'react';
import { sign } from 'Images';
import { useNavigate } from 'react-router-dom';

import '../Styles/error.css';

interface ErrorProps {};

const Error: React.FC<ErrorProps> = () => {
    const navigate = useNavigate();

    return (
      <div className='error-page-container'>
        <div className='sign-container'>
          <img src={sign} alt='Road Sign' className='sign' />
          <div className='error-message'>ERROR 404</div>
          <button className='home-button' onClick={() =>navigate('/')}>HOME</button>
          <button className='back-button' onClick={() =>navigate(-1)}>GO BACK</button>
        </div>
      </div>
    )
};

export default Error;
