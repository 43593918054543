import React, { useEffect, useState } from 'react';
import {
  TabbedContainer, 
  DetailsContent,
  EducationContent,
  EmploymentContent,
 } from 'Components';
import { useUserDeets } from "CustomHooks";
import * as FT from 'Types/formInterfaces';

import './Styles/profile.scss';

interface ProfileProps {
  // content: React.ReactNode[];
}

const tabs = [
  'Details', 
  'Education', 
  'Employment', 
  'Authorisations', 
  'Achievements', 
  'In Progress',
  'Notes'
];

// This is just a placeholder for the statuses
// These will be fetched from the server
const statuses = [0, 1, 2, 3, 0, 0];

const Profile: React.FC<ProfileProps> = () => {
  const [profilePicture, setProfilePicture] = useState<File | null>(null);
  const [preview, setPreview] = useState<string | null>(null);
  const [sameAsResidential, setSameAsResidential] = useState<boolean>(true);
  const user = useUserDeets();

  // Handle user.fullName splitting on spaces
  const userNameArray = user?.fullName?.split(' ');
  let userFirstName = '';
  let userMiddleName = '';
  let userLastName = '';
  switch (userNameArray?.length) {
    case 1:
      userFirstName = userNameArray[0];
      break;
    case 2:
      userFirstName = userNameArray[0];
      userLastName = userNameArray[1];
      break;
    case 3:
      userFirstName = userNameArray[0];
      userMiddleName = userNameArray[1];
      userLastName = userNameArray[2];
      break;
    default:
      break;
  }


  /////////////////////////////// FORM STATE MANAGEMENT //////////////////////////////////////
  const [detailsFormData, setDetailsFormData] = useState({
    preferedName: user.username || '',
    title: '',
    firstName: userFirstName || '',
    middleName: userMiddleName || '',
    lastName: userLastName || '',
    gender: '',
    mobileNumber: user.phoneNumber || '',
    workNumber: '',
    homeNumber: '',
    addressLine1: '',
    addressLine2: '',
    suburb: '',
    state: '',
    postcode: '',
    address2Line1: '',
    address2Line2: '',
    suburb2: '',
    state2: '',
    postcode2: '',
    contactName: '',
    contactNumber: '',
    previousName: '',
  });

  const [educationFormData, setEducationFormData] = useState<FT.EducationFormData>({
    usi: '',
    literacy: '',
    diet: '',
    access: '',
    challenges: [],
    support: '',
    secondarySchool: null,
    lui: '',
    highestCompleted: '',
    yearCompleted: '',
    priorQuals: [],
    otherQuals: '',
  });

  const [employmentFormData, setEmploymentFormData] = useState<FT.EmploymentFormData>({
    employer: {
      employer: '',
      currentRole: '',
      requester: '',
      requestedTime: new Date(),
      finishedTime: new Date(),
      emailCompetencies: '',
    },
    supervisor: {
      supervisor: '',
      mobileNumber: '',
      requester: '',
      requestedTime: new Date(),
      finishedTime: new Date(),
    },
    currentEmploymentStatus: '',
    whyTraining: '',
  });

  // const 

  // NOTE: This is a mapping of form names to their respective setter functions
  // MAKE SURE TO ADD MORE FORMS HERE AS THEY ARE CREATED 
  const formStates: any = {
    'details': setDetailsFormData,
    'education': setEducationFormData,
    'employment': setEmploymentFormData,
  }
  // AND HERE for the data to be passed to the form components
  const formData: { [key: string]: any } = {
    details: detailsFormData,
    education: educationFormData,
    employment: employmentFormData,
  };
  
  
  
  /////////////////////////////// HELPER FUNCTIONS //////////////////////////////////////
  const handleSubmit = (e: React.FormEvent, formName: string) => {
    e.preventDefault();
  
    const formDataToSubmit = new FormData();
  
    // Dynamically access the form data based on formName
    const currentFormData = formData[formName];
  
    if (!currentFormData) {
      console.error(`Form data for ${formName} not found.`);
      return;
    }
  
    // Append the form data to FormData object
    Object.keys(currentFormData).forEach((key) => {
      formDataToSubmit.append(key, currentFormData[key as keyof typeof currentFormData]);
    });
  
    // If profile picture exists (conditionally based on form name)
    if (formName === 'details' && profilePicture) {
      formDataToSubmit.append('profilePicture', profilePicture);
    }
  
    // TO DO: Send formDataToSubmit to the server
    formDataToSubmit.forEach((value, key) => {
      console.log(`${key}:`, value);
    });
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
    formName: string
  ) => {
    const { name, value } = e.target;
    const setFormData = formStates[formName];
  
    if (setFormData) {
      setFormData((prev: any) => ({
        ...prev,
        [name]: value,
      }));
    } else {
      console.error(`Form setter for ${formName} not found.`);
    }
  };

   const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      try {
        setProfilePicture(file);

        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result as string;
          setPreview(base64String);
        };
        reader.readAsDataURL(file);
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
  };

 // Initial render the postal address = residential address
  useEffect(() => {
    if (sameAsResidential) {
      setDetailsFormData((prev) => ({
        ...prev,
        address2Line1: prev.addressLine1,
        address2Line2: prev.addressLine2,
        suburb2: prev.suburb,
        state2: prev.state,
        postcode2: prev.postcode,
      }));
    }
  }, [sameAsResidential, detailsFormData.addressLine1, detailsFormData.addressLine2, detailsFormData.suburb, detailsFormData.state, detailsFormData.postcode]);

  // The checkbox handler remains the same
  const handleCheckboxChange = () => {
    setSameAsResidential((prevState) => {
      const newState = !prevState;

      if (newState) {
        setDetailsFormData((prev) => ({
          ...prev,
          address2Line1: prev.addressLine1,
          address2Line2: prev.addressLine2,
          suburb2: prev.suburb,
          state2: prev.state,
          postcode2: prev.postcode,
        }));
      } else {
        setDetailsFormData((prev) => ({
          ...prev,
          address2Line1: '',
          address2Line2: '',
          suburb2: '',
          state2: '',
          postcode2: '',
        }));
      }
      return newState;
    });
  };
  

  const handleRadioChange = (formName: string, fieldName: string, value: string) => {
    const formSetter = formStates[formName]; // Dynamically get the setter function
  
    if (formSetter) {
      formSetter((prev: any) => ({
        ...prev,
        [fieldName]: value, // Update the specific field with the new value
      }));
    } else {
      console.error(`No form setter found for form: ${formName}`);
    }
  };
  

  const handleCheckboxGroupChange = (e: any, groupName: keyof FT.EducationFormData) => {
    const { name, checked } = e.target;
    console.log('name:', name);
  
    setEducationFormData((prev) => ({
      ...prev,
      [groupName]: checked
        ? [...(prev[groupName] as string[]), name] // Add if checked
        : (prev[groupName] as string[]).filter((item) => item !== name), // Remove if unchecked
    }));
  };
  
  // console.log('detailsFormData:', detailsFormData);
  // console.log('educationFormData:', educationFormData);

  const content = [
    // Tab 0
    <DetailsContent
      profilePicture={profilePicture}
      preview={preview}
      handleFileChange={handleFileChange}
      sameAsResidential={sameAsResidential}
      handleCheckboxChange={handleCheckboxChange}
      formData={detailsFormData}
      setFormData={setDetailsFormData}
      handleInputChange={(e) => handleInputChange(e, 'details')}
      handleSubmit={(e) => handleSubmit(e, 'details')} 
    />,
  
    // Tab 1
    <EducationContent
      key="education"
      formData={educationFormData}
      setFormData={setEducationFormData}
      handleInputChange={(e) => handleInputChange(e, 'education')}
      handleRadioChange={handleRadioChange}
      handleCheckboxGroupChange={handleCheckboxGroupChange}
      handleSubmit={(e) => handleSubmit(e, 'education')}  
    />,
    
    // Tab 2
    <EmploymentContent
      key="employment"
      formData={employmentFormData}
      setFormData={setEmploymentFormData}
      handleInputChange={(e) => handleInputChange(e, 'employment')}
      handleRadioChange={handleRadioChange}
      handleCheckboxGroupChange={handleCheckboxGroupChange}
      handleSubmit={(e) => handleSubmit(e, 'employment')}  
    />,
    
    // Tab 3
    <form className='form-container' onSubmit={(e) => handleSubmit(e, 'authorisations')} id="form-3">
      Authorisations
    </form>,
  
    // Tab 4
    <form className='form-container' onSubmit={(e) => handleSubmit(e, 'achievements')} id="form-4">
      Achievements
    </form>,
  
    // Tab 5
    <form className='form-container' onSubmit={(e) => handleSubmit(e, 'in progress')} id="form-5">
      In Progress
    </form>,
  
    // Tab 6
    <form className='form-container' onSubmit={(e) => handleSubmit(e, 'notes')} id="form-6">
      Notes
    </form>,
  ];

  return (
     <TabbedContainer tabs={tabs} content={content} statuses={statuses}/>
  );
};

export default Profile;