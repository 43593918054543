import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useUserDeets } from 'CustomHooks';
import emailjs from '@emailjs/browser';  // Import EmailJS SDK

import '../Styles/bugReportForm.scss';
import SpacerDiv from 'Components/SpacerDiv';

const BugReportForm: React.FC = () => {
  const user = useUserDeets();
  const initialEventId = useSelector((state: any) => state.activityMonitor.eventId);

  const [eventId, setEventId] = useState(initialEventId);

  const handleEventChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEventId(e.target.value);
  };

  const initialFormData = {
    eventId: eventId || 'N/A',
    name: user.fullName || '',
    email: user.email || '',
    accessLevel: user.accessLevel || 0,
    description: ''
  };

  const [formData, setFormData] = useState(initialFormData);
  const [screenshot, setScreenshot] = useState<File | null>(null);
  const [preview, setPreview] = useState<string | null>(null);
  const [formStatus, setFormStatus] = useState('');

  // Handle form input changes
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleRemoveScreenshot = () => {
    setScreenshot(null);
    setPreview(null);
  };

  // Handle file change and compression
  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      try {
        setScreenshot(file);

        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result as string;
          setPreview(base64String); // Set the image preview
        };
        reader.readAsDataURL(file);
      } catch (error) {
        console.error('Error compressing file:', error);
        setFormStatus('Failed to process the image. Try again.');
      }
    }
  };

  // Handle form submission and sending the email
  const sendEmail = () => {
    const templateParams = {
      to_name: 'TP Devs',
      from_name: formData.name,
      reply_to: formData.email,
      description: formData.description,
      eventId: eventId || 'No event ID avaliable',
      attachment: preview ? `data:image/jpeg;base64,${preview.split(',')[1]}` : '',
    };
    
    emailjs.send(
      'service_2ejdo3r',
      'template_uj0nvio',
      templateParams,
      '0BDy_XfuMJhfQI6UO'
    )
      .then((response: any) => {
        console.log('SUCCESS!', response.status, response.text);
        setFormStatus('Bug report submitted and emailed!');
      })
      .catch((error: any) => {
        console.error('FAILED...', error);
        setFormStatus('Failed to submit bug report.');
      });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    sendEmail();
    setFormData(initialFormData);
    setScreenshot(null);
    setPreview(null);
  };

  return (
    <div className='bug-report-form-container'>
      <h2>Report a Bug</h2>
      <form onSubmit={handleSubmit} className='bug-report-form'>
        
      {/* Event ID */}
        <div className='bug-report-input-container'>
          <label htmlFor='name'>Event ID:</label>
          <input
              type="number"
              value={eventId}
              onChange={handleEventChange}
            />
        </div>
 
        {/* Name field */}
        <div className='bug-report-input-container'>
          <label htmlFor='name'>Name:</label>
          <input
            type='text'
            id='name'
            name='name'
            value={formData.name}
            onChange={handleInputChange}
            required
          />
        </div>

        {/* Email field */}
        <div className='bug-report-input-container'>
          <label htmlFor='email'>Email:</label>
          <input
            type='email'
            id='email'
            name='email'
            value={formData.email}
            onChange={handleInputChange}
            required
          />
        </div>

        {/* Description field */}
        <div className='bug-report-input-container column'>
          <p>Please provide a description of the issue...</p>
          <textarea
            id='description'
            name='description'
            placeholder='What were you doing and where were you in the application when the bug occurred?'
            value={formData.description}
            onChange={handleInputChange}
            required
          />
        </div>

        {/* Screenshot upload */}
        <div className='bug-report-input-container column'>
          <SpacerDiv rem={0.5} />
          {!preview && <p className='important-text'>Please add a screenshot to help fix the issue...</p>}
          <input
            type='file'
            id='screenshot'
            name='screenshot'
            accept='image/*'
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />

          {preview && (
            <div className='img-preview'>
              <img src={preview} alt='Screenshot preview' />
            </div>
          )}
          {preview ? (
            <>
              <button type='button' className='btn-file-input remove' onClick={handleRemoveScreenshot}>
                REMOVE SCREENSHOT
              </button>
              <SpacerDiv rem={0.5} />
            </>
          ) : (
            <button type='button' className='btn-file-input' onClick={() => document.getElementById('screenshot')?.click()}>
              ADD SCREENSHOT
            </button>
          )}
       
        </div>

        {/* Submit button */}
        <div className='bug-submit'>
          <button type='submit' className='btn-primary'>Submit Bug Report</button>
        </div>
      </form>

      {/* Form status feedback */}
      {formStatus && <p>{formStatus}</p>}
    </div>
  );
};

export default BugReportForm;
