import React from 'react';
import { SpacerDiv } from 'Components';
import { EducationFormData } from 'Types/formInterfaces';

interface AuthorisationsContentProps {
  formData: any;
  setFormData: any;
  // eslint-disable-next-line no-unused-vars
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, name: string) => void;
  // eslint-disable-next-line no-unused-vars
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  // eslint-disable-next-line no-unused-vars
  handleCheckboxGroupChange: (e: React.ChangeEvent<HTMLInputElement>, groupName: keyof EducationFormData) => void;
  // eslint-disable-next-line no-unused-vars
  handleRadioChange: (formName: string, fieldName: string, value: string) => void;
}

const AuthorisationsContent: React.FC<AuthorisationsContentProps> = ({
  formData,
  setFormData,
  handleInputChange,
  handleSubmit,
  handleCheckboxGroupChange,
  handleRadioChange,
}) => {
  return (
    <form className='form-container' onSubmit={handleSubmit} id="form-3">
   
    </form>
  );
};

export default AuthorisationsContent;
