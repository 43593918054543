import React from 'react';
import { VideoPlayer, AudioPlayer, SpacerDiv } from '../../Components';

const MediaPage: React.FC = () => {

  return (
    <div className="MediaPage">
      <SpacerDiv />
      <h2>Audio & Video Tests</h2>

      {/* Video element */}
      <div style={{ marginTop: '20px' }}>
        <VideoPlayer src="https://www.w3schools.com/html/mov_bbb.mp4" width={400} controls />
      </div>

      {/* Audio element */}
      <div style={{ marginTop: '20px' }}>
        <AudioPlayer src="https://www.w3schools.com/html/horse.mp3" controls />
      </div>
    </div>
  );
};

export default MediaPage;
