import React, {useContext, useState, useRef, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import {ConstLink} from "ConstLink";
// import Select from 'react-select';
// import BorderedComponent from 'Components/BorderedComponent';
// import CurrencyInput from 'react-currency-input-field';
// import QRCode from "react-qr-code"
import QRCodeStyling from "qr-code-styling";
// import UploadDropzone from 'Components/UploadDropzone';  No longer Used
// import Upload from "Components/Dropzone/upload/Upload";
// import {Container, Row, Col } from 'react-bootstrap';
import { useUserField } from 'CustomHooks';

// import FileUpload from 'Components/FileUpload';


import QRLogo from 'Images/QRLogo.png';
export default function TemplateSteps() {

    const navigate = useNavigate();
    const isLoggedIn = useUserField('loggedIn');
    const { setQuickMenu, _setStepButtons } = useContext(ConstLink);
    const [_pageName, setPageName] = useState('');
    // const [QRCodeVal, setQRCodeVal] = useState("QR Code Value will link to class details");
    const [activeTab, setActiveTab] = useState('Tab 1');
    const [url, _setUrl] = useState("QR Code Value will link to class details");
    // const [QRCode, QRCode2] = useRef(null);
    const QRCode = useRef(null)
    const QRCode2 = useRef(null)
    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(formData);

        switch (activeTab) {
            case 'Tab 1':
                setActiveTab('Tab 2');
                break;
            case 'Tab 2':
                setActiveTab('Tab 3');
                break;
            case 'Tab 3':
                setActiveTab('Tab 4');
                break;
            case 'Tab 4':
                setActiveTab('Tab 5');
                break;
            case 'Tab 5':
                setActiveTab('Tab 6');
                break;
            case 'Tab 6':
                setActiveTab('Tab 7');
                break;
            case 'Tab 7':
                setActiveTab('Tab 8');
                break;
            default:
                break;
        }
    };
    const handleChangeTab = (tabIndex) => {
        handleTabClick(tabIndex)
    }


    if ( !isLoggedIn ) {
        navigate('/login')
    }
    function cloneQRCode(qrCode) {
        const config = JSON.parse(JSON.stringify(qrCode._options));
        return new QRCodeStyling(config);
    }

    useEffect(() => {
        updateQuickMenu();
        const pathArray = window.location.pathname.split('/');
        const pageParam = pathArray[pathArray.length - 1];
        setPageName(pageParam || 'Default Page');
        qrCode.append(QRCode.current);

        const qrCodeClone = cloneQRCode(qrCode);
        qrCodeClone.append(QRCode2.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        qrCode.update({
            data: url
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url]);

    // const onUrlChange = (event) => {
    //     event.preventDefault();
    //     setUrl(event.target.value);
    // };

    const updateQuickMenu = () => {
        setQuickMenu(
            ["Item 1", 'route'],
            ["Item 2", 'route'],
            ["Item 3", 'route'],
            ["Item 4", 'route'],
            ["Item 5", 'route'],
            ["Item 6", 'route'], );
    };

    // Helper function to get a formatted date in the format "YYYY-MM-DD"
    const getFormattedDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    // const [cboBox1, setCboBox1] = useState('');

    // const options = [
    //     { value: 'option1', label: 'Option 1' },
    //     { value: 'option2', label: 'Option 2' },
    //     { value: 'option3', label: 'Option 3' },
    //     // Add more options as needed as this is your dropdown choices and would normally be pulled from the DBase
    // ];

    // const handleCombo1Change = (selectedOption) => {
    //     console.log(selectedOption); // Do something with the selected option
    // };

    useEffect(() => {
        const tabContents = document.getElementsByClassName('tabcontent');

        for (let i = 0; i < tabContents.length; i++) {
            if (tabContents[i].id === activeTab) {
                tabContents[i].style.display = 'block';
            } else {
                tabContents[i].style.display = 'none';
            }
        }
    }, [activeTab]);

    const [formData, setFormData] = useState({
        textInputVal: '',
        dropdownVal: '',
        startDateVal: getFormattedDate(new Date()),
        finishDateVal: getFormattedDate(new Date()),
        startTimeVal: '08:00',
        finishTimeVal: '15:00',
        classMinimumVal: '',
        classMaximumVal: '',
        classCostVal: '',
        isPrivateVal: 'checked',
        venueVal: '',
        borderedComponentVal: '',
    });

    const _handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;
        const newValue = type === 'checkbox' ? checked : value;
        setFormData((prevData) => ({
            ...prevData,
            [name]: newValue
        }));
    };


    // const customSelectStyles = {
    //     control: (baseStyles, state) => ({
    //         ...baseStyles,
    //         borderColor: state.isFocused ? '#d0d0d0' : '#d0d0d0', // Border color changes on focus
    //         boxShadow: state.isFocused ? '0 0 5px #955cce' : 'none', // Box shadow changes on focus
    //         '&:hover': {
    //             borderColor: '#955cce', // Border color changes on hover
    //         },
    //     }),
    //     option: (baseStyles, state) => ({
    //         ...baseStyles,
    //         backgroundColor: state.isFocused ? '#c1acd9' : 'white', // Background color changes on option hover
    //         color: state.isFocused ? 'white' : 'black',
    //     }),
    // };

    const [isChecked, setIsChecked] = useState(true);
    const [_isPrivate, setIsPrivate] = useState(true)
    const [_isPrivateMsg, setIsPrivateMsg] = useState('Class is Private')

    const _handleCheckboxChange = () => {
        if (isChecked) {
            // setIsChecked((prevIsChecked) => !prevIsChecked);
            setIsChecked(false);
            setIsPrivate(false);
            setIsPrivateMsg('Class is Public')
        } else {
            setIsChecked(true);
            setIsPrivate(true);
            setIsPrivateMsg('Class is Private')
        }

    };

    // Style the QR Code
    const qrCode = new QRCodeStyling({
        width: 300,
        height: 300,
        image: QRLogo,
        dotsOptions: {
            color: "#393939",
            type: "classy"
        },
        imageOptions: {
            imageSize: 0.4,
            crossOrigin: "anonymous",
            margin: 5
        },
        backgroundOptions: {
            color: "transparent"
        },
        cornersSquareOptions: {
            type: "square",
            color: "#955CCE",
        }
    });

    return (

        <div>

            <div
                style={{
                    height: '100px',
                    width: '100%',
                }}
            ></div>
{/* ======================================================This is where the number of arrowed tabs are set================================================================== */}

            <div className="container justify-content-left align-items-center" style={{ position: 'relative', zIndex: 0}}>
                <div className="col-12">
                    <div className="tab" style={{ display: 'flex'}}>
                        <div className="arrow-tab2" style={{ display: 'flex', justifyContent: 'flex-start', zIndex: 10 }}>
                            <div className={activeTab === 'Tab 1' ? 'arrow-tab active' : 'arrow-tab'}
                                 onClick={() => handleTabClick('Tab 1')}
                                // style={{zIndex: 94}}
                            >
                                Tab 1
                            </div>

                        </div>

                        <div className="arrow-tab2" style={{ display: 'flex', justifyContent: 'flex-start', zIndex: 9  }}>
                            <div className={activeTab === 'Tab 2' ? 'arrow-tab active' : 'arrow-tab'}
                                 onClick={() => handleTabClick('Tab 2')}
                                // style={{zIndex: 94}}
                            >
                                Tab 2
                            </div>

                        </div>

                        <div className="arrow-tab2" style={{ display: 'flex', justifyContent: 'flex-start', zIndex: 8 }}>
                            <div className={activeTab === 'Tab 3' ? 'arrow-tab active' : 'arrow-tab'}
                                 onClick={() => handleTabClick('Tab 3')}
                                // style={{zIndex: 94}}
                            >
                                Tab 3
                            </div>
                        </div>
                        <div className="arrow-tab2" style={{ display: 'flex', justifyContent: 'flex-start', zIndex: 7  }}>
                            <div className={activeTab === 'Tab 4' ? 'arrow-tab active' : 'arrow-tab'}
                                 onClick={() => handleTabClick('Tab 4')}
                                // style={{zIndex: 94}}
                            >
                                Tab 4
                            </div>
                        </div>
                        <div className="arrow-tab2" style={{ display: 'flex', justifyContent: 'flex-start', zIndex: 6 }}>
                            <div className={activeTab === 'Tab 5' ? 'arrow-tab active' : 'arrow-tab'}
                                 onClick={() => handleTabClick('Tab 5')}
                                // style={{zIndex: 94}}
                            >
                                Tab 5
                            </div>
                        </div>
                        <div className="arrow-tab2" style={{ display: 'flex', justifyContent: 'flex-start', zIndex: 5  }}>
                            <div className={activeTab === 'Tab 6' ? 'arrow-tab active' : 'arrow-tab'}
                                 onClick={() => handleTabClick('Tab 6')}
                                // style={{zIndex: 94}}
                            >
                                Tab 6
                            </div>
                        </div>
                        <div className="end-tab2" style={{ zIndex: 4 }}>
                            <div className={activeTab === 'Tab 7' ? 'arrow-tab active' : 'arrow-tab'}
                                 onClick={() => handleTabClick('Tab 7')}
                                // style={{zIndex: 94}}
                            >
                                Tab 7
                            </div>
                        </div>
                    </div>


{/* ===================================================================== TAB 1 =========================================================================*/}
{/* each arrowed Tab has its own master DIV with the id named there and refering to the tab setup above the TAB 1 marker.  All div rules are respected   */}

                <div id="Tab 1" className="tabcontent">

                    <form onSubmit={handleSubmit}>


                    </form>
                    <div style={{ display: 'flex', justifyContent: 'right', width: '100%', padding: 25}}>

                        {/*<button type="button" onClick={() => handleChangeTab('Tab 1')} className={"btn-primary"}>BACK</button>*/}
                        <button type="submit"  className={"btn-primary"}>NEXT</button>

                    </div>

                </div>



{/*========================================================= TAB 2 ======================================================================*/}

                    <div id="Tab 2" className="tabcontent">

                        <form onSubmit={handleSubmit}>



                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', padding: 25}}>

                                <button type="button" onClick={() => handleChangeTab('Tab 1')} className={"btn-primary"}>BACK</button>
                                <button type="submit"  className={"btn-primary"}>NEXT</button>

                            </div>


                        </form>
                    </div>






{/* =========================================================== TAB 3 ======================================================================*/}

                    <div id="Tab 3" className="tabcontent">
                        <form onSubmit={handleSubmit}>


                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', padding: 25}}>

                                <button type="button" onClick={() => handleChangeTab('Tab 1')} className={"btn-primary"}>BACK</button>
                                <button type="submit"  className={"btn-primary"}>NEXT</button>

                            </div>
                        </form>

                    </div>

{/* =========================================================== TAB 4 ======================================================================*/}

                    <div id="Tab 4" className="tabcontent">
                        <form onSubmit={handleSubmit}>


                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', padding: 25}}>

                                <button type="button" onClick={() => handleChangeTab('Tab 1')} className={"btn-primary"}>BACK</button>
                                <button type="submit"  className={"btn-primary"}>NEXT</button>

                            </div>
                        </form>
                    </div>
{/* =========================================================== TAB 5 ======================================================================*/}

                    <div id="Tab 5" className="tabcontent">
                        <form onSubmit={handleSubmit}>


                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', padding: 25}}>

                                <button type="button" onClick={() => handleChangeTab('Tab 1')} className={"btn-primary"}>BACK</button>
                                <button type="submit"  className={"btn-primary"}>NEXT</button>

                            </div>
                        </form>

                    </div>

{/* =========================================================== TAB 6 ======================================================================*/}
{/*By commenting this out it skips this step and takes it to 6 overall  -  Don't forget to comment out the top section as well  */}

                    {/*<div id="Tab 6" className="tabcontent">*/}
                    {/*    <form onSubmit={handleSubmit}>*/}


                    {/*        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', padding: 25}}>*/}

                    {/*            <button type="button" onClick={() => handleChangeTab('Tab 1')} className={"btn-primary"}>BACK</button>*/}
                    {/*            <button type="submit"  className={"btn-primary"}>NEXT</button>*/}

                    {/*        </div>*/}
                    {/*    </form>*/}

                    {/*</div>*/}

{/* =========================================================== TAB 7 ======================================================================*/}

                    <div id="Tab 7" className="tabcontent">
                        <form onSubmit={handleSubmit}>


                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', padding: 25}}>

                                <button type="button" onClick={() => handleChangeTab('Tab 1')} className={"btn-primary"}>BACK</button>
                                {/*<button type="submit"  className={"btn-primary"}>NEXT</button>*/}

                            </div>
                        </form>
                    </div>




{/* ================================================== End Of TAB 7 ======================================================================*/}


                </div>

            </div>



        </div>
    );
}

// =============================== To Do =================================== //

// Rogue pixel hunt
// adjust for tablet view

// =============================== Bugs =================================== //

// Tabs need to have Z Value reset to incorporate Footer
// Not clickable under footer
