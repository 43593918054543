import React, {useContext, useState, useRef, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import {ConstLink} from "ConstLink";
import Select from 'react-select';
import BorderedComponent from 'Components/BorderedComponent';
import CurrencyInput from 'react-currency-input-field';
import QRCodeStyling from "qr-code-styling";
// import Upload from "Components/Dropzone/upload/Upload";
import ModuleTable from "Components/ModuleTable";
import TabFive from "Components/TabFive";
// import Search from "Components/Search";
import {Row, Col} from "react-bootstrap"
import { useUserField } from 'CustomHooks';

import QRLogo from 'Images/QRLogo.png';
export default function ClassEdit() {

    // const [studentList, setStudentList] = useState([]);
    const navigate = useNavigate();
    const isLoggedIn = useUserField('loggedIn');
    const { setQuickMenu, _setStepButtons } = useContext(ConstLink);
    const [_pageName, setPageName] = useState('');
    // const [QRCodeVal, setQRCodeVal] = useState("QR Code Value will link to class details");
    const [activeTab, setActiveTab] = useState('Tab 1');
    const [url, _setUrl] = useState("QR Code Value will link to class details");
    const QRCode = useRef(null)
    const QRCode2 = useRef(null)


    // These are all of the items for the accordion function
    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    }
        // Function to fetch the data from the database and populate the accordionData state
    const fetchStudentList = async () => {
        // Replace this with your actual database call logic
        // For example:
        // const response = await fetch('your-api-endpoint');
        // const data = await response.json();
        // setAccordionData(data);
        // For now, let's use a static example data:

        // const exampleData = [
        //     {
        //         heading: 'Student 1',
        //         mobile: '123-456-7890',
        //         billing: 'Student',
        //         usi: '12345',
        //         competent: 'Uncehcked',
        //         actions: 'Approve',
        //         extraLineItems: [
        //             { code: 'CODE-1', name: 'Item 1', evidence: 'Evidence 1' },
        //             { code: 'CODE-2', name: 'Item 2', evidence: 'Evidence 2' },
        //         ],
        //     },                {
        //         heading: 'Student 2',
        //         mobile: '123-456-7890',
        //         billing: 'Employer',
        //         usi: '12345',
        //         competent: 'Uncehcked',
        //         actions: 'Approve',
        //         extraLineItems: [
        //             { code: 'CODE-1', name: 'Item 1', evidence: 'Evidence 1' },
        //             { code: 'CODE-2', name: 'Item 2', evidence: 'Evidence 2' },
        //         ],
        //     },                {
        //         heading: 'Student 3',
        //         mobile: '123-456-7890',
        //         billing: 'Student',
        //         usi: '12345',
        //         competent: 'Uncehcked',
        //         actions: 'Approve',
        //         extraLineItems: [
        //             { code: 'CODE-1', name: 'Item 1', evidence: 'Evidence 1' },
        //             { code: 'CODE-2', name: 'Item 2', evidence: 'Evidence 2' },
        //         ],
        //     },
        //     // Add more data objects as needed
        // ];

    // setStudentList(exampleData);
};
    useEffect(() => {
        fetchStudentList();
    }, []);

    // // State to manage open/closed state of each accordion row
    // const [_expandedRows, setExpandedRows] = useState({});
    // // Function to toggle the accordion row's open/closed state
    // const toggleAccordion = (index) => {
    //     setExpandedRows((prevState) => ({
    //         ...prevState,
    //         [index]: !prevState[index],
    //     }));
    // };
    // ========= End of Accordian Function ==========

    const handleSubmit = (event) => {
        event.preventDefault();

        switch (activeTab) {
            case 'Tab 1':
                setActiveTab('Tab 2');
                break;
            case 'Tab 2':
                setActiveTab('Tab 3');
                break;
            case 'Tab 3':
                setActiveTab('Tab 4');
                break;
            case 'Tab 4':
                setActiveTab('Tab 5');
                break;
            case 'Tab 5':
                setActiveTab('Tab 6');
                break;
            case 'Tab 6':
                setActiveTab('Tab 7');
                break;
            case 'Tab 7':
                setActiveTab('Tab 8');
                break;
            // If the activeTab is 'Tab 8', do nothing as it's the last tab
            default:
                break;
        }

    };
    const handleChangeTab = (tabIndex) => {
        handleTabClick(tabIndex)
    }

    if ( !isLoggedIn ) {
        navigate('/login')
    }
    function cloneQRCode(qrCode) {
        const config = JSON.parse(JSON.stringify(qrCode._options));
        return new QRCodeStyling(config);
    }

    useEffect(() => {

        updateQuickMenu();
        const pathArray = window.location.pathname.split('/');
        const pageParam = pathArray[pathArray.length - 1];
        setPageName(pageParam || 'Default Page');
        qrCode.append(QRCode.current);
        const qrCodeClone = cloneQRCode(qrCode);
        qrCodeClone.append(QRCode2.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        qrCode.update({
            data: url
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url]);
    
    // const onUrlChange = (event) => {
    //     event.preventDefault();
    //     setUrl(event.target.value);
    // };

    const updateQuickMenu = () => {
        setQuickMenu(
            ["Marking", 'route'],
            ["My Other Classes", 'route'],
            ["Back To Calendar", 'route'],
            ["Item 4", 'route'],
            ["Item 5", 'route'],
            ["Item 6", 'route'], );
    };
    const getFormattedDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    // const [cboBox1, setCboBox1] = useState('');

    const options = [
        { value: 'option1', label: 'Option 1' },
        { value: 'option2', label: 'Option 2' },
        { value: 'option3', label: 'Option 3' },
        // Add more options as needed
    ];

    // const handleCombo1Change = (selectedOption) => {
    //     console.log(selectedOption); // Do something with the selected option
    // };

    useEffect(() => {
        const tabContents = document.getElementsByClassName('tabcontent');
            for (let i = 0; i < tabContents.length; i++) {
                if (tabContents[i].id === activeTab) {
                    tabContents[i].style.display = 'block';
                } else {
                    tabContents[i].style.display = 'none';
                }
        }
    }, [activeTab]);

    const [formData, setFormData] = useState({
        nameClassVal: '',
        templateVal: '',
        startDateVal: getFormattedDate(new Date()),
        finishDateVal: getFormattedDate(new Date()),
        startTimeVal: '08:00',
        finishTimeVal: '15:00',
        classMinimumVal: '',
        classMaximumVal: '',
        classCostVal: '',
        isPrivateVal: 'checked',
        venueVal: '',
        businessVal: '',
        trainerVal: '',
        thirdPartyVal: '',
        aenVal: '',
        assessmentMethodVal: '',
        deliveryMethodVal: '',
        deliveryModeVal: '',
        txtCustomVal: '',
        venueAddress1Val: '',
        venueAddress2Val: '',
        venueSuburbVal: '',
        venueStateVal: '',
        venuePostCodeVal: '',
        venuePhoneVal: '',
        venueWebVal: '',
        venueParkingVal: '',
        venuePublicTransportVal: '',
        venueDisableVal: '',
        venueAuditDateVal: '',
        venueHousekeepingVal: '',
        venueHousekeepingTxtVal: '',
        venueLightingVal: '',
        venueLightingTxtVal: '',
        venueVentilationVal: '',
        venueVentilationTxtVal: '',
        venueNoiseVal: '',
        venueNoiseTxtVal: '',
        venueHygieneVal: '',
        venueHygieneTxtVal: '',
        venueElectricalVal: '',
        venueElectricalTxtVal: '',
        venueFirstAidVal: '',
        venueFirstAidTxtVal: '',
        venueFireVal: '',
        venueFireTxtVal: '',
        venueSmokingVal: '',
        venueSmokingTxtVal: '',
        venueSpecialTxtVal: '',
    });
    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;
        const newValue = type === 'checkbox' ? checked : value;
        setFormData((prevData) => ({
            ...prevData,
            [name]: newValue
        }));
    };

    const customSelectStyles = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: state.isFocused ? '#d0d0d0' : '#d0d0d0', // Border color changes on focus
            boxShadow: state.isFocused ? '0 0 5px #955cce' : 'none', // Box shadow changes on focus
            '&:hover': {
                borderColor: '#955cce', // Border color changes on hover
            },
        }),
        option: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: state.isFocused ? '#c1acd9' : 'white', // Background color changes on option hover
            color: state.isFocused ? 'white' : 'black',
        }),
    };

    const [isChecked, setIsChecked] = useState(true);
    const [_isPrivate, setIsPrivate] = useState(true)
    const [isPrivateMsg, setIsPrivateMsg] = useState('Class is Private')

    const handleCheckboxChange = () => {
        if (isChecked) {
            setIsChecked(false);
            setIsPrivate(false);
            setIsPrivateMsg('Class is Public')
        } else {
            setIsChecked(true);
            setIsPrivate(true);
            setIsPrivateMsg('Class is Private')
        }
    };

    const qrCode = new QRCodeStyling({
        width: 300,
        height: 300,
        image: QRLogo,
        dotsOptions: {
            color: "#393939",
            type: "classy"
        },
        imageOptions: {
            imageSize: 0.4,
            crossOrigin: "anonymous",
            margin: 5

        },
        backgroundOptions: {
            color: "transparent"
        },
        cornersSquareOptions: {
            type: "square",
            color: "#955CCE",
        }
    });

    return (
        <div>
            <div
                style={{
                    height: '100px',
                    width: '100%',
                }}
            >

            </div>
            <div className="container justify-content-left align-items-center" style={{ position: 'relative', zIndex: 0}}>
                <div className="col-12">
                    <div className="tab" style={{ display: 'flex'}}>
                        <div className="arrow-tab2" style={{ display: 'flex', justifyContent: 'flex-start', zIndex: 10 }}>
                            <div className={activeTab === 'Tab 1' ? 'arrow-tab active' : 'arrow-tab'}
                                 onClick={() => handleTabClick('Tab 1')}>
                                    Details
                            </div>
                        </div>
                        <div className="arrow-tab2" style={{ display: 'flex', justifyContent: 'flex-start', zIndex: 9  }}>
                            <div className={activeTab === 'Tab 2' ? 'arrow-tab active' : 'arrow-tab'}
                                 onClick={() => handleTabClick('Tab 2')}>
                                Modules
                            </div>
                        </div>
                        <div className="arrow-tab2" style={{ display: 'flex', justifyContent: 'flex-start', zIndex: 8 }}>
                            <div className={activeTab === 'Tab 3' ? 'arrow-tab active' : 'arrow-tab'}
                                 onClick={() => handleTabClick('Tab 3')}>
                                Calendar
                            </div>
                        </div>
                        <div className="arrow-tab2" style={{ display: 'flex', justifyContent: 'flex-start', zIndex: 7  }}>
                            <div className={activeTab === 'Tab 4' ? 'arrow-tab active' : 'arrow-tab'}
                                 onClick={() => handleTabClick('Tab 4')}>
                                Venue
                            </div>
                        </div>
                        <div className="arrow-tab2" style={{ display: 'flex', justifyContent: 'flex-start', zIndex: 6 }}>
                            <div className={activeTab === 'Tab 5' ? 'arrow-tab active' : 'arrow-tab'}
                                 onClick={() => handleTabClick('Tab 5')}>
                                Upload
                            </div>
                        </div>
                        <div className="end-tab2" style={{ zIndex: 4 }}>
                            <div className={activeTab === 'Tab 7' ? 'arrow-tab active' : 'arrow-tab'}
                                 onClick={() => handleTabClick('Tab 7')}>
                                Attendance
                            </div>
                        </div>
                    </div>
                    <div id="Tab 1" className="tabcontent">
                        <form onSubmit={handleSubmit}>
                            <div className={"row w-100"} style={{marginTop: 15}}>
                                <div className={"col-5"} style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                    <div ref={QRCode} className={"qrCode"}/>
                                </div>
                                <div className={"col-7"} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                                    <div className={"row w-100"}>
                                        <div className={"col-6"} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-start'}} >
                                            <label htmlFor="nameClass">Name of Class:</label>
                                            <input
                                                id="nameClass"
                                                className="inputText"
                                                type="text"
                                                name="nameClass"
                                                placeholder="Class Name"
                                                onChange={handleInputChange}
                                                title="This is what will appear on the Calendar page.
                                                        Please keep this short and to the convention of the first 3 letters being what has been assigned to you
                                                        eg. TRA for Training Professionals"
                                                required
                                            />
                                        </div>
                                        <div className={"col-6"} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center'}} >
                                            <label htmlFor="comboBox">Template:</label>
                                            <Select
                                                id="comboBox"
                                                options={options}
                                                value={formData.templateVal}
                                                styles={customSelectStyles}
                                                isClearable
                                                isSearchable
                                                placeholder="Select or type..."
                                                title="This is the physical street address where the training will
                                                        predominantly be undertaken and based from"
                                            />
                                        </div>
                                    </div>
                                    <div className={"row w-100"}>
                                        <div className={"col-6"} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start'}} >
                                            <label htmlFor="startDate">Start Date</label>
                                            <input
                                                id="startDate"
                                                type="date"
                                                name="startDate"
                                                title="This is the first day you expect the class to start"
                                                value={formData.startDateVal}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className={"col-6"} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                                            <label htmlFor="finishDate">Finish Date</label>
                                            <input
                                               id="finishDate"
                                               type="date"
                                               name="finishDate"
                                               value={formData.finishDateVal}
                                               title="This is the last day you expect the class to run"
                                               onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                    <div className={"row w-100"}>
                                        <div className={"col-6"} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start'}} >
                                            <label htmlFor="startTime">Start Time</label>
                                            <input
                                                id={"startTime"}
                                                type="time"
                                                name="startTime"
                                                value={formData.startTimeVal}
                                                onChange={handleInputChange}
                                                title="This is the Target Start time of the Class"
                                           />
                                        </div>
                                        <div className={"col-6"} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start'}} >
                                            <label htmlFor="finishTime">Finish Time</label>
                                            <input
                                                id="finishTime"
                                                type="time"
                                                name="finishTime"
                                                value={formData.finishTimeVal}
                                                onChange={handleInputChange}
                                                title="This is the Approximate finish time of the Class"
                                            />
                                        </div>
                                    </div>
                                    <div className={"row w-100"}>
                                        <div className={"col-6"} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start'}} >
                                            <label htmlFor="classMinimum">Class Size</label>
                                            <input
                                                id={"classMinimum"}
                                                type="number"
                                                name="classMinimum"
                                                placeholder="Min"
                                                value={formData.classMinimumVal}
                                                onChange={handleInputChange}
                                                title="This is used to set the minimum number of people you want in a class
                                               to help inform you as to whether you want to cancel the class"
                                            />
                                        </div>
                                        <div className={"col-6"} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start'}} >
                                            <label htmlFor="classMaximum"> </label><br />
                                            <input
                                                id={"classMaximum"}
                                                type="number"
                                                name="classMax"
                                                placeholder="Max"
                                                onChange={handleInputChange}
                                                title="This is the Maximum number of people you want to be able to be booked
                                                    into this class.  This is especially useful if you have less control
                                                    of how many people may have access to the QR code etc."
                                            />
                                        </div>
                                    </div>
                                    <div className={"row w-100"}>
                                        <div className={"col-6"} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start'}} >
                                            <label htmlFor="classCost">Course Cost</label>
                                            <CurrencyInput
                                                className={"currencyInput"}
                                                id="courseCost"
                                                name="courseCost"
                                                placeholder="$0.00"
                                                decimalsLimit={2}
                                                onChange={handleInputChange}
                                                prefix={'$'}
                                                title="This is the amount that this course costs.  When you put this cost in here
                                                a Business or Person can pay it online depending on the payment methods you
                                                have set up.  eg. Credit Card, Stripe etc."
                                            />
                                        </div>
                                        <div className={"col-6"} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-end'}} >
                                            <div className="row-container" style={{display: 'flex', alignItems: 'center'}}>
                                                <label htmlFor="isPrivate" style={{marginRight: 15, marginBottom: 15}}>{isPrivateMsg}</label>
                                                <label className="slide-switch">
                                                    <input
                                                        id="isPrivate"
                                                        type="checkbox"
                                                        checked={isChecked}
                                                        onChange={handleCheckboxChange}
                                                        title="Leave this ticked unless you want to advertise this class for the General Public to book into"
                                                    />
                                                    <span className="slider"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"row"} style={{width: '100%', paddingLeft: 25}}>
                                <div className={"col-6"} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                    <label htmlFor="comboBox">Venue:</label>
                                    <Select
                                        id="comboBox"
                                        options={options}
                                        styles={customSelectStyles}
                                        isClearable
                                        isSearchable
                                        placeholder="Select or type..."
                                        title="This is the physical street address where the training will
                                                predominantly be undertaken and based from"
                                        required
                                    />
                                    <br />
                                    <label htmlFor="comboBox">Business:</label>
                                    <Select
                                        id="comboBox"
                                        options={options}
                                        styles={customSelectStyles}
                                        isClearable
                                        isSearchable
                                        placeholder="Select or type..."
                                        title="This is the Business that is paying for the Training"
                                    />
                                </div>
                                <div className={"col-6"} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                    <label htmlFor="comboBox">Trainer:</label>
                                    <Select
                                        id="comboBox"
                                        options={options}
                                        styles={customSelectStyles}
                                        isClearable
                                        isSearchable
                                        placeholder="Select or type..."
                                        title="This is the person that will be the Assessor for this class"
                                        required
                                    />
                                    <br />
                                    <label htmlFor="comboBox">3rd Party Contract:</label>
                                    <Select
                                        id="comboBox"
                                        options={options}
                                        styles={customSelectStyles}
                                        isClearable
                                        isSearchable
                                        placeholder="Select or type..."
                                        title="This is for if this training is being conducted under a
                                                Third Party Contract."
                                    />
                                </div>
                            </div>
                            <br />
                            <div className={"row w-100"} style={{width: '100%', paddingLeft: 25}}>
                                <div className={"col-4"} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start'}} >
                                    <label htmlFor="aenNumber">AEN Number:</label>
                                    <input
                                        id="aenNumber"
                                       className="inputText"
                                        type="text"
                                        name="aenNumber"
                                        placeholder="HRWL Class AEN"
                                        onChange={handleInputChange}
                                        title="This is only used for High Risk Work Licence Classes.
                                                This number is the Class identifier as it appears in
                                               the High Risk Work Licence Assessors Portal."
                                    />
                                </div>
                                <div className={"col-4"} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start'}} >
                                    <label htmlFor="assessmentMethodr">Assessment Method:</label>
                                            <select
                                                id="assessmentMethod"
                                                name="assessmentMethod"
                                                onChange={handleInputChange}
                                                title="This is the Assessment Method you have used"
                                            >
                                                <option value="Full Assessment">Full Assessment</option>
                                                <option value="RPL">Recognition Of Prior Learning</option>
                                                <option value="CT">Credit Transfer</option>
                                            </select>
                                </div>
                                <div className={"col-4"} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start'}} >
                                    <label htmlFor="deliveryMode">Delivery Mode:</label>
                                            <select
                                                id="deliveryMode"
                                                name="deliveryMode"
                                                onChange={handleInputChange}
                                                title="This is the way the class has been delivered.
                                                Workplace is when delivered in conjunction with an employer
                                                Classroom Based is when they are only doing it in a classroom
                                                External is where they are doing it via correspondence"
                                            >
                                                <option value="workplace">Workplace-based Delivery</option>
                                                <option value="internal">Classroom-based Delivery</option>
                                                <option value="external">Correspondence-based Delivery</option>
                                            </select>
                                </div>
                            </div><br/>
                            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', padding: 25}}>
                                <button type="submit" className={"btn-primary"}>NEXT</button>
                            </div>
                        </form>
                    <div className={"row w-100"} style={{width: '100%', paddingLeft: 25}}>
                        <div className={"col-10"} style={{textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start'}} >
                            <label htmlFor="interestedEmail">Invite someone</label>
                            <input
                                id="interestedEmail"
                                type="email"
                                name="interestedEmail"
                                placeholder="email for invitation"
                                onChange={handleInputChange}
                                title="This is used to email someone all of the information on this particular course.
                                                From here they have all of the information they need to make a decision on whether
                                                they want to book in. They can also pay, put in/upload any information of files
                                                they need to"
                            />
                        </div>
                        <div className={"col-2"} style={{}} >
                            <button type="submit" className={"btn-primary"} style={{marginTop: 25, width: '100%'}}>Send Email</button>
                        </div>
                    </div>
                        <label>
                            for Students that have been Marked Competent in this Class <br/><br/>
                            Statement Link - First Last - Codes
                        </label>
                    </div>

{/* =================== TAB 2 =================*/}

                    <div id="Tab 2" className="tabcontent">
                        <form onSubmit={handleSubmit}>
                            <div className={"row w-100"} style={{marginTop: 15}}>
                                <div className={"col-5"} style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                    {/*<h1 style={{height: '100%'}}>QRcode</h1>*/}
                                    <div ref={QRCode2} className={"qrCode"}/>
                                </div>
                                <div className={"col-7"} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', paddingLeft: 25}}>
                                    <label htmlFor="comboBox" style={{paddingTop: 25}}>Statement Type</label>
                                    <select
                                        name="statementType"
                                        onChange={handleInputChange}
                                        title="This is the way the class has been delivered.
                                                Workplace is when delivered in conjunction with an employer
                                                Classroom Based is when they are only doing it in a classroom
                                                External is where they are doing it via correspondence"
                                        >
                                        <option value="SOA">Statement of Attainment Only (SOA is nationally Recognised Training)</option>
                                        <option value="SOC">Statement Of Completion Only (SOC is NOT nationally Recognised Training</option>
                                        <option value="PCOC">Partial Completion of Certificate (Used for when 1 Third of more of a Certificate is Planned</option>
                                        <option value="Cert">Certificate (Used when delivery of a full certificate is planned)</option>
                                    </select>
                                    <div className={"col-12"} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center'}} >
                                        <label htmlFor="comboBox" style={{paddingTop: 25}}>Template:</label>
                                        <Select
                                            id="comboBox"
                                            options={options}
                                            // value={formData.templateVal}
                                            styles={customSelectStyles}
                                            // styles={colourStyles}
                                            isClearable
                                            isSearchable
                                            placeholder="Select or type..."
                                            title="This is the physical street address where the training will
                                                predominantly be undertaken and based from"
                                        />
                                    </div>
                                    <div
                                        style={{
                                            height: '75px',
                                            width: '100%',
                                            // paddingLeft: '25px',
                                            paddingRight: 0,
                                            marginBottom: '25px',
                                            marginLeft: 0,
                                            marginTop: 50
                                        }}
                                    >
                                        <BorderedComponent name="Custom Text to appear on the Statement" style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-center'}}>
                                            <div className={"row"} >
                                                <div className={"col-12"} style={{ textAlign: 'flex-center', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start'}} >
                                                    <input
                                                        id="txtCustom"
                                                        className="inputText"
                                                        type="text"
                                                        name="txtCustom"
                                                        placeholder="Any text you type here will appear on the statement"
                                                        onChange={handleInputChange}
                                                        title="This is used to put whatever custom text you want onto a Statement.  To allow
                                                        this you have to choose one of the Custom Text Categories then whatever you
                                                        write in here will appear on the Statement.  This is especially useful when
                                                        HR has requested specific information to be covered in a course"
                                                    />
                                                </div>
                                            </div>
                                        </BorderedComponent>
                                    </div>
                                </div>
                            </div>
                            <ModuleTable />
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', padding: 25}}>
                                <button type="button" onClick={() => handleChangeTab('Tab 1')} className={"btn-primary"}>BACK</button>
                                <button type="submit"  className={"btn-primary"}>NEXT</button>
                            </div>
                        </form>
                    </div>

{/* =================== TAB 3 =================*/}

                    <div id="Tab 3" className="tabcontent">
                        <form onSubmit={handleSubmit}>
                            <div className={"row w-100"} style={{marginTop: 15}}>
                                <div className={"col-5"} style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                    <div ref={QRCode2} className={"qrCode"}/>
                                </div>
                                <div className={"col-7"} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                                    <div className={"row w-100"} style={{marginTop: 15}}>
                                        <div className={"col-6"} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', paddingLeft: 25}}>
                                            <label htmlFor="comboBox" style={{paddingTop: 27}}>Start Date for classes</label>
                                            <input
                                                type="date"
                                                value={formData.startDateVal}
                                                name="startDate"
                                                title="This is the first day you expect the classes to start"
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className={"col-6"} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', paddingLeft: 25}}>
                                            <label htmlFor="comboBox" style={{paddingTop: 27}}>Finish Date for classes</label>
                                            <input
                                                    type="date"
                                                    value={formData.finishDateVal}
                                                    name="FinishDate"
                                                    title="This is the last day you expect the classes to run"
                                                    onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                    <div className={"row w-100"} style={{marginTop: 15}}>
                                        <div className={"col-3"} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', paddingLeft: 25}}>
                                                <button type="resetDates" title="This will reset all dates leaving only the Start Date and Finish Date">Reset Dates</button>
                                        </div>
                                        <div className={"col-2"} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', paddingLeft: 25}}>
                                        </div>
                                        <div className={"col-3"} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', paddingLeft: 25}}>
                                            <button type="updateDates" title="Until you hit this button any date changes you have made
                                                                                will be lost if you navigate away from this page.
                                                                                So hit it now!">Update Class Dates</button>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', padding: 25}}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className={"row w-100"} style={{marginTop: 15}}>
                            <div className={"col-6"} style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                            <button type="button" onClick={() => handleChangeTab('Tab 2')} className={"btn-primary"}  title="This button will take you back in the months available to you">BACK</button>
                            </div>
                            <div className={"col-6"} style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                            <button type="submit" className={"btn-primary"} title="This button will advance the months available to you">NEXT</button>
                            </div>
                        </div>
                    </div>

 {/* =================== TAB 4 =================*/}

                <div id="Tab 4" className="tabcontent">
                    <form onSubmit={handleSubmit}>
                        <Row>
                            <Col xs={6} md={6} xl={6} style={{marginTop: 15, textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                <h1 style={{height: '100%'}}>Venue Photo</h1>
                            </Col>
                            <Col xs={6} md={6} xl={6} style={{marginTop: 15, textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                <Row>
                                    <Col xs={12} md={12} xl={12} style={{
                                        marginTop: 15, 
                                        textAlign: 'left',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'flex-center',
                                        alignItems: 'flex-center' }}>
                                        <label htmlFor="comboBox">Training Venue -</label>
                                        <Select
                                            id="comboBox"
                                            value={formData.venueVal}
                                            options={options}
                                            styles={customSelectStyles}
                                            // onChange={handleCombo1Change}
                                            isClearable
                                            isSearchable
                                            placeholder="Venue Description"
                                            title="This is the name of the Physical Venue the training
                                                    is being held at"
                                            required
                                        />
                                    </Col>
                                        <Row>
                                            <Col xs={12} md={12} xl={12} style={{padding: 0, marginLeft: 15, marginTop: 25, textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                <label htmlFor="comboBox">Address</label>
                                                <input
                                                    type="text"
                                                    name="venueAddress1"
                                                    value={formData.venueAddress1Val}
                                                    placeholder="Venue Address"
                                                    onChange={handleInputChange}
                                                    title="This is the address of the venue"
                                                />
                                                <input
                                                    type="text"
                                                    name="venueAddress2"
                                                    value={formData.venueAddress2Val}
                                                    placeholder="Venue Address"
                                                    onChange={handleInputChange}
                                                    title="This is line 2 of the address of the venue"
                                                />
                                            </Col>
                                            <Row>
                                                <Col xs={6} md={6} xl={6} style={{padding: 0, marginLeft: 15, textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                    <input
                                                        type="text"
                                                        name="venueSuburb"
                                                        value={formData.venueSuburbVal}
                                                        placeholder="Suburb"
                                                        onChange={handleInputChange}
                                                        title="This is the Suburb of the venue"
                                                    />
                                                </Col>
                                                <Col xs={2} md={2} xl={2} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                    <input
                                                        type="text"
                                                        name="venueState"
                                                        value={formData.venueStateVal}
                                                        placeholder="State" onChange={handleInputChange}
                                                        title="This is state of the venue"
                                                    />
                                                </Col>
                                                <Col xs={3} md={3} xl={3} style={{textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                    <input
                                                        type="text"
                                                        name="venuePostCode"
                                                        value={formData.venuePostCodeVal}
                                                        placeholder="Post Code"
                                                        onChange={handleInputChange}
                                                        title="This is the postcode of the venue"
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={6} md={6} xl={6} style={{marginTop: 25, textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                    Phone
                                                    <input
                                                        type="text"
                                                        name="venuePhone"
                                                        value={formData.venuePhoneVal}
                                                        placeholder="Phone"
                                                        onChange={handleInputChange}
                                                        title="This is the Phone number for the venue"
                                                    />
                                                </Col>
                                                <Col xs={6} md={6} xl={6} style={{marginTop: 25, textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                    Web
                                                    <input
                                                        type="text"
                                                        name="venueWeb"
                                                        value={formData.venueWebVal}
                                                        placeholder="Website URL"
                                                        onChange={handleInputChange}
                                                        title="This is the web site for the venue"
                                                    />
                                                </Col>
                                            </Row>
                                        </Row>

                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6} md={6} xl={6} style={{marginTop: 25, textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                Public Transport
                                <input
                                    type="text"
                                    name="venuePublicTransport"
                                    value={formData.venuePublicTransportVal}
                                    placeholder="Parking"
                                    onChange={handleInputChange}
                                    title="This is special instructions for finding parking at the venue"
                                />
                                Lunch / Meals
                                <input
                                    type="text"
                                    name="venueMeals"
                                    value={formData.venueMealsVal}
                                    placeholder="Meal Accessibility"
                                    onChange={handleInputChange}
                                    title="This is to give people an idea of what food facilities will be
                                                available to them at the venue"
                                />
                            </Col>
                            <Col xs={6} md={6} xl={6} style={{marginTop: 25, textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                Disabled Access
                                <input
                                    type="text"
                                    name="venueDisable"
                                    value={formData.venueDisableVal}
                                    placeholder="Call ahead"
                                    onChange={handleInputChange}
                                    title="This is special instructions for Disabled Access at the venue"
                                />
                                Public Transport
                                <input
                                    type="text"
                                    name="venuePublicTransport"
                                    value={formData.venuePublicTransportVal}
                                    placeholder="Parking"
                                    onChange={handleInputChange}
                                    title="This is special instructions for finding parking at the venue"
                                />
                            </Col>
                        </Row>
                        <h1 style={{marginTop: 35, marginBottom: 25, textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-left', alignItems: 'flex-left'}}>Venue WHS Audit </h1>
                        <Row>
                            <Col xs={4} md={4} xl={4} style={{marginTop: 25, textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                            Auditor - FirstName Lastname
                            </Col>
                            <Col xs={4} md={4} xl={4} style={{marginTop: 25, textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                Phone - mobile#
                            </Col>
                            <Col xs={1} md={1} xl={1} style={{marginTop: 25, textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                Date -
                            </Col>
                            <Col xs={3} md={3} xl={3} style={{marginTop: 25, textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                <input
                                type="date"
                                name="auditDate"
                                value={formData.venueAuditDateVal}
                                title="This is the date that the Audit was done.  This will need
                                                to be re-updated at least quarterly"
                                onChange={handleInputChange}
                            />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={3} md={3} xl={3} style={{marginTop: 25, textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                HOUSEKEEPING
                                (floors, Aisles, Work Areas)
                            </Col>
                            <Col xs={1} md={1} xl={1} style={{marginTop: 25, textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                Suitable?
                                <input
                                    type="checkbox"
                                    name="housekeepingSuitable"
                                    value={formData.venueHousekeepingVal}
                                    defaultChecked="unchecked"
                                    onChange={handleInputChange}
                                    title="When you are satisfied this is not a Danger to your students
                                                please tick this"
                                />
                            </Col>
                            <Col xs={8} md={8} xl={8} style={{marginTop: 25, textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                <input
                                    type="text"
                                    name="housekeepingComments"
                                    value={formData.venueHousekeepingTxtVal}
                                    placeholder="What stood out or needs fixing?"
                                    onChange={handleInputChange}
                                    title="This is for commenting on the general state of the venues
                                                Housekeeping and listing anything that may need fixing"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={3} md={3} xl={3} style={{marginTop: 25, textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                Lighting (Quality, Suitability)
                            </Col>
                            <Col xs={1} md={1} xl={1} style={{marginTop: 25, textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                Suitable?
                                <input
                                    type="checkbox"
                                    name="lightingSuitable"
                                    value={formData.venueLightingVal}
                                    defaultChecked="unchecked"
                                    onChange={handleInputChange}
                                    title="When you are satisfied the lighting is sufficient
                                                please tick this"
                                />
                            </Col>
                            <Col xs={8} md={8} xl={8} style={{marginTop: 25, textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                <input
                                    type="text"
                                    name="lightingComments"
                                    value={formData.venueLightingTxtVal}
                                    placeholder="What stood out or needs fixing?"
                                    onChange={handleInputChange}
                                    title="This is to ensure that lighting is sufficient for people to
                                                undertake exams or learn at the venue"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={3} md={3} xl={3} style={{marginTop: 25, textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                VENTILATION / AIR CONDITIONING (Source, Adequacy, Condition)
                            </Col>
                            <Col xs={1} md={1} xl={1} style={{marginTop: 25, textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                Suitable?
                                <input
                                    type="checkbox"
                                    name="ventilationSuitable"
                                    value={formData.venueVentilationVal}
                                    defaultChecked="unchecked"
                                    onChange={handleInputChange}
                                    title="When you are satisfied this supports a learning environment
                                                please tick this"
                                />
                            </Col>
                            <Col xs={8} md={8} xl={8} style={{marginTop: 25, textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                <input
                                    type="text"
                                    name="ventialtionComments"
                                    value={formData.venueVentilationTxtVal}
                                    placeholder="What stood out or needs fixing?"
                                    onChange={handleInputChange}
                                    title="This is to ensure that Temperature Ranges etc are able to be
                                                kepy within ranges that support learning and limit distractions
                                                at the venue"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={3} md={3} xl={3} style={{marginTop: 25, textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                NOISE (Source, Intensity)
                            </Col>
                            <Col xs={1} md={1} xl={1} style={{marginTop: 25, textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                Suitable?
                                <input
                                    type="checkbox"
                                    name="noiseSuitable"
                                    value={formData.venueNoiseVal}
                                    defaultChecked="unchecked"
                                    onChange={handleInputChange}
                                    title="When you are satisfied that there are no un-nessecary distractions
                                                please tick this"
                                />
                            </Col>
                            <Col xs={8} md={8} xl={8} style={{marginTop: 25, textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                <input
                                    type="text"
                                    name="noiseComments"
                                    value={formData.venueNoiseTxtVal}
                                    placeholder="What stood out or needs fixing?"
                                    onChange={handleInputChange}
                                    title="This is to ensure that the level of noise at the venue will support
                                                the learning process and not allow or create too many distractions."
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={3} md={3} xl={3} style={{marginTop: 25, textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                HYGIENE / Sanitation (Facilities, Cleaning, Disposal)
                            </Col>
                            <Col xs={1} md={1} xl={1} style={{marginTop: 25, textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                Suitable?
                                <input
                                    type="checkbox"
                                    name="hygieneSuitable"
                                    value={formData.venueHygieneVal}
                                    defaultChecked="unchecked"
                                    onChange={handleInputChange}
                                    title="When you are satisfied that the general Hygiene and Sanitation are good
                                                please tick this"
                                />
                            </Col>
                            <Col xs={8} md={8} xl={8} style={{marginTop: 25, textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                <input
                                    type="text"
                                    value={formData.venueHygieneTxtVal}
                                    name="hygieneComments"
                                    placeholder="What stood out or needs fixing?"
                                    onChange={handleInputChange}
                                    title="This is to ensure that the level of clenliness of the venue doesn't put
                                                people at risk."
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={3} md={3} xl={3} style={{marginTop: 25, textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                ELECTRICAL (Power Outlets, Cords, Connections, Switches)
                            </Col>
                            <Col xs={1} md={1} xl={1} style={{marginTop: 25, textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                Suitable?
                                <input
                                    type="checkbox"
                                    value={formData.venueElectricalVal}
                                    name="electricalSuitable"
                                    defaultChecked="unchecked"
                                    onChange={handleInputChange}
                                    title="When you are satisfied that there are no un-nessecary dangers due to
                                                electrical Dangers please tick this"
                                />
                            </Col>
                            <Col xs={8} md={8} xl={8} style={{marginTop: 25, textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                <input
                                    type="text"
                                    value={formData.venueElectricalTxtVal}
                                    name="electricalComments"
                                    placeholder="What stood out or needs fixing?"
                                    onChange={handleInputChange}
                                    title="This is to ensure that the people are not put at unreasonable risk
                                                due to electrical installations."
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={3} md={3} xl={3} style={{marginTop: 25, textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                FIRST AID (Suitably Located, Stocked, Clean)
                            </Col>
                            <Col xs={1} md={1} xl={1} style={{marginTop: 25, textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                Suitable?
                                <input
                                    type="checkbox"
                                    name="firstaidSuitable"
                                    value={formData.venueFirstAidVal}
                                    defaultChecked="unchecked"
                                    onChange={handleInputChange}
                                    title="When you are satisfied that there are sufficient First Aid supplies
                                                available please tick this"
                                />
                            </Col>
                            <Col xs={8} md={8} xl={8} style={{marginTop: 25, textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                <input
                                    type="text"
                                    name="firstaidComments"
                                    value={formData.venueFirstAidTxtVal}
                                    placeholder="What stood out or needs fixing?"
                                    onChange={handleInputChange}
                                    title="This is to ensure that the level of First Aid Facilities are able to
                                                handle the types of emergencies you may expect to encounter while
                                                conducting training."
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={3} md={3} xl={3} style={{marginTop: 25, textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                FIRE RISK (Extinguishers, Alarms, Flamable Materials)
                            </Col>
                            <Col xs={1} md={1} xl={1} style={{marginTop: 25, textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                Suitable?
                                <input
                                    type="checkbox"
                                    name="fireSuitable"
                                    value={formData.venueFireVal}
                                    defaultChecked="unchecked"
                                    onChange={handleInputChange}
                                    title="When you are satisfied that there are sufficient Fire Fighting Assets
                                                for the training you are conducting please tick this"
                                />
                            </Col>
                            <Col xs={8} md={8} xl={8} style={{marginTop: 25, textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                <input
                                    type="text"
                                    name="fireComments"
                                    value={formData.venueFireTxtVal}
                                    placeholder="What stood out or needs fixing?"
                                    onChange={handleInputChange}
                                    title="This is to ensure that the fire fighting assets you would need to fight
                                                fires you would reasonably expect to result from training."
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={3} md={3} xl={3} style={{marginTop: 25, textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                SMOKING POLICY (Parties Advised, Enforced)
                            </Col>
                            <Col xs={1} md={1} xl={1} style={{marginTop: 25, textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                Suitable?
                                <input
                                    type="checkbox"
                                    name="smokingSuitable"
                                    value={formData.venueSmokingtVal}
                                    defaultChecked="unchecked"
                                    onChange={handleInputChange}
                                    title="When you are satisfied that the smoking policy has been identified
                                                communicated and implemented please tick this"
                                />
                            </Col>
                            <Col xs={8} md={8} xl={8} style={{marginTop: 25, textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                <input
                                    type="text"
                                    name="smokingComments"
                                    value={formData.venueSmokingTxtVal}
                                    placeholder="What stood out or needs fixing?"
                                    onChange={handleInputChange}
                                    title="This is to ensure that the Smoking Policies for the Venue are Communicated
                                                and being adhered to within the Venue."
                                />
                            </Col>
                        </Row>
                        <Row style={{marginTop: 35, padding: 25}}>
                            Special Notes / Follow Up:
                            <input
                                type="text"
                                name="specialComments"
                                value={formData.venueSpecialTxtVal}
                                placeholder="What needs to be followed up?"
                                onChange={handleInputChange}
                                title="This is to ensure that anything that needs to be followed up for
                                                rectification is being listed and passed on to someone that can
                                                action the change."
                            />
                        </Row>
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', padding: 25}}>
                            <button type="button" onClick={() => handleChangeTab('Tab 2')} className={"btn-primary"}>BACK</button>
                            <button type="submit"className={"btn-primary"}>NEXT</button>
                        </div>
                    </form>
                </div>

 {/* =================== TAB 5 =================*/}

                    <TabFive/>

                    {/*<div id="Tab 5" className="tabcontent">*/}
                    {/*    <Row>*/}
                    {/*        <Col xs={5} md={5} xl={5} style={{marginTop: 15, textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>*/}
                    {/*            /!*<h1 style={{height: '100%'}}>QRcode</h1>*!/*/}
                    {/*        </Col>*/}
                    {/*        <Col xs={6} md={6} xl={6} style={{*/}
                    {/*            marginTop: 50,*/}
                    {/*            textAlign: 'left',*/}
                    {/*            display: 'flex',*/}
                    {/*            flexDirection: 'column',*/}
                    {/*            justifyContent: 'flex-center',*/}
                    {/*            alignItems: 'flex-center'*/}
                    {/*        }}>*/}

                    {/*            /!*<Search />*!/*/}
                    {/*            <input*/}
                    {/*                type="text"*/}
                    {/*                name="Class details"*/}
                    {/*                value={formData.venueSmokingTxtVal}*/}
                    {/*                placeholder="Enter the class ID here"*/}
                    {/*                onChange={handleInputChange}*/}
                    {/*                title="This is to ensure that only the students and modules for the class are loaded"*/}
                    {/*            />*/}
                    {/*            <button style={{width: '200px', marginTop: 0}}>Fetch Class Details</button>*/}
                    {/*        </Col>*/}
                    {/*    </Row>*/}
                    {/*    <Row style={{marginTop: 50}}>*/}
                    {/*        <table>*/}
                    {/*            <thead>*/}
                    {/*            <tr className={'tr2'}>*/}
                    {/*                <th>Student</th>*/}
                    {/*                <th>Mobile</th>*/}
                    {/*                <th>Billing</th>*/}
                    {/*                <th>USI</th>*/}
                    {/*                <th>Competent</th>*/}
                    {/*                <th>Actions</th>*/}
                    {/*            </tr>*/}
                    {/*            </thead>*/}
                    {/*            <tbody>*/}
                    {/*            {studentList.map((row, index) => (*/}
                    {/*                <React.Fragment key={index}>*/}
                    {/*                    <tr  onClick={() => toggleAccordion(index)} key={index} className={index % 2 === 0 ? 'dark-grey' : 'light-grey'}>*/}
                    {/*                        <td>{row.heading}</td>*/}
                    {/*                        <td>{row.mobile}</td>*/}
                    {/*                        <td>{row.billing}</td>*/}
                    {/*                        <td>{row.usi}</td>*/}
                    {/*                        <td>{row.competent}</td>*/}
                    {/*                        <td>{row.actions}</td>*/}
                    {/*                    </tr>*/}
                    {/*                    {expandedRows[index] && (*/}
                    {/*                        <tr >*/}
                    {/*                            <td colSpan={12} style={{paddingTop: 0, padding: 0}} >*/}
                    {/*                                <table style={{paddingTop: 5, border: 0, width: '100%', paddingLeft: 0, paddingRight: 0}} key={index} className={index % 2 === 0 ? 'dark-grey' : 'light-grey'}>*/}
                    {/*                                    <thead className={'tr2'}  >*/}
                    {/*                                    <tr className={'tr2'}  >*/}
                    {/*                                        <th style={{paddingLeft: 100, width: '20%'}} >Code</th>*/}
                    {/*                                        <th style={{textAlign: 'left', paddingLeft: 50, width: '40%'}} >Name</th>*/}
                    {/*                                        <th style={{textAlign: 'left', paddingLeft: 50, width: '40%'}} >Evidence</th>*/}
                    {/*                                    </tr>*/}
                    {/*                                    </thead>*/}
                    {/*                                    <tbody>*/}
                    {/*                                    {row.extraLineItems.map((item, itemIndex) => (*/}
                    {/*                                        <tr style={{paddingTop: 2, paddingBottom: 2}}>*/}
                    {/*                                            <td style={{paddingLeft: 100, width: '20%'}} >{item.code}</td>*/}
                    {/*                                            <td style={{textAlign: 'left', paddingLeft: 50, width: "40%"}} >{item.name}</td>*/}
                    {/*                                            <td style={{textAlign: 'left', paddingLeft: 50, width: '40%'}} >{item.evidence}</td>*/}
                    {/*                                        </tr>*/}
                    {/*                                    ))}*/}
                    {/*                                    </tbody>*/}
                    {/*                                </table>*/}
                    {/*                            </td>*/}
                    {/*                        </tr>*/}
                    {/*                    )}*/}
                    {/*                </React.Fragment>*/}
                    {/*            ))}*/}
                    {/*            </tbody>*/}
                    {/*        </table>*/}
                    {/*    </Row>*/}
                    {/*    <Upload />*/}
                    {/*</div>*/}

 {/* =================== TAB 6 =================*/}


                    {/*<div id="Tab 6" className="tabcontent">*/}
                    {/*    <h4>Tab 6</h4>*/}
                    {/*    <p>Stuff Here...</p>*/}
                    {/*</div>*/}

 {/* =================== TAB 7 =================*/}

                    <div id="Tab 7" className="tabcontent">
                        <h4>Tab 7</h4>
                        <p>Stuff Here...</p>
                    </div>
                </div>

            </div>


        </div>
    );
}

// =============================== To Do =================================== //

// Rogue pixel hunt
// adjust for tablet view

// =============================== Bugs =================================== //

// Tabs need to have Z Value reset to incorporate Footer
// Not clickable under footer
