import React, { useEffect, useRef } from 'react';
import QRCodeStyling from "qr-code-styling";
import QRLogo from 'Images/QRLogo.png'
import SpacerDiv from 'Components/SpacerDiv';

interface CalendarContentProps {
  formData: any;
  setFormData: any;
  url: string;
  // eslint-disable-next-line no-unused-vars
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, name: string) => void;
  // eslint-disable-next-line no-unused-vars
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  // eslint-disable-next-line no-unused-vars
  handleSelectChange: (fieldName: string, selectedOption: any) => void;
}

const CalendarContent: React.FC<CalendarContentProps> = ({
  formData,
  url,
  handleInputChange,
  handleSubmit,
}) => {
  const QRCode = useRef<HTMLDivElement>(null);
  const qrCodeInstance = useRef<QRCodeStyling | null>(null);

  useEffect(() => {
    qrCodeInstance.current = new QRCodeStyling({
      width: 300,
      height: 300,
      image: QRLogo,
      dotsOptions: {
        color: "#393939",
        type: "classy",
      },
      imageOptions: {
        imageSize: 0.4,
        crossOrigin: "anonymous",
        margin: 5,
      },
      backgroundOptions: {
        color: "transparent",
      },
      cornersSquareOptions: {
        type: "square",
        color: "#955CCE",
      },
    });
  
    // Clear the QR code container before appending
    if (QRCode.current) {
      QRCode.current.innerHTML = "";
      qrCodeInstance.current.append(QRCode.current);
    }
  }, []);
  
  useEffect(() => {
    if (qrCodeInstance.current) {
      qrCodeInstance.current.update({
        data: url,
      });
    }
  }, [url]);
  

  useEffect(() => {
    if (qrCodeInstance.current) {
      qrCodeInstance.current.update({
        data: url,
      });
    }
  }, [url]);

  return (
    <form 
      className="form-container"
      id="form-2"
      onSubmit={handleSubmit}
    >
      <div className='form-row'>

        <div ref={QRCode} className={"qrCode"} />

        <div className='form-column no-gap full-width top'>
          <SpacerDiv rem={1}/>
          <label className='align-left instruction-text'  htmlFor="startDate">Start Date</label>
          <input
            id="startDate"
            type="date"
            name="startDate"
            value={formData.startDateVal}
            onChange={(e) => handleInputChange(e, "calendar")}
          />
          <SpacerDiv rem={1}/>
          <button 
            type='button' 
            className='btn-add'
            style={{ width: "50%", minWidth: "150px"}}
            onClick={() => console.log('Reset dates button clicked')}
          >
            Reset Dates
          </button>
        </div>
        
        <div className='form-column no-gap full-width top'>
          <SpacerDiv rem={1}/>
          <label className='align-left instruction-text'  htmlFor="startDate">Finish Date</label>
          <input
            id="endDate"
            type="date"
            name="endDate"
            value={formData.endDateVal}
            onChange={(e) => handleInputChange(e, "calendar")}
          />
          <SpacerDiv rem={1}/>
          <button 
            type='button' 
            className='btn-add'
            style={{ width: "50%", minWidth: "150px"}}
            onClick={() => console.log('Update dates button clicked')}
          >
            Update Dates
          </button>
        </div>
      </div>
      
    </form>
  );
};

export default CalendarContent;